import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const imoney8888_diffPower = createIndicator({
  displayName: '金雞指標',
  id: 'imoney8888-diffPower',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const c = this.PineJS.Std.close(this._context)
      const nc = this._context.new_var(c)

      const ma5 = this.PineJS.Std.sma(nc, 5, this._context)
      const ma10 = this.PineJS.Std.sma(nc, 10, this._context)

      const diff = ((ma5 - ma10) / ma5) * 10
      const diff_array = this._context.new_var(diff)
      diff_array.get(2)

      const colorIndex = () => {
        if (diff >= 0 && diff >= diff_array.get(1)) {
          return 0
        }
        if (diff >= 0 && diff < diff_array.get(1)) {
          return 1
        }
        if (diff < 0 && diff <= diff_array.get(1)) {
          return 2
        }
        if (diff < 0 && diff > diff_array.get(1)) {
          return 3
        }
      }

      return [diff, colorIndex()]
    },
  },
  metainfo: {
    is_price_study: !1,
    _metainfoVersion: 42,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多方主力',
          },
          1: {
            name: '多方主力',
          },
          2: {
            name: '空方主力',
          },
          3: {
            name: '空方主力',
          },
        },
      },
    },
    defaults: {
      styles: {
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
      },
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#fe9700',
              width: 0,
              style: 0,
            },
            1: {
              color: '#fe9700',
              width: 0,
              style: 0,
            },
            2: {
              color: '#ffffff',
              width: 0,
              style: 0,
            },
            3: {
              color: '#ffffff',
              width: 0,
              style: 0,
            },
          },
        },
      },
      inputs: {},
    },
    styles: {
      vol: {
        title: '能量',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [],
  },
})
