import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const rich888_ma = createIndicator({
  displayName: '移動平均 [5日/10日/20日/60日]',
  id: 'rich888-ma',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma1 = this.PineJS.Std.sma(close_array, 5, this._context)
      const sma2 = this.PineJS.Std.sma(close_array, 20, this._context)
      const sma3 = this.PineJS.Std.sma(close_array, 60, this._context)

      return [sma1, sma2, sma3]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#924e48',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#36dad8',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ef319e',
        },
      },
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '5日',
        histogramBase: 0,
      },
      plot_1: {
        title: '20日',
        histogramBase: 0,
      },
      plot_2: {
        title: '60日',
        histogramBase: 0,
      },
    },

    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
