import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import SymbolCardHoldingProfitBody from '~/modules/investment-consultant/symbolList/body/SymbolCardHoldingProfitBody'
import SymbolRankingContent from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar'
import SymbolBrickBody from '~/modules/investment-consultant/symbolList/body/SymbolCardBrickBody'
import SymbolCanrdTrendChart from '~/modules/investment-consultant/symbolList/body/SymbolCardTrendChart'
import SymbolOverviewChart from '~/modules/investment-consultant/symbolList/overView/SymbolOverviewChart'
import SymbolSimpleCard from '~/modules/investment-consultant/symbolList/body/SymbolCardSimpleBody'
import SimpleQuoteBodyItem from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem'
import IntradayTrendChart from '~/modules/trendChart'
import { PriceGauges } from '../eurex/_private/component/Gauges'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useSnapshot } from 'valtio'
import { investmentStore } from './investment_TopBar'
import SymbolTransactionBody from '~/modules/investment-consultant/symbolList/body/SymbolCardTransactionBody'
import TemperatureGauges from '~/modules/investment-consultant/gaugus/TemperatureGauges'
import LinearGauges from '~/modules/investment-consultant/gaugus/LinearGauges'
import { Tabs } from '@mantine/core'
import SymbolHoldingProfitHeader from '~/modules/investment-consultant/symbolList/header/SymbolHoldingProfitHeader'
import SymbolRankingHeader from '~/modules/investment-consultant/symbolList/header/SymbolRankingHeader'
import QuoteDetail from '../win988/component/QuoteDetail'
import { QuoteBody } from '../win988/component/SymbolQuoteList'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'

const symbols = ['2303', 'YM-1', '6143', '2014', '6116', '3443', '3034']
const demoSymbol = ['2330', '2609']
export const Investment_SidePane1 = memo<ReactProps>(function Investment_SidePane1() {
  const theme = useThemeStore(s => s.theme)

  const currentSymbol = useSnapshot(investmentStore).currentSymbol

  return (
    <classes.container className={theme}>
      <Tabs
        defaultValue='gallery'
        orientation='vertical'
        css={fill}
      >
        <Tabs.List>
          <Tabs.Tab value='gallery'>走勢圖報價</Tabs.Tab>
          <Tabs.Tab value='messages'>Messages</Tabs.Tab>
          <Tabs.Tab value='settings'>Settings</Tabs.Tab>
          <Tabs.Tab value='aaa'>Settings</Tabs.Tab>
          <Tabs.Tab value='bbb'>商品組合</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='gallery'>
          <ComponentGroup1 />
        </Tabs.Panel>

        <Tabs.Panel value='messages'>
          <ComponentGroup2 />
        </Tabs.Panel>

        <Tabs.Panel value='settings'>
          <ComponentGroup3 />
        </Tabs.Panel>

        <Tabs.Panel value='aaa'>
          <ComponentGroup4 />
        </Tabs.Panel>
        <Tabs.Panel value='bbb'>
          <classes.pane>
            <classes.item
              css={css`
                height: 600px;
              `}
            >
              <SymbolPlatformBase.Display
                symbol={symbols}
                watchListGroup={'datatree_group_1'}
                listTypeSwitch={false}
              />
            </classes.item>
          </classes.pane>
        </Tabs.Panel>
      </Tabs>
    </classes.container>
  )
})

export const ComponentGroup1 = memo<ReactProps>(function ComponentGroup1() {
  return (
    <classes.pane>
      多商品走勢圖
      <classes.item
        css={css`
          width: 100%;
          height: 232px;
        `}
      >
        <SymbolOverviewChart.Display symbol={['3693', '2609', 'TX-1']} />
      </classes.item>
      單商品走勢圖
      <classes.item
        css={css`
          width: 100%;
          height: 172px;
        `}
      >
        <IntradayTrendChart
          symbol={'TX-1'}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </classes.item>
    </classes.pane>
  )
})

export const ComponentGroup2 = memo<ReactProps>(function ComponentGroup2() {
  return (
    <classes.pane>
      標題報價(一般)
      <classes.item>
        <TitleSymbolQuote.Default symbol={'2330'} />
      </classes.item>
      簡易報價(成交量)
      <classes.item>
        <TitleSymbolQuote.DefaulVolume symbol={'2330'} />
      </classes.item>
      簡易報價(預估量)
      <classes.item>
        <TitleSymbolQuote.EstimatedVol
          symbol={'2330'}
          days={5}
          unit={'張'}
        />
      </classes.item>
      簡易報價(開高低收)
      <classes.item>
        <TitleSymbolQuote.Ohlc symbol={'2330'} />
      </classes.item>
      商品詳細報價資訊
      <classes.item>
        <QuoteDetail.Display symbol={['2330']} />
      </classes.item>
    </classes.pane>
  )
})

export const ComponentGroup3 = memo<ReactProps>(function ComponentGroup3() {
  const currentSymbol = useSnapshot(investmentStore).currentSymbol

  useEffect(() => {
    signalrStore2.addQuote(['2330'])
    return () => {
      signalrStore2.removeQuote(['2330'])
    }
  }, [JSON.stringify(['2330'])])

  const value = signalrHooks2?.useQuotes(['2330'])
  return (
    <classes.pane>
      尾部漸層報價專
      <classes.item>
        <QuoteBody quote={value[0]} />
      </classes.item>
      走勢圖報價磚
      <classes.item>
        <SymbolCanrdTrendChart.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      一般報價磚
      <classes.item>
        <SimpleQuoteBodyItem.Default
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      部位持倉報價磚
      <classes.item>
        <SymbolTransactionBody.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
          bs='B'
        />
      </classes.item>
      持有成本
      <classes.item>
        <SymbolHoldingProfitHeader.DisplayStyled1 />
        <SymbolCardHoldingProfitBody.DisplayStyled1
          symbol={demoSymbol[0]}
          averagePrice={380}
          currentSymbol={currentSymbol}
          qty={1}
          pnl={1}
          marketPrice={400}
          lastModifiedDatetime='2023/12/31'
        />
      </classes.item>
      持有成本
      <classes.item>
        <SymbolHoldingProfitHeader.DisplayStyled2 />
        <SimpleQuoteBodyItem.Holding
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
          entryPrice={380}
          equity={1}
          profit={(530 - 480) * 1000}
          date='2023/12/31'
        />
      </classes.item>
      商品趨勢強弱
      <classes.item>
        <SymbolRankingHeader.Standard countArray={[5, 10, 20, 60]} />
        <SymbolRankingContent.standard.Display
          symbol={demoSymbol[0]}
          countArray={[5, 10, 20, 60]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      商品趨勢強弱
      <classes.item>
        <SymbolRankingHeader.Simple countArray={[5, 10, 20, 60]} />
        <SymbolRankingContent.simple.Display
          symbolNumber={demoSymbol[0]}
          countArray={[5, 10, 20, 60]}
          useChart={store.charting}
        />
      </classes.item>
      方塊報價
      <classes.item css={flex.h.default}>
        <SymbolBrickBody.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
        <SymbolBrickBody.Display
          symbol={demoSymbol[1]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      簡易報價
      <classes.item>
        <SymbolSimpleCard.Display symbol={demoSymbol[0]} />
      </classes.item>
    </classes.pane>
  )
})
export const ComponentGroup4 = memo<ReactProps>(function ComponentGroup4() {
  return (
    <classes.pane>
      <classes.item>
        <TemperatureGauges.Display currentValue={30} />
      </classes.item>
      <classes.item>
        <LinearGauges.Display currentValue={0.7} />
      </classes.item>

      <classes.item css={classCss.chartHeight}>
        <SymbolRankingHorizontalBar.Display
          symbols={symbols}
          type='changePercent'
          nameLabelWidth={30}
        />
      </classes.item>
      <classes.item css={classCss.chartHeight}>
        <SymbolRankingHorizontalBar.Display
          symbols={symbols}
          type='volume'
          nameLabelWidth={30}
        />
      </classes.item>
    </classes.pane>
  )
})

const classes = {
  container: styled.div`
    ${fill_vertical_cross_center};
    gap: 18px;
    padding: 8px;
    height: 100%;
    &.dark {
      background-color: #252525;
      color: #ffffff;
    }
    &.light {
      background-color: #ffffff;
      color: #252525;
    }
  `,
  pane: styled.div`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 8px;
    padding: 4px;
    width: 336px;
  `,
  item: styled.div`
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    gap: 2px;
    padding: 2px;
    //box-shadow: 0 0 4px 2px #dedede;
  `,
}

const classCss = {
  baseWidth: css`
    width: 336px;
  `,
  baseHeight: css`
    height: 40px;
  `,
  baseFill: css`
    height: 40px;
    width: 336px;
  `,
  chartHeight: css`
    min-height: 200px;
  `,
}
