import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'
import { LoadingStatus } from './LoadingStatus'
import { strategyPerformanceStyleds } from './strategyPerformanceStyled'
import { css } from '@emotion/react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { GodPerformanceManager } from '../godPerformanceManager'

const ACCOUNT_NAME = 'god'
const ACCOUNT_NAME_DEV = 'mengs_test'
const SYMBOL = 'TX-1'
const START_DATETIME = '2023-01-01 00:00:00'

/** 交易詳細資料與結論 */
export const GodPerformance = memo<ReactProps>(function GodPerformance() {
  const options = {
    symbol: SYMBOL,
    beginDatetime: START_DATETIME,
  }
  const config = {
    refreshInterval: 5000,
  }

  const quote = useSnapshot(signalrStore2.values.quote)[SYMBOL]
  const executions = (useVirtualTransaction(ACCOUNT_NAME, options, config) ?? []).reverse()
  const performance = new GodPerformanceManager(executions)
  const openPositions = performance.openPositions.map(pos => {
    const close = quote?.close ?? 0
    pos.unrealizedPnL =
      pos.side === 'long' ? (close - pos.averagePrice) * 200 : (pos.averagePrice - close) * 200
    return pos
  })

  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const intraDate = dayAPI(intraday).format('MM-DD')
  const loadingState = performance.closedTrades.length > 0

  return (
    <strategyPerformanceStyleds.container>
      {/* Open Interest */}
      <strategyPerformanceStyleds.title backgroundFill='#1976d2'>
        部位資訊
      </strategyPerformanceStyleds.title>

      <strategyPerformanceStyleds.openPositionTableHeader
        css={css`
          grid-template-columns: repeat(4, 25%);
        `}
      >
        <strategyPerformanceStyleds.value>部位方向</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>數量</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>均價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>浮動損益</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.openPositionTableHeader>

      {openPositions.map((position, index) => {
        const side = position.side === 'long' ? '做多' : '做空'
        const sideNumber = position.side === 'long' ? 1 : -1
        const quantityWithSide = sideNumber * position.quantity
        return (
          <strategyPerformanceStyleds.closedTradeTableContent
            key={index}
            css={css`
              ${fill_horizontal_all_center};
              height: 36px;
              display: grid;
              grid-template-columns: repeat(4, 25%);
              & > div {
                ${fill_horizontal_all_center};
              }
            `}
          >
            <strategyPerformanceStyleds.position value={quantityWithSide}>
              {side}
            </strategyPerformanceStyleds.position>

            <strategyPerformanceStyleds.position value={quantityWithSide}>
              {position.quantity}
            </strategyPerformanceStyleds.position>

            <strategyPerformanceStyleds.position value={quantityWithSide}>
              {position.averagePrice}
            </strategyPerformanceStyleds.position>

            <strategyPerformanceStyleds.position value={position.unrealizedPnL ?? 0}>
              {position.unrealizedPnL}
            </strategyPerformanceStyleds.position>
          </strategyPerformanceStyleds.closedTradeTableContent>
        )
      })}

      {/* 當日交易明細 */}
      <strategyPerformanceStyleds.title backgroundFill='#1976d2'>
        {intraDate.replace('-', '/')}當日平倉明細
      </strategyPerformanceStyleds.title>

      <strategyPerformanceStyleds.closedTradeTableHeader>
        <strategyPerformanceStyleds.value>平倉時間</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>部位</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>開倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>平倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>損益</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.closedTradeTableHeader>

      <strategyPerformanceStyleds.intradayContent>
        {loadingState &&
          performance
            .getClosedTradesByDate(intraDate)
            .reverse()
            .map((trade, index) => {
              const positionDisplay =
                trade.side === 'long' ? '做多' : trade.side === 'short' ? '做空' : ''
              const sideValue = trade.side === 'long' ? 1 : -1
              return (
                <strategyPerformanceStyleds.positionBody key={index}>
                  <strategyPerformanceStyleds.value>
                    {dayAPI(trade.closeTime).toISOString().slice(11, 18)}
                  </strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.value>
                    <strategyPerformanceStyleds.position value={sideValue}>
                      {positionDisplay}
                    </strategyPerformanceStyleds.position>
                  </strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.value>
                    {trade.openPrice}
                  </strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.value>
                    {trade.closePrice}
                  </strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.profit value={trade.pnl}>
                    {trade.pnl}
                  </strategyPerformanceStyleds.profit>
                </strategyPerformanceStyleds.positionBody>
              )
            })}
        {!loadingState && <LoadingStatus />}
      </strategyPerformanceStyleds.intradayContent>

      {/* 近月累積績效 */}
      <strategyPerformanceStyleds.title backgroundFill='#722c2c'>
        近月累積績效
      </strategyPerformanceStyleds.title>
      <strategyPerformanceStyleds.monthProfitHeader>
        <strategyPerformanceStyleds.value>交易月份</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>交易筆數</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>總獲利</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.monthProfitHeader>

      <strategyPerformanceStyleds.monthInfoContent>
        {loadingState &&
          performance.monthlyStatistics.map((item, index) => {
            return (
              <strategyPerformanceStyleds.monthProfitBody key={index}>
                <div>{item.month.replace('-', '/')}</div>
                <div>{item.totalNumberOfTrades}</div>
                <strategyPerformanceStyleds.profit value={item.pnl}>
                  {item.pnl}
                </strategyPerformanceStyleds.profit>
              </strategyPerformanceStyleds.monthProfitBody>
            )
          })}
        {!loadingState && <LoadingStatus />}
      </strategyPerformanceStyleds.monthInfoContent>
    </strategyPerformanceStyleds.container>
  )
})
