import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

//DMI順勢

//ma20 < ma60 && -1 0 +1  //ma120分界線
export const pionex_strategy06 = createIndicator({
  id: 'pionex-strategy06',
  displayName: 'pionex_strategy06',

  //簡易波動率
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = 14

      const close = this.ohlc.close
      const close_array = this.ohlc.closeArray
      const open = this.ohlc.open
      const high = this.ohlc.high
      const highArray = this.ohlc.highArray
      const low = this.ohlc.low
      const lowArray = this.ohlc.lowArray
      const volume = this.PineJS.Std.volume(this._context)
      const volume_array = this._context.new_var(volume)

      const ma = this.PineJS.Std.sma(close_array, 120, this._context)
      const volue_ma = this.PineJS.Std.sma(volume_array, 40, this._context)

      const highest = this.PineJS.Std.highest(highArray, 20, this._context)
      const lowest = this.PineJS.Std.lowest(lowArray, 20, this._context)

      const entryType = context.new_var()

      const position = context.new_var()
      const flag = this._context.new_var()
      const entryPrice = context.new_var()

      const tragetPrice = this._context.new_var()
      const stoplossPrice = this._context.new_var()

      position.get(1)

      let buy_icon = NaN
      let buy_target_icon = NaN
      let buy_exit_icon = NaN

      // ---------------------------------------- Condition ----------------------------------------

      const condition = volume > volue_ma * 5 && close < open && low <= lowest
      if (condition === true) {
        flag.set(1)
      }
      const longEntry = flag.get(0) === 1 && close > open

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entryPrice.set(close)
        entryType.set(1)
        tragetPrice.set(highest)
        stoplossPrice.set(lowest)
        buy_icon = 1
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        if (high >= tragetPrice.get(0)) {
          buy_target_icon = 1
          position.set(0)
          flag.set(0)
        }

        if (close < stoplossPrice.get(0)) {
          buy_exit_icon = 1
          position.set(0)
          flag.set(0)
        }
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2
      }

      return [buy_icon, buy_target_icon, buy_exit_icon, colorer()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'shapes',
      },

      {
        id: 'targetLongFlag',
        type: 'shapes',
      },
      {
        id: 'exitLongFlag',
        type: 'shapes',
      },
      {
        id: '部位',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 10, visible: true },
        targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
      },
      inputs: {},
    },
    styles: {
      entryLongFlag: {
        title: 'Buy',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_triangle_up',
        size: 'small',
        text: 'Buy',
      },
      targetLongFlag: {
        title: '買進停利',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Target',
      },
      exitLongFlag: {
        title: '買進出場',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          2: {
            color: '#b2b5be',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
