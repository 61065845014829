import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { ManualSignal } from '~/modules/investment-consultant/signalNotify/ManualSignal'
import { styleds } from '../god/styled'
import FuiButton from '../heineken_template/components/FuiButton'
import { store } from '../heineken_template/_private/store'
import { god_strategyGroup } from './god_initStrategies'

export const God_SidePane2 = memo<ReactProps>(function God_SidePane2() {
  const charting = useSnapshot(store.charting)
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        padding: 2px;
        gap: 4px;
        user-select: none;
      `}
    >
      <styleds.TradingTipsTitle>程式交易訊號</styleds.TradingTipsTitle>
      <div
        css={css`
          ${flex.h.allCenter}
          width:100%;
          height: 100% - 26px;
          gap: 4px;
        `}
      >
        {god_strategyGroup.intraday.map(config => {
          const isActive = charting.strategySelected.displayName === config.displayName
          return (
            <FuiButton.Display
              key={config.displayName}
              active={isActive}
              onClick={event => {
                store.charting.setStrategy(config)
                store.charting.updateFromStrategyState()
              }}
            >
              {config.displayName}
            </FuiButton.Display>
          )
        })}
      </div>

      <div
        css={css`
          ${flex.h.allCenter}
          width:100%;
          height: 100% - 26px;
          gap: 4px;
        `}
      >
        {god_strategyGroup.position.map(config => {
          const isActive = charting.strategySelected.displayName === config.displayName
          return (
            <FuiButton.Display
              key={config.displayName}
              active={isActive}
              onClick={event => {
                store.charting.setStrategy(config)
                store.charting.updateFromStrategyState()
              }}
            >
              {config.displayName}
            </FuiButton.Display>
          )
        })}
      </div>
      {/* 訊息 */}
      <styleds.TradingTipsTitle>鱷魚老師操作提示</styleds.TradingTipsTitle>
      <styleds.TradingTipsContent>
        <ManualSignal
          agent={'god'}
          limit={5}
        />
      </styleds.TradingTipsContent>
    </div>
  )
})

// const styleds = {
//   container: styled.div``,
// }
