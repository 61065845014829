import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy } from 'valtio'
import useMedia from '~/hooks/useMedia'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'
import { LogoutView } from './LogoutView'

export const win988_store = proxy({ userAvatar: new FuiUserAvatar() })

export const Win988_Topbar = memo<ReactProps>(function Win988_Topbar() {
  const { isPc } = useMedia()
  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: #171a21;
        border-bottom: 1px solid #444444;
      `}
    >
      {isPc && <ChartingServerSwitch charting={store.charting} />}
      {isPc && (
        <win988_store.userAvatar.AvatarModal
          cssset={css`
            width: 280px;
            height: 360px;
            transition: 0.3s;
          `}
        >
          <LogoutView />
        </win988_store.userAvatar.AvatarModal>
      )}
      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div
              css={css`
                ${fill_horizontal_cross_center};
                justify-content: end;
                background-color: #171a21;
                border-bottom: 1px solid #444444;
              `}
            >
              <ChartingServerSwitch charting={store.charting} />
              <win988_store.userAvatar.AvatarModal
                cssset={css`
                  width: 280px;
                  height: 360px;
                  transition: 0.3s;
                `}
              >
                <LogoutView />
              </win988_store.userAvatar.AvatarModal>
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})

//LogoutView
