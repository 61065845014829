import { css } from '@emotion/react'
import { memo } from 'react'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '~/pages/heineken_template/_private/store'

export const Weng888_TopBar = memo<ReactProps>(function Weng888_TopBar() {
  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: #12141c;
      `}
    >
      <ChartingServerSwitch
        charting={store.charting}
        css={css`
          background-color: #1c1f29;
        `}
      />
      <UserAvatarAsDialogButton />
    </div>
  )
})
