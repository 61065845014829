import { css } from '@emotion/react'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const good178_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
    }
  `
}

store.charting.darkOverrides = {
  'mainSeriesProperties.candleStyle.borderDownColor': '#5aF502',
  'mainSeriesProperties.candleStyle.borderUpColor': '#ff164E',
  'mainSeriesProperties.candleStyle.downColor': '#5aF502',
  'mainSeriesProperties.candleStyle.upColor': '#ff164E',
  'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
  'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
  'mainSeriesProperties.candleStyle.drawBorder': false,
  'mainSeriesProperties.showPriceLine': true,
  'mainSeriesProperties.showCountdown': true,
  'paneProperties.horzGridProperties.style': 1,
  'paneProperties.vertGridProperties.style': 1,
  'paneProperties.topMargin': 15,
  'paneProperties.bottomMargin': 15,
  'paneProperties.vertGridProperties.color': '#444444',
  'paneProperties.horzGridProperties.color': '#444444',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.background': '#13151d',
  'timeScale.rightOffset': 0,
  'scalesProperties.fontSize': 14,
  'scalesProperties.textColor': '#cccccc',
  'scalesProperties.lineColor': '#cccccc',
}

store.charting.lightOverrides = {
  'mainSeriesProperties.candleStyle.borderDownColor': '#00bb00',
  'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
  'mainSeriesProperties.candleStyle.downColor': '#00cc00',
  'mainSeriesProperties.candleStyle.upColor': '#bb0000',
  'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
  'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
  'mainSeriesProperties.candleStyle.drawBorder': false,
  'mainSeriesProperties.showPriceLine': true,
  'mainSeriesProperties.showCountdown': true,
  'paneProperties.horzGridProperties.style': 1,
  'paneProperties.vertGridProperties.style': 1,
  'paneProperties.topMargin': 15,
  'paneProperties.bottomMargin': 15,
  'paneProperties.vertGridProperties.color': '#cccccc',
  'paneProperties.horzGridProperties.color': '#cccccc',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.background': '#ffffff',
  'timeScale.rightOffset': 0,
  'scalesProperties.fontSize': 14,
  'scalesProperties.textColor': '#252525',
  'scalesProperties.lineColor': '#e1e2e3',
}
