import dayAPI from '~/utils/dayAPI'
import { store } from '~/pages/heineken_template/_private/store'
import { bs0a } from '~/trades/indicators/weng888/bs0a'
import { tsi1 } from '~/trades/indicators/weng888/tsi1'
import { ti0a } from '~/trades/indicators/weng888/ti0a'
import { lrc0 } from '~/trades/indicators/weng888/lrc0'
import { sar0 } from '~/trades/indicators/weng888/sar0'
import { shape } from '~/trades/indicators/weng888/shape'

export const weng888_initStrategies = () => {
  store.charting.initStrategy({
    configs: [
      {
        displayName: '台指籌碼',
        interval: '5',
        symbol: 'TX-1',
        indicators: [bs0a, ti0a, tsi1, lrc0, sar0, shape],
        calcFrom: dayAPI().subtract(7, 'day'),
        panesRatio: 60,
      },
    ],
  })
}
