import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const win9882_daytrade_signal_3m = createIndicator({
  id: 'win9882-daytrade-break',
  displayName: 'd',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const f_0 = function (e: number, t: number, i: number) {
        return (e - t) / (0.015 * i)
      }
      const hlc3 = this.PineJS.Std.hlc3(this._context)
      const length = 20
      const hlc3_array = this._context.new_var(hlc3)
      const sma20 = this.PineJS.Std.sma(hlc3_array, length, this._context)
      const dev20 = this.PineJS.Std.dev(hlc3_array, length, this._context)
      const line_up = 40
      const line_dn = -40

      const value = f_0(hlc3, sma20, dev20)
      const value_array = this._context.new_var(value)
      const value_color = value_array.get(0) > line_up ? 0 : value_array.get(0) < line_dn ? 1 : 2

      const close = this.ohlc.closeArray
      const open = this.ohlc.openArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const sma30 = this.PineJS.Std.sma(close, 30, this._context)
      const sma30_array = this._context.new_var(sma30)

      sma30_array.get(5)
      value_array.get(5)

      //

      const position = context.new_var()
      const entryPrice = context.new_var()
      const entriesToday = context.new_var()
      const trend = context.new_var()

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)

      let buy_icon = NaN
      let short_icon = NaN
      const buy_target_icon = NaN
      const short_target_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN

      if (isNaN(trend.get(0))) {
        if (value > line_up) {
          trend.set(1)
        }
        if (value < line_dn) {
          trend.set(-1)
        }
      }

      const longEntry =
        trend.get(0) === -1 &&
        position.get(0) !== -1 &&
        sma30_array.get(0) > sma30_array.get(1) &&
        value > line_up

      const shortEntry =
        trend.get(0) === 1 &&
        position.get(0) !== 1 &&
        sma30_array.get(0) < sma30_array.get(1) &&
        value < line_dn

      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entriesToday.set(entriesToday.get(0) + 1)
        entryPrice.set(close.get(0))
        trend.set(1)
        buy_icon = 1
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
        trend.set(-1)
        short_icon = 1
      }

      const sar_long = this.sarLongStopLoss('linear', 7, position, 10, 1.3, 0.3, 4)
      const sar_short = this.sarShortStopLoss('linear', 9, position, 10, 1.4, 0.3, 4)

      //exit

      if (position.get(0) === 1 && position.get(1) === 1) {
        if (close < sma20 && high - entryPrice.get(0) > 20) {
          buy_exit_icon = 1
          position.set(0)
        }

        if (close < sar_long) {
          buy_exit_icon = 1
          position.set(0)
        }
      }
      if (position.get(0) === -1 && position.get(1) === -1) {
        if (close > sma20 && entryPrice.get(0) - low > 20) {
          short_exit_icon = 1
          position.set(0)
        }

        if (close > sar_short) {
          short_exit_icon = 1
          position.set(0)
        }
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 3 : 4
      }

      return [
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'chars',
      },

      {
        id: 'entryShortFlag',
        type: 'chars',
      },
      {
        id: 'targetLongFlag',
        type: 'chars',
      },
      {
        id: 'targetShortFlag',
        type: 'chars',
      },
      {
        id: 'exitLongFlag',
        type: 'chars',
      },
      {
        id: 'exitShortFlag',
        type: 'chars',
      },

      {
        id: '多空輔助',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        entryShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
        targetLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        targetShortFlag: {
          color: '#5cb642',
          textColor: '#5cb642',
          transparency: 20,
          visible: true,
        },
        exitLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        exitShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
      },
    },
    styles: {
      entryLongFlag: {
        title: '多單進場',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'Buy',
      },
      entryShortFlag: {
        title: '多單進場',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'Short',
      },
      targetLongFlag: {
        title: '多單停利',
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'tiny',
        text: 'target',
      },
      targetShortFlag: {
        title: '空單停利',
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'tiny',
        text: 'target',
      },
      exitLongFlag: {
        title: '多單出場',
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'tiny',
        text: 'Exit',
      },
      exitShortFlag: {
        title: '空單出場',
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
