import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { ThemeToggle } from '~/components/theme/ThemeToggle'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fill, fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { store } from '../heineken_template/_private/store'

export const investmentStore = proxy({
  demoSymbol: '2330' as '2330' | '2609',
  currentSymbol: '2330' as '2330' | '2609',
})

export const Investment_TopBar = memo<ReactProps>(function Investment_TopBar() {
  const currentSymbol = useSnapshot(investmentStore).currentSymbol
  return (
    <div
      css={css`
        ${fill_horizontal_cross_center}
        background-color: #364563;
      `}
    >
      <ThemeToggle
        onClick={event => {
          store.charting.setThemeMode(useThemeStore.getState().theme)
          store.charting.updateFromThemeMode()
        }}
      />
      <div
        onClick={() =>
          currentSymbol === '2330'
            ? (investmentStore.currentSymbol = '2609')
            : (investmentStore.currentSymbol = '2330')
        }
      >
        商品切換 {currentSymbol}
      </div>
    </div>
  )
})
