import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { trendStore } from '~/pages/lung88988/_private/useTrendStore'

export const weng888_dayTradeKbar = createIndicator({
  displayName: '當沖關鍵訊號',
  id: 'weng888-dayTradeKbar',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const chip = this.PineJS.Std.high(this._context)
      const unlimited1 = this._context.new_unlimited_var(chip)

      const value = unlimited1.adopt(time1, i, 0) // <=這是籌碼
      // const value1_array = this._context.new_var(value)
      // value1_array.get(1000)

      //主圖商品
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)
      const marketposition = this._context.new_var()
      const pvflag = this._context.new_var()
      const entryPrice = this._context.new_var()
      const defense = this._context.new_var()
      const entriesToday = this._context.new_var()

      const hlc3 = this.PineJS.Std.hlc3(this._context)
      const open = this.PineJS.Std.open(this._context)
      const close = this.PineJS.Std.close(this._context)
      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)
      const volume = this.PineJS.Std.volume(this._context)
      const volume_array = this._context.new_var(volume)
      const avgVolume = this.PineJS.Std.sma(volume_array, 20, this._context)
      const dayTradeAvgPrice = this.dayAvgPrice(0)
      const prevDayTradeAvgPrice = this._context.new_var()
      const itime = this.PineJS.Std.time(this._context)
      const hours = new Date(itime).getHours()
      const minutes = new Date(itime).getMinutes()
      const openTime = hours === 9 && minutes === 0

      const openD = this.dailyOpen(1, 0) ?? [1]

      marketposition.get(2)
      entryPrice.get(5)
      defense.get(2)

      if (hours === 13 && minutes === 30) {
        prevDayTradeAvgPrice.set(dayTradeAvgPrice)
        marketposition.set(0)
        entriesToday.set(0)
      }

      const long =
        value > 5 &&
        hlc3 > dayTradeAvgPrice &&
        hlc3 > prevDayTradeAvgPrice.get(0) &&
        hlc3 > openD[0] &&
        close > open &&
        volume > avgVolume &&
        entriesToday.get(0) < 4 &&
        !openTime

      const short =
        value < -5 &&
        hlc3 < dayTradeAvgPrice &&
        hlc3 < prevDayTradeAvgPrice.get(0) &&
        hlc3 < openD[0] &&
        close < open &&
        volume > avgVolume &&
        entriesToday.get(0) < 4 &&
        !openTime

      const swingLong = value > 0 && hlc3 > dayTradeAvgPrice && hlc3 <= prevDayTradeAvgPrice.get(0)
      const swingShort = value < 0 && hlc3 < dayTradeAvgPrice && hlc3 >= prevDayTradeAvgPrice.get(0)

      let buy_icon = NaN
      let short_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN

      if (long && hours < 13) {
        marketposition.set(1)
      }
      if (short && hours < 13) {
        marketposition.set(-1)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
        buy_icon = 1
        entryPrice.set(close)
        pvflag.set(0)
        defense.set(low)
        entriesToday.set(entriesToday.get(0) + 1)
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
        short_icon = 1
        entryPrice.set(close)
        pvflag.set(0)
        defense.set(high)
        entriesToday.set(entriesToday.get(0) + 1)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
        if (pvflag.get(0) === 0 && high > entryPrice * 1.025) {
          buy_target_icon = 1
          pvflag.set(1)
        }
        if (pvflag.get(0) === 1 && high > entryPrice * 1.06 && isNaN(buy_target_icon)) {
          buy_exit_icon = 1
          pvflag.set(2)
        }
        if (hlc3 < dayTradeAvgPrice && hlc3 < defense.get(0)) {
          marketposition.set(0)
        }
        if (hlc3 < dayTradeAvgPrice && pvflag.get(0) >= 1) {
          marketposition.set(0)
        }
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
        if (pvflag.get(0) === 0 && low < entryPrice * 0.975) {
          short_target_icon = 1
          pvflag.set(1)
        }
        if (pvflag.get(0) === 1 && low < entryPrice * 0.94 && isNaN(short_target_icon)) {
          short_exit_icon = 1
          pvflag.set(2)
        }

        if (hlc3 > dayTradeAvgPrice && hlc3 > defense.get(0)) {
          marketposition.set(0)
        }
        if (hlc3 > dayTradeAvgPrice && pvflag.get(0) >= 1) {
          marketposition.set(0)
        }
      }

      const colorIndex = () => {
        if (marketposition.get(0) === 1) return 0
        else if (
          (marketposition.get(0) === 0 && swingLong) ||
          (marketposition.get(0) === 0 && long)
        )
          return 1
        else if (
          (marketposition.get(0) === 0 && swingShort) ||
          (marketposition.get(0) === 0 && short)
        )
          return 2
        else if (marketposition.get(0) === -1) return 3
        else return 4
      }

      return [
        dayTradeAvgPrice,
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,
        colorIndex(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 2,
          visible: true,
          linewidth: 1,
          plottype: 6,
          trackPrice: false,
          transparency: 30,
          color: '#5577ff',
        },
        plot_1: {
          color: '#ff2e26',
          textColor: '#ff2e26',
          transparency: 0,
          visible: true,
        },
        plot_2: {
          color: '#22f42a',
          textColor: '#22f42a',
          transparency: 0,
          visible: true,
        },
        plot_3: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_4: {
          color: '#22f42a',
          textColor: '#22f42a',
          transparency: 0,
          visible: true,
        },
        plot_5: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_6: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 0,
          visible: true,
        },
      },
    },
    styles: {
      plot_0: {
        title: '日成本線',
        histogramBase: 0,
      },
      plot_1: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單',
        title: '多單參考訊號',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單',
        title: '空單參考訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'small',
        text: 'Target',
        title: '多單參考停利訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'small',
        text: 'Target',
        title: '空單參考停利訊號',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        text: 'Exit',
        title: '多單參考停利訊號',
      },
      plot_6: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        text: 'Exit',
        title: '空單參考停利訊號',
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ff484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cf642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#666666',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
