import { css } from '@emotion/react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const winner98_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${FuiButton.classes.button.Root} {
        ${fill_horizontal_all_center};
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
      }

      ${FuiButton.classes.button.default.Default} {
        background-color: #353d57;
        border: 1px solid #666666;
        color: #aaaaaa;
      }

      ${FuiButton.classes.button.default.Active} {
        background-color: #53565d;
        border: 1px solid #eeeeee;
        color: #eeeeee;
      }
    }
  `

  store.charting.darkOverrides = {
    'mainSeriesProperties.candleStyle.borderDownColor': '#00cc30',
    'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
    'mainSeriesProperties.candleStyle.downColor': '#00cc30',
    'mainSeriesProperties.candleStyle.upColor': '#cc0000',
    'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
    'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'mainSeriesProperties.showPriceLine': false,
    'mainSeriesProperties.showCountdown': true,
    'paneProperties.horzGridProperties.style': 1,
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.topMargin': 15,
    'paneProperties.bottomMargin': 15,
    'paneProperties.vertGridProperties.color': '#333333',
    'paneProperties.horzGridProperties.color': '#333333',
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': '#181b25',

    'timeScale.rightOffset': 0,
    'scalesProperties.fontSize': 16,
    'scalesProperties.textColor': '#cccccc',
    'scalesProperties.lineColor': '#cccccc',
  }
}
