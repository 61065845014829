import { css } from '@emotion/react'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_main_center,
} from '~/modules/AppLayout/FlexGridCss'

export const TradeBot = memo<ReactProps>(function TradeBot() {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        display: grid;
        grid-template-columns: 16% 64% 20%;
        height: 82px;
        padding: 0px 8px;
        font-size: 12px;
        &:hover {
          background-color: #f2f3f5;
          cursor: hover;
        }
      `}
    >
      <div>
        <img
          src='https://www.pionex.com/static/media/FuturesBot_light.805c031d.png'
          css={css`
            width: 36px;
            height: 36px;
          `}
        ></img>
      </div>
      <div css={fill_vertical_main_center}>
        <span
          css={css`
            font-size: 14px;
            font-weight: bold;
          `}
        >
          合約網格
        </span>
        <span
          css={css`
            color: #626773;
          `}
        >
          支持做多做空 收益最高放大100倍 短中線
        </span>
      </div>
      <a
        href={'https://www.pionex.com/zh-TW/futures/BTC.PERP_USDT/Bot'}
        target={'_blank'}
        rel='noreferrer'
        css={css`
          ${fill_horizontal_all_center};
          border-radius: 16px;
          background-color: #fe632d;
          width: 56px;
          height: 24px;
          color: #ffffff;
          font-weight: bold;
        `}
      >
        創建訂單
      </a>
    </div>
  )
})
