import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { PageModeStore } from '~/modules/cons-misc/mode-buttons/PageModeButton'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton'
import { store } from '~/pages/heineken_template/_private/store'
import { FirebaseSymbolGroup, UserDashBord } from '~/pages/kabujukuTW/index.page'
import styleds from '~/pages/kabujukuTW/_private/styleds'

export const Kabujuku_Sidebar2 = memo<ReactProps>(function Sidebar2(props) {
  const state = useSnapshot(PageModeStore)
  const symbolNumber = useSnapshot(store.charting).symbol

  return (
    <div css={styleds.SideBarCss}>
      <UserDashBord />
      <div css={styleds.symbolNameTitle}>
        {symbolNumber}
        <SymbolName symbol={symbolNumber} />
      </div>
      <div
        css={css`
          width: 100%;
          height: 180px;
        `}
      >
        <IntradayTrendChart
          symbol={symbolNumber}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <InformationForm symbol={symbolNumber} />
      <div css={styleds.watchlistAddCss}>
        <div
          css={css`
            ${flex.h.allCenter}
            color:#222222;
          `}
        >
          自選股狀態
        </div>
        <AddSymbolButtonSmall
          groupName={'goldbrain_stock_group_1'}
          symbolNumber={symbolNumber}
          listSize={50}
        />
      </div>
      <div
        css={css`
          ${styleds.dayTrendTitle};
          background-color: #d8d8d8;
        `}
      >
        自選股
      </div>
      <div css={styleds.rightPageContentCss}>
        {state.buttonMode === 'fireBaseStock' && <FirebaseSymbolGroup />}
        {state.buttonMode === 'userStock' && (
          <WatchListWithChart
            groupName={'goldbrain_stock_group_1'}
            useChart={store.charting}
            indicators={store.charting.strategySelected.indicators}
          />
        )}
      </div>
    </div>
  )
})
