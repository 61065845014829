import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

export const win9882_daytrade_signal_1m_default = createIndicator({
  id: 'win9882-daytrade1m',
  displayName: '極短當沖',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const stopLoss = 20
      const target = 50

      const position = context.new_var()
      const entryPrice = context.new_var()
      const entriesToday = context.new_var()

      const close = this.ohlc.closeArray
      const open = this.ohlc.openArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volume = context.new_var(this.PineJS.Std.volume(context))
      const datetime = dayAPI(this.PineJS.Std.time(context))

      const ma_cross = context.new_var()

      const dayTradeAvgPrice = this.PineJS.Std.wma(close, 20, this._context)

      const sma20 = this.PineJS.Std.sma(close, 20, this._context)
      const sma60 = this.PineJS.Std.sma(close, 60, this._context)

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)
      close.get(100)
      open.get(100)
      high.get(100)
      low.get(100)
      volume.get(100)

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN

      if (
        (close.get(0) > sma20 && close.get(1) < sma20) ||
        (close.get(0) < sma20 && close.get(1) > sma20)
      ) {
        ma_cross.set(1)
      }

      const longEntry =
        ma_cross.get(0) === 1 &&
        close.get(0) > dayTradeAvgPrice &&
        close.get(0) > sma20 &&
        sma20 > sma60

      const shortEntry =
        ma_cross.get(0) === 1 &&
        close.get(0) < dayTradeAvgPrice &&
        close.get(0) < sma20 &&
        sma20 < sma60

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entriesToday.set(entriesToday.get(0) + 1)
        entryPrice.set(close.get(0))
        buy_icon = 1
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
        short_icon = 1
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        if (high.get(0) > entryPrice.get(1) + target) {
          buy_target_icon = 1
          position.set(0)
          ma_cross.set(0)
        }

        if (close < sma60 && high.get(0) - entryPrice.get(0) > 10) {
          buy_exit_icon = 1
          position.set(0)
          ma_cross.set(0)
        }

        if (datetime.hour() === 4 && datetime.minute() === 50) {
          buy_exit_icon = 1
          position.set(0)
          ma_cross.set(0)
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        if (low.get(0) < entryPrice.get(1) - target) {
          short_target_icon = 1
          position.set(0)
          ma_cross.set(0)
        }

        if (close > sma60 && entryPrice.get(0) - low.get(0) > 10) {
          short_exit_icon = 1
          position.set(0)
          ma_cross.set(0)
        }

        if (datetime.hour() === 4 && datetime.minute() === 50) {
          short_exit_icon = 1
          position.set(0)
          ma_cross.set(0)
        }
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 3 : 4
      }

      return [
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'chars',
      },

      {
        id: 'entryShortFlag',
        type: 'chars',
      },
      {
        id: 'targetLongFlag',
        type: 'chars',
      },
      {
        id: 'targetShortFlag',
        type: 'chars',
      },
      {
        id: 'exitLongFlag',
        type: 'chars',
      },
      {
        id: 'exitShortFlag',
        type: 'chars',
      },
      {
        id: '多空輔助',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        entryShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
        targetLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        targetShortFlag: {
          color: '#5cb642',
          textColor: '#5cb642',
          transparency: 20,
          visible: true,
        },
        exitLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        exitShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
      },
    },
    styles: {
      entryLongFlag: {
        title: '多單進場',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'Buy',
      },
      entryShortFlag: {
        title: '空單進場',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'Short',
      },
      targetLongFlag: {
        title: '多單停利',
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'tiny',
        text: 'target',
      },
      targetShortFlag: {
        title: '空單停利',
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'tiny',
        text: 'target',
      },
      exitLongFlag: {
        title: '多單出場',
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'tiny',
        text: 'Exit',
      },
      exitShortFlag: {
        title: '空單出場',
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
