import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { PositionInfomation } from '~/modules/investment-consultant/positionValueForm/PositonInfotion'
import IntradayTrendChart from '~/modules/trendChart'
import Col_WatchSymbolToggleButton from '~/pages/heineken_template/_col/col_WatchSymbolToggleButton'
import { store } from '~/pages/heineken_template/_private/store'

import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { styleds } from './styleds'
import { useGetIndicatorsValue } from './useIndicatorsValue'

export const Mvp888Stock_SidePane2 = memo<ReactProps>(function Mvp888Stock_SidePane2() {
  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol
  return (
    <styleds.SideBar>
      <styleds.QuoteTitleContent>
        <TitleSymbolQuote.Default symbol={symbolNumber} />
      </styleds.QuoteTitleContent>
      <div
        css={css`
          width: 100%;
          height: 164px;
        `}
      >
        <IntradayTrendChart
          symbol={symbolNumber}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <Col_WatchSymbolToggleButton.Display groupName='mvp888_stock_group_1' />
      <div
        css={css`
          width: 100%;
          height: 248px;
          font-size: 14px;
        `}
      >
        <PositionInfomation
          useChart={store.charting}
          PositionOptions={useGetIndicatorsValue()}
        />
      </div>
      <div
        css={css`
          ${fill_vertical_cross_center}
          justify-content: end;
          height: calc(100% - 480px);
        `}
      >
        <styleds.FaviconLogo src='mvp888/favicon.png' />
      </div>
    </styleds.SideBar>
  )
})
