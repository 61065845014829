import styled from '@emotion/styled'
import { Title } from '@mui/icons-material'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'

export const styleds = {
  SideBar: styled.div`
    ${fill_vertical_cross_center};
    background-color: #191b23;
    padding: 8px 4px;
    gap: 8px;
  `,
  SideBarTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    width: 80%;
    background-color: #586377;
    border-radius: 5px;
  `,
  TimeRangeContainer: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    width: 80%;
    gap: 4px;
  `,
  CrucialPriceContainer: styled.div`
    ${fill_vertical_all_center};
    width: 80%;
    height: 160px;
    background-color: #222734;
    border-radius: 10px;
    padding: 8px;
    gap: 8px;
    font-size: 16px;
  `,
  FaviconLogo: styled.img`
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  `,
}

export const CirculProgressbarStyled = {
  Container: styled.div`
    ${flex.v.allCenter}
    width: 80%;
    height: 164px;
    background-color: #222734;
    border-radius: 10px;
    padding: 8px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #333845;
    }
  `,
  ProgressbarContent: styled.div`
    ${flex.v.allCenter}
    width: 50%;
    height: 116px;
  `,
  NameTitle: styled.div`
    ${flex.v.allCenter}
    width: 50%;
    height: 24px;
    background-color: #434d6e;
    border-radius: 5px;
  `,
}
