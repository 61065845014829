import { Dayjs } from 'dayjs'
import { filter } from 'lodash'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { useMarketPriceResource } from '~/modules/options/api/useMarketPriceResource'
import { useOptionsGreeksResource } from '~/modules/options/api/useOptionsGreeksResource'
import { filterSocket } from '~/modules/options/utils/filterSocketUtil'
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'
import { getOptionIntradayEndDate } from './getOptionContractDateTime'
import { optionAnalyzeStore } from './optionAnalyzeStore'
import { getWeeklyMxfContract } from './useGetOptionContract'
import { useOptionReferencePrice } from './useOptionReferencePrice'

interface CallData {
  pxc: number
  volc: number
  prevRefc: number
  ivc: number
  deltac: number
  strikePrice: string
  dateTimec: string
}

interface PutData {
  pxp: number
  volp: number
  prevRefp: number
  ivp: number
  deltap: number
  strikePrice: string
  dateTimep: string
}

interface IvData {
  symbol: string
  delta: number
  iv: number
}

export type OptionData = CallData & PutData

//to do:需要給出fake data 以免日後api有問題前端爆掉

/** 距離結算剩餘天數 */
const getRemainingDays = (startDate: Dayjs, endDate: Dayjs) => {
  const start = dayAPI(startDate)
  const end = dayAPI(endDate)

  // 定義一個工作日的判斷函數
  function isTradeDay(date: Dayjs) {
    //如果是周六或周日，返回 false
    return date.day() !== 0 && date.day() !== 6
  }

  // 計算日期區間的天數
  const days = end.diff(start, 'day') + 1

  // 計算工作日數
  let tradeDays = 0
  for (let i = 0; i < days; i++) {
    const date = start.add(i, 'day')
    if (isTradeDay(date)) {
      tradeDays++
    }
  }

  return tradeDays
}

export const useOptionQuote = (): {
  quoteData: OptionData[]
  /** 價平 */
  atTheMoneyPrice: number
} => {
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  /** store */
  const state = useSnapshot(optionAnalyzeStore)

  /** 預設的選擇權契約代號 */
  const contract = state.currentContract

  /** 交易量最大的合約結算日 */
  const settlementDate = state.currentSettlementDate

  /** 剩餘結算天數 結算當天會是 (目前無法處理國定假日,因為沒有提供未來放假日期的api,先假設工作日都有交易~) */
  const remainingDays = getRemainingDays(dayAPI(), dayAPI(settlementDate))

  /** 拿選擇權 波動率、delta值 */
  const getGreeks: IvData[] = useOptionsGreeksResource({
    contract: contract,
  }).res.data ?? [
    {
      symbol: '0',
      delta: 0,
      iv: 0,
    },
  ]

  /** 拿'所有'選擇權指定時間的報價 */
  const getQuote = useMarketPriceResource(
    contract,
    dayAPI(getOptionIntradayEndDate(intraday)), //回朔:可指定時間(目前用不到 先不開發)
  )

  /** Signalr */
  const signalr = useSignalrStoreValueOHLC(state_ => state_.value)

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(getQuote?.map(s => s.symbol) || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(getQuote?.map(s => s.symbol) || [])], 'ohlc')
    }
  }, [JSON.stringify(getQuote?.map(s => s.symbol))])

  /** 拿'所有'選擇權'即時'報價 */
  const quoteSource = Object.values(signalr) //getQuote(回朔模式)

  /** 目前合約 給出type 周別 月別 (用於filterSocket過濾買賣權函式) */
  const contractObject = useParseOptionsContractMonthString(contract)

  /** 將已取得報價過濾出`買權` */
  const qouteCallSource = filter(quoteSource, datum => filterSocket(datum, 'CALL', contractObject))
  /** 將已取得報價過濾出`賣權` */
  const quotePutSource = filter(quoteSource, datum => filterSocket(datum, 'PUT', contractObject))

  /** 將波動率報價過濾出`買權` */
  const ivCallSource = filter(getGreeks, datum => filterSocket(datum, 'CALL', contractObject))
  /** 將波動率報價過濾出`賣權` */
  const ivPutSource = filter(getGreeks, datum => filterSocket(datum, 'PUT', contractObject))

  /** callSource合併ivCallSource 獨立的選擇權客製化報價格式 */
  const dataCall = ivCallSource
    ?.reduce((acc: CallData[], ivObj) => {
      const matchingDefaultObj = qouteCallSource.find(
        defaultObj => defaultObj?.symbol === ivObj.symbol,
      )
      if (matchingDefaultObj) {
        const newObj = {
          pxc: matchingDefaultObj.close,
          volc: matchingDefaultObj.volume,
          prevRefc: matchingDefaultObj.prevRef,
          ivc: ivObj.iv * 100,
          deltac: ivObj.delta,
          strikePrice: matchingDefaultObj.symbol.slice(3, -2) ?? '',
          dateTimec: matchingDefaultObj.datetime,
        }
        return [...acc, newObj]
      }
      return acc
    }, [])
    .sort((a, b) => Number(a.strikePrice) - Number(b.strikePrice))

  /** putSource合併ivPutSource 獨立的選擇權客製化報價格式 */
  const dataPut = ivPutSource
    ?.reduce((acc: PutData[], ivObj) => {
      const matchingDefaultObj = quotePutSource?.find(
        defaultObj => defaultObj?.symbol === ivObj.symbol,
      )
      if (matchingDefaultObj) {
        const newObj = {
          pxp: matchingDefaultObj.close,
          volp: matchingDefaultObj.volume,
          prevRefp: matchingDefaultObj.prevRef,
          ivp: ivObj.iv * 100,
          deltap: ivObj.delta,
          strikePrice: matchingDefaultObj.symbol.slice(3, -2) ?? '',
          dateTimep: matchingDefaultObj.datetime,
        }
        return [...acc, newObj]
      }
      return acc
    }, [])
    .sort((a, b) => Number(a.strikePrice) - Number(b.strikePrice))

  /** 獨立的選擇權客製化報價 */
  const mergedData =
    dataCall?.reduce((acc: (CallData & PutData)[], callObj) => {
      const matchingPut = dataPut?.find(putObj => putObj.strikePrice === callObj.strikePrice)
      if (matchingPut) {
        acc.push({ ...callObj, ...matchingPut })
      }
      return acc
    }, []) ?? []

  /** 所有履約價 */
  const allStrikePrice = quoteSource.length
    ? quoteSource.map(s => Number(s?.symbol.slice(3, -2)))
    : [0]

  /** 標的價格 (週小台) */
  const mtx = getWeeklyMxfContract(state.currentContract)
  const currentClose = useOptionReferencePrice(mtx)

  /** 價平位置 */
  const atTheMoneyPrice = allStrikePrice.reduce((prev, curr) => {
    return Math.abs(curr - currentClose) < Math.abs(prev - currentClose) //&& currentClose >= curr
      ? curr
      : prev
  })

  /** 價平和 */
  const atTheMoneySum = mergedData
    .filter(s => Number(s.strikePrice) === atTheMoneyPrice)
    .map(s => s.pxc + s.pxp)[0]

  /** 找到mergedData價平的兩邊成交價 */
  const findAtTheMoneyPrice = mergedData?.filter(s =>
    Number(Number(s?.strikePrice) === atTheMoneyPrice),
  )

  /** 合成期貨價格 (價平 + (call - put)) */
  const syntheticFutures =
    atTheMoneyPrice + (findAtTheMoneyPrice[0]?.pxc - findAtTheMoneyPrice[0]?.pxp)

  //store相關----
  /** 價平 */
  optionAnalyzeStore.atTheMoneyPrice = atTheMoneyPrice
  /** 價平和 */
  optionAnalyzeStore.atTheMoneySum = atTheMoneySum
  /** 所選契約結算日 */
  optionAnalyzeStore.currentSettlementDate = settlementDate
  /** 剩餘天數 */
  optionAnalyzeStore.remainingDays = remainingDays
  /** 合成期貨 */
  optionAnalyzeStore.syntheticFutures = syntheticFutures

  return { quoteData: mergedData, atTheMoneyPrice: atTheMoneyPrice ?? 0 }
}
