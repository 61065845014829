import styled from '@emotion/styled'
import { css, Switch, SwitchProps } from '@mui/material'
import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import { clas } from '~/utils/clas'

const classes = {
  Root: styled.div`
    ${flex.h.allCenter};
    gap: 4px;
  `,
  text: {
    Root: styled.div`
      ${flex.h.allCenter};
      gap: 4px;
    `,

    /** 左右文字 */
    Text: styled.div``,

    /** # 分隔左右文字的字元 */
    slashText: styled.div``,

    /** # 被 `checked` 時，的文字顏色 */
    CheckedText: styled.div``,
  },
  Switch: styled.div``.withComponent(Switch),
}

const defaultCssset = css`
  ${classes.text.Root} {
    cursor: pointer;
  }

  ${TemplateTheme.classes.Light} & {
    ${classes.text.Text} {
      color: rgb(170, 170, 170);
    }

    ${classes.text.CheckedText} {
      color: black;
    }
  }

  ${TemplateTheme.classes.Dark} & {
    ${classes.text.Text} {
      color: rgb(170, 170, 170);
    }

    ${classes.text.CheckedText} {
      color: white;
    }
  }
`

const Display = memo<
  ReactProps<{
    onChange: (checked: boolean) => unknown
    checked?: boolean
    leftText: string
    rightText: string

    /**
     * # 用於分隔左右文字的分隔字元
     *
     * ## 預設斜線 `/`
     */
    slashText?: string
  }>
>(function Display(props) {
  const slashText = props.slashText ?? '/'

  const onChange = (checked: boolean) => {
    props.onChange(checked)
  }

  return (
    <classes.Root
      className={props.className}
      css={defaultCssset}
    >
      <classes.text.Root
        onClick={event => {
          onChange(!props.checked)
        }}
      >
        <classes.text.Text className={clas([props.checked === false && classes.text.CheckedText])}>
          {props.leftText}
        </classes.text.Text>

        <classes.text.slashText>{slashText}</classes.text.slashText>

        <classes.text.Text className={clas([props.checked === true && classes.text.CheckedText])}>
          {props.rightText}
        </classes.text.Text>
      </classes.text.Root>

      <classes.Switch
        checked={props.checked}
        onChange={(event, checked) => {
          onChange(checked)
        }}
      />
    </classes.Root>
  )
})

export default {
  /**
   * # 帶有左右文字的 Switch 組件
   *
   * ## 如果不需要文字，可直接使用 mui/switch 組件
   */
  Display,
  classes,
}
