import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stocknerve_1222 = createIndicator({
  id: 'stocknerve-1222',
  displayName: 'stocknerve-1222',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol_ = context.symbol.info?.name ?? ''
      const prefix: string = symbol_.substr(0, 2)
      const suffix: string = symbol_.substr(2)
      const isStockFutures = /^[A-Z]{2}$/?.test(prefix) || suffix === 'F-1'

      if (isStockFutures) {
        context.new_sym(ticker + '#goodway_sfChip960', this.PineJS.Std.period(this._context))
        context.new_sym(ticker + '#bs_indicator_acc', this.PineJS.Std.period(this._context))
      }
    },
    main(context, inputCallback) {
      const tt0 = this._context.new_unlimited_var(this._context.symbol.time)

      context.select_sym(1)
      const tt1 = this._context.new_unlimited_var(this._context.symbol.time)
      const bvav = this._context.new_unlimited_var(this.PineJS.Std.open(context)).adopt(tt1, tt0, 0)
      const tbta = this._context.new_unlimited_var(this.PineJS.Std.high(context)).adopt(tt1, tt0, 0)

      context.select_sym(2)
      const tt2 = this._context.new_unlimited_var(this._context.symbol.time)
      const uvdv = this._context.new_unlimited_var(this.PineJS.Std.open(context)).adopt(tt2, tt0, 0)

      context.select_sym(0)
      const w1 = 300
      const w2 = 960
      const w3 = 20
      const n1 = 0
      const bvavSeries = this._context.new_var(bvav)
      const tbtaSeries = this._context.new_var(tbta)
      const uvdvSeries = this._context.new_var(uvdv)
      bvavSeries.get(w1)
      tbtaSeries.get(w1)
      uvdvSeries.get(w1)

      const condition1 =
        bvavSeries.get(0) - tbtaSeries.get(0) + uvdvSeries.get(0) <
        bvavSeries.get(w1) - tbtaSeries.get(w1) + uvdvSeries.get(w1)

      const condition2 = uvdvSeries.get(0) < uvdvSeries.get(w3)

      return [
        condition1 ? this.ohlc.high + 2 : NaN,
        !condition1 ? this.ohlc.low - 2 : NaN,
        condition2 ? this.ohlc.high + 2 : NaN,
        !condition2 ? this.ohlc.low - 2 : NaN,
        //n1 > 0 ? this.ohlc.high + 3 : NaN,
        //n1 < 0 ? this.ohlc.low - 3 : NaN,
      ]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'plot1', type: 'chars' },
      { id: 'plot2', type: 'chars' },
      { id: 'plot3', type: 'chars' },
      { id: 'plot4', type: 'chars' },
      /*{ id: 'plot5', type: 'line' },
      { id: 'plot6', type: 'line' },*/
    ],
    defaults: {
      styles: {
        plot1: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 30,
          visible: true,
        },
        plot2: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 30,
          visible: true,
        },
        plot3: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 30,
          visible: true,
        },
        plot4: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 30,
          visible: true,
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      plot1: {
        title: 'plot1',
        isHidden: false,
        location: 'AboveBar',
        char: '-',
        size: 'tiny',
        text: '',
      },
      plot2: {
        title: 'plot2',
        isHidden: false,
        location: 'BelowBar',
        char: '-',
        size: 'tiny',
        text: '',
      },
      plot3: {
        title: 'plot3',
        isHidden: false,
        location: 'AboveBar',
        char: '.',
        size: 'tiny',
        text: '',
      },
      plot4: {
        title: 'plot4',
        isHidden: false,
        location: 'BelowBar',
        char: '.',
        size: 'tiny',
        text: '',
      },
    },
    inputs: [],
  },
})
