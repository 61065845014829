import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

//DMI順勢
export const pionex_strategy05 = createIndicator({
  id: 'pionex-strategy05',
  displayName: 'pionex_strategy05',

  //簡易波動率
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)

      const close = this.ohlc.close
      const open = this.ohlc.open
      const high = this.ohlc.high
      const low = this.ohlc.low
      const close_array = this.ohlc.closeArray
      const high_array = this.ohlc.highArray
      const low_array = this.ohlc.lowArray

      const highest_entry = this.PineJS.Std.highest(high_array, 24, this._context)
      const lowest_entry = this.PineJS.Std.lowest(low_array, 24, this._context)

      const highest_exit = this.PineJS.Std.highest(high_array, 10, this._context)
      const lowest_exit = this.PineJS.Std.lowest(low_array, 10, this._context)

      const highest = this.PineJS.Std.highest(high_array, 24 * 3, this._context)
      const lowest = this.PineJS.Std.lowest(low_array, 24 * 3, this._context)

      const ma90 = this.PineJS.Std.sma(close_array, 90, this._context)
      const ma21 = this.PineJS.Std.sma(close_array, 21, this._context)

      const hh_entry = this._context.new_var(highest_entry)
      const ll_entry = this._context.new_var(lowest_entry)

      const hh_exit = this._context.new_var(highest_exit)
      const ll_exit = this._context.new_var(lowest_exit)

      hh_entry.get(1)
      ll_entry.get(1)
      hh_exit.get(1)
      ll_exit.get(1)

      const entryType = context.new_var()

      const position = context.new_var()
      const entryPrice = context.new_var()

      position.get(1)

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN

      // ---------------------------------------- Condition ----------------------------------------

      const longEntry =
        close > hh_entry.get(1) &&
        close > ma90 &&
        close > ma21 &&
        entryType.get(0) !== 1 &&
        high < highest

      const shortEntry =
        close < ll_entry.get(1) &&
        close < ma90 &&
        close < ma21 &&
        entryType.get(0) !== -1 &&
        low > lowest

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entryPrice.set(low)
        //entryType.set(1)
        buy_icon = 1
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(high)
        //entryType.set(-1)
        short_icon = 1
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        if (close < ll_exit.get(1)) {
          buy_target_icon = 1
          position.set(0)
        }

        if (close < entryPrice.get(0) * 0.9995) {
          buy_exit_icon = 1
          position.set(0)
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        if (close > hh_exit.get(1)) {
          short_target_icon = 1
          position.set(0)
        }

        if (close > entryPrice.get(0) * 1.0005) {
          short_exit_icon = 1
          position.set(0)
        }
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2
      }

      return [
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,

        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'shapes',
      },

      {
        id: 'entryShortFlag',
        type: 'shapes',
      },
      {
        id: 'targetLongFlag',
        type: 'shapes',
      },
      {
        id: 'targetShortFlag',
        type: 'shapes',
      },
      {
        id: 'exitLongFlag',
        type: 'shapes',
      },
      {
        id: 'exitShortFlag',
        type: 'shapes',
      },

      {
        id: '部位',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
        entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
        targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        targetShortFlag: {
          color: '#00b070',
          textColor: '#fafafa',
          transparency: 20,
          visible: true,
        },
        exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
      },
      inputs: {
        // in_0: 20,
        // in_1: 10,
      },
    },
    styles: {
      entryLongFlag: {
        title: '買進',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_triangle_up',
        size: 'small',
        text: 'Buy',
      },
      entryShortFlag: {
        title: '賣出',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_triangle_down',
        size: 'small',
        text: 'Short',
      },
      targetLongFlag: {
        title: '買進停利',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Target',
      },
      targetShortFlag: {
        title: '賣出停利',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Target',
      },
      exitLongFlag: {
        title: '買進出場',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Exit',
      },
      exitShortFlag: {
        title: '賣出出場',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          2: {
            color: '#b2b5be',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [
      // {
      //   id: 'in_0',
      //   name: '進場',
      //   defval: 14,
      //   type: 'integer',
      //   min: 1,
      //   max: 120,
      // },
      // {
      //   id: 'in_1',
      //   name: '出場',
      //   defval: 14,
      //   type: 'integer',
      //   min: 1,
      //   max: 120,
      // },
    ],
  },
})
