/* eslint-disable no-var */
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorStoreSetStateDebounced, useIndicatorStore } from '~/store/useIndicatorStore'
import { useSnapshot } from 'valtio'
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store'

const sounds = useSoundStore.getState()

export const stock_sg2 = createIndicator({
  id: 'stocksg2',
  displayName: '出手',
  filterOnTheme: 'dark',
  constructorScope: {
    init(context, inputCallback) {
      localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString())
    },

    main(context, inputCallback) {
      const userPosition = good178S_store.usedStrategyTrend
      const state = useIndicatorStore.getState()

      const donchian = (e: any) => {
        var t = this._context.new_var(this.PineJS.Std.low(this._context)),
          i = this._context.new_var(this.PineJS.Std.high(this._context)),
          o = this.PineJS.Std.avg(
            this.PineJS.Std.lowest(t, e, this._context),
            this.PineJS.Std.highest(i, e, this._context),
          )
        return o
      }

      const f_1 = () => {
        const e = 7 //this._input(0)
        const t = 29 //this._input(1)
        const i = 47 //this._input(2)
        const o = 13 //this._input(3)
        const r = donchian(e)
        const s = donchian(t)
        const a = this.PineJS.Std.avg(r, s)
        const l = donchian(i)
        const c = this.PineJS.Std.close(this._context)
        const u = this.PineJS.Std.gt(a, l) ? 0 : 1
        return [r, s, c, a, l, -o, o, o, u]
      }

      const len = 1
      let pv0 = NaN
      let pv1 = NaN
      const pvflag = this._context.new_var()

      let pb1 = NaN
      let pb2 = NaN
      let pb3 = NaN
      let pb4 = NaN
      let pb5 = NaN
      let ps1 = NaN
      let ps2 = NaN
      let ps3 = NaN
      let ps4 = NaN
      let ps5 = NaN

      const open = this.PineJS.Std.open(this._context)
      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)
      const close = this.PineJS.Std.close(this._context)
      const itime = this.PineJS.Std.time(this._context)
      const resolution = context.symbol.period

      const r = f_1()

      const r0 = this._context.new_var(r[0])
      const r1 = this._context.new_var(r[1])
      const r2 = this._context.new_var(r[2])
      const r3 = this._context.new_var(r[3])
      const r4 = this._context.new_var(r[4])

      const ichi_mm = r1.get(1)
      const ichi_ll = Math.min(r3.get(14), r4.get(14))
      const ichi_hh = Math.max(r3.get(14), r4.get(14))

      const entryPrice = this._context.new_var()
      //商品數值 開高低收Array
      const high_array = this._context.new_var(high)
      const open_array = this._context.new_var(open)
      const low_array = this._context.new_var(low)
      const close_array = this._context.new_var(close)
      const itime_array = this._context.new_var(itime)

      var bState = this._context.new_var()
      var bDt = this._context.new_var() //紀錄部位成立的時間
      var pvDt = this._context.new_var() //紀錄停利訊號成立的時間

      var localTimeVar = this._context.new_var()
      const timeDeltaMs = 500
      const kbarLoadingTimeMs = 10000
      const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs
      const isBarChanging =
        isNaN(localTimeVar.get(1)) ||
        Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs
      localTimeVar.set(new Date().getTime())

      itime_array.get(1)
      low_array.get(1)
      high_array.get(1)
      close_array.get(1)
      bDt.get(0)
      bDt.get(1)
      pvDt.get(0)
      pvDt.get(1)
      bState.get(1)
      bState.get(2)
      pvflag.get(1)
      pvflag.get(2)

      //Entry High Entry Low-------------------------------------------------------------------
      const entryHigh = this._context.new_var()
      const entryLow = this._context.new_var()
      if (bState.get(0) === 0) {
        entryHigh.set(0)
        entryLow.set(0)
      }
      if (bState.get(0) === 1 && high > entryHigh) {
        entryHigh.set(high)
      }
      if (bState.get(0) === -1 && low < entryLow) {
        entryLow.set(low)
      }

      //部位進場-------------------------------------------------------------------
      if (close_array.get(1) > ichi_mm && close_array.get(1) > ichi_hh) {
        bState.set(1)
      }
      if (close_array.get(1) < ichi_mm && close_array.get(1) < ichi_ll) {
        bState.set(-1)
      }

      //部位進場與成立訊號-------------------------------------------------------------------
      if (userPosition !== 'short' && bState.get(0) === 1 && bState.get(1) !== 1) {
        pv0 = 1
        pv1 = NaN
        bDt.set(itime)
        entryPrice.set(open)
        pvflag.set(0)
        //傳出策略進場相關資訊
        state.entryPrice = Number(open)
        state.entryTime = Number(itime)
        indicatorStoreSetStateDebounced(state)
      }

      if (userPosition !== 'long' && bState.get(0) === -1 && bState.get(1) !== -1) {
        pv0 = NaN
        pv1 = 1
        bDt.set(itime)
        entryPrice.set(open)
        pvflag.set(0)
        //傳出策略進場相關資訊
        state.entryPrice = Number(-open)
        state.entryTime = Number(itime)
        indicatorStoreSetStateDebounced(state)
      }

      //單一方向策略部位歸零
      if (
        userPosition === 'long' &&
        bState.get(1) === 1 &&
        close_array.get(1) < ichi_mm &&
        close_array.get(1) < ichi_ll
      ) {
        bState.set(0)
        entryPrice.set(0)
        state.entryPrice = Number(0)
        state.entryTime = Number(itime)
      }
      if (
        userPosition === 'short' &&
        bState.get(1) === -1 &&
        close_array.get(1) > ichi_mm &&
        close_array.get(1) > ichi_hh
      ) {
        bState.set(0)
        entryPrice.set(0)
        state.entryPrice = Number(0)
        state.entryTime = Number(itime)
      }
      //根據股票面額返回相對應的停利點數
      const targetValue = (l1: number, l2: number, l3: number, l4: number, l5: number) => {
        if (close < 10) return l1
        if (close < 50) return l2
        if (close < 100) return l3
        if (close < 500) return l4
        if (close < 5000) return l5
      }

      if (resolution === 'D') {
        var p1 = targetValue(0.03, 0.03, 0.04, 0.06, 0.08) ?? 0.003
        var p2 = targetValue(0.06, 0.06, 0.07, 0.09, 0.11) ?? 0.005
        var p3 = targetValue(0.1, 0.1, 0.11, 0.14, 0.16) ?? 0.01
        var p4 = targetValue(0.15, 0.15, 0.16, 0.19, 0.21) ?? 0.015
        var p5 = targetValue(0.21, 0.21, 0.22, 0.26, 0.27) ?? 0.02
      } else {
        p1 = 0.05
        p2 = 0.1
        p3 = 0.15
        p4 = 0.25
        p5 = 0.35
      }

      //多單出場-------------------------------------------------------------------
      if (userPosition !== 'short' && bState.get(0) === 1) {
        if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
          pvflag.set(1)
          pb1 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
          pvflag.set(2)
          pb2 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
          pvflag.set(3)
          pb3 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 3 && high > entryPrice * (1 + p4)) {
          pvflag.set(4)
          pb4 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 4 && high > entryPrice * (1 + p5)) {
          pvflag.set(5)
          pb5 = 1
          pvDt.set(itime)
        }
      }
      //空單出場-------------------------------------------------------------------
      if (userPosition !== 'long' && bState.get(0) === -1) {
        if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
          pvflag.set(1)
          ps1 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
          pvflag.set(2)
          ps2 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
          pvflag.set(3)
          ps3 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 3 && low < entryPrice * (1 - p4)) {
          pvflag.set(4)
          ps4 = 1
          pvDt.set(itime)
        } else if (pvflag.get(0) === 4 && low < entryPrice * (1 - p5)) {
          pvflag.set(5)
          ps5 = 1
          pvDt.set(itime)
        }
      }

      /*Log--------------------------*/
      const LogExitText =
        '[部位出場] ' +
        new Date().getHours() +
        ':' +
        (new Date().getMinutes() < 10 ? '0' : '') +
        new Date().getMinutes() +
        ':' +
        (new Date().getSeconds() < 10 ? '0' : '') +
        new Date().getSeconds() +
        '\n' +
        '[訊號]' +
        new Date(itime_array.get(1)).getHours() +
        ':' +
        (new Date(itime_array.get(1)).getMinutes() < 10 ? '0' : '') +
        new Date(itime_array.get(1)).getMinutes() +
        ' [聲音]' +
        new Date(itime).getHours() +
        ':' +
        (new Date(itime).getMinutes() < 10 ? '0' : '') +
        new Date(itime).getMinutes() +
        '\n' +
        '停利階段 ' +
        pvflag.get(0) +
        '\n' +
        '----------------------'

      const LogEntryText =
        '[部位進場] ' +
        new Date().getHours() +
        ':' +
        (new Date().getMinutes() < 10 ? '0' : '') +
        new Date().getMinutes() +
        ':' +
        (new Date().getSeconds() < 10 ? '0' : '') +
        new Date().getSeconds() +
        '\n' +
        '[訊號]' +
        new Date(itime_array.get(1)).getHours() +
        ':' +
        (new Date(itime_array.get(1)).getMinutes() < 10 ? '0' : '') +
        new Date(itime_array.get(1)).getMinutes() +
        ' [聲音]' +
        new Date(itime).getHours() +
        ':' +
        (new Date(itime).getMinutes() < 10 ? '0' : '') +
        new Date(itime).getMinutes() +
        '\n' +
        '部位 ' +
        bState.get(0) +
        '\n' +
        '----------------------'

      /*進場聲音--------------------------*/
      if (isInitialized && isBarChanging) {
        if (bState.get(1) === 1 && bState.get(2) !== 1 && bDt.get(0) === itime_array.get(1)) {
          sounds.playSoundVictoryDebounced()
          console.log(LogEntryText)
        } else if (
          bState.get(1) === -1 &&
          bState.get(2) !== -1 &&
          bDt.get(0) === itime_array.get(1)
        ) {
          sounds.playSoundVictoryDebounced()
          console.log(LogEntryText)
        }
      }

      /*出場條件--------------------------*/
      const isExitCondition =
        (pvflag.get(1) === 1 && pvflag.get(2) === 0 && pvDt.get(0) === itime_array.get(1)) ||
        (pvflag.get(1) === 2 && pvflag.get(2) === 1 && pvDt.get(0) === itime_array.get(1)) ||
        (pvflag.get(1) === 3 && pvflag.get(2) === 2 && pvDt.get(0) === itime_array.get(1)) ||
        (pvflag.get(1) === 4 && pvflag.get(2) === 3 && pvDt.get(0) === itime_array.get(1)) ||
        (pvflag.get(1) === 5 && pvflag.get(2) === 4 && pvDt.get(0) === itime_array.get(1))

      /*出場聲音--------------------------*/
      if (isInitialized && isBarChanging) {
        if (isExitCondition) {
          sounds.playSoundMagicDebounced()
          console.log(LogExitText)
        }
      }

      state.target1 = Number(p1)
      state.target2 = Number(p2)
      state.nowClose = Number(close)
      state.targetFlag = Number(pvflag.get(0))
      state.targetType = 'percent'
      state.symbolNumber = String(this.PineJS.Std.ticker(this._context))
      indicatorStoreSetStateDebounced(state)
      return [pv0, pv1, pb1, pb2, pb3, pb4, pb5, ps1, ps2, ps3, ps4, ps5]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    isCustomIndicator: true,
    defaults: {
      styles: {
        plot_0: {
          color: '#ff0454',
          textColor: '#ff0454',
          transparency: 0,
          visible: true,
        },
        plot_1: {
          color: '#5fcafd',
          textColor: '#5fcafd',
          transparency: 0,
          visible: true,
        },
        plot_b1: {
          color: '#CFBF00',
          textColor: '#CFBF00',
          transparency: 10,
          visible: true,
        },
        plot_b2: {
          color: '#FFEE00',
          textColor: '#FFEE00',
          transparency: 10,
          visible: true,
        },
        plot_b3: {
          color: '#EE00FF',
          textColor: '#EE00FF',
          transparency: 10,
          visible: true,
        },
        plot_b4: {
          color: '#FF0068',
          textColor: '#FF0068',
          transparency: 10,
          visible: true,
        },
        plot_b5: {
          color: '#FFEE00',
          textColor: '#FFEE00',
          transparency: 10,
          visible: true,
        },
        plot_s1: {
          color: '#209048',
          textColor: '#209048',
          transparency: 20,
          visible: true,
        },
        plot_s2: {
          color: '#55FFEE',
          textColor: '#55FFEE',
          transparency: 20,
          visible: true,
        },
        plot_s3: {
          color: '#209048',
          textColor: '#209048',
          transparency: 20,
          visible: true,
        },
        plot_s4: {
          color: '#00FF55',
          textColor: '#00FF55',
          transparency: 20,
          visible: true,
        },
        plot_s5: {
          color: '#55FFEE',
          textColor: '#55FFEE',
          transparency: 20,
          visible: true,
        },
      },
      inputs: {
        // in_0: 7,
        // in_1: 29,
        // in_2: 47,
        // in_3: 13,
        // in_4: 0.003,
        // in_5: 0.005,
        // in_6: 0.01,
        // in_7: 0.015,
        // in_8: 0.02,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'shapes',
      },
      {
        id: 'plot_1',
        type: 'shapes',
      },
      {
        id: 'plot_b1',
        type: 'chars',
      },
      {
        id: 'plot_b2',
        type: 'chars',
      },
      {
        id: 'plot_b3',
        type: 'chars',
      },
      {
        id: 'plot_b4',
        type: 'chars',
      },
      {
        id: 'plot_b5',
        type: 'chars',
      },
      {
        id: 'plot_s1',
        type: 'chars',
      },
      {
        id: 'plot_s2',
        type: 'chars',
      },
      {
        id: 'plot_s3',
        type: 'chars',
      },
      {
        id: 'plot_s4',
        type: 'chars',
      },
      {
        id: 'plot_s5',
        type: 'chars',
      },
    ],
    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        plottype: 'shape_triangle_up',
        size: 'normal',
        text: '',
        title: 'Shapes',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        plottype: 'shape_triangle_down',
        size: 'normal',
        text: '',
        title: 'Shapes',
      },
      plot_b1: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        char: '*',
        size: 'small',
        title: 'Shapes',
      },
      plot_b2: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        char: '+',
        size: 'small',
        title: 'Shapes',
      },
      plot_b3: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
      plot_b4: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
      plot_b5: {
        isHidden: false,
        location: 'AboveBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
      plot_s1: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        char: '*',
        size: 'small',
        title: 'Shapes',
      },
      plot_s2: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        char: '+',
        size: 'small',
        title: 'Shapes',
      },
      plot_s3: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
      plot_s4: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
      plot_s5: {
        isHidden: false,
        location: 'BelowBar', // Top, Bottom
        char: '$',
        size: 'small',
        title: 'Shapes',
      },
    },
    is_price_study: true,
    inputs: [
      // {
      //   id: 'in_0',
      //   name: 'Conversion Line Periods',
      //   defval: 7,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_1',
      //   name: 'Base Line Periods',
      //   defval: 29,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_2',
      //   name: 'Lagging Span 2 Periods',
      //   defval: 47,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_3',
      //   name: 'Displacement',
      //   defval: 13,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_4',
      //   name: '停利1',
      //   defval: 0.003,
      //   type: 'float',
      //   min: 0,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_5',
      //   name: '停利2',
      //   defval: 0.005,
      //   type: 'float',
      //   min: 0,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_6',
      //   name: '停利3',
      //   defval: 0.01,
      //   type: 'float',
      //   min: 0,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_7',
      //   name: '停利4',
      //   defval: 0.015,
      //   type: 'float',
      //   min: 0,
      //   max: 1e12,
      // },
      // {
      //   id: 'in_8',
      //   name: '停利5',
      //   defval: 0.02,
      //   type: 'float',
      //   min: 0,
      //   max: 1e12,
      // },
    ],
    scriptIdPart: '',
  },
})

export const stock_sg2_light = stock_sg2.duplicate({
  id: 'stocksg2--light',
  filterOnTheme: 'light',
  metainfo: {
    defaults: {
      styles: {
        plot_0: {
          color: '#FF0000',
          textColor: '#FF0000',
        },
        plot_1: {
          color: '#2196F3',
          textColor: '#2196F3',
        },
        plot_b1: {
          color: '#CFBF00',
          textColor: '#CFBF00',
        },
        plot_b2: {
          color: '#FFEE00',
          textColor: '#FFEE00',
        },
        plot_b3: {
          color: '#EE00FF',
          textColor: '#EE00FF',
        },
        plot_b4: {
          color: '#FF0068',
          textColor: '#FF0068',
        },
        plot_b5: {
          color: '#f2c24f',
          textColor: '#f2c24f',
        },
        plot_s1: {
          color: '#209048',
          textColor: '#209048',
        },
        plot_s2: {
          color: '#5ea3ef',
          textColor: '#5ea3ef',
        },
        plot_s3: {
          color: '#67ad5b',
          textColor: '#67ad5b',
        },
        plot_s4: {
          color: '#209048',
          textColor: '#209048',
        },
        plot_s5: {
          color: '#5ea3ef',
          textColor: '#5ea3ef',
        },
      },
    },
  },
})
