import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { fill_horizontal_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar'
import { store } from '../heineken_template/_private/store'

export const Yaya168Stock_TopBar = memo<ReactProps>(function Yaya168Stock_TopBar() {
  const { isPc, isPhone } = useMedia()
  return (
    <div
      css={css`
        ${flex.h.allCenter};
        background-color: #0c0100;
        justify-content: end;
      `}
    >
      {isPc && (
        <div
          css={css`
            ${fill_horizontal_cross_center};
            justify-content: end;
            //background: linear-gradient(72deg, #111111aa, #2a1111aa);
            background-image: url('yaya168/slider.png');
            background-repeat: repeat-x;
          `}
        >
          <div
            css={css`
              ${flex.h.allCenter};
              background: linear-gradient(72deg, #111111ee, #2a1111ee);
            `}
          >
            <ChartingServerSwitch charting={store.charting} />
            <UserAvatarAsDialogButton />
          </div>
        </div>
      )}

      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div
              css={css`
                ${flex.h.allCenter};
              `}
            >
              {/* {!isPhone && <img src='m168168/slider.jpg' />} */}
              <ChartingServerSwitch charting={store.charting} />
              <UserAvatarAsDialogButton />
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})
