import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 成交量 */
export const rich888_volume = createIndicator({
  displayName: '成交量',
  id: 'rich888-volume',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const vol = this.PineJS.Std.volume(this._context)
      const display_volume = vol > 10e7 ? vol / 10e7 : vol
      const vol_array = this._context.new_var(display_volume)

      const vol_ma = this.PineJS.Std.sma(vol_array, this._input(0), this._context)

      const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1

      return [display_volume, colorIndex, vol_ma]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],

    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#e66a64',
              width: 0,
              style: 0,
            },
            1: {
              color: '#79b36d',
              width: 0,
              style: 0,
            },
          },
        },
      },
      inputs: { in_0: 20 },
    },

    is_price_study: !1,
    inputs: [
      {
        id: 'in_0',
        name: 'vol_ma',
        defval: 20,
        type: 'integer',
        min: 1,
        max: 200,
      },
    ],
    scriptIdPart: '',
    precision: 0,
  },
})
