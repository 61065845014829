import { memo } from 'react'
import { useSnapshot } from 'valtio'
import styleds from '~/pages/weng888_stock/_private/styleds'
import { weng888Stock_agentStore } from '~/pages/weng888_stock/_private/weng888Stock_agentStore'
import { store } from '~/pages/heineken_template/_private/store'
import { HoldingQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { useVirtualHoldingSort } from '~/modules/investment-consultant/signalrQuoteSort/useSignalrSortCondition'
import IntradayTrendChart from '~/modules/trendChart'
import { css } from '@emotion/react'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { WatchSymbolToggleSingleButton } from '~/pages/heineken_template/_col/col_WatchSymbolToggleSingleButton'
import { stock_signal } from '~/trades/indicators/weng888/stock_signal'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { weng888Stock_bandTrade } from './weng888Stock_initStrategies'

export const Weng888Stock_Sidebar2 = memo<ReactProps>(function Sidebar1(props) {
  const agentState = useSnapshot(weng888Stock_agentStore)

  const holdingDataLong = useVirtualAccountHolding('weng888_stock_long_2') ?? []
  const holdingDataShort = useVirtualAccountHolding('weng888_stock_short_2') ?? []
  const dataSortLong = useVirtualHoldingSort(holdingDataLong)
  const dataSortShort = useVirtualHoldingSort(holdingDataShort)

  const resultData = agentState.holdingPositionType === 'long' ? dataSortLong : dataSortShort

  const highStockPrice = resultData.filter(
    (item: { averagePrice: number }) => item.averagePrice >= 100,
  )
  const lowStockPrice = resultData.filter(
    (item: { averagePrice: number }) => item.averagePrice < 100,
  )
  const dataResult = agentState.stockPriceLowMode === true ? lowStockPrice : highStockPrice

  const charting = useSnapshot(store.charting)

  return (
    <styleds.Sidebar>
      <TitleSymbolQuote.Default symbol={charting.symbol} />
      <div
        css={css`
          ${fill_horizontal_all_center}
          width: 100%;
          height: 32px;
          padding: 4px;
          gap: 4px;
        `}
      >
        <WatchSymbolToggleSingleButton
          type='long'
          groupName='weng888_stock_group_1'
        />
        <WatchSymbolToggleSingleButton
          type='short'
          groupName='weng888_stock_group_2'
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: 164px;
          border-radius: 5px;
          background-color: #1f222caa;
          padding: 4px 0px;
        `}
      >
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>

      <styleds.Title>
        <div
          css={css`
            ${fill_horizontal_all_center};
            width: calc(100% - 132px);
            background-color: #2c2f38;
          `}
        >
          <FuiButton.Display active={agentState.isHoldingSymbol === true}>
            波段持股追蹤
          </FuiButton.Display>
        </div>

        <div
          css={css`
            ${fill_horizontal_all_center};
            width: 132px;
            gap: 4px;
          `}
        >
          <styleds.HoldingPosition
            variant='long'
            active={agentState.holdingPositionType === 'long'}
            onClick={() => (weng888Stock_agentStore.holdingPositionType = 'long')}
          >
            多方
          </styleds.HoldingPosition>
          <styleds.HoldingPosition
            variant='short'
            active={agentState.holdingPositionType === 'short'}
            onClick={() => (weng888Stock_agentStore.holdingPositionType = 'short')}
          >
            空方
          </styleds.HoldingPosition>
        </div>
      </styleds.Title>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 272px);
        `}
      >
        <HoldingQuoteListInteractWithChart
          data={dataResult}
          chart={store.charting}
          indicators={weng888Stock_bandTrade}
          handleClick={() => {
            store.charting.changeInterval('1D')
            weng888Stock_agentStore.isHoldingSymbol = true
          }}
        />
      </div>
    </styleds.Sidebar>
  )
})
