import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'

export const Display = memo<
  ReactProps<{
    strategy: ChartTypes.StrategyConfig
    onClick?: (config: ChartTypes.StrategyConfig) => unknown
    FuiButtonProps?: Omit<
      NoClassName<ParametersHead<typeof FuiButton['Display']>>,
      'active' | 'onClick'
    >
  }>
>(function Display(props) {
  const isActive =
    useSnapshot(store.charting).strategySelected.displayName === props.strategy.displayName

  return (
    <FuiButton.Display
      {...props.FuiButtonProps}
      className={props.className}
      active={isActive}
      onClick={event => {
        store.charting.setStrategy(props.strategy)
        store.charting.updateFromStrategyState()
      }}
    >
      {props.children || props.strategy.displayName}
    </FuiButton.Display>
  )
})

export default {
  Display,
}
