import { idID } from '@mui/material/locale'
import { globalBlue, globalYellow } from '~/modules/AppLayout/Colors'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stock_signal = createIndicator({
  displayName: '波段富翁',
  id: 'stock-signal',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const marketposition = this._context.new_var()
      const entryPrice = this._context.new_var()
      const targetFlag = this._context.new_var()

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const open = this.PineJS.Std.open(this._context)
      const open_array = this._context.new_var(open)
      const volume = this.PineJS.Std.volume(this._context)
      const volume_array = this._context.new_var(volume)
      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)

      const in1 = 20
      const in2 = 80
      const in3 = 40

      const sma1 = this.PineJS.Std.sma(close_array, in1, this._context)
      const sma2 = this.PineJS.Std.sma(close_array, in2, this._context)
      const sma3 = this.PineJS.Std.sma(close_array, in3, this._context)
      const sma1_array = this._context.new_var(sma1)
      const sma2_array = this._context.new_var(sma2)
      const sma3_array = this._context.new_var(sma3)

      marketposition.get(2)
      entryPrice.get(2)
      targetFlag.get(2)
      close_array.get(5)
      open_array.get(5)
      volume_array.get(5)
      sma1_array.get(5)
      sma2_array.get(5)
      sma3_array.get(5)

      const condition_buy =
        sma1_array.get(1) > sma2_array.get(1) &&
        close_array.get(1) > sma2_array.get(1) &&
        sma1_array.get(1) > sma1_array.get(2) &&
        sma2_array.get(1) > sma2_array.get(2) &&
        close_array.get(1) > open_array.get(1) &&
        sma3_array.get(1) > sma2_array.get(1) &&
        volume_array.get(1) >= 1000
      const condition_short =
        sma1_array.get(1) < sma2_array.get(1) &&
        close_array.get(1) < sma2_array.get(1) &&
        sma1_array.get(1) < sma1_array.get(2) &&
        sma2_array.get(1) < sma2_array.get(2) &&
        close_array.get(1) < open_array.get(1) &&
        sma3_array.get(1) < sma2_array.get(1) &&
        volume_array.get(1) >= 1000

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target0 = NaN
      let short_target0 = NaN

      if (condition_buy) {
        marketposition.set(1)
      }
      if (condition_short) {
        marketposition.set(-1)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
        buy_icon = 1
        entryPrice.set(open)
        targetFlag.set(0)
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
        short_icon = 1
        entryPrice.set(open)
        targetFlag.set(0)
      }

      if (marketposition.get(1) === 1) {
        if (high > entryPrice.get(0) * 1.1 && targetFlag.get(0) === 0) {
          buy_target0 = 1
          targetFlag.set(1)
        }
        if (high > entryPrice.get(0) * 1.2 && targetFlag.get(0) === 1) {
          buy_target0 = 1
          targetFlag.set(2)
        }
        if (high > entryPrice.get(0) * 1.35 && targetFlag.get(0) === 2) {
          buy_target0 = 1
          targetFlag.set(3)
        }
      }
      if (marketposition.get(1) === -1) {
        if (low < entryPrice.get(0) * 0.9 && targetFlag.get(0) === 0) {
          short_target0 = 1
          targetFlag.set(1)
        }
        if (low < entryPrice.get(0) * 0.8 && targetFlag.get(0) === 1) {
          short_target0 = 1
          targetFlag.set(2)
        }
        if (low < entryPrice.get(0) * 0.65 && targetFlag.get(0) === 2) {
          short_target0 = 1
          targetFlag.set(3)
        }
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) === 1 && close < sma2) {
        marketposition.set(0)
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) === -1 && close > sma2) {
        marketposition.set(0)
      }

      const colorIndex = marketposition.get(0) === 1 ? 0 : marketposition.get(0) === -1 ? 2 : 1

      return [NaN, NaN, buy_icon, short_icon, buy_target0, short_target0, colorIndex]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: globalBlue.b900,
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: globalYellow.y800,
        },
        plot_2: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_3: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 0,
          visible: true,
        },
        plot_4: {
          color: '#8d8e2d',
          textColor: '#8d8e2d',
          transparency: 10,
          visible: true,
        },
        plot_5: {
          color: '#3e8b8e',
          textColor: '#3e8b8e',
          transparency: 10,
          visible: true,
        },
      },
      //填充區域
      filledAreasStyle: {
        filledAreaId1: {
          color: '#fff',
          transparency: 0,
          visible: !0,
        },
      },
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#fff',
              width: 0,
              style: 0,
            },
            1: {
              color: '#fff',
              width: 0,
              style: 1,
            },
          },
        },
      },
    },
    styles: {
      plot_2: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單',
        title: '多單參考訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單',
        title: '空單參考訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'small',
        title: '多單參考停利',
      },
      plot_5: {
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'small',
        title: '空單參考停利',
      },
    },
    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#eb4442',
            width: 1,
            style: 0,
          },
          1: {
            color: '#666666',
            width: 1,
            style: 0,
          },
          2: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
