import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore'

export const mvp888_bandTrade = createIndicator({
  displayName: '波段多空策略',
  id: 'mvp888-bandTrade',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#big3_net_stock'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const high = this.PineJS.Std.high(this._context)
      const chip = this.PineJS.Std.close(this._context)
      const unlimited1 = this._context.new_unlimited_var(chip)

      const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼

      // const value1_array = this._context.new_var(value)
      // value1_array.get(1000)

      //主圖商品
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)

      const marketposition = this._context.new_var()
      const entryPrice = this._context.new_var()

      const symbol = context.symbol.info?.ticker
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const itime = this.PineJS.Std.time(this._context)
      const ma5 = this.PineJS.Std.sma(close_array, 5, this._context)
      const ma20 = this.PineJS.Std.sma(close_array, 20, this._context)
      const ma10 = this.PineJS.Std.sma(close_array, 10, this._context)
      const ma5_array = this._context.new_var(ma5)
      const ma20_array = this._context.new_var(ma20)
      const ma10_array = this._context.new_var(ma10)

      const hlc3 = this.PineJS.Std.hl2(this._context)
      const open = this.PineJS.Std.open(this._context)

      const volume = this.PineJS.Std.volume(this._context)
      const volume_array = this._context.new_var(volume)
      const ma_volume = this.PineJS.Std.sma(volume_array, 20, this._context)

      marketposition.get(2)
      entryPrice.get(2)
      ma5_array.get(10)
      ma20_array.get(10)

      const long =
        //value1 > 0 &&
        close > ma5 &&
        ma5 > ma20 &&
        ma5 > ma10 &&
        close > open &&
        volume > ma_volume &&
        ma5_array.get(0) > ma5_array.get(1) &&
        volume > 1000

      const short =
        //value1 < 0 &&
        close < ma5 &&
        ma5 < ma20 &&
        ma5 < ma10 &&
        close < open &&
        volume > ma_volume &&
        ma5_array.get(0) < ma5_array.get(1) &&
        volume > 1000

      let buy_icon = NaN
      let short_icon = NaN

      if (long && !symbol?.includes('-')) {
        marketposition.set(1)
      }
      if (short && !symbol?.includes('-')) {
        marketposition.set(-1)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
        buy_icon = 1
        entryPrice.set(close)
        indicatorsValueStore.openPositionProfit = 0
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = 1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [entryPrice * 1.1, entryPrice * 1.2]
        indicatorsValueStore.stopLossPrice = entryPrice * 0.91
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
        short_icon = 1
        entryPrice.set(close)
        indicatorsValueStore.openPositionProfit = 0
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = -1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [entryPrice * 0.9, entryPrice * 0.8]
        indicatorsValueStore.stopLossPrice = entryPrice * 1.09
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
        indicatorsValueStore.openPositionProfit = close - entryPrice.get(0)
        if (
          (close < entryPrice.get(0) * 0.97 &&
            close_array.get(0) < ma5_array.get(0) &&
            close_array.get(1) < ma5_array.get(1)) || //&& value1 < 0
          (close_array.get(0) < ma5_array.get(0) &&
            close_array.get(1) < ma5_array.get(1) &&
            close < ma20)
        ) {
          marketposition.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
        indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close
        if (
          (close > entryPrice.get(0) * 1.03 &&
            close_array.get(0) > ma5_array.get(0) &&
            close_array.get(1) > ma5_array.get(1)) || //&& value1 > 0
          (close_array.get(0) > ma5_array.get(0) &&
            close_array.get(1) > ma5_array.get(1) &&
            close > ma20)
        ) {
          marketposition.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      const colorIndex = () => {
        if (marketposition.get(0) === 1) return 0
        //else if (swingLong) return 1
        //else if (swingShort) return 2
        else if (marketposition.get(0) === -1) return 3
        else return 4
      }

      return [ma5, buy_icon, short_icon, !symbol?.includes('-') ? colorIndex() : NaN]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          visible: true,
          linewidth: 1,
          plottype: 0,
          trackPrice: false,
          transparency: 30,
          color: '#5577ff',
        },
        plot_1: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_2: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 0,
          visible: true,
        },
      },
    },
    styles: {
      plot_0: {
        title: '日成本線',
        histogramBase: 0,
      },
      plot_1: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單',
        title: '多單參考訊號',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單',
        title: '空單參考訊號',
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
