import { css } from '@emotion/react'
import { memo } from 'react'
import { BiUser } from 'react-icons/bi'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ManualSignal } from '~/modules/investment-consultant/signalNotify/ManualSignal'
import { store } from '~/pages/heineken_template/_private/store'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import Styled from '~/pages/win168/_private/styleds'

export const Win168_SidePane2 = memo<ReactProps>(function SidePane2(props) {
  return (
    <Styled.Sidebar>
      <div
        css={css`
          ${flex.h.allCenter}
          width:100%;
          background-color: #333333;
        `}
      >
        <ChartingServerSwitch charting={store.charting} />
        <BiUser size={26} />
        <UserAvatarAsDialogButton />
      </div>

      <div
        css={css`
          ${flex.v.allCenter}
          width: 100%;
          height: 300px;
        `}
      >
        <img
          src='/win168/logo1.png'
          css={css`
            width: 100%;
            height: 250px;
          `}
        />
      </div>

      <Styled.Title>操作提醒＆日日春高勝率當沖訊號</Styled.Title>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 340px);
        `}
      >
        <ManualSignal
          agent={'win168'}
          limit={10}
        />
      </div>
    </Styled.Sidebar>
  )
})
