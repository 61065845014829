import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { SignalWallTypes } from '~/modules/signal-wall/SignalWallTypes'
import dayAPI from '~/utils/dayAPI'
import { DatumOfSouth } from './south_SidePane1'
import { south_store } from './south_store'

/**
 * Returns signals by symbol.
 *
 * @param symbol
 * @param originalSignals
 * @returns
 */
export const getSignalsBySymbol = (
  symbol: string,
  originalSignals: SignalWallTypes.Datum[],
): SignalWallTypes.Datum[] => {
  return Array.from(
    new Set(
      originalSignals
        .filter(signal => signal.symbol == symbol)
        .map(signal => JSON.stringify(signal)),
    ),
  )
    .map(stringSignal => JSON.parse(stringSignal))
    .sort((a, b) => {
      const [aTime, bTime] = [new Date(a.datetime), new Date(b.datetime)]
      if (aTime < bTime) {
        return -1
      }
      if (aTime > bTime) {
        return 1
      }
      return 0
    })
}

/**
 * Set position info into south_agentStore
 *
 * @param symbol
 * @param signals
 */
export const setPositionInfo = (symbol: string, signals: SignalWallTypes.Datum[]): void => {
  console.log('Set position info for:', symbol)
  console.log(signals)
  if (signals.length === 0) {
    console.log(`No longer signal for symbol: ${symbol}`)
  } else {
    const entryData = signals[0].signal_data as SignalWallTypes.DatumOfSouth

    south_store.symbol = symbol
    south_store.side =
      Number(entryData.current_position) === 1
        ? 'Long'
        : Number(entryData.current_position) === -1
        ? 'Short'
        : 'None'
    south_store.currentPosition = entryData.current_position
    south_store.entryTime = signals[0].datetime
    south_store.entryPrice = entryData.future_price
    south_store.stoplossPrice = entryData.future_stoploss
  }
  // else {
  //   const entryData = signals[0].signal_data as SignalWallTypes.DatumOfSouth
  //   const exitData = signals[1].signal_data as SignalWallTypes.DatumOfSouth

  //   south_store.symbol = symbol
  //   south_store.side =
  //     Number(entryData.current_position) === 1
  //       ? 'Long'
  //       : Number(entryData.current_position) === -1
  //       ? 'Short'
  //       : 'None'
  //   south_store.currentPosition = exitData.current_position
  //   south_store.entryTime = signals[0].datetime
  //   south_store.entryPrice = entryData.future_price
  //   south_store.stoplossPrice = entryData.future_stoploss
  //   south_store.exitTime = signals[1].datetime
  //   south_store.exitPrice = exitData.future_price
  //   // console.log('Symbol=', south_store.symbol)
  //   // console.log('Side=', south_store.side)
  //   // console.log('CurrentPosition=', south_store.currentPosition)
  //   // console.log('EntryTime=', south_store.entryTime)
  //   // console.log('EntryPrice=', south_store.entryPrice)
  //   // console.log('StoplossPrice=', south_store.stoplossPrice)
  //   // console.log('ExitTime=', south_store.exitTime)
  //   // console.log('ExitPrice=', south_store.exitPrice)
  // }
}

export const getEntrySignals = (signals: SignalWallTypes.Datum[], signalId: string) =>
  signals
    .filter(signal => {
      const data = signal.signal_data as unknown as DatumOfSouth
      return Number(data?.current_position) !== 0 && signal.signal_id === signalId
    })
    .map(signal => signal)
