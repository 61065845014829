import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss'

export default {
  Topbar: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    background-color: #111111;
    color: #fff;

    img {
      width: 100px;
    }
  `,
  Footer: styled.div`
    #__body & {
      background-color: #111111;
      color: #fff;
    }
  `,
  SideBarCss: css`
    ${flex.v.crossCenter};
    width: 100%;
    height: 100%;
    border-radius: 5px;
    font-size: 18px;
    padding: 2.5px;
  `,
  dayTrendTitle: css`
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
  `,
  dayTrendSymbol: css`
    width: 100%;
    height: calc(50% - 60px);
    margin-bottom: 5px;
  `,
  symbolNameTitle: css`
    width: 100%;
    hieght: 40px;
    color: #333333;
    font-size: 16px;
    padding: 10px;
    ${flex.h.crossCenter}
  `,
  watchlistAddCss: css`
    ${flex.h.allCenter};
    ${pureGrid};
    width: 100%;
    height: 40px;
    grid-template-columns: 40% 60%;
    padding: 5px;
  `,
  rightPageContentCss: css`
    width: 100%;
    height: calc(100% - 500px);
  `,
}
