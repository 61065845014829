import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  flex,
  jc,
} from '~/modules/AppLayout/FlexGridCss'

export const styleds = {
  Sidebar: styled.div`
    ${flex.v.crossCenter};
    width: 100%;
    height: 100%;
    gap: 4px;
    padding: 4px;
    background-color: #212529;
  `,
  buttonGroupContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 8px;
  `,
  filterContent: styled.div`
    ${fill_horizontal_cross_center};
    height: 40px;
    background-color: #1a1c1f;
    gap: 8px;
    padding: 0px 4px;
  `,
  filterTitle: styled.div`
    width: 68px;
  `,
  ButtonGroupContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 8px;
    padding: 0px 2px;
  `,
  SymbolListContent: styled.div`
    width: 100%;
    height: calc(50% - 72px);
  `,
  SideBarTitle: styled.div`
    ${fill_horizontal_all_center};
    border-radius: 8px;
    height: 32px;
    background: linear-gradient(72deg, #524c59, #334e5e);
  `,
  TrendChartContent: styled.div`
    width: 100%;
    height: 164px;
  `,
  SymbolBoard: styled.div<{ size: 'larger' | 'normal' }>`
    ${fill_vertical_all_center};
    width: 100%;
    gap: 4px;
    border-radius: 5px;
    background-color: #2e3238;
    padding: 4px 0px;

    ${options => {
      const larger =
        options.size === 'larger' &&
        css`
          height: 408px;
        `
      const normal =
        options.size === 'normal' &&
        css`
          height: 208px;
        `

      return css([larger, normal])
    }}
  `,

  HoldingPosition: styled.div<{ variant?: 'long' | 'short'; active: boolean }>`
    ${options => {
      const baseCssset = css`
        ${fill_horizontal_all_center}
        height: 28px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
      `

      const longCssset =
        options.variant === 'long' &&
        options.active === false &&
        css`
          background-color: #552222;
          border: 1px solid #353535;
          color: #777777;
        `

      const shortCssset =
        options.variant === 'short' &&
        options.active === false &&
        css`
          background-color: #225522;
          border: 1px solid #353535;
          color: #777777;
        `

      const longActiveCssset =
        options.variant === 'long' &&
        options.active === true &&
        css`
          background-color: #994444;
          border: 1px solid #cccccc;
          color: #eeeeee;
        `

      const shortActiveCssset =
        options.variant === 'short' &&
        options.active === true &&
        css`
          background-color: #449944;
          border: 1px solid #cccccc;
          color: #eeeeee;
        `

      return css([baseCssset, longCssset, longActiveCssset, shortActiveCssset, shortCssset])
    }}
  `,
}
