import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 60/20日移動平均線 */
export const pionex_band = createIndicator({
  displayName: '通道',
  id: 'pionex-band',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close_array = this.ohlc.closeArray
      const sma = this.PineJS.Std.ema(close_array, 44, this._context)

      const up = sma * (1 + 0.007)
      const dn = sma * (1 - 0.007)

      return [up, dn]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#e15241',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#42a5f5',
        },
      },
      //填充區域
    },
    styles: {
      plot_0: {
        title: '上通道',
        histogramBase: 0,
      },
      plot_1: {
        title: '下通道',
        histogramBase: 0,
      },
    },

    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
