import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss'
import SymbolRankingBody from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'
import SymbolRankingHeader from '~/modules/investment-consultant/symbolList/header/SymbolRankingHeader'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { FirebaseSymbolList } from './FireBaseSymbolList'
import { lung88988Stock_agentStore } from './lung88988Stock_agentStore'
import { Styleds } from './styleds'

export const Lung88988Stock_Sidepane2 = memo<ReactProps>(function Lung88988Stock_Sidepane2() {
  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol

  return (
    <Styleds.SideBar>
      <Styleds.SymbolNameContainer>
        {symbolNumber}
        <SymbolName symbol={symbolNumber} />
      </Styleds.SymbolNameContainer>
      <Styleds.TrendChartContainer>
        <IntradayTrendChart
          symbol={symbolNumber}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </Styleds.TrendChartContainer>
      <Styleds.SymbolInfoContainer>
        <InformationForm symbol={symbolNumber} />
      </Styleds.SymbolInfoContainer>
      <Styleds.SymbolRankingGroup>
        <SymbolRankingHeader.Simple countArray={[1, 5, 20, 60]} />
        <SymbolRankingBody.simple.Display
          symbolNumber={symbolNumber}
          useChart={store.charting}
          countArray={[1, 5, 20, 60]}
        />
      </Styleds.SymbolRankingGroup>
      <Styleds.WatchListAddContainer>
        <div
          css={css`
            ${flex.h.allCenter}
          `}
        >
          自選股狀態
        </div>
        <AddSymbolButtonSmall
          groupName={'lung88988_stock_group_1'}
          symbolNumber={symbolNumber}
          listSize={50}
        />
      </Styleds.WatchListAddContainer>
      <Styleds.ScreenerTypeButtonGroup>
        <lung88988Stock_agentStore.tabs2.WithButton to='龍王精選'>
          <FuiButton.Display>龍王精選</FuiButton.Display>
        </lung88988Stock_agentStore.tabs2.WithButton>

        <lung88988Stock_agentStore.tabs2.WithButton to='自選股'>
          <FuiButton.Display>自選股</FuiButton.Display>
        </lung88988Stock_agentStore.tabs2.WithButton>
      </Styleds.ScreenerTypeButtonGroup>
      <div
        css={css`
          ${flex.h.allCenter};
          width: 100%;
          height: calc(100% - 510px);
        `}
      >
        <lung88988Stock_agentStore.tabs2.WithContent for='龍王精選'>
          <FirebaseSymbolList />
        </lung88988Stock_agentStore.tabs2.WithContent>

        <lung88988Stock_agentStore.tabs2.WithContent for='自選股'>
          <WatchListWithChart
            groupName={'lung88988_stock_group_1'}
            useChart={store.charting}
          />
        </lung88988Stock_agentStore.tabs2.WithContent>
      </div>
    </Styleds.SideBar>
  )
})
