import { store } from '~/pages/heineken_template/_private/store'
import { kabujukuAvgLine } from '~/trades/indicators/kabujukuTW/kabujukuAvgLine'

export const kubujuku_initStrategies = () => {
  store.charting.initStrategy({
    configs: [
      {
        displayName: '',
        indicators: [kabujukuAvgLine],
        symbol: 'TSEA',
        interval: '1D',
      },
    ],
  })
}
