import { css } from '@emotion/react'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { good178S_store, resetIndicatorsValue } from '~/pages/good178_stock/_private/good178S_store'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'

export const PriceTrend = memo<ReactProps>(function TrendClock() {
  const { isPhone } = useMedia()
  const length = isPhone ? 5 : 10
  const trendValue = useSnapshot(good178S_store).trend.slice(0, length)

  const Score = memo<ReactProps<{ value: number }>>(function Block(props) {
    const value = Math.ceil(props.value / 10)

    const color = () => {
      if (value <= -4) {
        return '#1B4E20'
      }
      if (value > -4 && value <= -3) {
        return '#43A047'
      }
      if (value > -3 && value <= -2) {
        return '#43A047'
      }
      if (value > -2 && value <= -1) {
        return '#81C784'
      }
      if (value > -1 && value < 1) {
        return '#B0BEC5'
      }
      if (value >= 1 && value < 2) {
        return '#E57373'
      }
      if (value >= 2 && value < 3) {
        return '#E53935'
      }
      if (value >= 3 && value < 4) {
        return '#E53935'
      }
      if (value >= 4) {
        return '#951C1C'
      }
    }

    return (
      <div
        css={css`
          ${fill_vertical_all_center}
          background-color: ${color()};
          color: #ffffff;
          width: 32px;
          border-radius: 3px;
        `}
      >
        {value.toFixed(0)}
      </div>
    )
  })

  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        height: 24px;
        gap: 2px;
        padding: 0px 16px;
        & > p {
          margin: 0px;
        }
      `}
    >
      <div
        css={css`
          background-color: #999999;
          color: #ffffff;
          padding: 0px 8px;
          border-radius: 3px;
          margin-right: 16px;
        `}
      >
        近期盤勢分數
      </div>

      <p>舊</p>
      {trendValue.map(s => {
        return (
          <Score
            value={s}
            key={s}
          />
        )
      })}
      <p>新</p>
    </div>
  )
})

export const Good178S_Row2 = memo<ReactProps>(function Good178S_Row2(props) {
  const state = useSnapshot(good178S_store)
  const theme = useThemeStore(s => s.theme)
  const topbarColor = theme === 'dark' ? '#141721' : '#ffffff'
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        height: 72px;
        background-color: ${topbarColor};
        gap: 8px;
      `}
    >
      <PriceTrend />
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 32px;
          background-color: ${topbarColor};
          padding: 2px 16px;
          gap: 16px;
        `}
      >
        <FuiButton.Display
          variant='long'
          active={state.usedStrategyTrend === 'long'}
          onClick={() => {
            good178S_store.usedStrategyTrend = 'long'
            store.charting.updateFromState()
          }}
        >
          多方策略
        </FuiButton.Display>

        <FuiButton.Display
          active={state.usedStrategyTrend === 'default'}
          onClick={event => {
            good178S_store.usedStrategyTrend = 'default'
            store.charting.updateFromState()
          }}
        >
          多空策略
        </FuiButton.Display>

        <FuiButton.Display
          variant='short'
          active={state.usedStrategyTrend === 'short'}
          onClick={event => {
            good178S_store.usedStrategyTrend = 'short'
            store.charting.updateFromState()
          }}
        >
          空方策略
        </FuiButton.Display>
      </div>
    </div>
  )
})
