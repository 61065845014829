import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createDesktopCss } from '~/css/createDesktopCss'

import {
  fill_vertical_all_center,
  fill_vertical_main_center,
} from '~/modules/AppLayout/FlexGridCss'

export const Fubon_Footer = memo<ReactProps>(function Fubon_Footer() {
  const theme = useThemeStore(t => t.theme)

  return (
    <styleds.container className={theme}>
      <styleds.infoContent>
        <p>客戶專線</p>
        <p>富邦期貨總公司: (02)2388-2626 (密碼解鎖、憑證註銷) 出金專線: (02)2312-6777</p>
        <p>總公司：100 台北市中正區襄陽路9號3樓、3樓之1、20樓及21樓</p>
        <p>許可字號：112年金管期總字第004號</p>
        <p>©富邦期貨股份有限公司</p>
        <p>最佳瀏覽狀態請使用 Internet Explorer 11.0 以上 1024 x 768 解析度</p>
      </styleds.infoContent>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_all_center};
    &.dark {
      background-color: #222227;
    }
    &.light {
      background-color: #d8d8d8;
    }
  `,
  infoContent: styled.div`
    ${fill_vertical_main_center};
    gap: 4px;
    & > * {
      padding: 4px 0px;
      margin: 0px;
      line-height: 2;
      font-size: 14px;
    }

    ${createDesktopCss(
      css`
        width: 960px;
      `,
    )}
  `,
}
