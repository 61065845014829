import { proxy } from 'valtio'
import { FuiUserAvatar } from '../heineken_template/components/FuiUserAvatar'

export const datatree_store = proxy({
  userAvatar: new FuiUserAvatar(),
  /** 大戶動向 (bs) (trend) */
  valueTrend: 0 as number,

  /** 大戶掛單能量 (ti) (power) */
  valuePower: 0 as number,

  /** 散戶能量 (ti) (small power) */
  valueSmallPower: 0 as number,
})
