import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { store } from '~/pages/heineken_template/_private/store'
import { winner_tx_attack } from '~/trades/indicators/winner/winner_tx_attack'
import { clas } from '~/utils/clas'

const classes = {
  Button: styled.button`
    display: flex;
    width: 100%;
    padding: 4px 8px;
    place-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
  `,
  ButtonOfActive: styled.div``,
}

/** # 攻擊線指標 */
const Button = memo<ReactProps>(function AttackLineToggle(props) {
  const hasAttackLine = useSnapshot(store.charting)
    .indicators.map(item => item.displayName)
    .includes(winner_tx_attack.displayName)

  return (
    <classes.Button
      className={clas([props.className, hasAttackLine && classes.ButtonOfActive])}
      onClick={event => {
        if (hasAttackLine) {
          store.charting.removeIndicatorsById(new RegExp(winner_tx_attack.id))
        } else {
          store.charting.addIndicators([winner_tx_attack])
        }
      }}
    >
      {hasAttackLine ? '隱藏' : '顯示'}攻擊線
    </classes.Button>
  )
})

export default {
  classes,
  Button,
}
