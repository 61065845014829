import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import { usePercentDiffValue } from '~/modules/SDK/percentDiffChart/usePercentDiffValue'
import PriceDiffChart from '~/modules/SDK/priceDiffChart'
import usePriceDiffValue from '~/modules/SDK/priceDiffChart/usePriceDiffValue'
import IntradayTrendChart from '~/modules/trendChart'
import StockContribtuionTable from '~/pages/futuresai/monitors/component/StockContribtuionTable'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import PercentDiffChart from '~/modules/SDK/percentDiffChart'

const PriceDiff = memo<ReactProps>(function TradeInfoCard() {
  const { diffValue, diffUpdateTime } = usePriceDiffValue()
  return (
    <styleds.container>
      <styleds.priceDiffTitle>
        <styleds.titleDescribe>期現盤中價差圖</styleds.titleDescribe>
        <span>價差: {diffValue} </span>
        <span>更新時間: {diffUpdateTime}</span>
      </styleds.priceDiffTitle>

      <div
        css={css`
          width: 100%;
          height: 120px;
          background-image: linear-gradient(177deg, #2a2b34, #1c1d24);
          border-radius: 4px;
        `}
      >
        <PriceDiffChart
          symbol1='TXAM-1'
          symbol2='TSEA'
        />
      </div>
    </styleds.container>
  )
})

const ElectricGoldPriceDiff = memo<ReactProps>(function ElectricGoldPriceDiff() {
  const { changPercent1, changPercent2, changPercentUpdateTime } = usePercentDiffValue(
    'TSE13',
    'TSE17',
  )

  return (
    <styleds.container>
      <styleds.priceDiffTitle>
        <styleds.titleDescribe>電金指數盤中漲跌幅差值</styleds.titleDescribe>
        <span>電子: {isNaN(changPercent1) ? '-' : changPercent1}%</span>
        <span>金融: {isNaN(changPercent2) ? '-' : changPercent2}%</span>
      </styleds.priceDiffTitle>
      <styleds.trendChart>
        <PercentDiffChart.dispaly
          symbol1='TSE13'
          symbol2='TSE17'
        />
      </styleds.trendChart>
      <TitleSymbolQuote.Default symbol={'TSE13'} />
      <styleds.trendChart>
        <IntradayTrendChart
          symbol={'TSE13'}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2}
          priceTicksSize={14}
        />
      </styleds.trendChart>
      <TitleSymbolQuote.Default symbol={'TSE17'} />
      <styleds.trendChart>
        <IntradayTrendChart
          symbol={'TSE17'}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2}
          priceTicksSize={14}
        />
      </styleds.trendChart>
    </styleds.container>
  )
})

const StockContribtuion = memo<ReactProps>(function StockContribtuion() {
  return (
    <styleds.container>
      <StockContribtuionTable.Display limit={10} />
    </styleds.container>
  )
})

export default {
  PriceDiff: PriceDiff,
  ElectricGoldPriceDiff: ElectricGoldPriceDiff,
  StockContribtuion: StockContribtuion,
}

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
  `,
  trendChart: styled.div`
    width: 100%;
    height: 100px;
  `,
  priceDiffTitle: styled.div`
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 30px;
    font-size: 14px;
    background-image: linear-gradient(177deg, #2a2b34, #1c1d24);
    border-radius: 4px;
    padding: 0px 8px;
  `,
  titleDescribe: styled.div`
    background-color: #3c3e48;
    padding: 0px 4px;
    border-radius: 2px;
    font-weight: 400;
    color: #f1f1f1;
  `,
}
