import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { reduce } from 'lodash'
import React, { memo, useEffect, useMemo } from 'react'
import { Margin } from 'recharts'
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts-new'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fontWeight600 } from '~/css/font'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { getSymbolFromTo } from '~/modules/monitor/getSymbolFromToUtil'
import { getSymbolTicks } from '~/modules/monitor/getSymbolTicksUtil'
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { useDatePick } from '~/modules/screener/useDatePick'
import { Socket } from '~/modules/SDK/socket2/Socket'
import dayAPI from '~/utils/dayAPI'
import { useSignalrStore } from '../Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '../Signalr/useSignalrStoreValueOHLC'

const categoryChartMargin: Margin = {
  top: 8,
  right: 8,
  left: 0,
  bottom: 0,
}
/** 目前只支援國內商品 `symbol1：可以放期貨|現貨` `symbol2：只能放現貨商品` */
const PercentDiffChart = memo<
  ReactProps<{
    symbol1: Socket.SymbolString
    symbol2: Socket.SymbolString
    noAutoSubscribe?: boolean
  }>
>(function PriceDiffChart(props) {
  const noAutoSubscribe = props.noAutoSubscribe ?? false
  const theme = useThemeStore(s => s.theme)
  const tickFill = theme === 'dark' ? '#cccccc' : '#222222'

  useEffect(() => {
    if (noAutoSubscribe) return
    useSignalrStore.getState().subscribeAdd([...([props.symbol1, props.symbol2] || [])], 'ohlc')
    return () => {
      useSignalrStore
        .getState()
        .subscribeRemove([...([props.symbol1, props.symbol2] || [])], 'ohlc')
    }
  }, [noAutoSubscribe, props.noAutoSubscribe, props.symbol1, props.symbol2])

  //價差數字
  const data = [props.symbol1, props.symbol2] as string[]
  const symbolsInfo = useSignalrStoreValueOHLC(state => state.useGroupOHLC(data))
  const lastTradePrevRef1 = symbolsInfo[0]?.prevRef ?? 0
  const lastTradePrevRef2 = symbolsInfo[1]?.prevRef ?? 0

  //--實驗性

  /* 最近交易日 */
  const lastTradeString = useDatePick()
  const lastTradeDay = dayAPI(lastTradeString)
  /* 一般日期 */
  const baseDate =
    new Date().getHours() < 9 || new Date().getDay() === 6 || new Date().getDay() === 0
      ? lastTradeDay
      : dayAPI()

  const resultDate = baseDate
  const tickRange = getSymbolTicks(props.symbol2, resultDate)
  const indexRange = getSymbolFromTo(props.symbol2, resultDate)

  /* 拿商品1資料 */
  const { data: historyData1 } = useSymbolHistoryResource({
    symbol: props.symbol1,
    fromTo: indexRange,
    resolution: '1',
    intraday: true,
  })

  /* 拿商品2資料 */
  const { data: historyData2 } = useSymbolHistoryResource({
    symbol: props.symbol2,
    fromTo: indexRange,
    resolution: '1',
    intraday: true,
  })

  /** 商品1資料區間 */
  const symbolData1 = useMemo(() => {
    return historyData1?.map(datum => {
      return {
        /** Recharts x 軸時間資料 */
        unixtime: dayAPI(datum.time).unix(),
        /** Recharts 圖表座標 */
        datetime: dayAPI(datum.time).format('HH:mm'),
        /** 漲跌幅 */
        changePercent: (datum.close / lastTradePrevRef1 - 1) * 100,
      }
    })
  }, [lastTradePrevRef1, historyData1])

  /** 商品2資料區間 */
  const symbolData2 = useMemo(() => {
    return historyData2?.map(datum => {
      return {
        /** Recharts x 軸時間資料 */
        unixtime: dayAPI(datum.time).unix(),
        /** Recharts 圖表座標 */
        datetime: dayAPI(datum.time).format('HH:mm'),
        /** 漲跌幅 */
        changePercent: (datum.close / lastTradePrevRef2 - 1) * 100,
      }
    })
  }, [lastTradePrevRef2, historyData2])

  //用來判斷是擷取 09:00~13:30 | 08:45~13:44
  const lengthSlice = props.symbol1.includes('-') ? [15, 286] : [0, 300]

  const changePercent1 =
    symbolData1
      ?.map(a => Math.round(a.changePercent * 10) / 10)
      .slice(lengthSlice[0], lengthSlice[1]) ?? []

  const changePercent2 =
    symbolData2
      ?.map(a => Math.round(a.changePercent * 10) / 10)
      .slice(lengthSlice[0], lengthSlice[1]) ?? []

  const dateTime1 = symbolData1?.map(a => a.unixtime).slice(lengthSlice[0], lengthSlice[1]) ?? []

  //漲跌幅差
  const changePercentValue = changePercent1.map(function (num, idx) {
    return num - changePercent2[idx]
  })

  //數值
  const valueType = changePercentValue

  //純價差 [value: close]
  const value = valueType.map(x => ({ value: x }))

  //餵給recharts的data格式
  let resultData: {
    diff: number
    datetime: number
    symbol1: number
    symbol2: number
  }[] = []

  resultData = reduce(
    valueType,
    (prev, value_, index) => {
      prev[index] = {
        diff: valueType[index],
        datetime: dateTime1[index],
        symbol1: changePercent1[index],
        symbol2: changePercent2[index],
      }
      return prev
    },
    [] as typeof resultData,
  )

  //YAxis 設定
  const changePercentYAxisProps: YAxisProps = {
    dataKey: 'changePercent',
    hide: true,
    yAxisId: 2,
  }
  const valueYAxisProps: YAxisProps = {
    dataKey: 'value',
    hide: false,
    yAxisId: 1,
    tick: { fontSize: 14, fill: tickFill },
    tickFormatter: string => string.toFixed(2) + '%',
  }
  const maxValue = Math.max(...value.map(a => a.value))
  const minValue = Math.min(...value.map(a => a.value))
  const valueDiffRange: [number, number] = [minValue, maxValue]
  const valueTickRange: number[] = [minValue, maxValue]

  const highPercentRange = Math.max(...changePercent1, ...changePercent2)
  const LowPercentRange = Math.min(...changePercent1, ...changePercent2)
  const max = Math.max(Math.abs(LowPercentRange), Math.abs(highPercentRange))
  const changePercentRange: [number, number] = [max * -1.25, max * 1.25]

  //XAxis 設定
  const xAxisProps: XAxisProps = {
    type: 'number',
    dataKey: 'datetime',
    domain: indexRange,
    tickMargin: 0,
    interval: 0,
    ticks: tickRange,
    hide: false,
    height: 20,
    tick: { fontSize: 14, fill: tickFill },
    tickFormatter: timeStr => new Date(timeStr * 1000).getHours().toString(),
  }

  //客製化提示
  const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
    if (!payload || (payload && payload.length < 1)) return null
    const datum = payload[0].payload

    const changePct1 = datum.symbol1
    const changePct2 = datum.symbol2
    const arrowSymbol = (changePct: number) => {
      return changePct === 0 ? '-' : changePct > 0 ? '▲' : '▼'
    }
    const changeColor = (changePct: number) => {
      return changePct === 0 ? tickFill : changePct > 0 ? '#ee2222' : '#11cc11'
    }

    const ItemText = styled.div`
      margin-left: 8px;
      color: ${props_ => props_.color};
      ${fontWeight600}
    `

    const backgroundFill = theme === 'dark' ? '#222222' : '#eeeeee'
    const stringFill = theme === 'dark' ? '#cccccc' : '#222222'
    return (
      <div
        css={css`
          padding: 0px 5px 0px 5px;
          background-color: ${backgroundFill};
          color: ${stringFill};
          opacity: 0.8;
          border: 1px solid #b4b4b4;
          border-radius: 5px;
          font-size: 14px;
          line-height: 20px;
        `}
      >
        <div css={flex.h.default}>
          時間
          <ItemText>{dayAPI(datum.datetime * 1000).format('HH:mm')}</ItemText>
        </div>
        <div css={flex.h.default}>
          <div
            css={css`
              color: ${classesFill.mainSymbolLine};
            `}
          >
            {props.symbol1}
          </div>

          <ItemText color={changeColor(changePct1)}>
            {arrowSymbol(changePct1) + changePct1?.toFixed(2) + '%'}
          </ItemText>
        </div>
        <div css={flex.h.default}>
          <div
            css={css`
              color: ${classesFill.compareSymbolLine};
            `}
          >
            {props.symbol2}
          </div>
          <ItemText color={changeColor(changePct2)}>
            {arrowSymbol(changePct2) + changePct2?.toFixed(2) + '%'}
          </ItemText>
        </div>
        <div css={flex.h.default}>
          幅度差
          <ItemText color={'#2CA8F5'}>{datum.diff?.toFixed(2) + '%'}</ItemText>
        </div>
      </div>
    )
  }

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <ResponsiveContainer
        width='100%'
        height='100%'
      >
        <ComposedChart
          data={resultData}
          margin={categoryChartMargin}
        >
          <YAxis
            {...valueYAxisProps}
            domain={valueDiffRange}
            ticks={valueTickRange}
          />
          <YAxis
            {...changePercentYAxisProps}
            domain={changePercentRange}
          />
          <XAxis {...xAxisProps} />
          <Bar
            yAxisId={1}
            dataKey='diff'
            barSize={1}
            isAnimationActive={false}
            fill={classesFill.diffBarFill}
          />

          <Line
            yAxisId={2}
            type='monotone'
            dataKey='symbol1'
            stroke={classesFill.mainSymbolLine}
            strokeWidth={1.5}
          />
          <Line
            yAxisId={2}
            type='monotone'
            dataKey='symbol2'
            stroke={classesFill.compareSymbolLine}
            strokeWidth={1.5}
          />
          <Tooltip content={<CustomizedTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
})

const classesFill = {
  diffBarFill: '#2763D6',
  mainSymbolLine: '#ffff00',
  compareSymbolLine: '#aa00aa',
}

export default { dispaly: PercentDiffChart, classesFill }
