import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import GaugeChart from 'react-gauge-chart'
import { useThemeStore } from '~/components/theme/useThemeStore'
import {
  fill,
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'

const TemperatureGauges = memo<ReactProps<{ currentValue: number }>>(function TemperatureGauges(
  props,
) {
  const theme = useThemeStore(s => s.theme)

  const needleFill = theme === 'dark' ? classesFill.needleDark : classesFill.needleLight

  return (
    <classes.Root css={baseStyled}>
      <classes.container>
        <classes.mark>
          <classes.itemTop
            width={100}
            margin={0}
          >
            <div>盤整</div>
          </classes.itemTop>
          <classes.item
            width={60}
            margin={-18}
          >
            <div>偏空</div>
            <div>偏多</div>
          </classes.item>
          <classes.item
            width={88}
            margin={0}
          >
            <div>強空</div>
            <div>強多</div>
          </classes.item>
          <classes.item
            width={100}
            margin={8}
          >
            <div>過冷</div>
            <div>過熱</div>
          </classes.item>
        </classes.mark>
        <div
          css={css`
            ${fill_horizontal_all_center};
            position: absolute;
            margin-top: 22px;
            width: 100%;
          `}
        >
          <classes.icon className={theme} />
          <GaugeChart
            className={theme}
            id={'chart'}
            percent={props.currentValue / 100}
            formatTextValue={() => ''}
            nrOfLevels={100}
            textColor='transparent'
            arcPadding={0.0}
            cornerRadius={0}
            arcWidth={0.1}
            needleBaseColor={needleFill}
            needleColor={needleFill}
            colors={['#54a843', '#8fd673', '#bce1ad', '#e9e9df', '#f7d1d0', '#e5a2a0', '#e45751']}
            arcsLength={[0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075]}
          />
        </div>
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    ${fill_vertical_cross_center};
  `,
  container: styled.div`
    ${fill_vertical_cross_center};
    width: 232px;
    height: 124px;
    position: relative;
    font-size: 14px;
  `,
  mark: styled.div`
    ${fill_vertical_all_center};
    width: 100%;
    position: absolute;
    padding: 4px;
  `,
  item: styled.div<{ margin: number; width: number }>`
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin}px;
  `,
  itemTop: styled.div<{ margin: number; width: number }>`
    ${fill_horizontal_all_center};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin};
  `,
  icon: styled.div`
    position: absolute;
    width: 140px;
    height: 70px;
    border-radius: 70px 70px 0 0;
    margin-top: -6px;
  `,
}

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.container} {
  }
  ${classes.mark} {
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #252525;
    }
  }
  ${classes.itemTop} {
  }
  ${classes.item} {
  }
  ${classes.icon} {
    &.dark {
      background: #aaaaaa11;
      border: 1px solid #888888;
      border-width: 2px 2px 0 2px;
    }
    &.light {
      background: #11111111;
      border: 1px solid #cacaca;
      border-width: 2px 2px 0 2px;
    }
  }
`

const classesFill = {
  needleDark: '#ffffff',
  needleLight: '#252525',
}

export default {
  Display: TemperatureGauges,
  classes,
  classesFill,
}
