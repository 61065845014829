import { css } from '@emotion/react'
import { Fragment, memo, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { HoldingCostPrice } from '~/modules/investment-consultant/positionValueFormOld/HoldingCostPrice'
import QuerySettings from '~/modules/screener/components/invesement-consultant/QuerySettings'
import ScreenerSimpleTable from '~/modules/screener/components/invesement-consultant/ScreenerTable'
import { useConditionState } from '~/modules/screener/containers/useConditionState'
import { useIntradayPick } from '~/modules/screener/useDatePick'
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple'
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store'
import { styleds } from '~/pages/good178_stock/_private/good178S_styleds'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import Col_WatchSymbolToggleButton from '~/pages/heineken_template/_col/col_WatchSymbolToggleButton'
import Fr_SimpleTopDown from '~/pages/heineken_template/_fr/fr_SimpleTopDown'
import { store } from '~/pages/heineken_template/_private/store'
import { stock_sg3 } from '~/trades/indicators/good178/stock_sg3'

function useResource(
  target:
    | typeof good178S_store.tabOfToday.store.active
    | typeof good178S_store.tabOf60k.store.active,
) {
  const intraDay = useIntradayPick()

  const targetLinkedName = (
    {
      當日多方訊號: 'good178_stock',
      當日空方訊號: 'good178_stock',
      一小時多方訊號: 'good178_stock_minute',
      一小時空方訊號: 'good178_stock_minute',
    } as const
  )[target]

  const data = useVirtualTransaction(targetLinkedName, {
    beginDatetime: intraDay,
    endDatetime: intraDay,
  })

  return useMemo(() => {
    if (target === '當日多方訊號') {
      return (
        data
          ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
          ?.map(datum => datum.symbol) || []
      )
    }
    if (target === '當日空方訊號') {
      return (
        data
          ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
          ?.map(datum => datum.symbol) || []
      )
    }
    if (target === '一小時多方訊號') {
      return (
        data
          ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
          ?.map(datum => datum.symbol) || []
      )
    }
    if (target === '一小時空方訊號') {
      return (
        data
          ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
          ?.map(datum => datum.symbol) || []
      )
    }

    return []
  }, [data, target])
}

export const Good178S_Sidebar1 = memo<ReactProps>(function Good178S_Sidebar1(props) {
  const tabOfToday = useSnapshot(good178S_store.tabOfToday.store)
  const tabOf60k = useSnapshot(good178S_store.tabOf60k.store)
  const realtimeSymbols = useResource(tabOfToday.active)
  const realtime60Symbols = useResource(tabOf60k.active)
  const state = useSnapshot(good178S_store)
  const theme = useThemeStore(s => s.theme)

  return (
    <styleds.Sidebar theme={theme}>
      <styleds.ModeButtonGroup>
        <good178S_store.tabsOfMain.WithButton to='選股'>
          <FuiButton.Display>選股</FuiButton.Display>
        </good178S_store.tabsOfMain.WithButton>

        <good178S_store.tabsOfMain.WithButton to='當日訊號'>
          <FuiButton.Display>當日訊號</FuiButton.Display>
        </good178S_store.tabsOfMain.WithButton>

        <good178S_store.tabsOfMain.WithButton to='60分K訊號'>
          <FuiButton.Display>60分訊號</FuiButton.Display>
        </good178S_store.tabsOfMain.WithButton>

        <good178S_store.tabsOfMain.WithButton to='自選股'>
          <FuiButton.Display>自選股</FuiButton.Display>
        </good178S_store.tabsOfMain.WithButton>
      </styleds.ModeButtonGroup>

      <Col_WatchSymbolToggleButton.Display groupName='good178_stock_group_1' />

      <styleds.DashboardContent openHoldingPrices={state.holdingPricesCollapse.store.open}>
        <good178S_store.tabsOfMain.WithContent for='當日訊號'>
          <styleds.VirtualTransactionSelector>
            <good178S_store.tabOfToday.WithButton to='當日多方訊號'>
              <FuiButton.Display variant='long'>多方訊號</FuiButton.Display>
            </good178S_store.tabOfToday.WithButton>

            <good178S_store.tabOfToday.WithButton to='當日空方訊號'>
              <FuiButton.Display variant='short'>空方訊號</FuiButton.Display>
            </good178S_store.tabOfToday.WithButton>
          </styleds.VirtualTransactionSelector>

          <styleds.SymbolListContent>
            <SimpleQuoteListInteractWithChart
              data={realtimeSymbols}
              chart={store.charting}
              handleClick={() => {
                store.charting.changeInterval('D')
                store.charting.removeIndicators([stock_sg3])
              }}
            />
          </styleds.SymbolListContent>
        </good178S_store.tabsOfMain.WithContent>

        <good178S_store.tabsOfMain.WithContent for='60分K訊號'>
          <styleds.VirtualTransactionSelector>
            <good178S_store.tabOf60k.WithButton to='一小時多方訊號'>
              <FuiButton.Display variant='long'>多方訊號</FuiButton.Display>
            </good178S_store.tabOf60k.WithButton>

            <good178S_store.tabOf60k.WithButton to='一小時空方訊號'>
              <FuiButton.Display variant='short'>空方訊號</FuiButton.Display>
            </good178S_store.tabOf60k.WithButton>
          </styleds.VirtualTransactionSelector>

          <styleds.SymbolListContent>
            <SimpleQuoteListInteractWithChart
              data={realtime60Symbols}
              chart={store.charting}
              handleClick={() => {
                store.charting.changeInterval('60')
                store.charting.removeIndicators([stock_sg3])
                store.charting.addIndicators([stock_sg3])
              }}
            />
          </styleds.SymbolListContent>
        </good178S_store.tabsOfMain.WithContent>

        <good178S_store.tabsOfMain.WithContent for='選股'>
          <div
            css={css`
              ${fill_vertical_all_center};
            `}
          >
            <useConditionState.Provider>
              <styleds.ScreenerHeader>
                <QuerySettings />
              </styleds.ScreenerHeader>

              <styleds.ScreenerList>
                <ScreenerSimpleTable useChart={store.charting} />
              </styleds.ScreenerList>
            </useConditionState.Provider>
          </div>
        </good178S_store.tabsOfMain.WithContent>

        <good178S_store.tabsOfMain.WithContent for='自選股'>
          <WatchListWithChart
            groupName={'good178_stock_group_1'}
            useChart={store.charting}
          />
        </good178S_store.tabsOfMain.WithContent>
      </styleds.DashboardContent>

      <good178S_store.holdingPricesCollapse.RenderProps>
        {renderProps => (
          <styleds.RenderButton
            theme={theme}
            onClick={event => {
              good178S_store.holdingPricesCollapse.toggle()
            }}
          >
            部位資訊
            {renderProps.open && <div>-</div>}
            {!renderProps.open && <div>+</div>}
          </styleds.RenderButton>
        )}
      </good178S_store.holdingPricesCollapse.RenderProps>
      <styleds.PositionInfoContent openHoldingPrices={state.holdingPricesCollapse.store.open}>
        <good178S_store.holdingPricesCollapse.WithContent>
          <HoldingCostPrice />
        </good178S_store.holdingPricesCollapse.WithContent>
      </styleds.PositionInfoContent>
    </styleds.Sidebar>
  )
})
