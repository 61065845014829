import { memo } from 'react'
import { css, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import type { Trade } from '~/modules/SDK/Trade/Trade'

export const AvailableStrategySelect = memo<
  ReactProps<{
    strategies: Trade.Strategy[]
    changeDelegate: (event: SelectChangeEvent<any>) => void
    value?: string
  }>
>(function AvailableStrategySelect({ strategies, changeDelegate, value }) {
  return (
    <FormControl>
      <InputLabel disableAnimation={true}>選擇策略</InputLabel>
      <Select
        css={css`
          width: 200px;
        `}
        size='small'
        label='選擇策略'
        value={value ?? ''}
        onChange={e => changeDelegate(e)}
      >
        {strategies.map(account => (
          <MenuItem
            key={account.id}
            value={account.name}
          >
            {account.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
