import { max } from 'lodash'
import { type } from 'os'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 多空分界 */
export const s178178_min_kbar_old = createIndicator({
  displayName: '多空分界(min)舊版',
  id: 's178178minkbarold',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const interval = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W
      const display = !interval.includes('D')
      if (display) {
        const in1 = this._input(0)
        const in2 = this._input(1)

        const sar_type = this._input(6) === 0 ? 'nonLinear' : 'linear'
        const close = this.PineJS.Std.close(this._context)
        const high = this.PineJS.Std.high(this._context)
        const low = this.PineJS.Std.low(this._context)
        const close_array = this._context.new_var(close)

        const day_high = this._context.new_var()
        const day_low = this._context.new_var()

        const itime = this.PineJS.Std.time(this._context)
        const start_h = new Date(itime).getHours()
        const start_m = new Date(itime).getMinutes()
        const amplitude = this._context.new_var()
        const avgAmplitude = this._context.new_var()
        avgAmplitude.get(1)
        amplitude.get(10)

        if (isNaN(avgAmplitude.get(0))) {
          avgAmplitude.set(0)
        }

        if (start_h === 9 && start_m === 0) {
          day_high.set(high)
          day_low.set(low)
        }

        if (start_h >= 9 && start_h < 13) {
          if (high > day_high.get(0)) {
            day_high.set(high)
          }
          if (low < day_low.get(0)) {
            day_low.set(low)
          }
        }
        if (start_h === 13 && start_m === 0) {
          amplitude.set(day_high.get(0) - day_low.get(0))
          const day4 = amplitude.get(0) + amplitude.get(1) + amplitude.get(2) + amplitude.get(3)
          avgAmplitude.set(day4 / 4)
        }

        const sma1 = this.PineJS.Std.sma(close_array, in1, this._context)
        const sma1_array = this._context.new_var(sma1)
        const stdev = this.PineJS.Std.stdev(close_array, in1, this._context)
        const band = stdev * in2
        const band_up = sma1 + band
        const band_dn = sma1 - band
        const bigSma = this.PineJS.Std.sma(close_array, this._input(8), this._context)
        sma1_array.get(1)
        sma1_array.get(2)
        close_array.get(1)

        const state = this._context.new_var()
        state.get(0)
        state.get(1)

        const long = close > band_up && close_array.get(1) < band_up && close > bigSma
        const short = close < band_dn && close_array.get(1) > band_dn && close < bigSma

        if (long) {
          state.set(1)
        }
        if (short) {
          state.set(-1)
        }
        const strength = this._input(7)
        const long_stdv = this._input(2)
        const long_af = this._input(3)
        const sar_long = this.sarLongStopLoss(
          sar_type,
          strength,
          state,
          20,
          long_stdv,
          long_af,
          0.5,
        )
        const short_stdv = this._input(4)
        const short_af = this._input(5)
        const sar_short = this.sarShortStopLoss(
          sar_type,
          strength,
          state,
          20,
          short_stdv,
          short_af,
          0.5,
        )

        const long_end = low < sar_long.get(0) || close < bigSma
        const short_end = high > sar_short.get(0) || close > bigSma

        if (state.get(0) === 1 && long_end) {
          state.set(0)
        }
        if (state.get(0) === -1 && short_end) {
          state.set(0)
        }

        const colorIndex = state.get(0) === 1 ? 0 : state.get(0) === -1 ? 2 : 1

        return [sar_long.get(0), sar_short.get(0), bigSma, colorIndex]
      } else return [NaN, NaN, NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],

    defaults: {
      styles: {
        plot_0: {
          color: '#ffff00',
          transparency: 95,
          linewidth: 1,
          plottype: 7,
          visible: true,
        },
        plot_1: {
          color: '#00ffff',
          transparency: 95,
          linewidth: 1,
          plottype: 7,
          visible: true,
        },
        plot_2: {
          color: '#00ffff',
          transparency: 0,
          linewidth: 1,
          plottype: 7,
          visible: true,
        },
      },
      //樣式調整
      inputs: {
        in_0: 240,
        in_1: 1,
        in_2: 4,
        in_3: 0.0075,
        in_4: 3,
        in_5: 0.0095,
        in_6: 0,
        in_7: 0,
        in_8: 480,
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ff0000',
            width: 1,
            style: 0,
          },
          1: {
            color: '#bbbb00',
            width: 1,
            style: 0,
          },
          2: {
            color: '#00ee00',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: 'ma1_length',
        defval: 240,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_1',
        name: 'stdv',
        defval: 1,
        type: 'float',
        min: 1,
        max: 5,
      },
      {
        id: 'in_2',
        name: '多單出場-標準差',
        defval: 4,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_3',
        name: '多單出場-sar起始',
        defval: 0.0075,
        type: 'float',
        min: 0.00001,
        max: 1e4,
      },
      {
        id: 'in_4',
        name: '空單出場-標準差',
        defval: 3,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_5',
        name: '空單出場-sar起始',
        defval: 0.0095,
        type: 'float',
        min: 0.00001,
        max: 1e4,
      },
      {
        id: 'in_6',
        name: '非線性/線性',
        defval: 0,
        type: 'integer',
        min: 0,
        max: 1,
      },
      {
        id: 'in_7',
        name: 'sar-強度',
        defval: 0,
        type: 'integer',
        min: 0,
        max: 10,
      },
      {
        id: 'in_8',
        name: '大週期均線',
        defval: 480,
        type: 'integer',
        min: 60,
        max: 1000,
      },
    ],
    scriptIdPart: '',
  },
})
