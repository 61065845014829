import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { asiajyeStore } from '~/pages/asiajye/_private/asiajyeStore'
import { store } from '~/pages/heineken_template/_private/store'
import { bandtrade } from '~/trades/indicators/asiajye/bandtrade'
import { daytrade } from '~/trades/indicators/asiajye/daytrade'
import { gaptrade } from '~/trades/indicators/asiajye/gaptrade'
import { power } from '~/trades/indicators/asiajye/power'
import { pricetrade } from '~/trades/indicators/asiajye/pricetrade'
import { userkd } from '~/trades/indicators/asiajye/userkd'
import { usermacd } from '~/trades/indicators/asiajye/usermacd'

/** 主要指標集：gap */
const gapTradeIndicators = [gaptrade, power]
/** 主要指標集：day */
const dayTradeIndicators = [daytrade, power]
/** 主要指標集：band */
const bandTradeIndicators = [bandtrade, power]

/** 短線指標集：turning */
const turningTradeIndicators = [pricetrade, power]

/** 策略組分類 */
export const asiajye_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '閃電多空',
      indicators: gapTradeIndicators,
      symbol: 'TX-1',
      interval: '1',
    },
    {
      displayName: '探極當沖',
      indicators: dayTradeIndicators,
      symbol: 'TX-1',
      interval: '3',
    },
    {
      displayName: '探極波段(特別會員限定)',
      indicators: bandTradeIndicators,
      symbol: 'TX-1',
      interval: '60',
    },
  ] as ChartTypes.StrategyConfig[],

  /** 短線策略集 */
  turning: [
    {
      displayName: '轉折多空',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.99667 : 1.00333
        asiajyeStore.turningTrade.assistPriceValue = 17
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
    {
      displayName: '短線轉折',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.99667 : 1.00333
        asiajyeStore.turningTrade.assistPriceValue = 0
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
    {
      displayName: '天地線1',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.994745 : 1.005255
        asiajyeStore.turningTrade.assistPriceValue = 0
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
    {
      displayName: '短波轉折',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.99282 : 1.00718
        asiajyeStore.turningTrade.assistPriceValue = 0
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
    {
      displayName: '天地線2',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.990855 : 1.009145
        asiajyeStore.turningTrade.assistPriceValue = 0
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
    {
      displayName: '長波轉折',
      indicators: turningTradeIndicators,
      symbol: 'TXAM-1',
      interval: '1',
      onLoad: () => {
        asiajyeStore.turningTrade.priceValue =
          asiajyeStore.turningTrade.turningMP === 1 ? 0.98889 : 1.01111
        asiajyeStore.turningTrade.assistPriceValue = 0
        asiajyeStore.turningTrade.position = asiajyeStore.turningTrade.turningMP
      },
    },
  ] as ChartTypes.StrategyConfig[],
}

export const asiajye_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...turningTradeIndicators,
    ...gapTradeIndicators,
    ...dayTradeIndicators,
    ...bandTradeIndicators,
    userkd,
    usermacd,
  ]

  store.charting.initStrategy({
    configs: [...asiajye_strategiesGroup.main, ...asiajye_strategiesGroup.turning],
  })
}
