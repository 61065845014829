import { css } from '@emotion/react'
import { memo } from 'react'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'

export const Winner98_Warring = memo(function Winner98_Warring() {
  return (
    <div
      css={css`
        ${fill_vertical_all_center}
        background-color:#252932;
        border-radius: 5px;
        padding: 0px 4px;
      `}
    >
      <span>
        本網頁／網站所載之投資分析內容，屬倫元證券投資顧問股份有限公司所有
        如需相關服務，請洽詢該公司客服電話：02-77251377
      </span>
    </div>
  )
})
