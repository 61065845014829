import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'

const styleOptions = {
  sideBar1: {
    gap: 'gap: 4px;',
    padding: 'padding: 4px;',
    backgroundColor: 'background-color: #16161e',
  },
  intradayTrendChart: {
    width: 'width: 100%;',
    height: 'height: 180px;',
  },
  screenerResultTitle: {
    width: 'width: 100%;',
    height: 'height: 32px;',
    backgroundColor: 'background-color: #a85958;',
    borderRadius: 'border-radius: 5px;',
    fontSize: 'font-size: 16px;',
  },
  screenerContent: {
    width: 'width: 100%;',
    height: 'height: calc(100% - 380px);',
    gap: 'gap: 4px;',
  },
  screenerResultContentTitle: {
    width: 'width: 100%;',
    height: 'height: 28px;',
    borderRadius: 'border-radius: 7px;',
  },
  screenerResultItem: {
    width: 'width: 100%;',
    height: 'height: calc(50% - 36px);',
  },
}

const styleds = {
  //#region Side Pane 1

  SideBar1: styled.div`
    ${fill_vertical_cross_center}
    ${styleOptions.sideBar1.backgroundColor};
    ${styleOptions.sideBar1.gap};
    ${styleOptions.sideBar1.padding};
  `,

  IntradayTrendChart: styled.div`
    ${styleOptions.intradayTrendChart.height}
    ${styleOptions.intradayTrendChart.width}
  `,

  ScreenerResultTitle: styled.div`
    ${fill_vertical_all_center}
    ${styleOptions.screenerResultTitle.width}
    ${styleOptions.screenerResultTitle.height}
    ${styleOptions.screenerResultTitle.backgroundColor}
    ${styleOptions.screenerResultTitle.fontSize}
  `,
  ScreenerResultContent: styled.div`
    ${fill_vertical_cross_center}
    ${styleOptions.screenerContent.width};
    ${styleOptions.screenerContent.height};
    ${styleOptions.screenerContent.gap};
  `,
  IndexState: styled.div`
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #09386a;
    border-radius: 5px;
    gap: 4px;
    font-size: 18px;
  `,

  //#endregion
}

export default styleds
