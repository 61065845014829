import { proxy } from 'valtio'

export type TrendState =
  | '多方強勢'
  | '空方強勢'
  | '多方震盪'
  | '空方震盪'
  | '拉回支撐'
  | '反彈壓力'
  | '盤整盤'

export const trendStore = proxy({
  state: '---' as TrendState,
})
