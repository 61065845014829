import { max } from 'lodash'
import { type } from 'os'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'

/** 多空分界 */
export const lung88988_trendTrade = createIndicator({
  displayName: '波段參考',
  id: 'lung88988-trendTrade',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))

      const ticker2 = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol2 = ticker2 + '#opof'
      this._context.new_sym(symbol2, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const positionType = useChartPositionStore.positionType

      const interval = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W
      const display = !interval.includes('D')
      if (display) {
        //奇妙的多商品code------------------------------------------------
        const i = this._context.new_unlimited_var(this._context.symbol.time)

        //#bs_indicator_acc------------------------------------------------
        this._context.select_sym(1)
        const time1 = this._context.new_unlimited_var(this._context.symbol.time)
        const quote1 = this.PineJS.Std.high(this._context)
        const unlimited1 = this._context.new_unlimited_var(quote1)

        const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼
        const value1_array = this._context.new_var(value1)
        value1_array.get(1000)

        //#opof------------------------------------------------
        this._context.select_sym(2)
        const time2 = this._context.new_unlimited_var(this._context.symbol.time)
        const qote2 = this.PineJS.Std.close(this._context)
        const unlimited2 = this._context.new_unlimited_var(qote2)

        const value2 = unlimited2.adopt(time2, i, 0) // <=這是籌碼
        const value2_array2 = this._context.new_var(value2)
        value2_array2.get(1000)

        //TX-1商品------------------------------------------------
        this._context.select_sym(0)
        this.PineJS.Std.period(this._context)
        const close = this.PineJS.Std.close(this._context) // <=這是商品本身
        const open = this.PineJS.Std.open(this._context)
        const itime = this.PineJS.Std.time(this._context)
        const itime_array = this._context.new_var(itime)
        const high = this.PineJS.Std.high(this._context)
        const low = this.PineJS.Std.low(this._context)
        const close_array = this._context.new_var(close)
        const open_array = this._context.new_var(open)

        //參數宣告------------------------------------------------
        const start_h = new Date(itime).getHours()
        const start_m = new Date(itime).getMinutes()
        const entryPrice = this._context.new_var()
        const entryTime = this._context.new_var()
        const exitPrice = this._context.new_var()
        const pvflag = this._context.new_var()
        const marketPosition = this._context.new_var()
        const kbarPoint = 5
        const resetKbarCount = 10

        const bigSma = this.PineJS.Std.sma(close_array, 360, this._context)
        const smallSma = this.PineJS.Std.sma(close_array, 60, this._context)
        const bigSma_array = this._context.new_var(bigSma)
        const smallSma_array = this._context.new_var(smallSma)

        close_array.get(10)
        open_array.get(10)
        bigSma_array.get(10)
        smallSma_array.get(10)
        marketPosition.get(10)
        itime_array.get(30)
        exitPrice.get(5)
        entryPrice.get(5)

        if (isNaN(entryTime.get(0))) {
          entryTime.set(0)
        }
        if (isNaN(entryPrice.get(0))) {
          entryPrice.set(0)
        }

        let buy_icon = NaN
        let short_icon = NaN
        let buy_exit_icon = NaN
        let short_exit_icon = NaN
        let buy_target_icon = NaN
        let short_target_icon = NaN

        //條件------------------------------------------------

        const openD = this.dailyOpen(1, 2)
        //const closeD = this.dailyClose(1, 2)
        const state = this._context.new_var()

        const baseValue1 = start_h >= 8 && start_h < 13 ? 50 : 0
        const baseLong = value2 > baseValue1 && value1 > baseValue1
        const baseShort = value2 < -baseValue1 && value1 < -baseValue1

        //部位確立------------------------------------------------
        if (start_h === 12) {
          if (value1 > 100 && close > openD[0]) {
            state.set(1)
          }
          if (value1 < 100 && close < openD[0]) {
            state.set(-1)
          }
          if (value1 > 1000 && close > openD[0] && positionType !== 'short') {
            marketPosition.set(1)
          }
          if (value1 < 1000 && close < openD[0] && positionType !== 'long') {
            marketPosition.set(-1)
          }
        }
        if (start_h > 12 && state.get(0) === 1 && value1 > 100 && positionType !== 'short') {
          marketPosition.set(1)
        }
        if (start_h > 12 && state.get(0) === -1 && value1 < 100 && positionType !== 'long') {
          marketPosition.set(-1)
        }

        //訊號顯示------------------------------------------------
        if (marketPosition.get(0) === 1 && marketPosition.get(1) !== 1) {
          buy_icon = 1
          short_icon = NaN
          entryPrice.set(close)
          entryTime.set(itime)
          pvflag.set(0)
        }

        if (marketPosition.get(0) === -1 && marketPosition.get(1) !== -1) {
          short_icon = 1
          buy_icon = NaN
          entryPrice.set(close)
          entryTime.set(itime)
          pvflag.set(0)
        }

        //多方出場------------------------------------------------
        if (marketPosition.get(0) === 1 && marketPosition.get(1) === 1) {
          if (low < entryPrice - 100) {
            marketPosition.set(0)
            entryTime.set(itime)
          }
          if (pvflag.get(0) === 0 && high > entryPrice + 150) {
            buy_target_icon = 1
            pvflag.set(1)
          }
          if (pvflag.get(0) === 1 && high > entryPrice + 300) {
            marketPosition.set(0)
            entryTime.set(itime)
            buy_exit_icon = 1
            pvflag.set(2)
          }
        }

        //空方出場------------------------------------------------
        if (marketPosition.get(0) === -1 && marketPosition.get(1) === -1) {
          if (high > entryPrice + 100) {
            marketPosition.set(0)
            entryTime.set(itime)
          }
          if (pvflag.get(0) === 0 && low < entryPrice - 150) {
            short_target_icon = 1
            pvflag.set(1)
          }
          if (pvflag.get(0) === 1 && low < entryPrice - 300) {
            marketPosition.set(0)
            entryTime.set(itime)
            short_exit_icon = 1
            pvflag.set(1)
          }
        }

        //時間出場------------------------------------------------
        // if ((start_h === 13 && start_m === 30) || (start_h === 4 && start_m === 30)) {
        //   if (marketPosition.get(0) === 1) {
        //     buy_exit_icon = 1
        //     marketPosition.set(0)
        //   }
        //   if (marketPosition.get(0) === -1) {
        //     short_exit_icon = 1
        //     marketPosition.set(0)
        //   }
        // }

        //k棒變色------------------------------------------------
        const colorIndex = marketPosition.get(0) === 1 ? 0 : marketPosition.get(0) === -1 ? 2 : 1

        return [
          buy_icon,
          short_icon,
          buy_target_icon,
          short_target_icon,
          buy_exit_icon,
          short_exit_icon,
          colorIndex,
        ]
      } else return [NaN, NaN, NaN, NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: 'plot_0',
        type: 'chars',
      },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'B',
        title: '多單參考訊號',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'S',
        title: '空單參考訊號',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '•',
        size: 'tiny',
        text: 'Target',
        title: '多單參考停利訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'BelowBar',
        char: '•',
        size: 'tiny',
        text: 'Target',
        title: '空單參考停利訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'tiny',
        text: 'Exit',
        title: '多單參考停利訊號',
      },
      plot_5: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'tiny',
        text: 'Exit',
        title: '空單參考停利訊號',
      },
    },
    defaults: {
      styles: {
        plot_0: {
          color: '#dd5e56',
          transparency: 0,
          linewidth: 1,
          plottype: 7,
          visible: true,
        },
        plot_1: {
          color: '#52a49a',
          transparency: 0,
          linewidth: 1,
          plottype: 7,
          visible: true,
        },
        plot_2: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 20,
          visible: true,
        },
        plot_3: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 20,
          visible: true,
        },
        plot_4: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_5: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 0,
          visible: true,
        },
      },
      //樣式調整
      inputs: {
        in_0: '能量+均線',
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ff0000',
            width: 1,
            style: 0,
          },
          1: {
            color: '#bbbb00',
            width: 1,
            style: 0,
          },
          2: {
            color: '#00ee00',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '策略模式',
        defval: '能量+均線',
        type: 'text',
        options: ['純能量', '能量+均線', '嚴苛'],
      },
    ],
    scriptIdPart: '',
  },
})
