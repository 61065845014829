import { proxy } from 'valtio'
import { FuiDatePicker } from '~/pages/heineken_template/components/FuiDatePicker'
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2'

export const weng888Stock_agentStore = proxy({
  /**
   * ## `true` 低價股模式
   *
   * ## `false` 高價股模式
   */
  stockPriceLowMode: false,

  /** # 選股日期 */
  stockPickedDate: new FuiDatePicker(),

  /** 持有股票標的方向 */
  holdingPositionType: 'long' as 'long' | 'short',

  /** 是否在持有中模式 */
  isHoldingSymbol: false as boolean,

  tabs: new FuiTabs2(['智能選股', '自選股', '當日訊號', '當沖訊號']),
})
