import { css } from '@emotion/react'
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import { ManualSignalClasses } from '~/modules/investment-consultant/signalNotify/ManualSignal'

export const jerry597_initStyling = (templateProps: TemplateProps) => {
  store.charting.darkOverrides = {
    'mainSeriesProperties.showPriceLine': false,
    'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
    'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
    'mainSeriesProperties.candleStyle.downColor': '#00ff00',
    'mainSeriesProperties.candleStyle.upColor': '#ff0000',
    'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
    'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
    'mainSeriesProperties.candleStyle.drawBorder': false,

    'paneProperties.topMargin': 10,
    'paneProperties.bottomMargin': 10,
    'paneProperties.vertGridProperties.color': '#252525',
    'paneProperties.horzGridProperties.color': '#252525',
    'paneProperties.horzGridProperties.style': 1,
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': '#151515',
    'paneProperties.legendProperties.showSeriesOHLC': true,

    'timeScale.rightOffset': 5,

    'scalesProperties.fontSize': 16,
    'scalesProperties.textColor': '#f8f9f9',

    'scalesProperties.showSeriesLastValue': true,
    'scalesProperties.showStudyLastValue': true,
  }

  templateProps.globalCssset = css`
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 3px;
        height: 32px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #dddddd;
        background-color: #3e4545;
        border: 1px solid #555555;
        &:hover {
          background-color: #4e5656;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background: linear-gradient(327deg, #d26f22, #baa81e);
        border: 1px solid #828aa1;
        transition: 0.5s;
        &:hover {
          background: linear-gradient(327deg, #e27f32, #baa81e);
          border: 1px solid #828aa1;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      .${PositionInfomationClasses.ItemTitle} {
        background-color: #c6d8e4;
      }
      .${PositionInfomationClasses.ItemValue} {
        background-color: #f3faff;
      }
      .${ManualSignalClasses.TimeButton} {
        background-color: #4d4d4d;
      }
      .${ManualSignalClasses.TimeButtonOnstate} {
        background-color: #4d4d4d;
        border: 1px solid #fe952a;
        &:hover {
        }
      }
    }
  `
}
