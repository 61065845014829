import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import MarqueeSymbolQuote from './component/MarqueeSymbolQuote'

export const DataTree_MarqueeQuote = memo<ReactProps>(function DataTree_MarqueeQuote() {
  const symbol = ['2330', '2002', '2603', '6116', '2323', '1313', '8069', 'TX-1', 'NQ-1']

  return (
    <styleds.container>
      <MarqueeSymbolQuote.Display symbol={symbol} />
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_all_center};
    background-color: #161726;
    padding: 4px;
    border-bottom: 1px solid #444444;
  `,
}
