import camelcaseKeys from 'camelcase-keys'
import { Dayjs } from 'dayjs'
import useSWR from 'swr-0-5-6'
import { apirc } from '~/configs/apirc'
import type { Option } from '~/modules/options/utils/Option'

export const useOptionsGreeksResource = (params?: { contract: string }) => {
  const restfulApiURL = apirc.options.optionsgreeksUrlGet({
    contract_month: params?.contract,
  })

  const swr = useSWR<Option.OptionsGreeksData[]>(params?.contract ? restfulApiURL : null, {
    revalidateOnFocus: false,
    refreshInterval: 30000,
  })

  return {
    res: {
      ...swr,
      data: swr.data ? (camelcaseKeys(swr.data) as Option.OptionsGreeksData[]) : null,
    },
  }
}
