//MarqueeSymbolQuote

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { store } from '~/pages/heineken_template/_private/store'
import MarqueeSymbolBody from './MarqueeSymbolBody'

const MarqueeSymbolQuote = memo<ReactProps<{ symbol: string[]; speed?: number }>>(
  function MarqueeSymbolQuote(props) {
    const symbol = props.symbol
    const speed = props.speed ?? 30

    useEffect(() => {
      signalrStore2.addQuote(symbol)
      return () => {
        signalrStore2.removeQuote(symbol)
      }
    }, [JSON.stringify(symbol)])

    const quote = signalrHooks2.useQuotes(symbol)

    return (
      <classes.Root css={baseStyled}>
        <Marquee
          //gradient={true}
          speed={speed}
          autoFill={true}
          pauseOnHover={true}
          css={marqeeCss}
        >
          {quote.map((s, index) => {
            return (
              <classes.item
                key={index}
                onClick={() => {
                  store.charting.changeSymbol(s.symbol)
                }}
              >
                <MarqueeSymbolBody.Display quote={s} />
              </classes.item>
            )
          })}
        </Marquee>
      </classes.Root>
    )
  },
)

const classes = {
  Root: styled.div`
    ${fill};
  `,
  item: styled.div`
    height: 100%;
    width: 160px;
  `,
}

const marqeeCss = css`
  height: 100%;
  gap: 8px;
  .initial-child-container {
    gap: 8px;
    height: 100%;
  }
  .marquee {
    gap: 8px;
    height: 100%;
  }
  .child {
    height: 100%;
  }
  // .overlay {
  //   &:before {
  //     background: linear-gradient(to right, #1d1e29aa, #1d1e2933);
  //   }
  //   &:after {
  //     background: linear-gradient(to right, #1d1e29aa, #1d1e2933);
  //   }
  // }
`

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.item} {
  }
`

export default {
  Display: MarqueeSymbolQuote,
  classes,
}
