import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { good178_initStrategies } from './good178_initStrategies'
import { good178_initStyling } from './good178_initStyling'
import { Good178_TopBar } from './good178_Topbar'

export const good178_init = (templateProps: TemplateProps) => {
  good178_initStrategies()
  good178_initStyling(templateProps)

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode(useThemeStore.getState().theme)

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TX-1',
    interval: 5,
    overrides: store.charting.darkOverrides,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr;
    user-select: none;

    grid-template-areas:
      'Row1 Row1'
      'Chart Chart'
      'Chart Chart';

    ${createIPadCss(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `
  templateProps.layout.Row1 = Good178_TopBar
}
