import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const rich888_chips = createIndicator({
  id: 'rich888-chips',
  displayName: '籌碼指標',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const type = ticker?.includes('-') ? '#foreigner_txf' : '#big3_net_stock'
      const symbol = ticker + type
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = this._context.new_unlimited_var(this._context.symbol.time)

      //#big3_net_stock------------------------------------------------
      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      //外資
      const quote1 = this.PineJS.Std.open(this._context)
      const unlimited1 = this._context.new_unlimited_var(quote1)
      const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼

      //自營
      const quote2 = this.PineJS.Std.low(this._context)
      const unlimited2 = this._context.new_unlimited_var(quote2)
      const value2 = unlimited2.adopt(time1, i, 0) // <=這是籌碼

      //#TX-1商品------------------------------------------------
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)

      const close = this.ohlc.closeArray
      close.get(10)

      const ma60 = this.PineJS.Std.sma(close, 60, this._context)
      const mainTrend = this.PineJS.Std.sma(close, this._input(1), this._context)

      //要顯示的籌碼
      const value = this._input(2) === 1 ? value1 : this._input(2) === 2 ? value2 : value1 + value2

      //假的籌碼
      const fake_array = this._context.new_var(value)
      const fake = this.PineJS.Std.sma(fake_array, this._input(0), this._context)

      const colorIndex = fake >= 0 ? 0 : 1

      //累加
      const acc = this._context.new_var()
      acc.get(100)

      //如果今日昨日穿越均線 外資&自營同時買超
      if (
        close.get(1) < mainTrend &&
        close > mainTrend &&
        value1 > 0 &&
        value2 > 0 &&
        close > ma60
      ) {
        acc.set(value)
      } else if (close.get(1) > mainTrend && close < mainTrend && value1 < 0 && value2 < 0) {
        acc.set(value)
      } else acc.set(0)

      const avgChip_array = this._context.new_var(Math.abs(value))
      const avgChip = this.PineJS.Std.sma(avgChip_array, 20, this._context)

      //顯示個別籌碼, 變色, 累加(條件), 上通道, 下通道, 假的每日籌碼
      return [fake, colorIndex, acc.get(0), avgChip, -avgChip]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多市場籌碼',
          },
          1: {
            name: '空市場籌碼',
          },
        },
      },
    },
    defaults: {
      styles: {
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 20,
          visible: !0,
          color: '#ffffff',
        },
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#d63864',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#e39e9c',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#7bb972',
        },
      },
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#e39e9c',
              width: 0,
              style: 0,
            },
            1: {
              color: '#7bb972',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 0,
      inputs: { in_0: 4, in_1: 5, in_2: 1 },
    },
    styles: {
      vol: {
        title: '籌碼',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_0: {
        title: '發動',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: '買方均值',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        title: '賣方均值',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: '籌碼敏感度',
        defval: 4,
        type: 'integer',
        min: 1,
        max: 200,
      },
      {
        id: 'in_1',
        name: '趨勢長度',
        defval: 5,
        type: 'integer',
        min: 1,
        max: 200,
      },
      {
        id: 'in_2',
        name: '類別[1外資][2自營][3加總]',
        defval: 1,
        type: 'integer',
        min: 1,
        max: 3,
      },
    ],
  },
})
