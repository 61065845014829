import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { store } from '~/pages/heineken_template/_private/store'
import { fill_horizontal_all_center } from '../AppLayout/FlexGridCss'

const WatchListAddButton = memo<
  ReactProps<{
    watchListSymbol: string[]
    watchlistActs: any
  }>
>(function WatchListAddButton(props) {
  const currentSymbol = useSnapshot(store).charting.symbol
  const hadSymbol = props.watchListSymbol?.includes(currentSymbol)
  const hadSymbolFill = hadSymbol ? hadSymbolbutton : defaultButton
  const theme = useThemeStore(s => s.theme)

  return (
    <classes.Root>
      <classes.container
        className={theme}
        css={hadSymbolFill}
        onClick={() => {
          hadSymbol
            ? props.watchlistActs.removeSymbol(currentSymbol)
            : props.watchlistActs.addSymbol(currentSymbol, 20)
        }}
      >
        {hadSymbol ? (
          <MdClose
            size='18px'
            fill={'#fafafa'}
          />
        ) : (
          <MdAdd
            size='18px'
            fill={'#fafafa'}
          />
        )}
        &nbsp;
        {hadSymbol ? '移出觀察清單' : '加入觀察清單'}
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    height: 100%;
    width: 100%;
  `,
  container: styled.div`
    ${fill_horizontal_all_center};
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;

    transition: 0.5s;

    &.dark {
      border: 1px solid #545454;
      &:hover {
        border: 1px solid #aaaaaa;
      }
    }
    &.ligh {
      border: 1px solid #545454;
      &:hover {
        border: 1px solid #343434;
      }
    }
  `,
}

const hadSymbolbutton = css`
  &.dark {
    background-color: #673535;
  }
  &.light {
    background-color: #aa3535;
  }
`
const defaultButton = css`
  &.dark {
    background-color: #32343f;
  }
  &.light {
    background-color: #eaeaea;
  }
`

export default {
  Display: WatchListAddButton,
  hadSymbolbutton,
  defaultButton,
}
