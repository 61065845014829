import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const wu5868_bband = createIndicator({
  id: 'wu5868-bband',
  displayName: 'BB',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.ohlc.closeArray
      close.get(100)

      const length = this._input(0)
      const stds = this._input(1)

      const bband = this.bollingerBand(close, length, stds)

      return [bband.top, bband.mid, bband.bottom]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'top', type: 'line' },
      { id: 'mid', type: 'line' },
      { id: 'bottom', type: 'line' },
    ],
    styles: {
      top: {
        title: '上軌',
        histogramBase: 0,
        joinPoints: false,
      },
      mid: {
        title: '中軌',
        histogramBase: 0,
        joinPoints: false,
      },
      bottom: {
        title: '下軌',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    defaults: {
      inputs: {
        length: 20,
        stds: 2,
      },
      styles: {
        top: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 60,
          visible: true,
          color: '#e15241',
        },
        mid: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 60,
          visible: true,
          color: '#4994ec',
        },
        bottom: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 60,
          visible: true,
          color: '#429488',
        },
      },
      precision: 2,
    },

    inputs: [
      {
        id: 'length',
        name: 'Length',
        defval: 20,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'stds',
        name: 'STDs',
        defval: 2,
        type: 'float',
        min: 1,
        max: 500,
        step: 0.1,
      },
    ],
  },
})
