import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { big3_store } from '~/pages/big3/_private/big3_store'

export const big3_trendTrade = createIndicator({
  displayName: '波段',
  id: 'big3-trendTrade',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))

      const symbolopof = ticker + '#opof'
      this._context.new_sym(symbolopof, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const chip = this.PineJS.Std.high(this._context)
      const unlimited1 = this._context.new_unlimited_var(chip)
      const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼

      this._context.select_sym(2)
      const time2 = this._context.new_unlimited_var(this._context.symbol.time)
      const opof = this.PineJS.Std.close(this._context)
      const unlimited2 = this._context.new_unlimited_var(opof)
      const value2 = unlimited2.adopt(time2, i, 0) // <=這是籌碼

      //主圖商品
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)
      const symbol = this._context.symbol.info?.name
      const interval = this._context.symbol.interval

      const len = 40
      const high = this.ohlc.high
      const low = this.ohlc.low
      const close = this.ohlc.close
      const close_array = this.ohlc.closeArray

      const marketposition = this._context.new_var()
      const entryPrice = this._context.new_var()
      const pvflag = this._context.new_var()
      const pvflagTime = this._context.new_var()

      const itime = this.PineJS.Std.time(this._context)
      const itime_array = this._context.new_var(itime)

      const hh = this._context.new_var(
        this.PineJS.Std.highest(this._context.new_var(high), len, this._context),
      )
      const ll = this._context.new_var(
        this.PineJS.Std.lowest(this._context.new_var(low), len, this._context),
      )

      hh.get(len)
      ll.get(len)

      const conditionNewHigh = close > hh.get(1)
      const conditionNewLow = close < ll.get(1)

      const alma = this.PineJS.Std.alma(close_array, 90, 0.85, 6)

      const bState = this._context.new_var()
      bState.get(1)

      marketposition.get(2)
      entryPrice.get(2)
      itime_array.get(5)
      pvflag.get(2)
      pvflagTime.get(2)

      const long = conditionNewHigh && close > alma
      const short = conditionNewLow && close < alma

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN
      let buy_stoploss_icon = NaN
      let short_stoploss_icon = NaN

      const basePonint = () => {
        if (interval <= 5) {
          return 2
        } else if (interval > 5 && interval <= 15) {
          return 3
        } else if (interval > 15) {
          return 5
        } else return 3
      }

      if (long) {
        marketposition.set(1)
      }
      if (short) {
        marketposition.set(-1)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
        buy_icon = 1
        entryPrice.set(close)
        pvflag.set(0)
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
        short_icon = 1
        entryPrice.set(close)
        pvflag.set(0)
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
        //停損1
        if (low < this.ladderChannl(15)[2]) {
          marketposition.set(0)
          buy_stoploss_icon = 1
        }
        //停損2
        if (low < entryPrice.get(0) * 0.997) {
          marketposition.set(0)
          buy_stoploss_icon = 1
        }
        //第一次停利
        if (pvflag.get(0) === 0 && high > entryPrice.get(0) * (1 + 0.0004 * basePonint())) {
          buy_target_icon = 1
          pvflag.set(1)
          pvflagTime.set(itime)
        }
        //第二次停利
        if (
          pvflag.get(0) === 1 &&
          itime_array.get(1) > pvflagTime.get(0) &&
          high > entryPrice.get(0) * (1 + 0.001 * basePonint())
        ) {
          buy_target_icon = 1
          pvflag.set(2)
          pvflagTime.set(itime)
        }
        //第三次停利
        if (
          pvflag.get(0) === 2 &&
          itime_array.get(1) > pvflagTime.get(0) &&
          high > entryPrice.get(0) * (1 + 0.0017 * basePonint())
        ) {
          buy_target_icon = 1
          pvflag.set(3)
          pvflagTime.set(itime)
        }
      }

      if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
        //停損2
        if (high > this.ladderChannl(15)[3]) {
          marketposition.set(0)
          short_stoploss_icon = 1
        }
        //停損2
        if (high > entryPrice.get(0) * 1.003) {
          marketposition.set(0)
          short_stoploss_icon = 1
        }
        //第一次停利
        if (pvflag.get(0) === 0 && low < entryPrice.get(0) * (1 - 0.0005 * basePonint())) {
          short_target_icon = 1
          pvflag.set(1)
          pvflagTime.set(itime)
        }
        //第二次停利
        if (
          pvflag.get(0) === 1 &&
          itime_array.get(1) > pvflagTime.get(0) &&
          low < entryPrice.get(0) * (1 - 0.001 * basePonint())
        ) {
          short_target_icon = 1
          pvflag.set(2)
          pvflagTime.set(itime)
        }
        //第三次停利
        if (
          pvflag.get(0) === 2 &&
          itime_array.get(1) > pvflagTime.get(0) &&
          low < entryPrice.get(0) * (1 - 0.0017 * basePonint())
        ) {
          short_target_icon = 1
          pvflag.set(3)
          pvflagTime.set(itime)
        }
      }

      big3_store.chip = value1
      big3_store.opof = value2

      const colorer = () => {
        return marketposition.get(0) === 1 ? 0 : marketposition.get(0) === -1 ? 1 : 2
      }

      return [
        NaN,
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_stoploss_icon,
        short_stoploss_icon,
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          visible: true,
          linewidth: 1,
          plottype: 0,
          trackPrice: false,
          transparency: 30,
          color: '#5577ff',
        },
        plot_1: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_2: {
          color: '#6ab6ec',
          textColor: '#6ab6ec',
          transparency: 0,
          visible: true,
        },
        plot_3: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_4: {
          color: '#6ab6ec',
          textColor: '#6ab6ec',
          transparency: 0,
          visible: true,
        },
        plot_5: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_6: {
          color: '#6ab6ec',
          textColor: '#6ab6ec',
          transparency: 0,
          visible: true,
        },
      },
    },
    styles: {
      plot_0: {
        title: '日成本線',
        histogramBase: 0,
      },
      plot_1: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單',
        title: '多單參考訊號',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單',
        title: '空單參考訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'tiny',
        text: '',
        title: '多單參考停利訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'tiny',
        text: '',
        title: '空單參考停利訊號',
      },
      plot_5: {
        isHidden: false,
        location: 'BelowBar',
        char: 'X',
        size: 'tiny',
        text: '離場',
        title: '多單參考停損訊號',
      },
      plot_6: {
        isHidden: false,
        location: 'AboveBar',
        char: 'X',
        size: 'tiny',
        text: '離場',
        title: '空單參考停損訊號',
      },
    },

    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          2: {
            color: '#b2b5be',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
