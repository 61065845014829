import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { big3_analyzer } from '~/trades/indicators/big3/big3_analyzer'
import { big3_dayPower } from '~/trades/indicators/big3/big3_dayPower'
import { big3_dayTrade } from '~/trades/indicators/big3/big3_dayTrade'
import { big3_dc } from '~/trades/indicators/big3/big3_dc'
import { big3_trendTrade } from '~/trades/indicators/big3/big3_trendTrade'
import { big3_priceLine } from '~/trades/indicators/big3/bog3_priceLine'
import { big3_bandDc } from '../../../trades/indicators/big3/big3_bandDc'

export const big3_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '順勢當沖',
      indicators: [big3_dayTrade, big3_dc, big3_priceLine, big3_dayPower, big3_analyzer],
      symbol: 'TXAM-1',
      interval: '3',
      panesRatio: 60,
    },
    {
      displayName: '順勢波段',
      indicators: [
        big3_trendTrade,
        big3_dc,
        big3_bandDc,
        big3_priceLine,
        big3_dayPower,
        big3_analyzer,
      ],
      symbol: 'TX-1',
      interval: '5',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const big3_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[
      big3_dayTrade,
      big3_trendTrade,
      big3_dc,
      big3_bandDc,
      big3_dayPower,
      big3_priceLine,
      big3_analyzer,
    ],
  ]

  store.charting.initStrategy({
    configs: [...big3_strategiesGroup.main],
  })
}
