import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import Styleds from './styleds'
import { store } from '~/pages/heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { lung88988_strategiesGroup } from './lung88988_initStrategies'
import { useTimeout, useUpdateEffect } from 'react-use'
import {
  TechnologyCircle,
  TechnologyLine,
  TechnologyTitle,
  TechnologyWind,
} from './technology_Component'
import { TrendState, trendStore } from './useTrendStore'
const trendColorFunction = (state: TrendState) => {
  if (state === '多方強勢') {
    return '#cc2F2F'
  } else if (state === '多方震盪') {
    return '#F44336'
  } else if (state === '拉回支撐') {
    return '#E57373'
  } else if (state === '反彈壓力') {
    return '#66BB6A'
  } else if (state === '空方震盪') {
    return '#4CAF50'
  } else if (state === '空方強勢') {
    return '#38cc3C'
  } else if (state === '盤整盤') {
    return '#0e2b57'
  }
}

export const Lung88988_SidePane1 = memo<ReactProps>(function SidePane1(props) {
  const charting = useSnapshot(store.charting)
  const chartPosition = useSnapshot(useChartPositionStore)
  const theme = useThemeStore(s => s.theme)
  const trendState = useSnapshot(trendStore)
  const trendColor = trendColorFunction(trendState.state)

  return (
    <Styleds.Sidebar className={theme}>
      <TitleSymbolQuote.Default symbol='TX-1' />
      <TechnologyLine transform={180} />
      <div
        css={css`
          width: 100%;
          height: 170px;
        `}
      >
        <IntradayTrendChart
          symbol={'TX-1'}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2}
          priceTicksSize={14}
        />
      </div>
      <TechnologyLine transform={0} />
      <TechnologyTitle>策略選擇</TechnologyTitle>
      <div
        css={css`
          ${flex.h.allCenter}
          width:100%;
          height: 26px;
          gap: 5px;
        `}
      >
        {lung88988_strategiesGroup.main.map(config => {
          const isActive = charting.strategySelected.displayName === config.displayName
          return (
            <FuiButton.Display
              key={config.displayName}
              active={isActive}
              onClick={event => {
                store.charting.setStrategy(config)
                store.charting.updateFromStrategyState()
              }}
            >
              {config.displayName}
            </FuiButton.Display>
          )
        })}
      </div>
      <Styleds.SidebarPositionButtonGroup>
        <FuiButton.Display
          variant='long'
          active={chartPosition.positionType === 'long'}
          onClick={event => {
            useChartPositionStore.positionType = 'long'
            store.charting.updateFromState()
          }}
        >
          多方操作
        </FuiButton.Display>
        <FuiButton.Display
          active={chartPosition.positionType === 'default'}
          onClick={event => {
            useChartPositionStore.positionType = 'default'
            store.charting.updateFromState()
          }}
        >
          預設
        </FuiButton.Display>
        <FuiButton.Display
          variant='short'
          active={chartPosition.positionType === 'short'}
          onClick={event => {
            useChartPositionStore.positionType = 'short'
            store.charting.updateFromState()
          }}
        >
          空方操作
        </FuiButton.Display>
      </Styleds.SidebarPositionButtonGroup>
      <TechnologyTitle>商品切換</TechnologyTitle>
      <div
        css={css`
          width: 100%;
          height: 132px;
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={['TX-1', 'TXAM-1']}
          chart={store.charting}
        />
      </div>
      <TechnologyTitle>盤勢狀況</TechnologyTitle>
      <TechnologyWind
        styledNumber={1}
        height={200}
      >
        <div
          css={css`
            ${flex.h.allCenter};
            position: absolute;
            width: 150px;
            height: 150px;
            font-family: sans-serif;
            background: linear-gradient(125deg, ${trendColor + 'aa'}, ${trendColor + '77'});
            border: 1.5px solid #aaaaaa;
            box-shadow: 0px 0px 40px 20px ${trendColor + '55'};
            border-radius: 50%;
          `}
        >
          {trendState.state}
        </div>
        <TechnologyCircle />
      </TechnologyWind>
    </Styleds.Sidebar>
  )
})

export const LoadingProgress = memo<ReactProps>(function LoadingProgress() {
  const [initializeReady, reset] = useTimeout(3000)
  useUpdateEffect(() => {
    reset()
  }, [initializeReady])

  const fadeinCss = css`
    ${flex.h.crossCenter};
    animation: progress 3s;
    height: 100%;
    width: 100%;
    background-color: #54e6e7;
    border-radius: 5px;
    @keyframes progress {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  `

  return (
    <div
      css={css`
        height: 20px;
        width: 100%;
        border: 1px solid #eeeeee;
        background-color: #232323;
        padding: 2px 1px;
        position: relative;
        border-radius: 5px;
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
          height: 100%;
          width: 100%;
          position: absolute;
          font-size: 12px;
        `}
      >
        {initializeReady() ? '載入完成' : '載入中'}
      </div>
      <div css={fadeinCss}></div>
    </div>
  )
})
