import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { s178178_attack } from '~/trades/indicators/s178178/s178178_attack'
import { s178178_dayTraderPower } from '~/trades/indicators/s178178/s178178_dayTraderPower'
import { s178178_kbar } from '~/trades/indicators/s178178/s178178_kbar'
import { s178178_min_kbar } from '~/trades/indicators/s178178/s178178_min_kbar'
import { s178178_min_kbar_old } from '~/trades/indicators/s178178/s178178_min_kbar_old'
import { s178178_power } from '~/trades/indicators/s178178/s178178_power'
import { s178178_signal } from '~/trades/indicators/s178178/s178178_signal'

const bandtrade = [s178178_signal, s178178_kbar, s178178_attack]
const dayTrade = [s178178_min_kbar, s178178_attack]

export const s178178_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '波段',
      indicators: bandtrade,
      symbol: 'TX-1',
      interval: '1D',
    },
    {
      displayName: '當沖',
      indicators: dayTrade,
      symbol: 'TX-1',
      interval: '5',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const s178178_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...bandtrade,
    ...dayTrade,
    ...[s178178_min_kbar_old],
  ]

  store.charting.initStrategy({
    configs: [...s178178_strategiesGroup.main],
  })
}
