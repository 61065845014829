import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { gorich55688_patternStore } from '~/pages/gorich55688_stock/gorich55688_patternStore'
import dayAPI from '~/utils/dayAPI'

export const gorich55688_twsePatterns = createIndicator({
  displayName: 'TWSE Patterns',
  id: 'gorich55688-twsePatterns',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const session = SessionType.TWSE
      const close = this.ohlc.closeArray
      const datetime = new Date(this.PineJS.Std.time(this._context))

      close.get(200)
      const dailyCloses = this.dailyClose(2, session)
      const prevClose = dailyCloses[1]

      const itime = this.PineJS.Std.time(this._context)

      if (datetime.getHours() === 9 && datetime.getMinutes() === 15) {
        if (
          close.get(3) > prevClose &&
          close.get(2) > close.get(3) &&
          close.get(2) > prevClose &&
          close.get(1) > close.get(2) &&
          close.get(1) > close.get(3) &&
          close.get(1) > prevClose
        ) {
          gorich55688_patternStore.pattern = '三點高盤'
          console.log('HHHH')
        } else if (
          close.get(3) < prevClose &&
          close.get(2) < close.get(3) &&
          close.get(2) < prevClose &&
          close.get(1) < close.get(2) &&
          close.get(1) < close.get(3) &&
          close.get(1) < prevClose
        ) {
          gorich55688_patternStore.pattern = '三點低盤'
          console.log('LLL')
        } else if (
          close.get(3) > prevClose &&
          close.get(2) <= close.get(3) &&
          close.get(1) <= close.get(2) &&
          close.get(1) <= close.get(3)
        ) {
          gorich55688_patternStore.pattern = '二點高盤'
          console.log('HLL')
        } else if (
          close.get(3) < prevClose &&
          close.get(2) >= close.get(3) &&
          close.get(1) >= close.get(2) &&
          close.get(1) >= close.get(3)
        ) {
          gorich55688_patternStore.pattern = '一點低盤'
          console.log('LHH')
        } else if (
          close.get(3) > prevClose &&
          close.get(2) > close.get(3) &&
          close.get(2) > prevClose &&
          close.get(1) <= close.get(2) &&
          close.get(1) > close.get(3) &&
          close.get(1) > prevClose
        ) {
          gorich55688_patternStore.pattern = '二點高盤 標準'
          console.log('HHL-Standard')
        } else if (
          close.get(3) > prevClose &&
          close.get(2) > close.get(3) &&
          close.get(2) > prevClose &&
          close.get(1) <= close.get(2) &&
          close.get(1) <= close.get(3)
        ) {
          gorich55688_patternStore.pattern = '二點高盤 震盪'
          console.log('HHL-NoTrend')
        } else if (
          close.get(3) > prevClose &&
          close.get(2) <= close.get(3) &&
          close.get(1) > close.get(2) &&
          close.get(1) > close.get(3) &&
          close.get(1) > prevClose
        ) {
          gorich55688_patternStore.pattern = '轉折二高盤 強一高'
          console.log('HLH Strong')
        } else if (
          close.get(3) > prevClose &&
          close.get(2) <= close.get(3) &&
          close.get(1) > close.get(2) &&
          close.get(1) <= close.get(3)
        ) {
          gorich55688_patternStore.pattern = '轉折二高盤 弱一高'
          console.log('HLH Weak')
        } else if (
          close.get(3) <= prevClose &&
          close.get(2) <= close.get(3) &&
          close.get(2) <= prevClose &&
          close.get(1) > close.get(2) &&
          close.get(1) <= close.get(3) &&
          close.get(1) <= prevClose
        ) {
          gorich55688_patternStore.pattern = '二點低盤 標準'
          console.log('LLH Standard')
        } else if (
          close.get(3) <= prevClose &&
          close.get(2) <= close.get(3) &&
          close.get(2) <= prevClose &&
          close.get(1) > close.get(2) &&
          close.get(1) > close.get(3)
        ) {
          gorich55688_patternStore.pattern = '二點低盤 震盪'
          console.log('LLH No trend')
        } else if (
          close.get(3) <= prevClose &&
          close.get(2) > close.get(3) &&
          close.get(1) <= close.get(2) &&
          close.get(1) <= close.get(3) &&
          close.get(1) <= prevClose
        ) {
          gorich55688_patternStore.pattern = '轉折二高盤 強一低'
          console.log('LHL strong')
        } else if (
          close.get(3) <= prevClose &&
          close.get(2) > close.get(3) &&
          close.get(1) <= close.get(2) &&
          close.get(1) > close.get(3)
        ) {
          gorich55688_patternStore.pattern = '轉折二底盤 弱一低'
          console.log('LHL Weak')
        }

        return [NaN]
      }

      // Reset on market close.
      if (this.isSessionFirstBar(session)) {
        gorich55688_patternStore.pattern = 'undefined'
      }
      return [NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !1,
          color: '#fe80c0',
        },
      },
    },
    is_price_study: !0,
    scriptIdPart: '',
    inputs: [],
  },
})
