import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple'
import { Mvp888Stock_SidePane1 } from './mvp888Stock_SidePane1'
import { Mvp888Stock_SidePane2 } from './mvp888Stock_SidePane2'
import { mvp888Stock_initStrategies } from './mvp888Stock_initStrategies'
import { Mvp888Stock_Topbar } from './mvp888Stock_Topbar'
import { Mvp888Stock_Footer } from './mvp888Stock_Footer'
import { mvp888Stock_initStyling } from './mvp888Stock_initStyling'
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'

export const mvp888Stock_init = (templateProps: TemplateProps) => {
  mvp888Stock_initStyling(templateProps)
  mvp888Stock_initStrategies()

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: '1D',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: true,
    disabledPaneMenu: true,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderSaveload: true,
    disabledHeaderCompare: true,
    overrides: store.charting.lightOverrides,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100% - 72px) 24px;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `

  templateProps.layout.Row1 = Mvp888Stock_Topbar
  templateProps.layout.Row2 = Mvp888Stock_Footer
  templateProps.layout.Drawer1 = (
    <Fr_WithDrawerCloseButton.Display
      right
      Button={
        <BsChevronRight
          css={css`
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #56659d99;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}
        />
      }
    >
      <Mvp888Stock_SidePane1 />
    </Fr_WithDrawerCloseButton.Display>
  )
  templateProps.layout.Drawer2 = (
    <Fr_WithDrawerCloseButton.Display
      left
      Button={
        <BsChevronLeft
          css={css`
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #56659d99;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}
        />
      }
    >
      <Mvp888Stock_SidePane2 />
    </Fr_WithDrawerCloseButton.Display>
  )
}
