import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { goldbrainStock_initStyling } from '~/pages/goldbrain_stock/_private/goldbrainStock_initStyling'
import { GoldbrainStock_SidePane1 } from '~/pages/goldbrain_stock/_private/goldbrainStock_SidePane1'
import { GoldbrainStock_SidePane2 } from '~/pages/goldbrain_stock/_private/goldbrainStock_SidePane2'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { goldbrain_bandtrade } from '~/trades/indicators/goldbrain/goldbrain_bandtrade'
import { goldbrain_cci } from '~/trades/indicators/goldbrain/goldbrain_cci'
import { goldbrain_cdp } from '~/trades/indicators/goldbrain/goldbrain_cdp'
import { goldbrain_shorttrade } from '~/trades/indicators/goldbrain/goldbrain_shorttrade'

export const goldbrainStock_init = (templateProps: TemplateProps) => {
  goldbrainStock_initStyling(templateProps)

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  store.charting.indicatorsPreloaded = [
    goldbrain_cci,
    goldbrain_shorttrade,
    goldbrain_bandtrade,
    goldbrain_cdp,
  ]

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: '30',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    overrides: store.charting.lightOverrides,
  }

  store.charting.initStrategy({
    configs: [
      {
        displayName: '長線釣大魚',
        indicators: [goldbrain_bandtrade, goldbrain_cci],
        symbol: 'TSEA',
        interval: '30',
      },
      {
        displayName: '短線獲利王',
        indicators: [goldbrain_shorttrade, goldbrain_cci],
        symbol: 'TSEA',
        interval: '3',
      },
    ],
  })

  templateProps.layout.cssset = css`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `

  templateProps.layout.Row1 = (
    <Preset_Topbar
      hideIfPcView
      showLeftBurger
      showRightBurger
    />
  )
  templateProps.layout.Drawer1 = GoldbrainStock_SidePane1
  templateProps.layout.Drawer2 = GoldbrainStock_SidePane2
}
