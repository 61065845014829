import dayjs from 'dayjs'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const gorich55688_twseTimePrice = createIndicator({
  id: 'gorich55688-twseTimePrice',
  displayName: 'TWSE Time Price',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(content, inputCallback) {
      if (this._context.symbol.ticker.includes('-')) return [NaN, NaN]
      const datetime = new Date(this.PineJS.Std.time(this._context))
      const close = this.ohlc.closeArray

      const isTimeToPlot1 = datetime.getHours() === 9 && datetime.getMinutes() === 0
      const isTimeToPlot2 = datetime.getHours() === 9 && datetime.getMinutes() === 10

      const price0905 = this._context.new_var()
      const price0915 = this._context.new_var()

      if (isTimeToPlot1) {
        price0905.set(close.get(0))
      }

      if (isTimeToPlot2) {
        price0915.set(close.get(0))
      }

      if (this.isSessionLastBar(SessionType.TWSE)) {
        price0905.set(0)
        price0915.set(0)
      }
      return [price0905.get(0) || NaN, price0915.get(0) || NaN]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    //#region Charting Objects
    plots: [
      { id: 'price0905', type: 'line' },
      { id: 'price0915', type: 'line' },
    ],
    styles: {
      price0905: {
        title: '0905',
        histogramBase: 0,
      },
      price0915: {
        title: '0915',
        histogramBase: 0,
      },
    },

    defaults: {
      precision: 0,
      styles: {
        price0905: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          visible: true,
          trackPrice: false,
          transparency: 0,
          color: '#00bfff',
        },
        price0915: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          visible: true,
          trackPrice: false,
          transparency: 0,
          color: '#ff99e6',
        },
      },
    },
    inputs: [],
    //#endregion
  },
})
