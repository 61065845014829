import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore'
import { trafficLightStore } from '~/pages/s178178/useTrafficLightStore'

/** 多空分界 */
export const s178178_min_kbar = createIndicator({
  displayName: '短線參考',
  id: 's178178minkbar',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))

      const ticker2 = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol2 = ticker2 + '#opof'
      this._context.new_sym(symbol2, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const interval = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W
      const display = !interval.includes('D')
      if (display) {
        //奇妙的多商品code------------------------------------------------
        const i = this._context.new_unlimited_var(this._context.symbol.time)

        //#bs_indicator_acc------------------------------------------------
        this._context.select_sym(1)
        const time1 = this._context.new_unlimited_var(this._context.symbol.time)
        const quote1 = this.PineJS.Std.high(this._context)
        const unlimited1 = this._context.new_unlimited_var(quote1)

        const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼
        const value1_array = this._context.new_var(value1)
        value1_array.get(1000)

        //#opof------------------------------------------------
        this._context.select_sym(2)
        const time2 = this._context.new_unlimited_var(this._context.symbol.time)
        const qote2 = this.PineJS.Std.close(this._context)
        const unlimited2 = this._context.new_unlimited_var(qote2)

        const value2 = unlimited2.adopt(time2, i, 0) // <=這是籌碼
        const value2_array2 = this._context.new_var(value2)
        value2_array2.get(1000)

        //TX-1商品------------------------------------------------
        this._context.select_sym(0)
        this.PineJS.Std.period(this._context)
        const close = this.PineJS.Std.close(this._context) // <=這是商品本身
        const itime = this.PineJS.Std.time(this._context)
        const high = this.PineJS.Std.high(this._context)
        const low = this.PineJS.Std.low(this._context)
        const close_array = this._context.new_var(close)

        //奇妙的多商品code------------------------------------------------

        const state = this._context.new_var()
        const entryPrice = this._context.new_var()
        const marketosition = this._context.new_var()

        const bigSma = this.PineJS.Std.sma(close_array, 480, this._context)
        const smallSma = this.PineJS.Std.sma(close_array, 60, this._context)
        const bigSma_array = this._context.new_var(bigSma)
        const smallSma_array = this._context.new_var(smallSma)

        close_array.get(10)
        bigSma_array.get(10)
        smallSma_array.get(10)
        entryPrice.get(5)
        state.get(10)

        const baseLong = value2 > 10 && value1 > 10
        const baseShort = value2 < -10 && value1 < -10

        const maSlopeLong =
          smallSma_array.get(0) > smallSma_array.get(1) &&
          smallSma_array.get(1) > smallSma_array.get(2)

        const maSlopeShort =
          smallSma_array.get(0) < smallSma_array.get(1) &&
          smallSma_array.get(1) < smallSma_array.get(2)

        /** 鬆懈模式 */
        const longCondition = baseLong
        const shortCondtion = baseShort
        /** 一般模式 */
        const defaultLongCondtion = baseLong && close > smallSma
        const defaultShortCondtion = baseShort && close < bigSma
        /** 嚴苛模式 */
        const proLongCondition = baseLong && close > smallSma && close > bigSma
        const proShortCondtion = baseShort && close < bigSma && close < smallSma && maSlopeShort
        const resultLong = this._input(0) === '標準' ? defaultLongCondtion : proLongCondition
        const resultShort = this._input(0) === '標準' ? defaultShortCondtion : proShortCondtion

        if (resultLong) {
          state.set(1)
          indicatorsValueStore.openPositionProfit = close - entryPrice.get(0)
        }
        if (resultShort) {
          state.set(-1)
          indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close
        }

        if (state.get(0) === 1 && state.get(1) !== 1) {
          entryPrice.set(close)
          indicatorsValueStore.entryPrice = close
          indicatorsValueStore.marketPosition = 1
          indicatorsValueStore.entryDate = itime
          indicatorsValueStore.multipleTargetPrice = [entryPrice + 40, entryPrice + 40 * 2]
          indicatorsValueStore.stopLossPrice = entryPrice - 30
          trafficLightStore.state = 'red'
        }

        if (state.get(0) === -1 && state.get(1) !== -1) {
          entryPrice.set(close)
          indicatorsValueStore.entryPrice = close
          indicatorsValueStore.marketPosition = -1
          indicatorsValueStore.entryDate = itime
          indicatorsValueStore.multipleTargetPrice = [entryPrice - 40, entryPrice - 40 * 2]
          indicatorsValueStore.stopLossPrice = entryPrice + 30
          trafficLightStore.state = 'green'
        }
        //sar停利停損------------------------------------------------
        if (state.get(0) === 1 && !resultLong) {
          state.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
          trafficLightStore.state = 'yellow'
        }
        if (state.get(0) === -1 && !resultShort) {
          state.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
          trafficLightStore.state = 'yellow'
        }

        const colorIndex = state.get(0) === 1 ? 0 : state.get(0) === -1 ? 2 : 1

        return [colorIndex]
      } else return [NaN, NaN, NaN, NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {},
      inputs: {
        in_0: '標準',
      },
    },
    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ff3f5f',
            width: 1,
            style: 0,
          },
          1: {
            color: '#efff10',
            width: 1,
            style: 0,
          },
          2: {
            color: '#00ff2c',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '策略模式',
        defval: '標準',
        type: 'text',
        options: ['標準', '嚴苛'],
      },
    ],
    scriptIdPart: '',
  },
})
