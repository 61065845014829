import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const winner_os_kbar = createIndicator({
  displayName: '多空分界',
  id: 'winneroskbar',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const interval = this.PineJS.Std.interval(this._context)

      const in1 = 60

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma1 = this.PineJS.Std.sma(close_array, in1, this._context)
      const shortStrong = close > sma1

      const colorIndex = shortStrong ? 1 : 3

      return [colorIndex]
    },
  },
  metainfo: {
    plots: [
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],

    defaults: {
      inputs: {
        in_0: 207,
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ec407a',
            width: 1,
            style: 0,
          },
          1: {
            color: '#ff0000',
            width: 1,
            style: 0,
          },
          2: {
            color: '#00ff00',
            width: 1,
            style: 0,
          },
          3: {
            color: '#00ff00',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [
      /*{
        id: 'in_0',
        name: 'length',
        defval: 207,
        type: 'integer',
        min: 1,
        max: 1e4,
      },*/
    ],
    scriptIdPart: '',
  },
})
