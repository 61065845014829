import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const day_vix2 = createIndicator({
  displayName: 'system2',
  id: 'system2',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const input1 = 20
      const input2 = 2

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma = this.PineJS.Std.sma(close_array, input1, this._context)
      const std = this.PineJS.Std.stdev(close_array, input1, this._context)

      const mid = input2 * std
      const up = sma + mid
      const dn = sma - mid

      const result = (up - dn) / sma
      const result_array = this._context.new_var(result)
      const result_sma = this.PineJS.Std.sma(result_array, 20, this._context)

      return [result, result_sma]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 4,
          trackPrice: !1,
          transparency: 40,
          visible: !0,
          color: '#85bd72',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#797b85',
        },
      },
      precision: 4,
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '%K',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: '%D',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [],
    precision: 2,
    scriptIdPart: '',
  },
})
