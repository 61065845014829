import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const gd1788_envelopes = createIndicator({
  displayName: 'gd1788_envelopes',
  id: 'gd1788envelopes',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)
      const up_precentage = this._input(1)
      const dn_precentage = this._input(2)

      const close_array = this.ohlc.closeArray

      const sma = this.PineJS.Std.sma(close_array, length, this._context)

      const up = sma * (1 + up_precentage)
      const dn = sma * (1 - dn_precentage)

      return [up, dn]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00ff00',
        },
      },
      precision: 4,
      inputs: {
        in_0: 120,
        in_1: 0.003,
        in_2: 0.003,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'up',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'dn',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: 'length',
        defval: 120,
        type: 'integer',
        min: 1,
        max: 240,
      },
      {
        id: 'in_1',
        name: 'up-value',
        defval: 0.003,
        type: 'float',
        min: 0.001,
        max: 1,
        step: 0.001,
      },
      {
        id: 'in_2',
        name: 'dn-value',
        defval: 0.003,
        type: 'float',
        min: 0.001,
        max: 1,
        step: 0.001,
      },
    ],
    scriptIdPart: '',
  },
})
