import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const s178178_attack = createIndicator({
  id: 's178178-attack',
  displayName: '盤中力道',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10) ||
      (symbol.length == 4 && parseInt(symbol) > 0)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator'
      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)
      const c = this.PineJS.Std.close(this._context)
      const v = this.PineJS.Std.volume(this._context)

      const result = c - v
      const colorIndex = result >= 0 ? 0 : 1

      return [result, colorIndex]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'plot_0',
        palette: 'volumePalette',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '市場多方能量',
          },
          1: {
            name: '市場空方能量',
          },
        },
      },
    },
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 10,
          color: '#ffffff',
        },
      },
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#ff3834',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00ff2c',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '攻擊',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
