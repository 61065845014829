/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore'

const sounds = useSoundStore.getState()
let exit_sound_flag_ = 0

export const bandtrade_enduring = createIndicator({
  id: 'bandtradeenduring',
  displayName: '長波聚寶',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const isCheckDay = this.isSettlementDay()

      const open = this.PineJS.Std.open(this._context)
      const high = this.PineJS.Std.high(this._context)
      const high_array = this._context.new_var(high)
      const daily_high = this._context.new_var()
      const low = this.PineJS.Std.low(this._context)
      const low_array = this._context.new_var(low)
      const daily_low = this._context.new_var()
      const close = this.PineJS.Std.close(this._context)
      const itime = this.PineJS.Std.time(this._context)
      const itime_array = this._context.new_var(itime)
      const start_h = new Date(itime).getHours()
      const start_m = new Date(itime).getMinutes()
      const start_w = new Date(itime).getDay()
      const pc_high = this._context.new_var()
      const pc_low = this._context.new_var()
      const bState = this._context.new_var()
      const entryTime = this._context.new_var()
      const bsentry = this._context.new_var()
      const entryHigh = this._context.new_var()
      const entryLow = this._context.new_var()
      const bDt = this._context.new_var()
      const bExitSound = this._context.new_var()
      const symbol = context.symbol.info?.ticker
      const resolution = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W

      var pv0 = NaN
      var pv1 = NaN
      var pvflag = this._context.new_var()
      var entryPrice = this._context.new_var()

      var pb1 = NaN
      var pb2 = NaN
      var pbx = NaN
      var ps1 = NaN
      var ps2 = NaN
      var psx = NaN

      //Input-------------------------------------------------------------------
      /** 多停損 */
      const b_stopLoss = 80
      /** 多停利 */
      const b_target = 150
      /** 空停損 */
      const s_stopLoss = 80
      /** 空停利 */
      const s_target = 150
      /** 停利單 */
      const datum = 250
      /** 百分停利 */
      const percentage = 15
      /** 多空控制 */
      const setPosition = this._input(0)

      //Entry High Entry Low-------------------------------------------------------------------
      if (bState.get(0) === 0) {
        entryHigh.set(0)
        entryLow.set(0)
      }
      if (bState.get(0) === 1 && high > entryHigh) {
        entryHigh.set(high)
      }
      if (bState.get(0) === -1 && low < entryLow) {
        entryLow.set(low)
      }

      if (start_h === 8 && start_m === 45 && start_w === 3) {
        daily_high.set(high)
        daily_low.set(low)
        bsentry.set(0)
        bDt.set(itime)
      }

      if (high > daily_high) {
        daily_high.set(high)
      }

      if (low < daily_low) {
        daily_low.set(low)
      }

      if (start_h === 12 && start_m === 45 && start_w === 3) {
        pc_high.set(daily_high * 1.005)
        pc_low.set(daily_low * 0.995)
      }

      bState.get(1)
      bState.get(2)
      entryPrice.get(0)
      entryPrice.get(1)
      bExitSound.get(1)
      low_array.get(1)
      high_array.get(1)
      entryHigh.get(1)
      entryLow.get(1)
      itime_array.get(5)
      entryTime.get(5)

      /** 部位確立 */
      if (start_h > 8) {
        if (
          setPosition !== -1 &&
          close > pc_high &&
          bsentry.get(0) <= 1 &&
          itime - bDt.get(0) > 120 * 60 * 1000
        ) {
          bState.set(1)
          bDt.set(itime)
        } else if (
          setPosition !== 1 &&
          close < pc_low &&
          bsentry.get(0) <= 1 &&
          itime - bDt.get(0) > 120 * 60 * 1000
        ) {
          bState.set(-1)
          bDt.set(itime)
        }
      }

      /** 訊號出訊至圖表 */
      if (bState.get(0) === 1 && bState.get(1) !== 1 && bsentry.get(0) <= 5) {
        pv0 = 1
        pv1 = NaN
        pvflag.set(0)
        bsentry.set(bsentry + 1)
        entryPrice.set(close)
        entryTime.set(itime)
        entryHigh.set(high)
      } else if (bState.get(0) === -1 && bState.get(1) !== -1 && bsentry.get(0) <= 5) {
        pv0 = NaN
        pv1 = 1
        pvflag.set(0)
        bsentry.set(bsentry + 1)
        entryPrice.set(close)
        entryTime.set(itime)
        entryLow.set(low)
      }

      //多方出場-------------------------------------------------------------------
      //月結算
      if (bState.get(0) === 1 && bState.get(1) === 1) {
        //月結算
        if (isCheckDay === true) {
          bState.set(0)
          pb2 = 1
          bDt.set(itime)
        }
        //停損價
        if (low < entryPrice.get(0) - b_stopLoss) {
          bState.set(0)
          pbx = 1
        }
        //百分比停利
        if (pvflag.get(0) === 0 && high > entryPrice.get(0) + b_target / 2) {
          pvflag.set(1)
          pb1 = 1
        }
        //百分比停利
        const long_trailing_price = entryHigh - (percentage / 100) * (entryHigh - entryPrice)
        if (entryHigh > entryPrice + datum && low < long_trailing_price) {
          pvflag.set(2)
          pb2 = 1
          bState.set(0)
        }
      }

      //空方出場-------------------------------------------------------------------
      if (bState.get(0) === -1 && bState.get(1) === -1) {
        //月結算
        if (isCheckDay === true) {
          bState.set(0)
          ps2 = 1
        }
        //停損價
        if (high > entryPrice.get(0) + s_stopLoss) {
          bState.set(0)
          psx = 1
        }
        //百分比停利
        if (pvflag.get(0) === 0 && low < entryPrice.get(0) - s_target / 2) {
          ps1 = 1
          pvflag.set(1)
        }
        //百分比停利
        const short_traling_price = entryLow + (percentage / 100) * (entryPrice - entryLow)
        if (entryLow < entryPrice - datum && high > short_traling_price) {
          pvflag.set(2)
          ps2 = 1
          bState.set(0)
        }
      }
      //對翻
      if (bState.get(0) === 1 && bState.get(1) === -1) {
        entryPrice.set(close)
        bDt.set(itime)
      }
      if (bState.get(0) === -1 && bState.get(1) === 1) {
        entryPrice.set(close)
        bDt.set(itime)
      }

      //聲音------------------------------------------------------------------
      const displayTime = new Date(itime).toLocaleTimeString('en-US')
      const kbarLoadingTimeMs = 10000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs
      const new_bar = this._context.symbol.isNewBar

      /** 進場部位聲音 */
      if (isInitialized && new_bar) {
        if (bState.get(1) === 1 && bState.get(2) !== 1 && entryTime.get(0) === itime_array.get(1)) {
          sounds.playSoundWin168LongDebounced()
          console.log('✅', displayTime, '進多單')
        } else if (
          bState.get(1) === -1 &&
          bState.get(2) !== -1 &&
          entryTime.get(0) === itime_array.get(1)
        ) {
          sounds.playSoundWin168ShortDebounced()
          console.log('✅', displayTime, '進空單')
        }
      }

      //出場部位聲音
      if (isInitialized && new_bar) {
        exit_sound_flag_ = 0
      }

      const long_exit_condition =
        (pb1 === 1 && exit_sound_flag_ !== 1) || (pb2 === 1 && exit_sound_flag_ !== 1)

      const short_exit_condition =
        (ps1 === 1 && exit_sound_flag_ !== 1) || (ps2 === 1 && exit_sound_flag_ !== 1)

      if (isInitialized) {
        if (long_exit_condition) {
          sounds.playSoundWin168ExitDebounced()
          exit_sound_flag_ = 1
          console.log('🚨', displayTime, '多單當沖停利')
        }
        if (short_exit_condition) {
          sounds.playSoundWin168ExitDebounced()
          exit_sound_flag_ = 1
          console.log('🚨', displayTime, '空單當沖停利')
        }
      }

      return [pv0, pv1, pb1, pb2, ps1, ps2]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_2: {
          color: '#ff0000',
          textColor: '#ff0000',
          transparency: 0,
          visible: true,
        },
        plot_3: {
          color: '#00ff00',
          textColor: '#00ff00',
          transparency: 0,
          visible: true,
        },
        plot_4: {
          color: '#ff2222',
          textColor: '#ff2222',
          transparency: 50,
          visible: true,
        },
        plot_5: {
          color: '#ff2222',
          textColor: '#ff2222',
          transparency: 0,
          visible: true,
        },
        plot_6: {
          color: '#538234',
          textColor: '#538234',
          transparency: 0,
          visible: true,
        },
        plot_7: {
          color: '#00ff00',
          textColor: '#00ff00',
          transparency: 0,
          visible: true,
        },
      },
      inputs: {
        in_0: 0,
      },
    },
    plots: [
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: 'plot_7',
        type: 'chars',
      },
    ],
    styles: {
      plot_2: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'B',
        title: '多單參考訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'S',
        title: '空單參考訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '多單參考停利(短)',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'normal',
        title: '多單參考停利',
      },
      plot_6: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '空單參考停利(短)',
      },
      plot_7: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'normal',
        title: '空單參考停利',
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '部位選擇:多[1] 空[-1] 多空[0]',
        defval: 0,
        type: 'integer',
        min: -1,
        max: 1,
      },
    ],
    scriptIdPart: '',
  },
})
