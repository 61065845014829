import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { meCheckHandlerHasMe } from '~/modules/SDK/me/meCheckHandlerHasMe'
import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { EurexLoginPage } from './component/LoginView'
import { Eurex_Footer } from './eurex_Footer'
import { eurex_initStrategies } from './eurex_initStrategies'
import { Eurex_Sidepane1 } from './eurex_SidePane1'
import { Eurex_Sidepane2 } from './eurex_SidePane2'
import { eurex_SidePaneMenu } from './eurex_SidePaneMenu'
import { Eurex_Topbar } from './eurex_Topbar'

export const eurex_init = (templateProps: TemplateProps) => {
  eurex_initStrategies()

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  templateProps.permissions.pageview = meCheckHandlerHasMe

  // store.charting.widgetOptions = {
  //   ...store.charting.widgetOptions,
  //   custom_css_url: '../../m168168/chartingTheme.css',
  // }

  store.charting.lightOverrides = {
    ...store.charting.lightOverrides,
    'mainSeriesProperties.candleStyle.borderDownColor': '#4caf50',
    'mainSeriesProperties.candleStyle.borderUpColor': '#d32f30',
    'mainSeriesProperties.candleStyle.downColor': '#4caf50',
    'mainSeriesProperties.candleStyle.upColor': '#d32f30',
    'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
    'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'paneProperties.topMargin': 10,
    'paneProperties.bottomMargin': 10,
    'timeScale.rightOffset': 5,
    'paneProperties.background': '#ffffff',
    'paneProperties.vertGridProperties.color': '#cccccc',
    'paneProperties.horzGridProperties.color': '#cccccc',
    'scalesProperties.textColor': '#555555',
    'scalesProperties.lineColor': '#cccccc',
    'scalesProperties.fontSize': 13,
    'mainSeriesProperties.showPriceLine': false,
  }

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'FDX-1',
    interval: '60',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    overrides: { ...store.charting.lightOverrides },
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 200px 1fr 336px 336px;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1 Row1'
      'Col1 Chart Drawer1 Drawer2'
      'Row2 Row2 Row2 Row2';

    .Chart {
      height: calc(100vh - 48px);
    }

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 224px calc(100vh - 300px) 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
      .Chart {
        height: calc(100vh - 300px);
      }
    `)}
  `

  templateProps.layout.Row1 = Eurex_Topbar
  templateProps.layout.Drawer1 = Eurex_Sidepane1
  templateProps.layout.Drawer2 = Eurex_Sidepane2
  templateProps.layout.Col1 = eurex_SidePaneMenu
  templateProps.layout.Row2 = Eurex_Footer
  templateProps.layout.login = EurexLoginPage
}
