import { AnyFunction } from 'tsdef'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stock_bb1 = createIndicator({
  id: 'stockbb1',
  displayName: '泰坦能量',

  constructorScope: {
    init(context, inputCallback) {
      return
    },

    main(context, inputCallback) {
      const in_0 = this._input(0)
      const in_1 = this._input(1)

      const i = this.PineJS.Std.close(this._context)
      const o = in_0
      const r = in_1
      const s = this._context.new_var(i)
      const a = this.PineJS.Std.sma(s, o, this._context)
      const l = this._context.new_var(i)
      const c = this.PineJS.Std.stdev(l, o, this._context)
      const u = r * c
      return [a, a + u, a - u]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,

          color: '#FF0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,

          color: '#0000FF',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,

          color: '#a4cfff',
        },
      },
      precision: 4,
      filledAreasStyle: {
        fill_0: {
          color: '#8996ff',
          transparency: 50,
          visible: !0,
        },
      },
      inputs: { in_0: 36, in_1: 0.6 },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Median',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Upper',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        title: 'Lower',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    filledAreas: [
      {
        id: 'fill_0',
        objAId: 'plot_1',
        objBId: 'plot_2',
        type: 'plot_plot',
        title: 'Plots Background',
      },
    ],
    inputs: [
      {
        id: 'in_0',
        name: 'length',
        defval: 36,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_1',
        name: 'mult',
        defval: 0.6,
        type: 'float',
        min: 0.001,
        max: 50,
      },
    ],
    is_price_study: !0,
  },
})
