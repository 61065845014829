import React, { memo, useEffect, useState } from 'react'
import IntradayTrendChart from '~/modules/trendChart'
import SymbolSimpleCard from '~/modules/investment-consultant/symbolList/body/SymbolCardSimpleBody'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { store } from '~/pages/heineken_template/_private/store'
import styled from '@emotion/styled'
import { fontWeight500 } from '~/css/font'
import { fill, fill_vertical_all_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'

const SymbolOverviewChart = memo<ReactProps<{ symbol: string[] }>>(function SymbolOverviewChart(
  props,
) {
  const charting = store.charting
  const [dispalySymbol, setDispalySymbol] = useState(charting.symbol)

  useEffect(() => {
    setDispalySymbol(charting.symbol)
  }, [charting.symbol])

  const theme = useThemeStore(s => s.theme)

  return (
    <classes.Root
      css={baseStyled}
      className={props.className}
    >
      <classes.container className={theme}>
        <classes.header className={theme}>
          <SymbolName symbol={dispalySymbol} />
          {dispalySymbol}
        </classes.header>
        <classes.trendchart className={theme}>
          <IntradayTrendChart
            symbol={dispalySymbol as Signalr.SymbolString}
            ticksSize={10.5}
            ticksHeight={15}
            priceTicksMargin={-9}
            priceTicksSize={10.5}
          />
        </classes.trendchart>
        <classes.itemContent className={theme}>
          <classes.item
            className={theme}
            onClick={() => setDispalySymbol(props.symbol[0])}
          >
            <SymbolSimpleCard.Display
              symbol={props.symbol[0]}
              currentSymbol={dispalySymbol}
            />
          </classes.item>
          <classes.item
            className={theme}
            onClick={() => setDispalySymbol(props.symbol[1])}
          >
            <SymbolSimpleCard.Display
              symbol={props.symbol[1]}
              currentSymbol={dispalySymbol}
            />
          </classes.item>
          <classes.item
            className={theme}
            onClick={() => setDispalySymbol(props.symbol[2])}
          >
            <SymbolSimpleCard.Display
              symbol={props.symbol[2]}
              currentSymbol={dispalySymbol}
            />
          </classes.item>
        </classes.itemContent>
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    ${fill};
  `,
  container: styled.div`
    ${fill_vertical_all_center};
    border-radius: 5px;
    border: 1px solid;
  `,
  header: styled.div`
    ${flex.h.allCenter};
    padding: 0px 5px;
    font-size: 14px;
    ${fontWeight500};
  `,
  trendchart: styled.div`
    ${flex.h.allCenter};
    width: 100%;
    height: 65%;
  `,
  itemContent: styled.div`
    ${flex.h.allCenter};
    width: 100%;
    height: 35%;
    padding: 5px;
    border-radius: 5px;
  `,
  /** 使用選擇器取代原本背影顏色 `> div` */
  item: styled.div`
    width: 33.3%;
    height: 100%;
    margin: 5px;
  `,
}

const baseStyled = css`
  ${classes.container} {
    &.dark {
      background-color: #222222;
      color: #ffffff;
      border-color: #555555;
    }
    &.light {
      background-color: #ffffff;
      color: #454545;
      border-color: #aaaaaa;
    }
  }
  ${classes.header} {
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #454545;
    }
  }
`

export default {
  Display: SymbolOverviewChart,
  classes,
}
