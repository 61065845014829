import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { jerry597_store } from '~/pages/jerry597_stock/jerry597_store'

export const jerry597_stockMainChart = createIndicator({
  id: 'jerry597-stockMainChart',
  displayName: '金旺股票指標',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const userPosition = jerry597_store.strategy
      const position = this._context.new_var()

      if (isNaN(position.get(0))) position.set(0)

      const close = this.ohlc.closeArray
      const macd = this.macd(18, 32, 12)
      const ma120 = this.PineJS.Std.sma(close, 120, this._context)
      const ma20 = this.PineJS.Std.sma(close, 20, this._context)
      const osc = this._context.new_var(macd.osc)

      const longCondition = osc.get(0) > 0 && close.get(0) > ma120
      const shortCondition = osc.get(0) < 0 && close.get(0) < ma120

      const longExitCondition = osc.get(0) < 0 && close.get(0) < ma20
      const shortExitCondition = osc.get(0) > 0 && close.get(0) > ma20

      let entryLongFlagLong = NaN
      let exitLongFlagLong = NaN
      let entryShortFlagLong = NaN
      let exitShortlagLong = NaN

      position.get(2)

      if (userPosition !== 'short' && longCondition) {
        position.set(1)
      }

      if (userPosition !== 'long' && shortCondition) {
        position.set(-1)
      }

      // ----------- Entry
      if (userPosition !== 'short' && position.get(0) === 1 && position.get(1) !== 1) {
        entryLongFlagLong = 1
      }
      // ----------- Exit
      if (userPosition !== 'short' && position.get(0) === 1 && position.get(1) === 1) {
        if (longExitCondition) {
          position.set(0)
          exitLongFlagLong = 1
        }
      }

      // ----------- Entry
      if (userPosition !== 'long' && position.get(0) === -1 && position.get(1) !== -1) {
        entryShortFlagLong = 1
      }
      // ----------- Exit
      if (userPosition !== 'long' && position.get(0) === -1 && position.get(1) === -1) {
        if (shortExitCondition) {
          position.set(0)
          exitShortlagLong = 1
        }
      }

      const barColor = position.get(0) === 1 ? 0 : position.get(0) === -1 ? 2 : 1

      return [entryLongFlagLong, exitLongFlagLong, entryShortFlagLong, exitShortlagLong, barColor]
    },
  },
  metainfo: {
    _metainfoVersion: 42,
    is_price_study: true,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    palettes: {
      barPalettes: {
        colors: {
          0: {
            color: '#ffbf00',
            width: 1,
            style: 0,
          },
          1: {
            color: '#ffffff',
            width: 1,
            style: 0,
          },
          2: {
            color: '#0040ff',
            width: 1,
            style: 0,
          },
        },
      },
    },
    plots: [
      { id: 'entryLongFlag', type: 'chars' },
      { id: 'exitLongFlag', type: 'chars' },
      { id: 'entryShortFlag', type: 'chars' },
      { id: 'exitShortFlag', type: 'chars' },
      { id: 'barColor', type: 'bar_colorer', palette: 'barPalettes' },
    ],
    defaults: {
      precision: 0,
      inputs: {},
      styles: {
        entryLongFlag: { color: '#ff0000', textColor: '#ff0000', transparency: 20, visible: true },
        exitLongFlag: { color: '#ffbf00', textColor: '#ffbf00', transparency: 20, visible: true },
        entryShortFlag: { color: '#5ffa6d', textColor: '#5ffa6d', transparency: 20, visible: true },
        exitShortFlag: { color: '#0040ff', textColor: '#0040ff', transparency: 20, visible: true },
      },
    },
    styles: {
      entryLongFlag: {
        title: '進場點(多)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單進場',
      },
      exitLongFlag: {
        title: '出場點(多)',
        isHidden: false,
        location: 'AboveBar',
        char: '*',
        size: 'small',
        text: '',
      },
      entryShortFlag: {
        title: '進場點(空)',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單進場',
      },
      exitShortFlag: {
        title: '出場點(空)',
        isHidden: false,
        location: 'BelowBar',
        char: '*',
        size: 'small',
        text: '',
      },
    },
    inputs: [],
  },
})
