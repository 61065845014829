import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { fill, fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { Styleds } from '../styleds'
import { Winner98StrategyButton1 } from '../winner98_SidebarPane1'
import { Winner98_ChartPositionSelector } from './winner98_ChartPositionSelecter'
import { Winner98_Warring } from './winner98_Warring'

export const Winner98_Charting = memo<ReactProps>(function Winner98_Charting() {
  const { isPc } = useMedia()
  const chartingHeight = isPc ? 48 : 96
  const fontSize = isPc ? 14 : 12
  return (
    <div css={fill}>
      {!isPc && (
        <div
          css={css`
            ${fill_horizontal_all_center}
            height: 48px;
            padding: 8px;
            background-color: #202227;
            border-bottom: 1px solid #555555;
          `}
        >
          <Winner98StrategyButton1 />
        </div>
      )}
      <div
        css={css`
          width: 100%;
          height: calc(100% - ${chartingHeight}px);
        `}
      >
        <Styleds.Charting />
      </div>
      <div
        css={css`
          width: 100%;
          height: 48px;
          font-size: ${fontSize}px;
          padding: 4px;
        `}
      >
        <Winner98_Warring />
      </div>
    </div>
  )
})
