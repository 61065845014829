import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ScrollArea, Table } from '@mantine/core'
import dayjs, { Dayjs } from 'dayjs'
import { memo, useEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { horizontalScrollbarCss, scrollbar2Css } from '~/css/scrollbarCss'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore'
import { useOptionQuote } from './optionAnalyze/useOptionQuote'
import { fadeIn } from './OptionChart'

/** 塞在表格內成交量條 ~.~ */
const VolBar = memo<ReactProps<{ value: number; max: number; position: 'start' | 'end' }>>(
  function VolBar(props) {
    const rotate = props.position === 'start' ? 90 : 270

    const greenColor = 'rgb(138, 176, 116)'
    const redColor = 'rgb(204, 98, 100)'
    const ratio = props.value / props.max

    //red色皆範圍:138~204 range:66
    const red = 138 + ratio * 66
    //green色皆範圍:98~176 range:68 *綠色下降敏感度提高1.5倍
    const green = 176 - ratio * 102

    const bgColor = `linear-gradient(${rotate}deg, ${greenColor}, rgb(${red}, ${green}, 100))`

    return (
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: ${props.position};
          background-color: #333333;
          border-radius: 5px;
        `}
      >
        <div
          css={css`
            width: ${(props.value / props.max) * 100}%;
            height: 100%;
            background: ${bgColor};
            border-radius: 5px;
          `}
        >
          &nbsp;
        </div>
      </div>
    )
  },
)

/** 價平欄位背景顏色 */
const atTheMoneyPriceMark = (strikePrice: string, atTheMoneyPrice_: number) => {
  if (Number(strikePrice) === atTheMoneyPrice_) {
    return '#363a48aa'
  } else return '#00000000'
}

/** Delta顏色 根據價平變化 */
const deltaMark = (delta: string, atTheMoneyPrice_: number, type: 'call' | 'put') => {
  const callType = type === 'call' && Number(delta) >= atTheMoneyPrice_
  const putType = type === 'put' && Number(delta) < atTheMoneyPrice_

  if (callType || putType) {
    return 'linear-gradient(100deg, #98911ecc, #9c8b2fbb);'
  } else return 'linear-gradient(100deg, #8b4f52cc, #af6266bb);'
}

/** 漲跌顏色 */
const changeFill = (close: number, prevref: number) => {
  if (close > prevref) {
    return '#ff2222'
  } else if (close < prevref) {
    return '#22cc22'
  } else {
    return '#efefef'
  }
}

/** 這裡會給intraday 8:45 || 15:00 用本機時間比較 清盤對應的時間 */
const currentDateTime = (intradate: Dayjs) => {
  const nowDateTime = dayAPI()
  const openTimeAM = dayAPI(intradate).startOf('day').add(8, 'hour').add(45, 'minute')
  const openTimePM = dayAPI(intradate).startOf('day').add(15, 'hour').add(0, 'minute')

  //日盤盤中
  const tradeTime = nowDateTime >= openTimeAM && nowDateTime < openTimePM

  const result = tradeTime ? openTimeAM : openTimePM

  return result
}

//todo: 清盤規則
//T
//如果是在intraday且現在時間>=08:45 但履約價最近一次成交時間是<08:45 返回0成交量
//T+1
//如果是在intraday且現在時間>=15:00 但履約價最近一次成交時間是<15:00 返回0成交量
const optionVolume = (optionDateTime: string, intraDateTime: Dayjs, vol: number) => {
  if (dayAPI(optionDateTime) >= currentDateTime(intraDateTime)) {
    return vol
  } else return 0
}

export const OptionTable = memo<ReactProps>(function OptionTable() {
  const opState = useSnapshot(optionAnalyzeStore)
  const dateState = useSnapshot(staticStore)
  const atTheMoneyPrice = opState.atTheMoneyPrice

  const intraday = dateState.tradedDate.intraday

  const [updatedAnimation, setUpdatedAnimation] = useState(false)

  useEffect(() => {
    setUpdatedAnimation(true)
    setTimeout(() => {
      setUpdatedAnimation(false)
    }, 1000)
  }, [opState.currentContract])

  const data = useOptionQuote().quoteData

  const maxVolCall = Math.max(...data.map(s => s.volc))
  const maxVolPull = Math.max(...data.map(s => s.volp))
  const maxVol = Math.max(maxVolCall, maxVolPull)

  const rows = data.map(element => (
    <tr
      css={css`
        ${tableStyled.row};
        background-color: ${atTheMoneyPriceMark(element.strikePrice, atTheMoneyPrice)};
      `}
      key={element.strikePrice}
    >
      <tableStyled.td>
        <VolBar
          max={maxVol}
          value={optionVolume(element.dateTimec, intraday, element.volc)}
          position={'end'}
        />
      </tableStyled.td>
      <tableStyled.td>{optionVolume(element.dateTimec, intraday, element.volc)}</tableStyled.td>
      <tableStyled.td>{element.ivc.toFixed(2)}%</tableStyled.td>
      <tableStyled.td
        css={css`
          color: ${changeFill(element.pxc, element.prevRefc)};
        `}
      >
        {element.pxc}
      </tableStyled.td>
      <tableStyled.td
        css={css`
          background: ${deltaMark(element.strikePrice, atTheMoneyPrice, 'call')};
          border-radius: 2px 0px 0px 2px;
        `}
      >
        {element.deltac.toFixed(2)}
      </tableStyled.td>
      <tableStyled.td
        css={css`
          background: linear-gradient(90deg, #2b458f, #4372d1);
        `}
      >
        {element.strikePrice}
      </tableStyled.td>
      <tableStyled.td
        css={css`
          background: ${deltaMark(element.strikePrice, atTheMoneyPrice, 'put')};
          border-radius: 0px 2px 2px 0px;
        `}
      >
        {element.deltap.toFixed(2)}
      </tableStyled.td>
      <tableStyled.td
        css={css`
          color: ${changeFill(element.pxp, element.prevRefp)};
        `}
      >
        {element.pxp}
      </tableStyled.td>
      <tableStyled.td>{element.ivp.toFixed(2)}%</tableStyled.td>
      <tableStyled.td>{optionVolume(element.dateTimep, intraday, element.volp)}</tableStyled.td>
      <tableStyled.td>
        <VolBar
          max={maxVol}
          value={optionVolume(element.dateTimep, intraday, element.volp)}
          position={'start'}
        />
      </tableStyled.td>
    </tr>
  ))

  return (
    <Table
      highlightOnHover
      withBorder
      css={css`
        width: 100%;
        height: 100%;
        font-family: Roboto, Helvetica, Arial, sans-serif;
      `}
    >
      <thead>
        <tr css={tableStyled.title}>
          <tableStyled.th>Vol(C)</tableStyled.th>
          <tableStyled.th>Vol(C)</tableStyled.th>
          <tableStyled.th>IV(C)</tableStyled.th>
          <tableStyled.th>Px(C)</tableStyled.th>
          <tableStyled.th>Delta(C)</tableStyled.th>
          <tableStyled.th>StrikePx</tableStyled.th>
          <tableStyled.th>Delta(P)</tableStyled.th>
          <tableStyled.th>Px(P)</tableStyled.th>
          <tableStyled.th>IV(P)</tableStyled.th>
          <tableStyled.th>Vol(P)</tableStyled.th>
          <tableStyled.th>Vol(P)</tableStyled.th>
        </tr>
      </thead>

      {!data || data.length === 0 ? (
        <tableStyled.loading>Loading...</tableStyled.loading>
      ) : (
        <tbody
          css={css`
            ${horizontalScrollbarCss};
            height: calc(100% - 8px);
            animation: ${updatedAnimation === true && fadeIn} 1.5s;
          `}
        >
          {rows}
        </tbody>
      )}
    </Table>
  )
})

const tableStyled = {
  title: css`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: repeat(11, 9%);
    height: 38px;
  `,
  row: css`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: repeat(11, 9%);
    height: 36px;
  `,
  loading: styled.div`
    ${fill_horizontal_all_center};
    height: calc(100% - 38px);
  `,
  th: styled.th`
    ${fill_horizontal_all_center};
    color: #679be8 !important;
    width: 60px;
  `,
  td: styled.td`
    ${fill_horizontal_all_center};
    height: 36px;
    width: 60px;
  `,
}
