import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Rich888_Footer } from './rich888_Footer'
import { rich888_initStrategies } from './rich888_initStrategies'
import { Rich888_SidePane1 } from './rich888_SidePane1'
import { Rich888_Topbar } from './rich888_Topbar'

export const rich888_init = (templateProps: TemplateProps) => {
  //rich888_initStyling(templateProps)
  rich888_initStrategies()

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')
  // templateProps.layout.login = (
  //   <AgentCarouselSubscriptionCheck resources={[{ image: [true, '/big3/loginView.jpg'] }]} />
  // )

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  // store.charting.widgetOptions = {
  //   ...store.charting.widgetOptions,
  //   custom_css_url: '../../big3/chartingTheme.css',
  // }
  store.charting.lightOverrides = {
    'mainSeriesProperties.candleStyle.upColor': '#e86d66',
    'mainSeriesProperties.candleStyle.downColor': '#121212',
    'mainSeriesProperties.candleStyle.borderUpColor': '#e94639',
    'mainSeriesProperties.candleStyle.borderDownColor': '#121212',
    'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
    'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
  }

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: 'D',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    overrides: store.charting.lightOverrides,
    disabledHeaderChartType: true,
    disabledHeaderCompare: true,
    disabledGoToDate: true,
    disabledHeaderSaveload: false,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100vh - 48px) 1fr;
    grid-template-columns: 1fr 1fr 336px;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Chart Chart Drawer1'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2";
    `)}
  `

  templateProps.layout.Row1 = Rich888_Topbar
  templateProps.layout.Row2 = Rich888_Footer
  templateProps.layout.Drawer1 = Rich888_SidePane1
}
