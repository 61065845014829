import { css } from '@emotion/react'
import { BsChevronRight } from 'react-icons/bs'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import Col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { hyt888Stock_initStyling } from '~/pages/hyt888_stock/_private/hyt888Stock_initStyling'
import { Hyt888Stock_Sidebar1 } from '~/pages/hyt888_stock/_private/hyt888Stock_Sidebar1'
import { stock_bb1 } from '~/trades/indicators/hyt888/stock_bb1'
import { stock_hma1 } from '~/trades/indicators/hyt888/stock_hma1'
import { stock_sg1 } from '~/trades/indicators/hyt888/stock_sg1'
import { stock_sma1 } from '~/trades/indicators/hyt888/stock_sma1'
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton'

export const hyt888Stock_init = (templateProps: TemplateProps) => {
  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')
  useThemeStore.setState({ theme: 'dark' })
  hyt888Stock_initStyling(templateProps)

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: true,
    disabledTimeframesToolbar: true,
    symbol: 'TSEA',
    interval: 'D',
  }

  store.charting.indicatorsPreloaded = [stock_bb1, stock_hma1, stock_sg1, stock_sma1]
  store.charting.indicators = [stock_bb1, stock_hma1, stock_sg1, stock_sma1]
  store.charting.symbol = 'TSEA'
  store.charting.interval = '60'

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Row1 Row1'
      'Drawer1 Chart'
      'Drawer1 Chart';

    ${createIPadCss(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `

  templateProps.layout.Row1 = (
    <Preset_Topbar
      showLeftBurger
      componentsInRight={<Col_UserAvatarAndChartingServersSimple.Display />}
      css={css`
        background-color: #141721;
        width: 100%;
        height: 48px;
      `}
    />
  )
  templateProps.layout.Drawer1 = (
    <Fr_WithDrawerCloseButton.Display
      right
      Button={
        <BsChevronRight
          css={css`
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #333333cc;
            border: 1px solid #555555;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}
        />
      }
    >
      <Hyt888Stock_Sidebar1 />
    </Fr_WithDrawerCloseButton.Display>
  )
}
