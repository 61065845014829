import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stock_dpo1 = createIndicator({
  id: 'stockdpo1',
  displayName: '機會大小',
  filterOnTheme: 'dark',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const f_0 = () => {
        const e = 60 /*this._input(0)*/
        const t = false /*this._input(1)*/
        const i = Math.floor(e / 2 + 1)
        const o = this._context.new_var(this.PineJS.Std.close(this._context))
        const r = this._context.new_var(this.PineJS.Std.sma(o, e, this._context))
        const rr = this._context.new_var(this.PineJS.Std.sma(o, 120, this._context))
        const s = this._context.new_var(this.PineJS.Std.close(this._context))
        const a = s.get(i) - r
        const l = this.PineJS.Std.close(this._context) - r.get(i)
        const c = t ? a : l
        const cc = this._context.new_var(c)
        const u = -i
        const h = t ? u : 0
        const p = this.PineJS.Std.stdev(close_array, 120, this._context)
        const pp = this.PineJS.Std.stdev(cc, 120, this._context)
        return [c, h, (p + pp) / 2]
      }

      const i = f_0()
      const up_line = i[2]
      const dn_line = -i[2]

      return [
        {
          value: i[0],
          offset: i[1],
        },
        up_line,
        dn_line,
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 42,
    is_price_study: !1,
    is_hidden_study: !1,
    scriptIdPart: '',
    isCustomIndicator: true,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 4,
          trackPrice: !1,
          transparency: 20,
          visible: !0,
          color: '#ffee22',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 15,
          visible: !0,
          color: '#FF0000',
        },
        plot_3: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 15,
          visible: !0,
          color: '#00eeee',
        },
      },
      precision: 2,

      inputs: {
        /*in_0: 21,
              in_1: !1*/
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '機會大小',
        histogramBase: 0,
        joinPoints: !1,
        isHidden: !1,
      },
    },

    inputs: [
      /*{
          id: "in_0",
          name: "Period",
          defval: 21,
          type: "integer",
          min: 1,
          max: 1e12
        },
        {
          id: "in_1",
          name: "isCentered",
          defval: !1,
          type: "bool"
        }*/
    ],
  },
})

export const stock_dpo1_light = stock_dpo1.duplicate({
  id: 'stockdpo1--light',
  filterOnTheme: 'light',
  metainfo: {
    defaults: {
      styles: {
        plot_0: {
          color: '#f2c24f',
        },
        plot_2: {
          color: '#FF0000',
        },
        plot_3: {
          color: '#00eeee',
        },
      },
    },
  },
})
