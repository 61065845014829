import { CircularProgress } from '@mui/material'
import { memo } from 'react'
import { strategyPerformanceStyleds } from './strategyPerformanceStyled'

export const LoadingStatus = memo<ReactProps>(function LoadingStatus() {
  return (
    <strategyPerformanceStyleds.emptyContent>
      <div>資料載入中</div>
      <CircularProgress
        size={24}
        disableShrink
      />
    </strategyPerformanceStyleds.emptyContent>
  )
})
