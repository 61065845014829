import dayAPI from '~/utils/dayAPI'
import { store } from '~/pages/heineken_template/_private/store'
import { ichi1 } from '~/trades/indicators/good178/ichi1'
import { dpo1, dpo1_light } from '~/trades/indicators/good178/dpo1'
import { ichi2 } from '~/trades/indicators/good178/ichi2'
import { cz1, cz1_light } from '~/trades/indicators/good178/cz1'
import { sg2, sg2_light } from '~/trades/indicators/good178/sg2'
import { ao1 } from '~/trades/indicators/good178/ao1'
import { sg3, sg3_light } from '~/trades/indicators/good178/sg3'

export const good178_initStrategies = () => {
  store.charting.initStrategy({
    configs: [
      {
        displayName: '主要',
        interval: '5',
        symbol: 'TX-1',
        indicators: [
          ichi1,
          ichi2,
          dpo1,
          dpo1_light,
          cz1,
          cz1_light,
          ao1,
          sg2,
          sg2_light,
          sg3,
          sg3_light,
        ],
        calcFrom: dayAPI().subtract(7, 'day'),
        //panesRatio: 60,
      },
    ],
  })
}
