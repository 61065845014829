import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_horizontal, fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '../heineken_template/_private/store'

export const Winner98_crypto_Topbar = memo<ReactProps>(function Winner98_crypto_Topbar() {
  const state = useSnapshot(useChartPositionStore)
  return (
    <div
      css={css`
        ${fill_horizontal};
        align-items: center;
        justify-content: end;
        gap: 16px;
        padding: 0px 16px;
        background-color: #141721;
        border-bottom: 2px solid #2b2e38;
      `}
    >
      <ChartingServerSwitch charting={store.charting} />
      <styleds.button
        fill='#d07191'
        selected={state.positionType === 'long'}
        onClick={event => {
          useChartPositionStore.positionType = 'long'
          store.charting.updateFromState()
        }}
      >
        Long
      </styleds.button>
      <styleds.button
        selected={state.positionType === 'short'}
        fill='#89cbdc'
        onClick={event => {
          useChartPositionStore.positionType = 'short'
          store.charting.updateFromState()
        }}
      >
        Short
      </styleds.button>
      <styleds.button
        selected={state.positionType === 'default'}
        fill='#555555'
        onClick={event => {
          useChartPositionStore.positionType = 'default'
          store.charting.updateFromState()
        }}
      >
        Default
      </styleds.button>
    </div>
  )
})

const styleds = {
  button: styled.div<{ fill: string; selected: boolean }>`
    ${fill_horizontal_all_center};
    width: 160px;
    height: 32px;
    background-color: ${props => props.fill};
    border-radius: 4px;
    border: 1.6px solid ${props => (props.selected === true ? '#ffffff' : 'transparent')};
    cursor: pointer;
    &:hover {
      background-color: ${props => props.fill}dd;
    }
  `,
}
