import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import MarqueeSymbolQuote from '../datatree/component/MarqueeSymbolQuote'
MarqueeSymbolQuote

export const Wu5868_MarqueeQuote = memo<ReactProps>(function Wu5868_MarqueeQuote() {
  const symbol = ['TSEA', 'TSE13', 'TSE17', 'TX-1', '2330', '2317', '2454']

  return (
    <styleds.container>
      <MarqueeSymbolQuote.Display symbol={symbol} />
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_all_center};
    background-color: #f9f9f9;
    padding: 4px;
    border-bottom: 1px solid #aaaaaa;
  `,
}
