import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import dayAPI from '~/utils/dayAPI'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import styled from '@emotion/styled'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'

/** 商品詳細資訊`開,高,低,收,量`之類的 */
export const SymbolInfoForm = memo<ReactProps<{ symbol: Signalr.SymbolString }>>(
  function SymbolInfoForm(props) {
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]
    const amp = (quote?.high ?? 0) - (quote?.low ?? 0)
    const time = dayAPI(quote?.datetime).format('HH:mm:ss')

    return (
      <styleds.symbolInfoContainer>
        <p>
          開盤
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='open'
          />
        </p>
        <p>
          最高
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='high'
          />
        </p>
        <p>
          最低
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='low'
          />
        </p>
        <p>
          總量
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='volume'
          />
        </p>
        <p>
          參考
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='prevRef'
          />
        </p>
        <p>
          單量
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='qty'
          />
        </p>
        <p>
          委買
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='bid'
          />
        </p>
        <p>
          委賣
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='ask'
          />
        </p>

        <p>振幅{amp.toFixed(2)}</p>
        <p>{time}</p>
      </styleds.symbolInfoContainer>
    )
  },
)

const styleds = {
  //商品元件
  symbolInfoContainer: styled.div`
    width: 100%;
    height: 88px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 33% 33% 33%;
    font-size: 14px;
    padding: 0 8px;
    > p {
      ${fill_horizontal_cross_center};
      margin: 0;
      white-space: nowrap;
    }
  `,
}
