import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { usePercentRangeResource } from '~/modules/screener/containers/useStockScreenerResource'

type MarketData = {
  '3days': { long: string[]; short: string[] }
  '5days': { long: string[]; short: string[] }
  '10days': { long: string[]; short: string[] }
  '20days': { long: string[]; short: string[] }
  '60days': { long: string[]; short: string[] }
  '120days': { long: string[]; short: string[] }
}

export const MarketStockHiLoChart = memo<ReactProps<{ data?: MarketData }>>(
  function MarketStockHiLoChart(props) {
    ///----

    const dailyRank3Long =
      usePercentRangeResource({
        days: 3,
        status: 'new_high',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank5Long =
      usePercentRangeResource({
        days: 5,
        status: 'new_high',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank10Long =
      usePercentRangeResource({
        days: 10,
        status: 'new_high',
        volume_gte: 500,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank20Long =
      usePercentRangeResource({
        days: 20,
        status: 'new_high',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank60Long =
      usePercentRangeResource({
        days: 60,
        status: 'new_high',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank120Long =
      usePercentRangeResource({
        days: 120,
        status: 'new_high',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank3Short =
      usePercentRangeResource({
        days: 3,
        status: 'near_low',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank5Short =
      usePercentRangeResource({
        days: 5,
        status: 'near_low',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank10Short =
      usePercentRangeResource({
        days: 10,
        status: 'near_low',
        volume_gte: 500,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank20Short =
      usePercentRangeResource({
        days: 20,
        status: 'near_low',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank60Short =
      usePercentRangeResource({
        days: 60,
        status: 'near_low',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const dailyRank120Short =
      usePercentRangeResource({
        days: 120,
        status: 'near_low',
        volume_gte: 500,
        volume_lte: 0,
      })?.data?.map(s => s.symbol) ?? []

    const newData = {
      '3days': { long: dailyRank3Long, short: dailyRank3Short },
      '5days': { long: dailyRank5Long, short: dailyRank5Short },
      '10days': { long: dailyRank10Long, short: dailyRank10Short },
      '20days': { long: dailyRank20Long, short: dailyRank20Short },
      '60days': { long: dailyRank60Long, short: dailyRank60Short },
      '120days': { long: dailyRank120Long, short: dailyRank120Short },
    }

    const data3 = newData['3days']
    const data5 = newData['5days']
    const data10 = newData['10days']
    const data20 = newData['20days']
    const data60 = newData['60days']
    const data120 = newData['60days']

    const max = Math.max(data3.long.length, data3.short.length) * 1.2

    const styleds = {
      days: styled.div`
        ${fill_vertical_all_center};
      `,
      barContent: styled.div<{ position: 'right' | 'left' }>`
        ${fill_horizontal_all_center};
        justify-content: ${option => (option.position === 'left' ? ' flex-end' : ' flex-start')};
      `,
      bar: styled.div<{ width: number; fill: string }>`
        background-color: ${option => option.fill};
        width: ${option => option.width * 100}%;
        height: 64%;
        border-radius: 3px;
      `,
      value: styled.div`
        ${fill_vertical_all_center};
      `,
      item: styled.div`
        ${fill_vertical_all_center};
      `,
    }

    const Item = memo<
      ReactProps<{
        data: {
          long: string[]
          short: string[]
        }
      }>
    >(function Item(props_) {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: 10% 32% 14% 32% 10%;
            width: 100%;
            height: 100%;
          `}
        >
          <styleds.value>{props_.data.long.length}</styleds.value>
          <styleds.barContent position='left'>
            <styleds.bar
              width={props_.data.long.length / max}
              fill={'#cc2222'}
            />
          </styleds.barContent>
          <styleds.days>{props_.children}</styleds.days>
          <styleds.barContent position='right'>
            <styleds.bar
              width={props_.data.short.length / max}
              fill={'#22cc22'}
            />
          </styleds.barContent>
          <styleds.value>{props_.data.short.length}</styleds.value>
        </div>
      )
    })

    return (
      <div
        css={css`
          display: grid;
          grid-template-rows: repeat(7, calc(14% - 8px));
          gap: 8px;
          width: 100%;
          height: 260px;
          background-color: #fafafa;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 40% 20% 40%;
            width: 100%;
            height: 100%;
            background-color: #ebebeb;
            border-radius: 5px;
          `}
        >
          <div css={fill_horizontal_all_center}>創高家數</div>
          <div css={fill_horizontal_all_center}>區間天數</div>
          <div css={fill_horizontal_all_center}>創低家數</div>
        </div>
        <Item data={data3}>3日</Item>
        <Item data={data5}>5日</Item>
        <Item data={data10}>10日</Item>
        <Item data={data20}>20日</Item>
        <Item data={data60}>60日</Item>
        <Item data={data120}>120日</Item>
      </div>
    )
  },
)
