import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 成交量 */
export const wu5868_volume = createIndicator({
  displayName: '成交量',
  id: 'wu5868-volume',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const volume = this.PineJS.Std.volume(this._context)
      const symbol = this.PineJS.Std.ticker(this._context)

      const resultVolume = symbol.includes('TSEA') ? volume / 10e7 : volume
      const volume_array = this._context.new_var(resultVolume)

      const volma1 = this.PineJS.Std.sma(volume_array, 5, this._context)
      const volma2 = this.PineJS.Std.sma(volume_array, 10, this._context)

      const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1

      return [resultVolume, volma1, volma2, colorIndex]
    },
  },
  metainfo: {
    precision: 0,
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],

    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '量',
          },
          1: {
            name: '量',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          transparency: 0,
          visible: !0,
          color: '#ff55ff',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          transparency: 0,
          visible: !1,
          color: '#ffcc00',
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 10,
          visible: !0,
          color: '#02aa22',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#cf304a',
              width: 0,
              style: 0,
            },
            1: {
              color: '#02aa22',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },
    styles: {
      vol: {
        title: '成交量',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: '5日均量',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        title: '10日均量',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})
