import { css } from '@emotion/react'
import { Fragment, memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import FuiSwitch from '~/pages/heineken_template/components/FuiSwitch'
import { weng888Stock_agentStore } from './weng888Stock_agentStore'
import Col_UserAvatarAndChartingServers from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServers'
import useMedia from '~/hooks/useMedia'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { store } from '~/pages/heineken_template/_private/store'
import styleds from './styleds'
import { useDatePick } from '~/modules/screener/useDatePick'
import { useMount } from 'react-use'

export const Weng888Stock_Topbar = memo<ReactProps>(function Weng888Stock_Topbar() {
  const agentState = useSnapshot(weng888Stock_agentStore)
  const { isPc } = useMedia()

  const datePick = useDatePick()
  useMount(() => {
    weng888Stock_agentStore.stockPickedDate.setDate(datePick)
  })

  return (
    <Fragment>
      {isPc && (
        <styleds.Topbar>
          <Col_UserAvatarAndChartingServers.Display />
          <div
            css={css`
              border-radius: 5px;
              background-color: #2d2f37;
              padding: 0px 0px 0px 8px;
            `}
          >
            <FuiSwitch.Display
              leftText='高價股模式'
              rightText='低價股模式'
              checked={agentState.stockPriceLowMode}
              onChange={checked => {
                weng888Stock_agentStore.stockPriceLowMode = checked
              }}
            />
          </div>
        </styleds.Topbar>
      )}
      {!isPc && (
        <styleds.Topbar>
          <Preset_Topbar
            showLeftBurger
            showRightBurger
            componentsInRight={
              <div css={fill_horizontal_all_center}>
                <ChartingServerSwitch charting={store.charting} />
                <UserAvatarAsDialogButton />
              </div>
            }
          ></Preset_Topbar>
        </styleds.Topbar>
      )}
    </Fragment>
  )
})
