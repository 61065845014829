import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { flex, jc } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { useMount } from 'react-use'
import dayAPI from '~/utils/dayAPI'
import { useDatePick } from '~/modules/screener/useDatePick'
import { store } from '~/pages/heineken_template/_private/store'

export const M168168Stock_Topbar = memo<ReactProps>(function Lung88988Stock_Topbar() {
  const { isPc, isPhone } = useMedia()

  return (
    <div
      css={css`
        ${flex.h.allCenter};
        background-color: #333333;
        justify-content: end;
      `}
    >
      {isPc && (
        <div
          css={css`
            ${flex.h.allCenter};
            justify-content: end;
            background: url('m168168/slider.jpg');
            background-repeat: repeat-x;
            background-color: #333333;
            border-bottom: 1px solid #777777;
            width: 100%;
            height: 100%;
          `}
        >
          <div
            css={css`
              background-color: #333333ee;
              ${flex.h.allCenter};
            `}
          >
            <ChartingServerSwitch charting={store.charting} />
            <UserAvatarAsDialogButton />
          </div>
        </div>
      )}
      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div
              css={css`
                ${flex.h.allCenter};
              `}
            >
              {/* {!isPhone && <img src='m168168/slider.jpg' />} */}
              <ChartingServerSwitch charting={store.charting} />
              <UserAvatarAsDialogButton />
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})
