import { proxy } from 'valtio'

export const indicatorsValueStore = proxy({
  /** 商品類型 */
  symbolChannel: '' as string,
  /** 商品名稱 */
  symbolNumber: '' as string,
  /** 進場日期 */
  entryDate: NaN as number,
  /** 部位 */
  marketPosition: 0 as 0 | 1 | -1,
  /** 進場價格 */
  entryPrice: NaN as number,
  /** 停利價格 */
  targetPrice: NaN as number,
  /** 停損價格 */
  stopLossPrice: NaN as number,
  /** 停利價格 */
  multipleTargetPrice: [] as number[],
  /** 停損價格 */
  multipleStopLossPrice: [] as number[],
  /** 現價 */
  close: NaN as number,
  /** 未平倉損益 */
  openPositionProfit: NaN as number,
  /** 最小跳動點 */
  movementTick: NaN as number,

  //null: [NaN, NaN] as number[],
})
