import { memo } from 'react'
import { styleds } from './styleds'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { big3_strategiesGroup } from './big3_initStrategies'
import { store } from '~/pages/heineken_template/_private/store'
import { useSnapshot } from 'valtio'
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'

import { big3_store } from './big3_store'
import { TbWaveSawTool } from 'react-icons/tb'

export const Big3_SidePane2 = memo<ReactProps>(function Big3_SidePane2() {
  const charting = useSnapshot(store.charting)
  const state = useSnapshot(big3_store)

  const powerTrend = state.powerTrend
  const dcDailyTrend = state.dcDailyTrend
  const priceLineTrend = state.priceLineTrend
  const analyzerTrend = state.analyzerTrend

  const score = powerTrend + dcDailyTrend + priceLineTrend + analyzerTrend
  const overView = () => {
    if (score === 4) return '多方趨勢'
    if (score === 3) return '多方趨勢'
    if (score === 2) return '震盪偏多'
    if (score === 1) return '區間盤整'
    if (score === 0) return '區間盤整'
    if (score === -1) return '區間盤整'
    if (score === -2) return '震盪偏空'
    if (score === -3) return '空方趨勢'
    if (score === -4) return '空方趨勢'
  }
  return (
    <styleds.SideBar>
      <styleds.SideBarTitle>策略選擇</styleds.SideBarTitle>
      <div
        css={css`
          ${fill_horizontal_all_center};
          width: 76%;
          height: 32px;
          gap: 4px;
        `}
      >
        {big3_strategiesGroup.main.map(config => {
          const isActive = config.displayName === charting.strategySelected.displayName
          return (
            <FuiButton.Display
              key={config.displayName}
              active={isActive}
              onClick={() => {
                store.charting.setStrategy(config)
                store.charting.updateFromStrategyState()
              }}
            >
              {config.displayName}
            </FuiButton.Display>
          )
        })}
      </div>
      <styleds.SideBarTitle>趨勢總評</styleds.SideBarTitle>
      <div
        css={css`
          ${fill_vertical_all_center};
          width: 76%;
          height: 120px;
          background-color: #222734;
          border-radius: 10px;
          padding: 8px;
          font-size: 24px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            background-color: #333845;
          }
        `}
      >
        {overView()}
      </div>
      <styleds.SideBarTitle>指標評分</styleds.SideBarTitle>
      <TradeBoardInfo value={state.powerTrend}>主力動向</TradeBoardInfo>
      <TradeBoardInfo value={state.dcDailyTrend}>趨勢偵測</TradeBoardInfo>
      <TradeBoardInfo value={state.priceLineTrend}>攻防轉換</TradeBoardInfo>
      <TradeBoardInfo value={state.analyzerTrend}>分析儀</TradeBoardInfo>
      <div
        css={css`
          ${fill_vertical_cross_center}
          justify-content: end;
          height: calc(100% - 690px);
          padding: 4px;
        `}
      >
        <styleds.FaviconLogo src='big3/background.png' />
      </div>
    </styleds.SideBar>
  )
})

export const TradeBoardInfo = memo<ReactProps<{ value: number }>>(function TradeBoardInfo(props) {
  const long = props.value > 0
  const short = props.value < 0
  const displayInfo = long ? '偏多' : short ? '偏空' : '盤整'
  const displayArrow = long ? <BiTrendingUp /> : short ? <BiTrendingDown /> : <TbWaveSawTool />
  const infoColor = long ? '#eb483f' : short ? '#6fe747' : '#999999'
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        width: 76%;
        height: 88px;
        background-color: #222734;
        border-radius: 10px;
        padding: 8px;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #333845;
        }
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter}
          width: 50%;
          height: 48px;
          color: ${infoColor};
          font-size: 24px;
        `}
      >
        {displayInfo}&nbsp;{displayArrow}
      </div>
      <div
        css={css`
          ${flex.v.allCenter}
          width: 50%;
          height: 24px;
          background-color: #434d6e;
          border-radius: 5px;
        `}
      >
        {props.children}
      </div>
    </div>
  )
})
