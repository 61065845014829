/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore'

export const gd1788_daytrade3 = createIndicator({
  id: 'gd1788-daytrade3',
  displayName: '順勢當沖',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const high = this.PineJS.Std.high(this._context)
      const high_array = this._context.new_var(high)
      const daily_high = this._context.new_var()
      const low = this.PineJS.Std.low(this._context)
      const low_array = this._context.new_var(low)
      const daily_low = this._context.new_var()
      const close = this.PineJS.Std.close(this._context)

      const itime = this.PineJS.Std.time(this._context)
      const start_h = new Date(itime).getHours()
      const start_m = new Date(itime).getMinutes()

      const pc_high = this._context.new_var()
      const pc_low = this._context.new_var()

      const marketposition = this._context.new_var()
      const entriesToday = this._context.new_var()
      const entryTime = this._context.new_var()

      const pvflag = this._context.new_var()

      const entryPrice = this._context.new_var()
      const arwPrice = this._context.new_var()

      let buy_icon = NaN
      let short_icon = NaN
      let buy_exit_icon = NaN
      let buy_exit_icon2 = NaN
      let short_exit_icon = NaN
      let short_exit_icon2 = NaN

      //0845 重新計算開盤區間---------------------
      if (start_h === 8 && start_m === 45) {
        daily_high.set(high)
        daily_low.set(low)
        entriesToday.set(0)
        marketposition.set(0)
      }

      //0900 停止計算開盤區間---------------------
      if (start_h === 9 && start_m === 0) {
        pc_high.set(daily_high * 1)
        pc_low.set(daily_low * 1)
      }

      //1500 重新計算夜盤區間---------------------
      if (start_h === 15 && start_m === 0) {
        daily_high.set(high)
        daily_low.set(low)
        marketposition.set(0)
        entriesToday.set(4)
        pvflag.set(4)
      }

      //1530 停止計算夜盤區間---------------------
      if (start_h === 15 && start_m === 30) {
        pc_high.set(daily_high * 1)
        pc_low.set(daily_low * 1)
        entriesToday.set(0)
      }

      if (high > daily_high) {
        daily_high.set(high)
      }

      if (low < daily_low) {
        daily_low.set(low)
      }

      if (start_h === 8) {
        pc_high.set(NaN)
        pc_low.set(NaN)
      }

      marketposition.get(1)
      marketposition.get(2)

      low_array.get(1)
      high_array.get(1)

      //日盤出手限制次數=4----------------------------
      /** 部位確立 */
      if (start_h > 0) {
        if (close > pc_high && entriesToday.get(0) <= 3) {
          marketposition.set(1)
          entryTime.set(itime)
          arwPrice.set(close)
        } else if (close < pc_low && entriesToday.get(0) <= 3) {
          marketposition.set(-1)
          entryTime.set(itime)
          arwPrice.set(itime)
        }
        // else if (
        //   (bState.get(0) === 1 && close < entryPrice * 0.996) ||
        //   (bState.get(0) === 1 && low < pc_low && bsentry.get(0) <= 3) ||
        //   (bState.get(0) === -1 && high > pc_high && bsentry.get(0) <= 3)
        // ) {
        //   bState.set(0)
        // } else if (
        //   (bState.get(0) === -1 && close > entryPrice * 1.004) ||
        //   (bState.get(0) === 1 && low < pc_low && bsentry.get(0) <= 3) ||
        //   (bState.get(0) === -1 && high > pc_high && bsentry.get(0) <= 3)
        // ) {
        //   bState.set(0)
        // }
      }

      // if (bsentry.get(0) === 4) {
      //   if (bState.get(0) === 1 && close < entryPrice * 0.996) {
      //     pvflag.set(4)
      //   }
      //   if (bState.get(0) === -1 && close > entryPrice * 1.004) {
      //     pvflag.set(4)
      //   }
      // }

      //如果15:30突破 要出現進場訊號 前一根K棒bState必須=0,強制歸0
      //否則第300行的bState會持續set 1 or -1
      //造成15:30無訊號
      // if (start_h === 15 && start_m === 27) {
      //   bState.set(0)
      // }

      const targetLong = this._input(0)
      const targetShort = this._input(1)
      const stopLossLong = this._input(2)
      const stopLossShort = this._input(3)

      /** 訊號出訊至圖表 */
      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1 && entriesToday.get(0) <= 3) {
        buy_icon = 1
        short_icon = NaN
        pvflag.set(0)
        entriesToday.set(entriesToday + 1)
        entryPrice.set(close)
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = 1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [
          entryPrice + targetLong,
          entryPrice + targetLong * 2,
        ]
        indicatorsValueStore.multipleStopLossPrice = [
          entryPrice + stopLossLong - 5,
          entryPrice + stopLossLong + 5,
        ]
      } else if (
        marketposition.get(0) === -1 &&
        marketposition.get(1) !== -1 &&
        entriesToday.get(0) <= 3
      ) {
        buy_icon = NaN
        short_icon = 1
        pvflag.set(0)
        entriesToday.set(entriesToday + 1)
        entryPrice.set(close)
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = -1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [
          entryPrice - targetShort,
          entryPrice - targetShort * 2,
        ]
        indicatorsValueStore.multipleStopLossPrice = [
          entryPrice + stopLossShort - 5,
          entryPrice + stopLossShort + 5,
        ]
      }

      /** 停利停醒 */
      if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
        indicatorsValueStore.openPositionProfit = close - entryPrice.get(0)
        if (pvflag.get(0) === 0 && high > entryPrice + targetLong) {
          pvflag.set(1)
          buy_exit_icon = 1
        } else if (pvflag.get(0) === 1 && high > entryPrice + targetLong * 2) {
          pvflag.set(2)
          buy_exit_icon2 = 1
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
        indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close
        if (pvflag.get(0) === 0 && low < entryPrice - targetShort) {
          pvflag.set(1)
          short_exit_icon = 1
        } else if (pvflag.get(0) === 1 && low < entryPrice - targetShort * 2) {
          pvflag.set(2)
          short_exit_icon2 = 1
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      indicatorsValueStore.close = close

      return [
        NaN, //this.PineJS.Std.sma(pc_high, 1, this._context),
        NaN, //this.PineJS.Std.sma(pc_low, 1, this._context),
        buy_icon,
        short_icon,
        buy_exit_icon,
        buy_exit_icon2,
        short_exit_icon,
        short_exit_icon2,
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00aa00',
        },
        plot_2: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 20,
          visible: true,
        },
        plot_3: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 20,
          visible: true,
        },
        plot_4: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 20,
          visible: true,
        },
        plot_5: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 20,
          visible: true,
        },
        plot_6: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 20,
          visible: true,
        },
        plot_7: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 20,
          visible: true,
        },
      },
      inputs: {
        in_0: 30,
        in_1: 30,
        in_2: 30,
        in_3: 30,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: 'plot_7',
        type: 'chars',
      },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        title: '多單參考訊號',
        text: 'B',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        title: '多單參考訊號',
        text: 'S',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '多單參考停利訊號',
        text: '1',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '多單參考停利訊號',
        text: '2',
      },
      plot_6: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '空單參考停利訊號',
        text: '1',
      },
      plot_7: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '空單參考停利訊號',
        text: '2',
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '多單參考停利點數',
        defval: 30,
        type: 'integer',
        min: 10,
        max: 150,
      },
      {
        id: 'in_1',
        name: '空單參考停利點數',
        defval: 30,
        type: 'integer',
        min: 10,
        max: 150,
      },
      {
        id: 'in_2',
        name: '多單參考停損點數',
        defval: 30,
        type: 'integer',
        min: 10,
        max: 150,
      },
      {
        id: 'in_3',
        name: '空單參考停損點數',
        defval: 30,
        type: 'integer',
        min: 10,
        max: 150,
      },
    ],
    scriptIdPart: '',
  },
})
