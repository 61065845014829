import { proxy } from 'valtio'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { FuiSignals } from '~/pages/heineken_template/components/FuiSignals'
import { south_strategies } from './south_strategies'

export const south_store = proxy({
  holdingPositionType: 'long' as 'long' | 'short',
  selectedStrategy: 'RT_south_01',
  signals: new FuiSignals({
    configs: south_strategies.map(strategy => strategy.config),
  }),

  // Position info

  symbol: '',
  currentPosition: 0,
  entryPrice: 0,
  exitPrice: 0,
  stoplossPrice: 0,
  entryTime: '',
  exitTime: '',
  side: '',
})
