import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import { fontWeight600 } from '~/css/font'

const styleOptions = {
  sideBar1: {
    gap: 'gap: 4px;',
    padding: 'padding: 4px;',
    backgroundColor: 'background-color: #16161e',
  },
  titleString: {
    width: 'width: 100%;',
    height: 'height: 32px;',
    backgroundColor: 'background-color: #333333;',
    borderRadius: 'border-radius: 5px;',
    fontSize: 'font-size: 16px;',
  },
}

export const styleds = {
  SideBar1: styled.div`
    ${fill_vertical_cross_center};
    ${fill_vertical_cross_center};
    ${styleOptions.sideBar1.backgroundColor};
    ${styleOptions.sideBar1.gap};
    ${styleOptions.sideBar1.padding};
    user-select: none;
  `,
  TitleString: styled.div`
    ${fill_vertical_all_center};
    ${styleOptions.titleString.width}
    ${styleOptions.titleString.height}
    ${styleOptions.titleString.backgroundColor}
    ${styleOptions.titleString.borderRadius}
    ${styleOptions.titleString.fontSize}
  `,
  TrendChartContent: styled.div`
    ${styleOptions.titleString.width};
    height: 164px;
  `,
  InformationContent: styled.div`
    ${fill_vertical_all_center};
    height: 240px;
  `,
  TrendLightContent: styled.div`
    ${fill_vertical_all_center};
    height: 120px;
  `,
  TradingTipsTitle: styled.div`
    ${fill_vertical_all_center}
    height: 32px;
    background-color: #e03939;
    border-radius: 2px;
    font-size: 20px;
    ${fontWeight600};
  `,
  TradingTipsContent: styled.div`
    ${fill_vertical_all_center}
    height: calc(100% - 160px);
    font-size: 16px;
    background: linear-gradient(90deg, #252525, #333333);
    border-radius: 8px;
  `,
}

export default styleds
