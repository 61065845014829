import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  Kabujuku,
  useKabujukuParamStore,
} from '~/modules/screener/components/ScreenerConfig/Kabujuku'
import {
  AvailableIndicator,
  useKabujukuResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { store } from '~/pages/heineken_template/_private/store'
import styleds from '~/pages/kabujukuTW/_private/styleds'

export const Kabujuku_Sidebar1 = memo<ReactProps>(function Kabujuku_Sidebar1(props) {
  const kabujukuParamState = useSnapshot(useKabujukuParamStore)
  const { join } = kabujukuParamState
  const selectedIndicators = Object.entries(kabujukuParamState.indicators)
    .filter(([_, v]) => v)
    .map(([k, _]) => k) as AvailableIndicator[]

  const { data: symbols } = useKabujukuResource({
    date: '2022-07-06',
    join,
    indicators: selectedIndicators,
  })
  return (
    <div css={styleds.SideBarCss}>
      <div
        css={css`
          ${styleds.dayTrendTitle};
          background-color: #d8d8d8;
        `}
      >
        條件篩選表
      </div>
      <div
        css={css`
          ${styleds.dayTrendSymbol}
          height: 180px;
        `}
      >
        <Kabujuku />
      </div>
      <div
        css={css`
          ${styleds.dayTrendTitle};
          background-color: #d8d8d8;
        `}
      >
        選股結果
      </div>
      <div
        css={css`
          ${styleds.dayTrendSymbol}
          height: calc(100% - 250px);
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={symbols ?? []}
          indicators={store.charting.strategySelected.indicators}
          chart={store.charting}
        />
      </div>
    </div>
  )
})
