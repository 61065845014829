import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple'
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'
import { south_initStyling } from './south_initStyling'
import { south_initStrategies } from './south_initStrategies'
import { South_Topbar } from './south_Topbar'
import { South_Footer } from './south_Footer'
import { South_SidePane1 } from './south_SidePane1'

export const south_init = (templateProps: TemplateProps) => {
  south_initStyling(templateProps)
  south_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TX-1',
    interval: '5',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: true,
    disabledPaneMenu: true,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderSaveload: true,
    disabledHeaderCompare: true,
    overrides: store.charting.darkOverrides,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100% - 72px) 24px;
    grid-template-columns: 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `

  templateProps.layout.Row1 = South_Topbar
  templateProps.layout.Row2 = South_Footer
  templateProps.layout.Drawer1 = (
    <Fr_WithDrawerCloseButton.Display
      right
      Button={
        <BsChevronRight
          css={css`
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #56659d99;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}
        />
      }
    >
      <South_SidePane1 />
    </Fr_WithDrawerCloseButton.Display>
  )
  // templateProps.layout.Drawer2 = (
  //   <Fr_WithDrawerCloseButton.Display
  //     left
  //     Button={
  //       <BsChevronLeft
  //         css={css`
  //           #__html {
  //             position: relative;
  //             z-index: 100;
  //           }
  //           color: #ffffff;
  //           background-color: #56659d99;
  //           border-radius: 5px;
  //           height: 64px;
  //           width: 28px;
  //           font-size: 24px;
  //         `}
  //       />
  //     }
  //   >
  //     {/* <south_SidePane2 /> */}
  //   </Fr_WithDrawerCloseButton.Display>
  // )
}
