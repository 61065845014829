import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { datatree_bs } from '~/trades/indicators/datatree/datatree_bs'
import { datatree_ti } from '~/trades/indicators/datatree/datatree_ti'

export const datatree_strategiesGroup = {
  main: [
    {
      displayName: '策略',
      indicators: [datatree_bs, datatree_ti],
      symbol: 'TX-1',
      interval: '5',
      panesRatio: 60,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const datatree_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[datatree_bs, datatree_ti],
  ]

  store.charting.initStrategy({
    configs: [...datatree_strategiesGroup.main],
  })
}
