import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'

export const StrategyCard = memo<ReactProps<{ config: ChartTypes.StrategyConfig }>>(
  function StrategyCard(props_) {
    const charting = useSnapshot(store.charting)
    const isActive = charting.strategySelected?.displayName === props_.config.displayName

    const strategyType = props_.config.type ?? 'default'

    const typeString =
      props_.config.type === 'long' ? '多方' : props_.config.type === 'short' ? '空方' : '中性'

    return (
      <styleds.container
        selected={isActive}
        onClick={event => {
          store.charting.setStrategy(props_.config)
          store.charting.updateFromStrategyState()
        }}
      >
        <styleds.infoContent>
          <styleds.strategyTitle>{props_.config.displayName}</styleds.strategyTitle>
          <div>
            適用週期:
            <styleds.textdDescribe>{props_.config.interval}分</styleds.textdDescribe>
          </div>
          <div>
            策略簡述:
            <styleds.textdDescribe>{props_.config.describe}</styleds.textdDescribe>
          </div>
        </styleds.infoContent>
        <styleds.stratrgyTypeButton strategyType={strategyType}>
          {typeString}
        </styleds.stratrgyTypeButton>
      </styleds.container>
    )
  },
)

const styleds = {
  container: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 82% 18%;
    height: 88px;
    border-radius: 4px;
    border: 1px solid ${props => (props.selected === true ? '#fe632d' : '#e0e4ea')};
    font-size: 12px;
    padding: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      border: 1px solid #fe632d;
      background-color: #f0f1f3;
      transition: 0.3s;
    }
  `,
  infoContent: styled.div`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-rows: 33% 33% 33%;
    & > * {
      ${fill_horizontal_cross_center};
      color: #71737a;
    }
  `,
  strategyTitle: styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #252525;
  `,
  textdDescribe: styled.div`
    font-size: 12px;
    color: #252525;
  `,
  stratrgyTypeButton: styled.div<{ strategyType: 'long' | 'short' | 'default' }>`
    ${fill_horizontal_all_center};
    width: 48px;
    height: 26px;
    border-radius: 8px;
    color: ${props => (props.strategyType === 'default' ? '#252525' : '#fafafa')};
    background-color: ${props =>
      props.strategyType === 'long'
        ? '#f04848'
        : props.strategyType === 'short'
        ? '#00b070'
        : '#eaeaea'};
  `,
}
