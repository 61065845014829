import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const god_volume = createIndicator({
  id: 'god-volume',
  displayName: '成交量',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const volume = context.new_var(this.PineJS.Std.volume(context))
      volume.get(100)
      const isVolumeIncreasing = volume.get(0) > volume.get(1)
      const isVolumeDecreasing = volume.get(0) < volume.get(1)

      return [isVolumeIncreasing ? volume.get(0) : NaN, isVolumeDecreasing ? volume.get(0) : NaN]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    inputs: [],

    plots: [
      { id: 'increasing', type: 'line' },
      { id: 'decreasing', type: 'line' },
    ],
    styles: {
      increasing: {
        title: '成交量',
        histogramBase: 0,
        joinPoints: false,
      },
      decreasing: {
        title: '成交量',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    defaults: {
      styles: {
        increasing: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff0000',
        },
        decreasing: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00ff00',
        },
      },
      precision: 2,
      inputs: {},
    },
  },
})
