/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'

export const stock_trade_original = createIndicator({
  id: 'stocktradeoriginal',
  displayName: '股票訊號original',
  enabledOn(symbol, data, channel) {
    return !symbol.includes('TSE') && data?.type !== channel.os_futures
  },
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const interval = this.PineJS.Std.interval(this._context)
      //商品數值 開高低收 時間
      const open = this.PineJS.Std.open(this._context)
      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)
      const close = this.PineJS.Std.close(this._context)
      const volume = this.PineJS.Std.volume(this._context)
      const itime = this.PineJS.Std.time(this._context)
      const itime_array = this._context.new_var(itime)
      const atr = this.PineJS.Std.atr(20, this._context)
      //商品數值 開高低收Array
      const open_array = this._context.new_var(open)
      const high_array = this._context.new_var(high)
      const low_array = this._context.new_var(low)
      const close_array = this._context.new_var(close)
      const volume_array = this._context.new_var(volume)
      //小時、分鐘
      const start_h = new Date(itime).getHours()
      const start_m = new Date(itime).getMinutes()
      //部位相關 狀態
      const bState = this._context.new_var()
      const bDt = this._context.new_var()
      const pvflag = this._context.new_var()
      const entryPrice = this._context.new_var()
      const resetTime = this._context.new_var()

      //部位相關 買賣、停利訊號
      let pv0 = NaN
      let pv1 = NaN
      let pb1 = NaN
      let pb2 = NaN
      let pb3 = NaN
      let ps1 = NaN
      let ps2 = NaN
      let ps3 = NaN

      //防止初始判斷不到resetTime而無法開始第一筆交易
      if (isNaN(resetTime.get(0))) {
        resetTime.set(0)
      }

      const sma1 = this.PineJS.Std.sma(close_array, 10, this._context)
      const sma2 = this.PineJS.Std.sma(close_array, 23, this._context)
      const sma3 = this.PineJS.Std.sma(close_array, 36, this._context)
      const sma1_array = this._context.new_var(sma1)
      const sma2_array = this._context.new_var(sma2)
      const sma3_array = this._context.new_var(sma3)

      sma1_array.get(10)
      sma2_array.get(10)
      sma3_array.get(10)
      bState.get(1)
      resetTime.get(5)
      itime_array.get(10)
      low_array.get(5)
      high_array.get(5)
      open_array.get(5)
      close_array.get(5)
      volume_array.get(5)

      /** 圖表日期 */
      const chartDate = new Date(itime).toLocaleDateString()
      /** 本機日期 */
      const localDate = new Date().toLocaleDateString()
      /** 圖表＆本機日期一致 且本機時間(小時) < 圖表時間13(小時) 目的：為了不在開盤馬上顯示訊號,在接近收盤才顯示訊號 */
      const noDisplayEntry = false
      //localDate === chartDate && new Date().getHours() < new Date(itime).getHours() + 5 //5

      //console.log(chartDate, localDate, noDisplayEntry)

      /** Ex: `close_array.get('0')` */
      const currentbar_number = 0
      const lastbar_number = currentbar_number + 1
      const amount = 1000

      const buy_condition =
        (sma1_array.get(currentbar_number) >= sma2_array.get(currentbar_number) &&
          close_array.get(currentbar_number) > close_array.get(lastbar_number) &&
          close_array.get(currentbar_number) > open_array.get(currentbar_number) &&
          open_array.get(currentbar_number) > close_array.get(lastbar_number) &&
          itime_array.get(5 + currentbar_number) >= resetTime.get(currentbar_number) &&
          volume_array.get(currentbar_number) >= amount) ||
        (close_array.get(currentbar_number) / open_array.get(currentbar_number) >= 1.09 &&
          volume_array.get(currentbar_number) >= amount)

      const short_condition =
        sma1_array.get(currentbar_number) < sma2_array.get(currentbar_number) &&
        sma2_array.get(currentbar_number) < sma3_array.get(currentbar_number) &&
        close_array.get(currentbar_number) < open_array.get(currentbar_number) &&
        close_array.get(currentbar_number) < close_array.get(lastbar_number) &&
        close_array.get(currentbar_number) < low_array.get(lastbar_number) &&
        itime_array.get(5 + currentbar_number) >= resetTime.get(currentbar_number) &&
        volume_array.get(currentbar_number) >= amount

      const buy_condition1 =
        (sma1_array.get(currentbar_number) >= sma2_array.get(currentbar_number) &&
          close_array.get(currentbar_number) > close_array.get(lastbar_number) &&
          close_array.get(currentbar_number) > open_array.get(currentbar_number) &&
          open_array.get(currentbar_number) > close_array.get(lastbar_number) &&
          itime_array.get(5 + currentbar_number) >= resetTime.get(currentbar_number)) ||
        close_array.get(currentbar_number) / open_array.get(currentbar_number) >= 1.09

      const short_condition1 =
        sma1_array.get(currentbar_number) < sma2_array.get(currentbar_number) &&
        sma2_array.get(currentbar_number) < sma3_array.get(currentbar_number) &&
        close_array.get(currentbar_number) < open_array.get(currentbar_number) &&
        close_array.get(currentbar_number) < close_array.get(lastbar_number) &&
        close_array.get(currentbar_number) < low_array.get(lastbar_number) &&
        itime_array.get(5 + currentbar_number) >= resetTime.get(currentbar_number)

      if (buy_condition) {
        bState.set(1)
      }
      if (short_condition) {
        bState.set(-1)
      }

      if (
        !buy_condition1 &&
        !short_condition1 &&
        bState.get(0) !== 0 &&
        sma1_array.get(currentbar_number) < sma2_array.get(currentbar_number) &&
        sma2_array.get(currentbar_number) > sma3_array.get(currentbar_number)
      ) {
        bState.set(0)
        resetTime.set(itime_array.get(currentbar_number))
        //spconsole.log('E', new Date(itime), new Date(resetTime.get(0)))
      }

      if (bState.get(0) === 1 && bState.get(1) !== 1 && !noDisplayEntry) {
        pv0 = 1
        pv1 = NaN
        resetTime.set(itime_array.get(1))
        pvflag.set(0)
        entryPrice.set(open)
        //console.log('B', new Date(itime), volume_array.get(currentbar_number))
      } else if (bState.get(0) === -1 && bState.get(1) !== -1 && !noDisplayEntry) {
        pv0 = NaN
        pv1 = 1
        resetTime.set(itime_array.get(1))
        pvflag.set(0)
        entryPrice.set(open)
        //console.log('S', new Date(itime), volume_array.get(currentbar_number))
      }

      // if (bState.get(0) === 1) {
      //   pv0 = 1
      // }
      // if (bState.get(0) === -1) {
      //   pv1 = 1
      // }

      //對應相關k棒週期 1日 與 N分線
      var p1 = interval === 1 ? 0.05 : 0.05
      var p2 = interval === 1 ? 0.24 : 0.24
      var p3 = interval === 1 ? 0.38 : 0.38

      if (bState.get(0) === 1 && bState.get(1) === 1) {
        if (pvflag.get(0) === 0 && high_array.get(0) > entryPrice * (1 + p1)) {
          pvflag.set(1), (pb1 = 1)
        } else if (pvflag.get(0) === 1 && high_array.get(0) > entryPrice * (1 + p2)) {
          pvflag.set(2), (pb2 = 1)
        } else if (pvflag.get(0) === 2 && high_array.get(0) > entryPrice * (1 + p3)) {
          pvflag.set(3), (pb3 = 1)
        }
      } else if (bState.get(0) === -1) {
        if (pvflag.get(0) === 0 && low_array.get(0) < entryPrice * (1 - p1)) {
          pvflag.set(1), (ps1 = 1)
        } else if (pvflag.get(0) === 1 && low_array.get(0) < entryPrice * (1 - p2)) {
          pvflag.set(2), (ps2 = 1)
        } else if (pvflag.get(0) === 2 && low_array.get(0) < entryPrice * (1 - p3)) {
          pvflag.set(3), (ps3 = 1)
        }
      }

      return [NaN, NaN, pv0, pv1, pb1, pb2, pb3, ps1, ps2, ps3]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00aa00',
        },
        plot_2: {
          color: '#ff0000',
          textColor: '#ff0000',
          transparency: 20,
          visible: true,
        },
        plot_3: {
          color: '#00bb00',
          textColor: '#00aa00',
          transparency: 20,
          visible: true,
        },
        plot_4: {
          color: '#aaaa00',
          textColor: '#aaaa00',
          transparency: 20,
          visible: true,
        },
        plot_5: {
          color: '#aa5500',
          textColor: '#aa5500',
          transparency: 20,
          visible: true,
        },
        plot_6: {
          color: '#aa2222',
          textColor: '#aa2222',
          transparency: 20,
          visible: true,
        },
        plot_7: {
          color: '#0aa',
          textColor: '#0aa',
          transparency: 20,
          visible: true,
        },
        plot_8: {
          color: '#4444aa',
          textColor: '#4444aa',
          transparency: 20,
          visible: true,
        },
        plot_9: {
          color: '#000aaa',
          textColor: '#000aaa',
          transparency: 20,
          visible: true,
        },
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: 'plot_7',
        type: 'chars',
      },
      {
        id: 'plot_8',
        type: 'chars',
      },
      {
        id: 'plot_9',
        type: 'chars',
      },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'B',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'S',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'small',
        title: 'Shapes',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        char: '✪',
        size: 'normal',
        title: 'Shapes',
      },
      plot_6: {
        isHidden: false,
        location: 'AboveBar',
        char: '☻',
        size: 'large',
        title: 'Shapes',
      },
      plot_7: {
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'small',
        title: 'Shapes',
      },
      plot_8: {
        isHidden: false,
        location: 'BelowBar',
        char: '✪',
        size: 'normal',
        title: 'Shapes',
      },
      plot_9: {
        isHidden: false,
        location: 'BelowBar',
        char: '☻',
        size: 'large',
        title: 'Shapes',
      },
    },
    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
