import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { trendStore } from '~/pages/lung88988/_private/useTrendStore'

export const lung88988_tradeRecon = createIndicator({
  displayName: '趨勢偵查',
  id: 'lung88988-tradeRecon',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker.includes('AM') ? 'TXAM-1#opmf_acc' : 'TX-1#opmf_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))

      const ticker2 = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol2 = ticker2 + '#bs_indicator_acc'
      this._context.new_sym(symbol2, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      /*
        open: 'call',
        high: 'put',
        low: 'strong_call',
        close: 'strong_put',
        volume: 'call',
      */
      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const low = this.PineJS.Std.low(this._context)
      const close = this.PineJS.Std.close(this._context)
      const unlimited1 = this._context.new_unlimited_var(low)
      const unlimited2 = this._context.new_unlimited_var(close)

      const call = unlimited1.adopt(time1, i, 0) // <=這是籌碼
      const put = unlimited2.adopt(time1, i, 0) // <=這是籌碼
      const value1_array = this._context.new_var(call)
      const value2_array = this._context.new_var(put)
      value1_array.get(1000)
      value2_array.get(1000)

      //#bs_indicator_acc------------------------------------------------
      this._context.select_sym(2)
      const time3 = this._context.new_unlimited_var(this._context.symbol.time)
      const quote3 = this.PineJS.Std.high(this._context)
      const unlimited3 = this._context.new_unlimited_var(quote3)

      const power = unlimited3.adopt(time3, i, 0) // <=這是籌碼
      const value3_array = this._context.new_var(power)
      value3_array.get(1000)

      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)

      const swing = call < 0 && put < 0
      const long = power > 0 && call > 0 && put < 0
      const short = power < 0 && call < 0 && put > 0
      const weaklong = power > 0 && swing && put / call > 10
      const weakshort = power < 0 && swing && call / put > 10

      /** 強多 */
      const strongLong = power > 0 && call > 0 && put < 0
      /** 震盪多 */
      const weakLong = power > 0 && call < 0 && put < 0
      /** 拉回支撐 */
      const swingLong = power > 0 && call < 0 && put > 0
      /** 強空 */
      const strongShort = power < 0 && call < 0 && put > 0
      /** 震盪空 */
      const weakShort = power < 0 && call < 0 && put < 0
      /** 反彈壓力 */
      const swingShort = power < 0 && call > 0 && put < 0

      const colorIndex = () => {
        if (strongLong) {
          trendStore.state = '多方強勢'
          return 0
        } else if (weakLong) {
          trendStore.state = '多方震盪'
          return 1
        } else if (swingLong) {
          trendStore.state = '拉回支撐'
          return 2
        } else if (swingShort) {
          trendStore.state = '反彈壓力'
          return 3
        } else if (weakShort) {
          trendStore.state = '空方震盪'
          return 4
        } else if (strongShort) {
          trendStore.state = '空方強勢'
          return 5
        } else {
          trendStore.state = '盤整盤'
          return 6
        }
      }

      return [NaN, NaN, 1, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
      {
        id: 'powerPalette',
        type: 'colorer',
        target: 'plot_2',
        palette: 'powerPalette',
      },
    ],
    palettes: {
      powerPalette: {
        colors: {
          0: {
            name: '多方強勢',
          },
          1: {
            name: '多方震盪',
          },
          2: {
            name: '拉回支撐',
          },
          3: {
            name: '反彈壓力',
          },
          4: {
            name: '空方震盪',
          },
          5: {
            name: '空方強勢',
          },
          6: {
            name: '盤整震盪盤',
          },
        },
      },
    },
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#006000',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
      },
      palettes: {
        powerPalette: {
          colors: {
            0: {
              color: '#da5441',
              width: 0,
              style: 0,
            },
            1: {
              color: '#e16868',
              width: 0,
              style: 0,
            },

            2: {
              color: '#e16868',
              width: 0,
              style: 0,
            },
            3: {
              color: '#5bb35f',
              width: 0,
              style: 0,
            },
            4: {
              color: '#5bb35f',
              width: 0,
              style: 0,
            },
            5: {
              color: '#00980a',
              width: 0,
              style: 0,
            },
            6: {
              color: '#aaaaaa',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '多趨勢',
        histogramBase: 0,
      },
      plot_1: {
        title: '空趨勢',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
