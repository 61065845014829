import styled from '@emotion/styled'
import { memo, useCallback } from 'react'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { AiFillStar } from 'react-icons/ai'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { store } from '~/pages/heineken_template/_private/store'
import { useSnapshot } from 'valtio'
import { SignalrSortKey, useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore'

export const CryptoQuote = memo<
  ReactProps<{
    quote: Signalr.ValueOfOHLC
    watchListSymbol: string[]
    watchlistActs: any
  }>
>(function CryptoQuote(props) {
  const quote = props.quote

  const convertedCode = quote?.symbol?.replace('USDT', '')?.toLowerCase()
  const iconSrc = 'pionex/symbol_icon/' + convertedCode + '.png'

  const quoteChanges = processQuoteToReadable(quote as Signalr.ValueOfOHLC)

  const hadSymbol = props.watchListSymbol?.includes(quote.symbol)

  const selected = store.charting.symbol === quote.symbol
  return (
    <classes.Root
      onClick={() => {
        store.charting.changeSymbol(quote.symbol)
      }}
    >
      <classes.container selected={selected}>
        <classes.nameContent>
          <classes.cryptoIcon src={iconSrc} />
          <div>{quote.symbol}</div>
        </classes.nameContent>
        <classes.quoteContent>
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType={'close'}
          />
        </classes.quoteContent>
        <classes.volumeContent>
          <AiFillStar
            size={13}
            css={css`
              margin-right: 4px;
              color: ${hadSymbol ? '#ffaa00' : '#9ea6b8'};
            `}
            onClick={event => {
              /** 阻止加入商品至自選股時執行父元素的切換商品事件 */
              event.stopPropagation()

              if (hadSymbol) {
                props.watchlistActs.removeSymbol(quote?.symbol)
              }
              if (!hadSymbol) {
                props.watchlistActs.addSymbol(quote?.symbol, 20)
              }
            }}
          />
          {(quote.volume / 1000000)?.toFixed(2)} M
        </classes.volumeContent>
        <classes.priceContent> = {(quote.close * 31.04).toFixed(2)} TWD</classes.priceContent>
        <classes.changeContent>
          <classes.changeButton change={quoteChanges.closeChangePercent}>
            <QuoteValueItem.change.Display
              quote={quote}
              changeType={'closeChangePercent'}
              unit='%'
            />
          </classes.changeButton>
        </classes.changeContent>
      </classes.container>
    </classes.Root>
  )
})

const UpArrow = memo<ReactProps<{ fill: string }>>(function UpArrow(props) {
  return (
    <svg
      width='6'
      height='6'
      viewBox='0 0 14 14'
    >
      <polygon
        transform='rotate(0,7,7)'
        points='7 2 14 12 0 12'
        fill={props.fill}
      ></polygon>
    </svg>
  )
})

const DnArrow = memo<ReactProps<{ fill: string }>>(function DnArrow(props) {
  return (
    <svg
      width='6'
      height='6'
      viewBox='0 0 14 14'
    >
      <polygon
        transform='rotate(180,7,7)'
        points='7 2 14 12 0 12'
        fill={props.fill}
      ></polygon>
    </svg>
  )
})

//排序方向
const SortArrow = memo<ReactProps<{ sortKey: SignalrSortKey }>>(function SortArrow(props) {
  const sortSnap = useSnapshot(useSortSignalrDataStore)

  const desc = sortSnap.orderKey === 'desc' && props.sortKey === sortSnap.sortKey
  const asc = sortSnap.orderKey === 'asc' && props.sortKey === sortSnap.sortKey

  const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc'

  const handleSortSelected = useCallback(
    (sortKey: SignalrSortKey) => {
      useSortSignalrDataStore.sortKey = sortKey
      useSortSignalrDataStore.orderKey = toggleOrderKey
    },
    [toggleOrderKey],
  )

  console.log(sortSnap.sortKey)
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        height: 14px;
        width: 16px;
        cursor: pointer;
      `}
      onClick={() => {
        handleSortSelected(props.sortKey)
      }}
    >
      <div css={fill_vertical_all_center}>
        <UpArrow fill={desc === true ? '#252525' : '#858ca3'} />
      </div>
      <div css={fill_vertical_all_center}>
        <DnArrow fill={asc === true ? '#252525' : '#858ca3'} />
      </div>
    </div>
  )
})

export const CryptoQuoteHeader = memo<ReactProps>(function CryptoQuoteHeader() {
  return (
    <classes.headerContainer>
      <classes.headerItem>
        交易對 <SortArrow sortKey='symbol' />
      </classes.headerItem>
      <classes.headerItem>
        交易量 <SortArrow sortKey='volume' />
      </classes.headerItem>
      <classes.headerItem>
        價格 <SortArrow sortKey='close' />
      </classes.headerItem>
      <classes.headerItem>
        漲跌 <SortArrow sortKey='changePrecent' />
      </classes.headerItem>
    </classes.headerContainer>
  )
})

const classes = {
  Root: styled.div`
    width: 320px;
    height: 60px;
  `,
  container: styled.div<{ selected: boolean }>`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 36% 44% 20%;
    grid-template-rows: 50% 50%;
    font-size: 13px;
    padding: 2px 8px;
    color: #9ea6b8;
    cursor: pointer;
    border-left: 3px solid ${props => (props.selected === true ? '#fd632d' : 'transparent')};
    background-color: ${props => (props.selected === true ? '#f8f8f8' : 'transparent')};
    border-bottom: 1px solid #f0f5fa;
    &:hover {
      transition: 0.3s;
      background-color: #f0f1f3;
    }
  `,
  cryptoIcon: styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
  `,
  nameContent: styled.div`
    ${fill_horizontal_cross_center};
    grid-column: 1 / 2;
    grid-row: 1;
    color: #252525;
  `,
  quoteContent: styled.div`
    ${fill_horizontal_cross_center};
    grid-column: 2 / 3;
    grid-row: 1;
    justify-content: end;
    padding-right: 8px;
  `,
  volumeContent: styled.div`
    ${fill_horizontal_cross_center};
    grid-column: 1 / 2;
    grid-row: 2;
  `,
  priceContent: styled.div`
    ${fill_horizontal_cross_center};
    grid-column: 2 / 3;
    grid-row: 2;
    justify-content: end;
    padding-right: 8px;
  `,
  changeContent: styled.div`
    ${fill_horizontal_all_center};
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  `,
  changeButton: styled.div<{ change: number }>`
    ${fill_horizontal_all_center};
    font-weight: bold;
    font-size: 12px;
    width: 56px;
    height: 28px;
    background-color: #fafafa;
    border-radius: 4px;
    background-color: ${props =>
      props.change > 0 ? '#fff4f4' : props.change < 0 ? '#e3f6e3' : '#f4f4f4'};
  `,
  headerContainer: styled.div`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 24% 24% 32% 20%;
    color: #71737a;
    height: 30px;
    font-size: 13px;
    user-select: none;
  `,
  headerItem: styled.div`
    ${fill_horizontal_all_center};
  `,
}
