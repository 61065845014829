import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { winnerA_Sidebar } from '~/pages/winnerA/_private/winnerA_Sidebar'
import { winnerA_Topbar } from '~/pages/winnerA/_private/winnerA_Topbar'
import { winnerB_initStrategies } from '~/pages/winnerB/_private/winnerB_initStrategies'
import { winner_tx_attack } from '~/trades/indicators/winner/winner_tx_attack'
import { winner_tsea_kd } from '~/trades/indicators/winner/winner_tsea_kd'
import { winner_tsea_ma } from '~/trades/indicators/winner/winner_tsea_ma'
import { winner_tsea_volume } from '~/trades/indicators/winner/winner_tsea_volume'
import { winnerA_initStyling } from '~/pages/winnerA/_private/winnerA_initStyling'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const winnerB_init = (templateProps: TemplateProps) => {
  // 這邊這時採用的是與 winnerA 一樣的 styling
  winnerA_initStyling(templateProps)
  winnerB_initStrategies()

  useThemeStore.setState({ theme: 'dark' })

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_B')
  const initIndicators = [winner_tsea_ma, winner_tsea_volume, winner_tsea_kd]

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: 5,
    customIndicators: initIndicators,
    custom_css_url: '../../winner/winnerA/chartingTheme.css',
    overrides: store.charting.darkOverrides,
  }

  store.charting.indicatorsPreloaded.push(winner_tx_attack)

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr 336px;
    user-select: none;

    grid-template-areas:
      'Row1 Row1'
      'Chart Drawer1'
      'Chart Drawer1';

    ${createIPadCss(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `
  templateProps.layout.Row1 = winnerA_Topbar
  templateProps.layout.Drawer1 = winnerA_Sidebar
}
