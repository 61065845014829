import { useIndicatorStore } from '~/store/useIndicatorStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const winner98_elevator = createIndicator({
  displayName: '電梯線',
  id: 'winner98elevator',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const high_array = this.ohlc.highArray
      const low_array = this.ohlc.lowArray
      const length = this._input(0)
      const r = this.PineJS.Std.low(this._context)
      const o = this._context.new_var(r)
      const dn = this.PineJS.Std.lowest(o, length, this._context)
      const a = this.PineJS.Std.high(this._context)
      const l = this._context.new_var(a)
      const up = this.PineJS.Std.highest(l, length, this._context)
      return [this.PineJS.Std.avg(dn, up)]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 9,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#2196f3',
        },
      },
      precision: 4,
      inputs: {
        in_0: 10,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '電梯',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '電梯length',
        defval: 10,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
    ],
    scriptIdPart: '',
  },
})
