import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { datatree_store } from '~/pages/datatree/datatree_store'

export const datatree_bs = createIndicator({
  displayName: '主散方向',
  id: 'datatree-bs',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10) ||
      (symbol.length == 4 && parseInt(symbol) > 0)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#bs_indicator_acc'
      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)
      const h = this.PineJS.Std.high(this._context)
      const l = this.PineJS.Std.low(this._context)
      const o = this.PineJS.Std.open(this._context)

      datatree_store.valueTrend = h
      return [h, l, o]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#0000ff',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#007f00',
        },
        plot_2: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 30,
          color: '#ff0000',
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '大戶',
        histogramBase: 0,
      },
      plot_1: {
        title: '散戶',
        histogramBase: 0,
      },
      plot_2: {
        title: '總和',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
