import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { fontWeight600 } from '~/css/font'

const styleds = {
  //#region Side Pane 1
  SideBar1: styled.div`
    ${fill_vertical_cross_center};
    background-color: #232222;
    gap: 4px;
    padding: 4px;
  `,
  IntradayTrendChart: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: 172px;
    background-color: #424242;
    border-radius: 4px;
    padding: 4px;
  `,
  StrategyTitle: styled.div`
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background: linear-gradient(90deg, #ff0000, #b02d2d);
    color: #ffffff;
    border-radius: 2px;
    font-size: 20px;
    ${fontWeight600};
  `,
  SymbolTypeButton: styled.div<{ selected: boolean }>`
    ${fill_vertical_all_center}
    width: 100%;
    height: 28px;
    background-color: ${props => (props.selected === true ? '#fe952a' : '#3f4545')};
    color: ${props => (props.selected === true ? '#fafafa' : '#aaaaaa')};
    border: 1px solid #878787;
    border-radius: 2px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      border: 1px solid #fafafa;
      transition: 0.5s;
    }
  `,
  ScreenerTitle: styled.div<{ variant: 'long' | 'short' }>`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    ${fontWeight600};
    ${options => {
      const buyCssset =
        options.variant === 'long' &&
        css`
          background-color: #c62a31;
        `

      const shortCssset =
        options.variant === 'short' &&
        css`
          background-color: #00ae00;
        `
      return css([buyCssset, shortCssset])
    }}
  `,
  ComponeyLogo: styled.img`
    width: 100%;
    height: 64px;

    border-radius: 4px;
  `,
  WatchlistButton: styled.div`
    display: grid;
    grid-template-columns: 40% 60%;
    background-color: #424242;
    height: 30px;
    width: 100%;
  `,
  //#endregion

  //#region Side Pane 2

  SideBar2: styled.div`
    ${fill_vertical_cross_center};
    background-color: #232222;
    gap: 4px;
    padding: 4px;
  `,
  Logo: styled.div`
    width: 100%;
    height: 176px;
    //background-color: #252525;
    background-image: url('jerry597/logo-god.png');
    background-size: 40%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
  `,
  TradingTipsTitle: styled.div`
    ${fill_vertical_all_center}
    height: 32px;
    background-color: #fe952a;
    border-radius: 2px;
    font-size: 22px;
    ${fontWeight600};
  `,
  TradingTipsContent: styled.div`
    ${fill_vertical_all_center}
    height: calc(100% - 208px);
    font-size: 16px;
    background: linear-gradient(90deg, #252525, #333333);
    border-radius: 8px;
  `,

  //#new
  StrategyArea: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: 72px;
    background-color: #424242;
    border-radius: 4px;
    padding: 4px;
    gap: 2px;
  `,
  SymbolLsitArea: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: calc(100% - 286px);
    background-color: #232222;
    border-radius: 4px;
    padding: 4px;
    gap: 2px;
  `,
  AreaTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    font-size: 20px;
    background-color: #333333;
    color: #ffffff;
    border-radius: 2px;
    gap: 8px;
    ${fontWeight600};
  `,
  SymbolList: styled.div`
    height: calc(50% - 48px);
    width: 100%;
  `,
  StrategyGroup: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 5px;
  `,
}

export default styleds
