import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerHasMe } from '~/modules/SDK/me/meCheckHandlerHasMe'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Investment_Footer } from './investment_Footer'
import { investment_initStyling } from './investment_initStyling'
import { Investment_SidePane1 } from './investment_SidePane1'
import { Investment_SidePaneMenu } from './investment_SidePaneMenu'
import { Investment_TopBar } from './investment_TopBar'

export const investment_init = (templateProps: TemplateProps) => {
  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  investment_initStyling(templateProps)

  templateProps.permissions.pageview = meCheckHandlerHasMe
  //templateProps.permissions.pageview = meCheckHandlerTrialCodeAndProductWeb

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 720px 400px;
    grid-template-columns: 200px 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Col1 Drawer1 Drawer1'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Drawer1 Drawer1'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 224px calc(100vh - 300px) 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Drawer1 Drawer1 Drawer1'
        'Row2 Row2 Row2';
    `)}
  `

  templateProps.layout.Row1 = Investment_TopBar
  templateProps.layout.Row2 = Investment_Footer
  templateProps.layout.Col1 = Investment_SidePaneMenu
  templateProps.layout.Drawer1 = Investment_SidePane1
  templateProps.layout.Charting = <div></div>
  //templateProps.layout.login = EurexLoginPage
}
