import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar'
import { Jerry597_Row } from './jerry597_Row'

export const Jerry597_Topbar = memo<ReactProps>(function Jerry597_Topbar() {
  const { isPc } = useMedia()

  return (
    <div>
      {isPc && (
        <div
          css={css`
            ${flex.h.allCenter}
            justify-content: end;
            background-color: #ac161b;
            width: 100%;
            height: 100%;
            padding: 0px 16px;
          `}
        >
          <ChartingServerSwitch
            charting={store.charting}
            css={css`
              background-color: #f0f0f0;
              color: #252525;
              .MuiSelect-icon {
                color: #252525;
              }
            `}
          />
          <UserAvatarAsDialogButton />
        </div>
      )}
      {!isPc && (
        <Preset_Topbar
          css={css`
            background-color: #273455;
          `}
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div css={fill_horizontal_all_center}>
              <ChartingServerSwitch
                charting={store.charting}
                css={css`
                  background-color: #ac161b;
                  color: #252525;
                  & > * {
                    color: #252525;
                  }
                `}
              />
              <UserAvatarAsDialogButton />
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})
