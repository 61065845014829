import { css } from '@emotion/react'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import Notification from '~/modules/notification/Notification'
import ModuleWrapper from '~/modules/virtual-exchange-center/components/Wrapper'
import RecordTable from '~/modules/virtual-exchange-center/components/RecordTable'
import { UserAccountModule } from '~/modules/virtual-exchange-center/components/AccountRelated'
import { ModuleSelect } from '~/modules/virtual-exchange-center/components/ModuleSelect'
import OrderForm from '~/modules/virtual-exchange-center/components/OrderForm'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import VirtualExchangeProvider from '~/modules/virtual-exchange-center/containers/VERootProvider'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler'

export const virtualExchange_init = (templateProps: TemplateProps) => {
  templateProps.permissions.pageview = meCreateCheckHandler(() => true)
  useThemeStore.setState({ theme: 'light' })
  templateProps.layout.cssset = css`
    grid-template-rows: 80px auto auto;
    padding: 16px;
    justify-content: stretch;
    align-items: stretch;
    user-select: none;
    place-content: inherit;
    grid-template-areas:
      'Row1 Row1'
      'Row2 Row2'
      'Row3 Row3';
    & .Row4,
    & .Row5,
    & .Row6,
    & .Chart {
      position: fixed;
    }
  `
  templateProps.layout.Row1 = function UpperRow() {
    return (
      <div
        css={css`
          ${flex.h.crossCenter};
          height: 100%;
        `}
      >
        <div
          css={css`
            position: fixed;
            top: 16px;
            right: 16px;
          `}
        >
          <UserAvatarAsDialogButton loginAsText='登入' />
        </div>
        <ModuleSelect />
        <div
          css={css`
            width: 16px;
          `}
        />
        <UserAccountModule />
      </div>
    )
  }
  templateProps.layout.Providers = VirtualExchangeProvider
  templateProps.layout.Row2 = ModuleWrapper
  templateProps.layout.Row3 = RecordTable

  templateProps.layout.Row4 = OrderForm
  templateProps.layout.Row5 = Notification
  templateProps.layout.Charting = undefined
}
