import styled from '@emotion/styled'
import { Button as Button_, buttonClasses, ButtonProps } from '@mui/material'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingSelectStrategyFn } from '~/modules/SDK/chart4/hooks/useChartingSelectStrategyFn'
import { clas } from '~/utils/clas'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'

const classes = {
  Button: styled.div``.withComponent(Button_),
  ButtonOfActive: styled.div``,
  Inner: styled.div``,
  /** Button 本身是 mui.Button */
  mui: buttonClasses,
}

const Button = memo<
  ReactProps<{
    strategy: ChartTypes.StrategyConfig
    charting: ChartingModule
    buttonProps?: ButtonProps
  }>
>(function ChartingStrategyButton(props) {
  const selectStrategy = useChartingSelectStrategyFn(props.charting)
  const charting = useSnapshot(props.charting)

  const isActive = charting.strategySelected.displayName === props.strategy.displayName

  return (
    <classes.Button
      {...props.buttonProps}
      className={clas([props.className, isActive && classes.ButtonOfActive])}
      onClick={event => {
        props.buttonProps?.onClick?.(event)
        selectStrategy(props.strategy)
      }}
    >
      <classes.Inner>{props.children || props.strategy.displayName}</classes.Inner>
    </classes.Button>
  )
})

export default {
  Button,
  classes,
}
