import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const pionex_atr = createIndicator({
  displayName: '波幅',
  id: 'pionex-atr',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const atr = this.PineJS.Std.atr(24, this._context)
      const atr_array = this._context.new_var(atr)
      const atr_ma = this.PineJS.Std.sma(atr_array, 240, this._context)
      const atr_up = atr_ma * 1.1
      const atr_dn = atr_ma * 0.9

      return [atr, atr_up, atr_dn]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#5ea3ef',
        },
        plot_1: {
          linestyle: 1,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ff4433',
        },
        plot_2: {
          linestyle: 1,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00980a',
        },
      },
      precision: 4,
      inputs: {
        // in_0: 9,
        // in_1: 3,
        // in_2: 3,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '波幅',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: '平均波幅-上限',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        title: '平均波幅-下限',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [
      // {
      //   id: 'in_0',
      //   name: 'length',
      //   defval: 14,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e4,
      // },
      // {
      //   id: 'in_1',
      //   name: 'smoothK',
      //   defval: 1,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e4,
      // },
      // {
      //   id: 'in_2',
      //   name: 'smoothD',
      //   defval: 3,
      //   type: 'integer',
      //   min: 1,
      //   max: 1e4,
      // },
    ],
    precision: 2,
    scriptIdPart: '',
  },
})
