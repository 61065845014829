import { css } from '@emotion/react'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'

export const lung88988Stock_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${TemplateTheme.classes.Dark} {
      }

      ${FuiButton.classes.button.Root} {
        display: flex;
        border-radius: 3px;
        height: 26px;
        font-size: 16px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #aaaaaa;
        border: 1px solid #777777;
        background-color: #333333;
        &:hover {
          background-color: #444444;
          color: #cccccc;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #6b7781;
        transition: 0.5s;
        border: 1px solid #eeeeee;
        &:hover {
          color: #eeeeee;
          background-color: #6b7781ee;
          border: 1px solid #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.long.Default} {
        color: #aaaaaa;
        background-color: #550000;
        &:hover {
          color: #eeeeee;
          background-color: #770000;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.short.Default} {
        color: #aaaaaa;
        background-color: #005500;
        &:hover {
          color: #eeeeee;
          background-color: #007700;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.long.Active} {
        color: #eeeeee;
        background-color: #aa0000;
        transition: 0.5s;
      }

      ${FuiButton.classes.button.short.Active} {
        color: #eeeeee;
        background-color: #00aa00;
        transition: 0.5s;
      }
    }
  `
}
