import { proxy } from 'valtio'

type Trend = 0 | 1 | -1

export const big3_store = proxy({
  timeRange: 'all' as 'original' | 'new' | 'all',
  retailMarketValue: NaN as number,
  mainMarketValue: NaN as number,
  crucialPrice: [NaN, NaN, NaN, NaN] as [number, number, number, number],
  symbolClose: NaN as number,
  dcDailyTrend: 0 as Trend,
  powerTrend: 0 as Trend,
  priceLineTrend: 0 as Trend,
  analyzerTrend: 0 as Trend,
  chip: 0 as number,
  opof: 0 as number,
})
