import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { ok1788Stock_initStrategies } from './ok1788Stock_initStrategies'
import { OK1788Stock_SidePane1 } from './ok1788Stock_SidePane1'
import { OK1788Stock_SidePane2 } from './ok1788Stock_SidePane2'
import { ok1788_customized2 } from '~/trades/indicators/ok1788/ok1788_customized2'
import { ok1788_customized1 } from '~/trades/indicators/ok1788/ok1788_customized1'
import { ok1788_customized3 } from '~/trades/indicators/ok1788/ok1788_customized3'
import { ok1788_customized0 } from '~/trades/indicators/ok1788/ok1788_customized0'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple'
import { ok1788Stock_initStyling } from './ok1788Stock_initStyling'

export const ok1788Stock_init = (templateProps: TemplateProps) => {
  ok1788Stock_initStyling(templateProps)
  ok1788Stock_initStrategies()

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  store.charting.indicatorsPreloaded = [
    ok1788_customized0,
    ok1788_customized1,
    ok1788_customized2,
    ok1788_customized3,
  ]

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: '1D',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    overrides: store.charting.lightOverrides,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `

  templateProps.layout.Row1 = (
    <Preset_Topbar
      showLeftBurger
      showRightBurger
      componentsInRight={col_UserAvatarAndChartingServersSimple.Display}
      css={css`
        background-color: #eeeeee;
      `}
    />
  )
  templateProps.layout.Drawer1 = OK1788Stock_SidePane1
  templateProps.layout.Drawer2 = OK1788Stock_SidePane2
}
