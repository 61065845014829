import { css } from '@emotion/react'
import TradingPlatformSymbol from '~/modules/symbolPlatform/base/QuoteBodyBase'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'
import MarqueeSymbolBody from './component/MarqueeSymbolBody'

export const datatree_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${SymbolPlatformBase.classes.container} {
        &.dark {
          background-color: #1d1e2b;
        }
      }
      ${SymbolPlatformBase.classes.header} {
        &.dark {
          background-color: #232d48;
        }
      }
      ${TradingPlatformSymbol.classes.container} {
        &.dark-active {
          background-color: #232733;
        }
      }
      ${SymbolPlatformBase.classes.slider} {
        &.dark {
          background-color: #1d1e2b;
          .mantine-SegmentedControl-active {
            background-color: #232f50;
          }
          .mantine-SegmentedControl-label {
            color: #dadada;
          }
          .mantine-SegmentedControl-labelActive {
            color: #fafafa;
          }
          .mantine-SegmentedControl-root {
            background-color: #11151c;
          }
        }
      }
    }
  `
}
