import { css } from '@emotion/react'
import { memo } from 'react'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { Styleds } from '../winner98/_private/styleds'

export const Winner98_crypto_Charting = memo<ReactProps>(function Winner98_crypto_Charting() {
  return (
    <div css={fill}>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 0px);
        `}
      >
        <Styleds.Charting />
      </div>
    </div>
  )
})
