import { proxy } from 'valtio'
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2'

export const yaya168Stock_store = proxy({
  tabsOfMain: new FuiTabs2(['墊高', '精選潛力股', '點火']),
  trendLeader: '盤整' as '多方' | '空方' | '盤整',
  trendState: '盤整' as '待確認' | '多方' | '空方' | '偏多' | '盤整' | '偏空',
  bisa: NaN as number,
  volume: NaN as number,
  bsValue: NaN as number,
  bsMaValue: NaN as number,
})
