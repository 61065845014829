import { stockDayTradeAvgPrice } from '~/trades/indicators/defaults/stockDayTradeAvgPrice'
import { go_off } from '~/trades/indicators/weng888/go_off'
import { stock_priceline } from '~/trades/indicators/weng888/stock_priceline'
import { stock_priceline_long } from '~/trades/indicators/weng888/stock_priceline_long'
import { stock_priceline_short } from '~/trades/indicators/weng888/stock_priceline_short'
import { weng888_Kbar } from '~/trades/indicators/weng888/weng888_Kbar'
import { store } from '~/pages/heineken_template/_private/store'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'
import { weng888_dayTrade } from '~/trades/indicators/weng888/weng888_dayTrade'
import { weng888_dayTradeKbar } from '~/trades/indicators/weng888/weng888_dayTradeKbar'
import dayAPI from '~/utils/dayAPI'
import { stock_signal } from '~/trades/indicators/weng888/stock_signal'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { weng888_dayPower } from '~/trades/indicators/weng888/weng888_dayPower'

export const weng888Stock_bandTrade = [weng888_dayPower, weng888_Kbar]
export const weng888Stock_dayTrade = [weng888_dayTrade, weng888_dayTradeKbar]
export const weng888Stock_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '市場熱度(日)',
      indicators: weng888Stock_bandTrade,
      interval: '1D',
    },
    {
      displayName: '市場熱度(60)',
      indicators: weng888Stock_bandTrade,
      interval: '60',
    },
    {
      displayName: '當沖',
      indicators: weng888Stock_dayTrade,
      interval: '3',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const weng888Stock_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...weng888Stock_bandTrade,
    ...weng888Stock_dayTrade,
    ...[stock_signal],
  ]

  // 預設顯示
  store.charting.indicators = weng888Stock_bandTrade
  store.charting.symbol = 'TSEA'
  store.charting.interval = '1D'

  store.charting.initStrategy({
    configs: [...weng888Stock_strategiesGroup.main],
  })
}
