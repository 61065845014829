import { proxy } from 'valtio'
import { FuiDatePicker } from '~/pages/heineken_template/components/FuiDatePicker'
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2'

export const hyt888Stock_store = proxy({
  stockDate: new FuiDatePicker(),
  tabsColumns: new FuiTabs2(['選股', '自選股']),
  tabsZScore: new FuiTabs2(['短線操作', '波段操作']),
  tabsUserWatch: new FuiTabs2(['多方自選股', '空方自選股']),
})
