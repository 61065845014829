import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore'

const sounds = useSoundStore.getState()

export const stock_sg1 = createIndicator({
  id: 'stocksg1',
  displayName: '期弈多空訊號',

  constructorScope: {
    init(context, inputCallback) {
      localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString())
    },
    main(context, inputCallback) {
      const state = useIndicatorStore.getState()
      //商品資訊
      const getSymbolnumber = this.PineJS.Std.ticker(this._context)
      state.symbolNumber = String(getSymbolnumber.replace(/"/g, '').replace('}', ''))
      state.update(state)

      let pricescale = 1

      const len = 1
      let pv0 = NaN
      let pv1 = NaN
      const pvflag = this._context.new_var()

      let pb1 = NaN
      let pb2 = NaN
      let pb3 = NaN
      let pb4 = NaN
      let pb5 = NaN

      let ps1 = NaN
      let ps2 = NaN
      let ps3 = NaN
      let ps4 = NaN
      let ps5 = NaN

      const h = this.PineJS.Std.high(this._context)
      const l = this.PineJS.Std.low(this._context)
      const c = this.PineJS.Std.close(this._context)
      const t = new Date(this.PineJS.Std.time(this._context))

      const f_0 = function (e: number, tt: number) {
        return 2 * e - tt
      }

      const hma1 = (e: AnyExplicit, in0: AnyExplicit) => {
        const i = this.PineJS.Std.close(this._context)
        const o = in0
        const r = o / 2
        const s = this._context.new_var(i)
        const a = this.PineJS.Std.wma(s, r, this._context)
        const ll = this._context.new_var(i)
        const cc = this.PineJS.Std.wma(ll, o, this._context)
        const u = f_0(a, cc)
        const hh = this.PineJS.Std.sqrt(o)
        const d = this.PineJS.Std.round(hh)
        const p = this._context.new_var(u)
        return this.PineJS.Std.wma(p, d, this._context)
      }

      const prettyFloat = function (x: any) {
        return parseFloat(x.toFixed(Math.log10(pricescale)))
      }
      const targetPrices = function (v: any, ps: any) {
        return ps
          .map(function (x: any) {
            return prettyFloat(v * x)
          })
          .join(' / ') as string
      }

      if (this._context.symbol.info) {
        pricescale = this._context.symbol.info.pricescale
        state.pricescale = pricescale
      }
      const nh = this._context.new_var(h)
      const nl = this._context.new_var(l)
      const nc = this._context.new_var(c)
      const interval = this.PineJS.Std.interval(this._context)
      const hma_value = hma1(context, 55) //this._input(0)

      const bbma = this.PineJS.Std.sma(nc, this._input(1), this._context) //this._input(1)
      const bbstd = 0.6 * this.PineJS.Std.stdev(nc, this._input(2), this._context) //this._input(2)
      const bbhh = bbma + bbstd
      const bbll = bbma - bbstd

      const entryPrice = this._context.new_var()

      nl.get(1)
      nh.get(1)

      const hh = this._context.new_var(
        this.PineJS.Std.highest(this._context.new_var(h), len, this._context),
      )
      const ll = this._context.new_var(
        this.PineJS.Std.lowest(this._context.new_var(l), len, this._context),
      )

      const bState = this._context.new_var()
      const bDt = this._context.new_var<Date>()

      const localTimeVar = this._context.new_var()
      const timeDeltaMs = 500
      const kbarLoadingTimeMs = 10000
      const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs
      const isBarChanging =
        isNaN(localTimeVar.get(1)) ||
        Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs
      localTimeVar.set(new Date().getTime())

      bState.get(1)
      bState.get(2)

      if (c > hma_value && c > bbhh) {
        bState.set(1)
        bDt.set(t)
      } else if (c < hma_value && c < bbll) {
        bState.set(-1)
        bDt.set(t)
      }

      if (bState.get(0) === 1 && bState.get(1) !== 1) {
        pv0 = 1
        pv1 = NaN
        entryPrice.set(c)
        pvflag.set(0)
        state.entryPrice = Number(c)
        state.stopLossPrices = [prettyFloat(entryPrice * 0.997), prettyFloat(entryPrice * 0.9955)]
      } else if (bState.get(0) === -1 && bState.get(1) !== -1) {
        pv0 = NaN
        pv1 = 1
        entryPrice.set(c)
        pvflag.set(0)
        state.entryPrice = Number(-c)
        state.stopLossPrices = [prettyFloat(entryPrice * 1.003), prettyFloat(entryPrice * 1.0045)]
      }

      const p1 = interval === 60 ? 0.17 : 0.028
      const p2 = interval === 60 ? 0.37 : 0.055
      const p3 = interval === 60 ? 0.55 : 0.092
      const p4 = interval === 60 ? 1.44 : 100
      const p5 = 10 // no use

      if (bState.get(0) === 1) {
        if (pvflag.get(0) === 0 && h > entryPrice * (1 + p1) && nh.get(1) < entryPrice * (1 + p1)) {
          pvflag.set(1), (pb1 = 1)
        } else if (
          pvflag.get(0) === 1 &&
          h > entryPrice * (1 + p2) &&
          nh.get(1) < entryPrice * (1 + p2)
        ) {
          pvflag.set(2), (pb2 = 1)
        } else if (
          pvflag.get(0) === 2 &&
          h > entryPrice * (1 + p3) &&
          nh.get(1) < entryPrice * (1 + p3)
        ) {
          pvflag.set(3), (pb3 = 1)
        } else if (
          pvflag.get(0) === 3 &&
          h > entryPrice * (1 + p4) &&
          nh.get(1) < entryPrice * (1 + p4)
        ) {
          pvflag.set(4), (pb4 = 1)
        } else if (
          pvflag.get(0) === 4 &&
          h > entryPrice * (1 + p5) &&
          nh.get(1) < entryPrice * (1 + p5)
        ) {
          pvflag.set(5), (pb5 = 1)
        }

        const _targetPrices = targetPrices(entryPrice, [1 + p1, 1 + p2, 1 + p3, 1 + p4])
          .toString()
          .split('/')
          .map(function (value) {
            return Number(value)
          })

        state.targetPrices = _targetPrices
      } else if (bState.get(0) === -1) {
        if (pvflag.get(0) === 0 && l < entryPrice * (1 - p1) && nl.get(1) > entryPrice * (1 - p1)) {
          pvflag.set(1), (ps1 = 1)
        } else if (
          pvflag.get(0) === 1 &&
          l < entryPrice * (1 - p2) &&
          nl.get(1) > entryPrice * (1 - p2)
        ) {
          pvflag.set(2), (ps2 = 1)
        } else if (
          pvflag.get(0) === 2 &&
          l < entryPrice * (1 - p3) &&
          nl.get(1) > entryPrice * (1 - p3)
        ) {
          pvflag.set(3), (ps3 = 1)
        } else if (
          pvflag.get(0) === 3 &&
          l < entryPrice * (1 - p4) &&
          nl.get(1) > entryPrice * (1 - p4)
        ) {
          pvflag.set(4), (ps4 = 1)
        } else if (
          pvflag.get(0) === 4 &&
          l < entryPrice * (1 - p5) &&
          nl.get(1) > entryPrice * (1 - p5)
        ) {
          pvflag.set(5), (ps5 = 1)
        }
        const _targetPrices = targetPrices(entryPrice, [1 - p1, 1 - p2, 1 - p3, 1 - p4])
          .toString()
          .split('/')
          .map(function (value) {
            return Number(value)
          })

        state.targetPrices = _targetPrices
      }

      /**
       * @example
       *   console.log(new Date())
       *   console.log(isInitialized, isBarChanging)
       *   console.log(bState.get(0), bState.get(1), bState.get(2))
       */
      if (isInitialized && isBarChanging) {
        if (bState.get(1) === 1 && bState.get(2) !== 1) {
          sounds.playSoundCoinDebounced()
        } else if (bState.get(1) === -1 && bState.get(2) !== -1) {
          sounds.playSoundCoinDebounced()
        }
      }

      state.update(state)

      return [pv0, pv1, pb1, pb2, pb3, pb4, pb5, ps1, ps2, ps3, ps4, ps5]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    defaults: {
      styles: {
        plot_0: {
          color: '#ff0000',
          textColor: '#ff0000',
          transparency: 20,
          visible: true,
        },
        plot_1: {
          color: '#2196f3',
          textColor: '#2196f3',
          transparency: 10,
          visible: true,
        },
        plot_b1: {
          color: '#cfbf00',
          textColor: '#cfbf00',
          transparency: 10,
          visible: true,
        },
        plot_b2: {
          color: '#ffee00',
          textColor: '#ffee00',
          transparency: 10,
          visible: true,
        },
        plot_b3: {
          color: '#ee00ff',
          textColor: '#ee00ff',
          transparency: 10,
          visible: true,
        },
        plot_b4: {
          color: '#ff0068',
          textColor: '#ff0068',
          transparency: 10,
          visible: true,
        },
        plot_b5: {
          color: '#ffee00',
          textColor: '#ffee00',
          transparency: 10,
          visible: true,
        },
        plot_s1: {
          color: '#209048',
          textColor: '#209048',
          transparency: 20,
          visible: true,
        },
        plot_s2: {
          color: '#55ffee',
          textColor: '#55ffee',
          transparency: 20,
          visible: true,
        },
        plot_s3: {
          color: '#00ff55',
          textColor: '#00ff55',
          transparency: 20,
          visible: true,
        },
        plot_s4: {
          color: '#209048',
          textColor: '#209048',
          transparency: 20,
          visible: true,
        },
        plot_s5: {
          color: '#55ffee',
          textColor: '#55ffee',
          transparency: 20,
          visible: true,
        },
      },
      inputs: {
        in_0: 55,
        in_1: 36,
        in_2: 36,
        in_3: 0.003,
        in_4: 0.007,
        in_5: 0.012,
        in_6: 0.018,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'chars',
      },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_b1',
        type: 'chars',
      },
      {
        id: 'plot_b2',
        type: 'chars',
      },
      {
        id: 'plot_b3',
        type: 'chars',
      },
      {
        id: 'plot_b4',
        type: 'chars',
      },
      {
        id: 'plot_b5',
        type: 'chars',
      },
      {
        id: 'plot_s1',
        type: 'chars',
      },
      {
        id: 'plot_s2',
        type: 'chars',
      },
      {
        id: 'plot_s3',
        type: 'chars',
      },
      {
        id: 'plot_s4',
        type: 'chars',
      },
      {
        id: 'plot_s5',
        type: 'chars',
      },
    ],
    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: 'Shapes',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: 'Shapes',
      },
      plot_b1: {
        isHidden: false,
        location: 'AboveBar',
        char: '♥️',
        size: 'small',
        title: 'Shapes',
      },
      plot_b2: {
        isHidden: false,
        location: 'AboveBar',
        char: '♥️',
        size: 'small',
        title: 'Shapes',
      },
      plot_b3: {
        isHidden: false,
        location: 'AboveBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
      plot_b4: {
        isHidden: false,
        location: 'AboveBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
      plot_b5: {
        isHidden: false,
        location: 'AboveBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
      plot_s1: {
        isHidden: false,
        location: 'BelowBar',
        char: '♥️',
        size: 'small',
        title: 'Shapes',
      },
      plot_s2: {
        isHidden: false,
        location: 'BelowBar',
        char: '♥️',
        size: 'small',
        title: 'Shapes',
      },
      plot_s3: {
        isHidden: false,
        location: 'BelowBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
      plot_s4: {
        isHidden: false,
        location: 'BelowBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
      plot_s5: {
        isHidden: false,
        location: 'BelowBar',
        char: '♥️',
        size: 'normal',
        title: 'Shapes',
      },
    },
    is_price_study: true,
    inputs: [
      {
        id: 'in_0',
        name: '用不到~待移除',
        defval: 55,
        type: 'integer',
        min: 1,
        max: 1e12,
      },
      {
        id: 'in_1',
        name: 'SMA',
        defval: 36,
        type: 'integer',
        min: 1,
        max: 1e12,
      },
      {
        id: 'in_2',
        name: 'STD',
        defval: 36,
        type: 'integer',
        min: 1,
        max: 1e12,
      },
      {
        id: 'in_3',
        name: '停利1',
        defval: 0.003,
        type: 'float',
        min: 0,
        max: 1e12,
      },
      {
        id: 'in_4',
        name: '停利2',
        defval: 0.007,
        type: 'float',
        min: 0,
        max: 1e12,
      },
      {
        id: 'in_5',
        name: '停利3',
        defval: 0.012,
        type: 'float',
        min: 0,
        max: 1e12,
      },
      {
        id: 'in_6',
        name: '停利4',
        defval: 0.018,
        type: 'float',
        min: 0,
        max: 1e12,
      },
    ],
    scriptIdPart: '',
  },
})
