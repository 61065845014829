import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { big3_store } from '~/pages/big3/_private/big3_store'

export const big3_dc = createIndicator({
  displayName: '階梯線',
  id: 'big3-dc',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)
      const dc = this.ladderChannl(length)

      if (!isNaN(dc[0])) {
        big3_store.dcDailyTrend = 1
      }
      if (!isNaN(dc[1])) {
        big3_store.dcDailyTrend = -1
      }

      return [dc[0], dc[1]]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#aa0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#00aa00',
        },
      },
      precision: 4,
      inputs: {
        in_0: 20,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Lower',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Upper',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '週期',
        defval: 20,
        type: 'integer',
        min: 1,
        max: 240,
      },
    ],
    scriptIdPart: '',
  },
})
