import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { big3_store } from '~/pages/big3/_private/big3_store'

export const big3_priceLine = createIndicator({
  displayName: '趨勢底線',
  id: 'big3-priceLine',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)
      const result = this.ladderMidPrice(length).get(0)

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const ma = this.PineJS.Std.sma(close_array, 3, this._context)

      if (ma > result) {
        big3_store.priceLineTrend = 1
      }
      if (ma < result) {
        big3_store.priceLineTrend = -1
      }
      if (ma === result) {
        big3_store.priceLineTrend = 0
      }

      return [result]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 9,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#00ffff',
        },
      },
      inputs: {
        in_0: 5,
      },
    },

    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '週期',
        defval: 5,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
    ],
    scriptIdPart: '',
  },
})
