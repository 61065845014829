import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { UnregisteredView } from '~/modules/investment-consultant/loginPage/UnregisteredView'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar'
import { store } from '../heineken_template/_private/store'
import { datatree_store } from './datatree_store'

export const Datatree_Topbar = memo<ReactProps>(function Datatree_Topbar() {
  const { isPc } = useMedia()

  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: #2a2e3b;
        padding: 0 16px;
      `}
    >
      {isPc && <ChartingServerSwitch charting={store.charting} />}
      {isPc && (
        <datatree_store.userAvatar.AvatarModal
          cssset={css`
            width: 280px;
            height: 480px;
          `}
          blur={1}
        >
          <UnregisteredView />
        </datatree_store.userAvatar.AvatarModal>
      )}
      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div
              css={css`
                ${fill_horizontal_cross_center};
                justify-content: end;
                background-color: #2a2e3b;
              `}
            >
              <ChartingServerSwitch charting={store.charting} />
              <datatree_store.userAvatar.AvatarModal
                cssset={css`
                  width: 280px;
                  height: 480px;
                `}
                blur={1}
              >
                <UnregisteredView />
              </datatree_store.userAvatar.AvatarModal>
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})
