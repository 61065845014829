import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { IndicatorsSelect } from '~/modules/cons-misc/IndicatorsSelect'

import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import { SimpleQuoteList } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { SoundTestButton } from '~/pages/heineken_template/_col/col_SoundTestButton'
import { store } from '~/pages/heineken_template/_private/store'
import Styled from '~/pages/win168/_private/styleds'

const symbols = [
  'TX-1',
  'TXAM-1',
  'OTCA',
  'TSE13',
  'TSE17',
  '2330',
  'YM-1',
  'NQ-1',
  'ES-1',
  'CN-1',
  'NK-1',
  'GC-1',
  'CL-1',
]

export const Win168_SidePane1 = memo<ReactProps>(function SidePane1(props) {
  const charting = useSnapshot(store.charting)

  return (
    <Styled.Sidebar>
      <Styled.Title>策略選擇</Styled.Title>
      <Styled.SidebarSection>
        <IndicatorsSelect
          charting={store.charting}
          strategiesConfig={store.charting.strategyConfigs}
        />
        <div
          css={css`
            width: 120px;
            height: 100%;
            padding: 4px;
          `}
        >
          <SoundTestButton />
        </div>
      </Styled.SidebarSection>

      <Styled.Title>
        <SymbolName symbol={charting.symbol} />
        商品走勢圖
      </Styled.Title>

      <div
        css={css`
          ${flex.v.allCenter};
          height: 200px;
          width: 100%;
        `}
      >
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={12}
          ticksHeight={20}
          priceTicksMargin={-15}
          priceTicksSize={11}
        />
      </div>
      <div
        css={css`
          ${flex.v.allCenter};
          width: 100%;
          height: 160px;
        `}
      >
        <InformationForm />
      </div>
      <div
        css={css`
          ${flex.v.allCenter};
          height: calc(100% - 490px);
          width: 100%;
        `}
      >
        <SimpleQuoteList data={symbols} />
      </div>
    </Styled.Sidebar>
  )
})
