import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Kabujuku_Footer } from '~/pages/kabujukuTW/_private/kabujuku_Footer'
import { kabujuku_initStyling } from '~/pages/kabujukuTW/_private/kabujuku_initStyling'
import { Kabujuku_Sidebar1 } from '~/pages/kabujukuTW/_private/kabujuku_Sidebar1'
import { Kabujuku_Sidebar2 } from '~/pages/kabujukuTW/_private/kabujuku_Sidebar2'
import { Kabujuku_Topbar } from '~/pages/kabujukuTW/_private/kabujuku_Topbar'
import { kubujuku_initStrategies } from '~/pages/kabujukuTW/_private/kubujuku_initStrategies'

export const kubujuku_init = (templateProps: TemplateProps) => {
  kubujuku_initStrategies()
  kabujuku_initStyling()
  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode('light')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    .Chart {
      height: calc(100vh - 48px);
    }
  `

  templateProps.layout.Row1 = Kabujuku_Topbar
  templateProps.layout.Drawer1 = Kabujuku_Sidebar1
  templateProps.layout.Drawer2 = Kabujuku_Sidebar2
  templateProps.layout.Row2 = Kabujuku_Footer
}
