import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { AgentCarouselSubscriptionCheck } from '~/modules/auth/components/AgentCarouselSubscriptionCheck'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import Styled from '~/pages/win168/_private/styleds'
import { win168_initStrategies } from '~/pages/win168/_private/win168_initStrategies'
import { Win168_SidePane1 } from '~/pages/win168/_private/win168_SidePane1'
import { Win168_SidePane2 } from '~/pages/win168/_private/win168_SidePane2'
import dayAPI from '~/utils/dayAPI'
import { win168_initStyling } from './win168_initStyling'

export const win168_init = (templateProps: TemplateProps) => {
  win168_initStyling()
  win168_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.calcFrom = dayAPI().subtract(30, 'day')
  templateProps.layout.login = (
    <AgentCarouselSubscriptionCheck resources={[{ image: [true, '/win168/login1.png'] }]} />
  )

  templateProps.layout.Charting = Styled.Charting

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    disabledLeftToolbar: true,
    enableVolumeIndicator: true,
    overrides: store.charting.darkOverrides,
    symbol: 'TXAM-1',
    interval: '3',
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `

  templateProps.layout.Row1 = (
    <Preset_Topbar
      hideIfPcView
      showLeftBurger
      showRightBurger
    />
  )
  templateProps.layout.Drawer1 = Win168_SidePane1
  templateProps.layout.Drawer2 = Win168_SidePane2
}
