import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { SignalSymbolArray, useFirebaseValue } from '~/hooks/useFirebaseValue'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'

const useFirebasePosition = proxy({
  positionType: 'long' as 'long' | 'short',
})

export const FirebaseSymbolList = memo(function FirebaseSymbol_() {
  const firebasePosition = useSnapshot(useFirebasePosition)

  const long = useFirebaseValue<SignalSymbolArray>('lung88988_signal', 'long')
  const short = useFirebaseValue<SignalSymbolArray>('lung88988_signal', 'short')
  const [fbLong, fbShort] = [long?.symbols, short?.symbols]
  const firebaseSymbolResult = () => (firebasePosition.positionType === 'long' ? fbLong : fbShort)

  return (
    <div
      css={css`
        ${flex.v.allCenter};
        width: 100%;
        height: 100%;
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
          width: 100%;
          height: 30px;
          gap: 2px;
        `}
      >
        <FuiButton.Display
          variant='long'
          active={firebasePosition.positionType === 'long'}
          onClick={event => {
            useFirebasePosition.positionType = 'long'
          }}
        >
          多方操作
        </FuiButton.Display>
        <FuiButton.Display
          variant='short'
          active={firebasePosition.positionType === 'short'}
          onClick={event => {
            useFirebasePosition.positionType = 'short'
          }}
        >
          空方操作
        </FuiButton.Display>
      </div>
      <div
        css={css`
          ${flex.h.allCenter}
          width:100%;
          height: calc(100% - 30px);
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={firebaseSymbolResult() ?? []}
          chart={store.charting}
        />
      </div>
    </div>
  )
})
