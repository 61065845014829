import { css } from '@emotion/react'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const asiajye_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${FuiButton.classes.button.Root} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 32px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
      }

      ${FuiButton.classes.button.default.Default} {
        background-color: rgb(238, 238, 238);
        border: 1px solid rgb(204, 204, 204);
        color: rgb(51, 51, 51);
      }

      ${FuiButton.classes.button.default.Active} {
        background-color: rgb(238, 238, 238);
        border: 1px solid rgb(90, 118, 133);
        color: rgb(51, 51, 51);
      }
    }
  `
}
