import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

export const lung88988_dayTradePower = createIndicator({
  displayName: '當沖力道參考',
  id: 'lung88988dayTraderPower',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#bs_indicator_acc'

      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)

      const h = this.PineJS.Std.high(this._context)
      const colorIndex = h >= 0 ? 0 : 1

      return [h, colorIndex]
    },
  },
  metainfo: {
    precision: 2,
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },

      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '市場多方能量',
          },
          1: {
            name: '市場空方能量',
          },
        },
      },
    },
    defaults: {
      styles: {
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
      },
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#dd5441',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00aa0a',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },
    styles: {
      vol: {
        title: '能量',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})
