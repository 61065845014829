import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { store } from '../heineken_template/_private/store'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { css } from '@emotion/react'
import useMedia from '~/hooks/useMedia'
import { horizontalScrollbarCss } from '~/css/scrollbarCss'

export const Pionex_QuoteInfo = memo<ReactProps>(function Pionex_QuoteInfo() {
  const charting = useSnapshot(store).charting

  useEffect(() => {
    signalrStore2.addQuote(charting.symbol)
    return () => {
      signalrStore2.removeQuote(charting.symbol)
    }
  }, [JSON.stringify(charting.symbol)])

  const value = useSnapshot(signalrStore2.values.quote)[charting.symbol]
  const quoteChanges = processQuoteToReadable(value as Signalr.ValueOfOHLC)
  const change = quoteChanges.closeChange
  const changeArrow = change > 0 ? '+' : null

  const convertedCode = value?.symbol?.replace('USDT', '')?.toLowerCase()
  const iconSrc = 'pionex/symbol_icon/' + convertedCode + '.png'

  const { isPc } = useMedia()

  return (
    <div css={fill_horizontal_all_center}>
      {isPc && (
        <styleds.container>
          <styleds.nameContent>
            <styleds.cryptoIcon src={iconSrc} />
            {charting.symbol}
          </styleds.nameContent>
          <styleds.mainQuoteContent change={change}>
            <span>{value?.close}</span>
            <span>
              {changeArrow}
              {quoteChanges.closeChange} / {changeArrow}
              {quoteChanges.closeChangePercent}%
            </span>
          </styleds.mainQuoteContent>

          <styleds.quoteContent
            css={css`
              color: #f04848;
            `}
          >
            <styleds.quoteTitle>買盤</styleds.quoteTitle>
            <span>{value?.ask}</span>
          </styleds.quoteContent>
          <styleds.quoteContent
            css={css`
              color: #00b070;
            `}
          >
            <styleds.quoteTitle>賣盤</styleds.quoteTitle>
            <span>{value?.bid}</span>
          </styleds.quoteContent>
          <styleds.quoteContent>
            <styleds.quoteTitle>24時高</styleds.quoteTitle>
            <span>{value?.high}</span>
          </styleds.quoteContent>
          <styleds.quoteContent>
            <styleds.quoteTitle>24時低</styleds.quoteTitle>
            <span>{value?.low}</span>
          </styleds.quoteContent>
          <styleds.quoteContent>
            <styleds.quoteTitle>24時量</styleds.quoteTitle>
            <span>{value?.volume?.toFixed(2)}</span>
          </styleds.quoteContent>
        </styleds.container>
      )}
      {!isPc && (
        <div
          css={css`
            ${fill_horizontal_all_center};
            padding: 0 16px;
            border: 1px solid #e0e3eb;
          `}
        >
          <styleds.nameContent>
            <styleds.cryptoIcon src={iconSrc} />
            {charting.symbol}
          </styleds.nameContent>

          <styleds.mainQuoteContent change={change}>
            <span>{value?.close}</span>
            <span>
              {changeArrow}
              {quoteChanges.closeChange} / {changeArrow}
              {quoteChanges.closeChangePercent}%
            </span>
          </styleds.mainQuoteContent>
        </div>
      )}
    </div>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 17% 23% 12% 12% 12% 12% 12%;
    border-bottom: 1px solid #dce0e6;
    color: #9ea6b8;
    font-size: 14px;
    padding: 8px;
  `,
  nameContent: styled.div`
    ${fill_horizontal_cross_center};
    font-weight: bold;
    color: #252525;
  `,
  mainQuoteContent: styled.div<{ change: number }>`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-rows: 50% 50%;
    color: ${props => (props.change ? '#f04848' : '#00b070')};
    font-weight: bold;
  `,
  quoteContent: styled.div`
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-rows: 50% 50%;
  `,
  quoteTitle: styled.div`
    color: #252525;
  `,
  cryptoIcon: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 4px;
  `,
}
