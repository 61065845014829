import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isString } from 'lodash'
import { Fragment, memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent'

const classes = {
  Root: styled.div`
    ${flex.h.allCenter};
    gap: 4px;
    width: 100%;
  `,
  Title: styled.div`
    ${flex.h.allCenter};
    flex-basis: 200px;
    text-align: center;
  `,
  Content: styled.div`
    ${flex.h.allCenter};
  `,
}

const cssset = css``

export const Display = memo<
  ReactProps<{
    title: string | React.ComponentType | React.ReactElement
  }>
>(function Display(props) {
  const Title = useElementAsComponent(
    isString(props.title) ? <Fragment>{props.title}</Fragment> : props.title,
  )

  return (
    <classes.Root
      className={props.className}
      css={cssset}
    >
      <classes.Title>
        <Title />
      </classes.Title>

      <classes.Content>{props.children}</classes.Content>
    </classes.Root>
  )
})

/**
 * # 提供一個可以用在每個 Row 的統一式兩欄式容器，左欄是文字（容納6~7個中文字寬），右欄是你要放入的組件
 *
 * @example
 *   function Compon() {
 *     return (
 *       <Col_SimpleRow.Display title={`選股日期`}>
 *         <DatePicker
 *           {...{
 *             disableToolbar: true,
 *             fullWidth: true,
 *             variant: 'inline',
 *             inputVariant: 'outlined',
 *             size: 'small',
 *             format: 'YYYY/MM/DD',
 *             InputProps: {
 *               style: {
 *                 height: 30,
 *               },
 *             },
 *           }}
 *           renderInput={params => <TextField {...params} />}
 *           value={agentState.stockPickedDate}
 *           onChange={(value: Dayjs | null) => {
 *             if (value) agentStore.stockPickedDate = value
 *           }}
 *           minDate={dayAPI().add(-20, 'day')}
 *           maxDate={dayAPI()}
 *         />
 *       </Col_SimpleRow.Display>
 *     )
 *   }
 */
export default {
  Display,
  classes,
}
