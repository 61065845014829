import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { pionex_atr } from '~/trades/indicators/pionex/pionex_atr'
import { pionex_band } from '~/trades/indicators/pionex/pionex_band'
import { pionex_ladder } from '~/trades/indicators/pionex/pionex_ladder'
import { pionex_power } from '~/trades/indicators/pionex/pionex_power'
import { pionex_stoch } from '~/trades/indicators/pionex/pionex_stoch'
import { pionex_strategy01 } from '~/trades/indicators/pionex/pionex_strategy01'
import { pionex_strategy02 } from '~/trades/indicators/pionex/pionex_strategy02'
import { pionex_strategy03 } from '~/trades/indicators/pionex/pionex_strategy03'
import { pionex_strategy04 } from '~/trades/indicators/pionex/pionex_strategy04'
import { pionex_strategy05 } from '~/trades/indicators/pionex/pionex_strategy05'
import { pionex_strategy06 } from '~/trades/indicators/pionex/pionex_strategy06'
import { pionex_trend } from '~/trades/indicators/pionex/pionex_trend'

const trade01 = []
const trade02 = []

export const pionex_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '趨勢突破',
      indicators: [pionex_strategy01, pionex_ladder, pionex_trend],
      interval: '60',
      describe: '階梯線與均線搭配突破進場',
      type: 'default',
      panesRatio: 80,
    },
    {
      displayName: '順勢波動',
      indicators: [pionex_strategy02, pionex_band, pionex_atr, pionex_trend],
      interval: '30',
      describe: '均線排列與波動突破進場',
      type: 'default',
      panesRatio: 70,
    },
    {
      displayName: '區間逆勢短單',
      indicators: [pionex_strategy03, pionex_trend],
      interval: '15',
      describe: '乖離或波動短暫過大時進場',
      type: 'default',
      panesRatio: 80,
    },
    {
      displayName: '逆勢擺盪',
      indicators: [pionex_strategy04, pionex_stoch, pionex_trend],
      interval: '5',
      describe: '長週期KD逆勢進場',
      type: 'default',
      panesRatio: 70,
    },
    {
      displayName: '平台突破',
      indicators: [pionex_strategy05, pionex_trend],
      interval: '15',
      describe: '價格突破小型整理平台進場',
      type: 'default',
      panesRatio: 80,
    },
    {
      displayName: '爆量急殺抄底做多',
      indicators: [pionex_strategy06],
      interval: '15',
      describe: '下跌幅度過大且爆量進場',
      type: 'long',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const pionex_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[pionex_ladder, pionex_band, pionex_stoch, pionex_trend, pionex_power, pionex_atr],
    // ...trade01,
    // ...trade02,
  ]

  store.charting.initStrategy({
    configs: [...pionex_strategiesGroup.main],
  })
}
