import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { styleds } from './styleds'
import {
  CheapCard,
  DefaultCard,
  MTXQuoteCard,
  TimeCard,
  TSEAQuoteCard,
  TXFQuoteCard,
} from './component/PriceCard'
import { optionAnalyzeStore } from './component/optionAnalyze/optionAnalyzeStore'
import { getOptionCheap } from './component/optionAnalyze/getOptionCheap'
import { useOptionQuote } from './component/optionAnalyze/useOptionQuote'

export const Opkevin_SidePane = memo<ReactProps>(function Opkevin_SidePane() {
  const opState = useSnapshot(optionAnalyzeStore)
  const data = useOptionQuote().quoteData

  const atTheMoneyPrice = opState.atTheMoneyPrice
  const atTheMoneySum = opState.atTheMoneySum
  const cheapValue = getOptionCheap(data, atTheMoneyPrice, atTheMoneySum)

  return (
    <styleds.sidePane>
      <TimeCard />
      <DefaultCard value={opState.remainingDays}>剩餘日</DefaultCard>
      <TSEAQuoteCard />
      <TXFQuoteCard />
      <MTXQuoteCard />
      <DefaultCard value={opState.syntheticFutures}>合成期貨</DefaultCard>
      <DefaultCard value={opState.atTheMoneyPrice}>價平</DefaultCard>
      <CheapCard value={cheapValue}>Cheap</CheapCard>
    </styleds.sidePane>
  )
})
