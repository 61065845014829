import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'

export const Datatree_Footer = memo<ReactProps>(function Datatree_Footer() {
  return (
    <styleds.container>
      <styleds.text>
        本資料僅供教學參考使用，不得作為任何金融商品推介買賣之依據，亦不得作為向他人提出投資建議使用。
        本站僅就資料提供適當意見與資訊，但不保證資料完整與正確性，如有遺漏或偏頗之處，請瀏覽人士，自行承擔一切風險，本站不負擔盈虧之法律責任。
        其中所出現之標的僅作為舉例說明使用，並無意圖向任何人推薦投資標的。{' '}
      </styleds.text>

      <styleds.text>Copyright © 20022-2023 DataTree</styleds.text>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_all_center};
    background-color: #1d1e2a;
    font-size: 10px;
    gap: 16px;
    padding: 16px;
  `,
  text: styled.p`
    margin: 2px;
  `,
}
