import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'

export const Rich888_Footer = memo<ReactProps>(function Rich888_Footer() {
  return (
    <styleds.container>
      <styleds.text>
        「本站資料僅供參考，本公司與推介分析之個別有價證券無不當之財務利益關係，過往之績效不代表未來獲利之保證，投資人應獨立判斷，審慎評估並自負投資風險。」{' '}
      </styleds.text>

      <styleds.text>版權所有© Da-hwa-international.大華國際投顧 All rights reserved.</styleds.text>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_all_center};
    background-color: #eaeaea;
    font-size: 14px;
    gap: 16px;
    padding: 16px;
  `,
  text: styled.p`
    margin: 2px;
  `,
}
