import { css } from '@emotion/react'
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import WatchSymbolToggleButtonSingle from '../heineken_template/_col/col_WatchSymbolToggleSingleButton'
import SymbolCardRankingBody from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'

export const daddy960_revive_initStyling = (templateProps: TemplateProps) => {
  store.charting.darkOverrides = {
    'mainSeriesProperties.showPriceLine': false,
    'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
    'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
    'mainSeriesProperties.candleStyle.downColor': '#00ff00',
    'mainSeriesProperties.candleStyle.upColor': '#ff0000',
    'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
    'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'paneProperties.topMargin': 10,
    'paneProperties.bottomMargin': 10,
    'paneProperties.vertGridProperties.color': '#363636',
    'paneProperties.horzGridProperties.color': '#363636',
    'paneProperties.horzGridProperties.style': 1,
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': '#16161e',
    'paneProperties.legendProperties.showSeriesOHLC': true,
    'timeScale.rightOffset': 5,
    'scalesProperties.fontSize': 16,
    'scalesProperties.textColor': '#f8f9f9',
    'scalesProperties.showSeriesLastValue': false,
    'scalesProperties.showStudyLastValue': false,
    'paneProperties.legendProperties.showStudyValues': false,
  }

  templateProps.globalCssset = css`
    #__body {
      ${SymbolCardRankingBody.classes.containerDefault} {
        background-color: #24252b;
        border: 1px solid #444444;
        color: #ffffff;
        padding: 0px 2px;
        &:hover {
          background-color: #35363c;
        }
      }
    }
  `
}
