import { memo } from 'react'
import { Footer } from '~/pages/kabujukuTW/modules/Footer'
import Styleds from '~/pages/kabujukuTW/_private/styleds'

export const Kabujuku_Footer = memo<ReactProps>(function Kabujuku_Footer(props) {
  return (
    <Styleds.Footer>
      <Footer />
    </Styleds.Footer>
  )
})
