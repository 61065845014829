/* eslint-disable no-var, no-console */
import { SocketChannel } from '~/modules/SDK/socket/SocketChannel'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const bs0a = createIndicator({
  id: 'bs0a',
  displayName: '主力籌碼',
  enabledOn(symbol, data, channel) {
    return data?.type === channel.tw_futures
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      this._context.select_sym(1)
      const o = this.PineJS.Std.open(this._context)
      const o_array = this._context.new_var(o)

      o_array.get(0)
      o_array.get(1)

      const colorIndex = () => {
        if (o_array.get(0) >= 0 && o_array.get(1) >= 0 && o_array.get(0) >= o_array.get(1)) return 0
        else if (o_array.get(0) >= 0 && o_array.get(1) >= 0 && o_array.get(0) < o_array.get(1))
          return 1
        else if (o_array.get(0) < 0 && o_array.get(1) >= 0 && o_array.get(0) < o_array.get(1))
          return 2
        else if (o_array.get(0) < 0 && o_array.get(1) < 0 && o_array.get(0) <= o_array.get(1))
          return 2
        else if (o_array.get(0) < 0 && o_array.get(1) < 0 && o_array.get(0) > o_array.get(1))
          return 3
      }

      return [o, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
          2: {
            name: '多方趨勢',
          },
          3: {
            name: '空方趨勢',
          },
        },
      },
    },
    defaults: {
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 20,
          visible: !0,
          color: '#000000',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#ff0000',
              width: 0,
              style: 0,
            },
            1: {
              color: '#aa0000',
              width: 0,
              style: 0,
            },
            2: {
              color: '#00ff00',
              width: 0,
              style: 0,
            },
            3: {
              color: '#00aa00',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      vol: {
        title: '主力籌碼',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [],
  },
})
