import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { SignUp } from './component/SignUp'
import { StrategyCard } from './component/StrategyCard'
import { TradeBoard } from './component/TradeBorad'
import { TradeBot } from './component/TradeBot'
import { pionex_strategiesGroup } from './pionex_initStrategies'

type MenuOptionType = 'trade' | 'openAccount' | 'tradeRobot' | 'tradingInfo'

export const pionex_store = proxy({
  menuOptions: 'trade' as MenuOptionType,
})

export const Pionex_SidePane1 = memo<ReactProps>(function Pionex_SidePane1() {
  const state = useSnapshot(pionex_store)
  return (
    <styleds.container>
      <styleds.menuBar>
        <styleds.typeButton
          select={state.menuOptions === 'trade'}
          onClick={() => {
            pionex_store.menuOptions = 'trade'
          }}
        >
          交易策略
        </styleds.typeButton>
        <styleds.typeButton
          select={state.menuOptions === 'tradeRobot'}
          onClick={() => {
            pionex_store.menuOptions = 'tradeRobot'
          }}
        >
          機器人
        </styleds.typeButton>
        <styleds.typeButton
          select={state.menuOptions === 'openAccount'}
          onClick={() => {
            pionex_store.menuOptions = 'openAccount'
          }}
        >
          開戶
        </styleds.typeButton>
      </styleds.menuBar>

      {state.menuOptions === 'trade' && (
        <styleds.optionContent>
          <styleds.pageTitle>選擇策略指標組</styleds.pageTitle>
          <Strategy />
        </styleds.optionContent>
      )}

      {state.menuOptions === 'tradeRobot' && (
        <styleds.optionContent>
          <styleds.pageTitle>創建機器人</styleds.pageTitle>
          <TradeBot />
          <styleds.pageTitle>手動交易</styleds.pageTitle>
          <TradeBoard />
        </styleds.optionContent>
      )}

      {state.menuOptions === 'openAccount' && (
        <styleds.optionContent>
          <styleds.pageTitle>註冊賬戶</styleds.pageTitle>
          <SignUp />
        </styleds.optionContent>
      )}
    </styleds.container>
  )
})

//--
const Strategy = memo<ReactProps>(function Strategy() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        gap: 8px;
      `}
    >
      {pionex_strategiesGroup.main.map(config => {
        return (
          <StrategyCard
            config={config}
            key={config.displayName}
          ></StrategyCard>
        )
      })}
    </div>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center}
    border-right: 1px solid #e0e4ea;
    //background-color: #f0f1f3;
  `,
  menuBar: styled.div`
    ${fill_horizontal_all_center};
    height: 36px;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    gap: 16px;
    padding: 4px 16px;
  `,
  typeButton: styled.div<{ select: boolean }>`
    ${fill_horizontal_all_center};
    background-color: ${props => (props.select === true ? '#fe632d' : '#ffefe6')};
    color: ${props => (props.select === true ? '#fafafa' : '#fe632d')};
    border: 1px solid ${props => (props.select === true ? '#fe632d' : '#fe632d')};
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: ${props => (props.select === true ? '#fe7a4d' : '#fff4ee')};
    }
  `,
  optionContent: styled.div`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 36px);
    padding: 8px;
    gap: 8px;
  `,
  pageTitle: styled.div`
    font-size: 14px;
    font-weight: bold;
  `,
}
