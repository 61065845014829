import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const mvp888_dayTrade = createIndicator({
  displayName: '當沖多空策略',
  id: 'mvp888-dayTrade',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#bs_indicator_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)
      const unlimited1 = this._context.new_unlimited_var(high)
      const unlimited2 = this._context.new_unlimited_var(low)

      const value1 = unlimited1.adopt(time1, i, 0) // <=這是籌碼
      const value2 = unlimited2.adopt(time1, i, 0) // <=這是籌碼
      const value = value1 / value2
      // const value1_array = this._context.new_var(value)
      // value1_array.get(1000)

      //主圖商品
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)
      const marketposition = this._context.new_var()
      const entryPrice = this._context.new_var()

      const symbol = context.symbol.info?.ticker
      const hlc3 = this.PineJS.Std.hl2(this._context)
      const open = this.PineJS.Std.open(this._context)
      const close = this.PineJS.Std.close(this._context)

      const dayTradeAvgPrice = this.dayAvgPrice(0)
      const prevDayTradeAvgPrice = this._context.new_var()
      const itime = this.PineJS.Std.time(this._context)
      const hours = new Date(itime).getHours()
      const minutes = new Date(itime).getMinutes()
      const openTime = hours === 9 && minutes === 0

      const openD = this.dailyOpen(1, 0)[0]

      marketposition.get(2)
      entryPrice.get(2)

      if (hours === 13 && minutes === 30) {
        prevDayTradeAvgPrice.set(dayTradeAvgPrice)
        marketposition.set(0)
      }

      const long =
        value1 > 0 &&
        hlc3 > dayTradeAvgPrice &&
        hlc3 > prevDayTradeAvgPrice.get(0) &&
        hlc3 > openD &&
        close > open &&
        !openTime

      const short =
        value1 < 0 &&
        hlc3 < dayTradeAvgPrice &&
        hlc3 < prevDayTradeAvgPrice.get(0) &&
        hlc3 < openD &&
        close < open &&
        !openTime

      const swingLong = value > 0 && hlc3 > dayTradeAvgPrice && hlc3 <= prevDayTradeAvgPrice.get(0)
      const swingShort = value < 0 && hlc3 < dayTradeAvgPrice && hlc3 >= prevDayTradeAvgPrice.get(0)
      let buy_icon = NaN
      let short_icon = NaN

      if (long && hours < 13 && !symbol?.includes('-')) {
        marketposition.set(1)
      }
      if (short && hours < 13 && !symbol?.includes('-')) {
        marketposition.set(-1)
      }

      if (long) {
        indicatorsValueStore.openPositionProfit = close - entryPrice.get(0)
      }
      if (short) {
        indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
        buy_icon = 1
        entryPrice.set(close)
        indicatorsValueStore.openPositionProfit = 0
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = 1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [entryPrice * 1.01, entryPrice * 1.025]
        indicatorsValueStore.stopLossPrice = entryPrice * 0.995
      }
      if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
        short_icon = 1
        entryPrice.set(close)
        indicatorsValueStore.openPositionProfit = 0
        indicatorsValueStore.entryPrice = close
        indicatorsValueStore.marketPosition = -1
        indicatorsValueStore.entryDate = itime
        indicatorsValueStore.multipleTargetPrice = [entryPrice * 0.99, entryPrice * 0.975]
        indicatorsValueStore.stopLossPrice = entryPrice * 1.005
      }

      if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
        indicatorsValueStore.openPositionProfit = close - entryPrice.get(0)

        if (hlc3 < dayTradeAvgPrice && value < 0) {
          marketposition.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
        indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close

        if (hlc3 > dayTradeAvgPrice && value > 0) {
          marketposition.set(0)
          indicatorsValueStore.marketPosition = 0
          indicatorsValueStore.entryDate = NaN
          indicatorsValueStore.entryPrice = NaN
        }
      }

      const colorIndex = () => {
        if (marketposition.get(0) === 1) return 0
        else if (swingLong) return 1
        else if (swingShort) return 2
        else if (marketposition.get(0) === -1) return 3
        else return 4
      }

      return [dayTradeAvgPrice, buy_icon, short_icon, !symbol?.includes('-') ? colorIndex() : NaN]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 2,
          visible: true,
          linewidth: 1,
          plottype: 6,
          trackPrice: false,
          transparency: 30,
          color: '#5577ff',
        },
        plot_1: {
          color: '#dd5e56',
          textColor: '#dd5e56',
          transparency: 0,
          visible: true,
        },
        plot_2: {
          color: '#52a49a',
          textColor: '#52a49a',
          transparency: 0,
          visible: true,
        },
      },
    },
    styles: {
      plot_0: {
        title: '日成本線',
        histogramBase: 0,
      },
      plot_1: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單',
        title: '多單參考訊號',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單',
        title: '空單參考訊號',
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
