import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

//KD 順勢
export const pionex_strategy04 = createIndicator({
  id: 'pionex-strategy04',
  displayName: 'pionex_strategy04',

  //簡易波動率
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const target = 1.05

      const datetime = dayAPI(this.PineJS.Std.time(context))
      const close = this.ohlc.close
      const open = this.ohlc.open
      const high = this.ohlc.high
      const high_array = this.ohlc.highArray
      const low = this.ohlc.low
      const low_array = this.ohlc.lowArray

      const len = this._input(0)
      const in1 = this._input(1)
      const in2 = this._input(2)
      const c = this.PineJS.Std.close(this._context)
      const h = this.PineJS.Std.high(this._context)
      const l = this.PineJS.Std.low(this._context)

      const nc = this._context.new_var(c)
      const nh = this._context.new_var(h)
      const nl = this._context.new_var(l)

      const rsv = this.PineJS.Std.stoch(nc, nh, nl, len, this._context)

      const nrsv = this._context.new_var(rsv)
      const k = this.PineJS.Std.sma(nrsv, in1, this._context)
      const f = this._context.new_var(k)
      const d = this.PineJS.Std.sma(f, in2, this._context)

      f.get(1)

      const position = context.new_var()
      const entryPrice = context.new_var()
      const entryType = context.new_var()

      position.get(1)

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN
      // ---------------------------------------- Condition ----------------------------------------
      const longEntry = k > 65 && entryType.get(0) !== 1
      const shortEntry = k < 35 && entryType.get(0) !== -1

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entryPrice.set(close)
        entryType.set(1)
        buy_icon = 1
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close)
        entryType.set(-1)
        short_icon = 1
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        if (k < d && k > 80) {
          buy_target_icon = 1
          position.set(0)
        }

        if (close < entryPrice.get(0) * 0.995) {
          buy_exit_icon = 1
          position.set(0)
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        if (k > d && k < 20) {
          short_target_icon = 1
          position.set(0)
        }

        if (close > entryPrice.get(0) * 1.005) {
          short_exit_icon = 1
          position.set(0)
        }
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2
      }

      return [
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'shapes',
      },

      {
        id: 'entryShortFlag',
        type: 'shapes',
      },
      {
        id: 'targetLongFlag',
        type: 'shapes',
      },
      {
        id: 'targetShortFlag',
        type: 'shapes',
      },
      {
        id: 'exitLongFlag',
        type: 'shapes',
      },
      {
        id: 'exitShortFlag',
        type: 'shapes',
      },
      {
        id: '部位',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
        entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
        targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        targetShortFlag: {
          color: '#00b070',
          textColor: '#fafafa',
          transparency: 20,
          visible: true,
        },
        exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
        exitLongLine: { color: '#f04848', transparency: 20, visible: true, linestyle: 1 },
      },
      inputs: {
        in_0: 63,
        in_1: 14,
        in_2: 7,
      },
    },
    styles: {
      entryLongFlag: {
        title: '買進',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_triangle_up',
        size: 'small',
        text: 'Buy',
      },
      entryShortFlag: {
        title: '賣出',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_triangle_down',
        size: 'small',
        text: 'Short',
      },
      targetLongFlag: {
        title: '買進停利',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Target',
      },
      targetShortFlag: {
        title: '賣出停利',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Target',
      },
      exitLongFlag: {
        title: '買進出場',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Exit',
      },
      exitShortFlag: {
        title: '賣出出場',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          2: {
            color: '#b2b5be',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: 'RSV',
        defval: 63,
        type: 'integer',
        min: 1,
        max: 72,
        step: 1,
      },
      {
        id: 'in_1',
        name: 'K',
        defval: 14,
        type: 'integer',
        min: 1,
        max: 72,
        step: 1,
      },
      {
        id: 'in_2',
        name: 'D',
        defval: 7,
        type: 'integer',
        min: 1,
        max: 72,
        step: 1,
      },
    ],
  },
})
