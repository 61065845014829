import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { fontWeight400, fontWeight600 } from '~/css/font'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_main_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import useMedia from '~/hooks/useMedia'

export const Eurex_Footer = memo<ReactProps>(function Eurex_Footer() {
  const { isPc } = useMedia()
  return (
    <div>
      {isPc ? (
        <styleds.Footer>
          <EurexTradInfo />
          <EurexInfoLogo />
        </styleds.Footer>
      ) : (
        <styleds.FooterMobile>
          <EurexTradInfo css={fill_vertical_all_center} />
          <EurexInfoLogo />
        </styleds.FooterMobile>
      )}
    </div>
  )
})

const styleds = {
  Footer: styled.div`
    ${fill_horizontal_all_center};
    background-color: #1b1851;
    width: 100%;
    height: 320px;
    font-family: Replica, Arial, serif;
    -webkit-text-stroke: 0.3px #ffffff;
    padding: 0px 240px;
  `,
  FooterMobile: styled.div`
    ${fill_vertical_all_center};
    background-color: #1b1851;
    width: 100%;
    font-family: Replica, Arial, serif;
    -webkit-text-stroke: 0.3px #ffffff;
    padding: 16px 32px;
    gap: 80px;
  `,
}

const EurexInfoLogo = memo<ReactProps>(function EurexInfoLogo() {
  const containerCss = css`
    ${fill_vertical_all_center};
  `
  const memberCss = css`
    ${fill_vertical_all_center};
    ${fontWeight600};
    color: #ffffff;
  `
  const imageCss = css`
    object-fit: cover;
    width: 300px;
  `
  const copyrightCss = css`
    ${fill_horizontal_all_center};
    color: #ffffff;
  `

  return (
    <div css={containerCss}>
      <div css={memberCss}>A member of</div>
      <img
        css={imageCss}
        src='eurex/eurex.png'
      />
      <div css={copyrightCss}>
        <p>© 歐洲期貨交易所法蘭克福公司</p>
      </div>
    </div>
  )
})

const EurexTradInfo = memo<ReactProps>(function EurexTradInfo(props) {
  const container = css`
    ${fill_vertical_main_center};
    color: #ffffff;
    font-weight: 900;
  `
  const defaultTitleCss = css`
    color: #01ce7c;
    line-height: 1.0625rem;
    font-size: 16px;
    -webkit-text-stroke: 0.3px #01ce7c;
  `
  const h2TitleCss = css`
    cursor: pointer;
    line-height: 1;
    font-size: 36px;
  `
  const h2LinkCss = css`
    color: #ffffff;
    &:hover {
      color: #1ac676;
      -webkit-text-stroke: 0.3px #01ce7c;
    }
  `

  const buttonCss = css`
    ${fill_vertical_all_center};
    width: 110px;
    height: 44px;
    border: 1px solid #cccccc;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #1ac676;
      border: 1px solid #1ac676;
    }
  `
  return (
    <div
      css={container}
      className={props.className}
    >
      <div css={defaultTitleCss}>來自亞洲的好消息：</div>
      <h2 css={h2TitleCss}>
        <a
          css={h2LinkCss}
          href={'https://www.eurex.com/ex-en/find/tc/trading-hours-statistics'}
        >
          <div>國際化產品</div>
          <div>全天候交易</div>
        </a>
      </h2>
      <div
        css={css`
          margin-bottom: 1.6875rem;
        `}
      >
        不受時區限制，享受無限交易機會
      </div>
      <div css={buttonCss}>
        <a
          css={css`
            color: #ffffff;
          `}
          href={'https://www.eurex.com/ex-en/find/tc/trading-hours-statistics'}
        >
          了解更多信息
        </a>
      </div>
    </div>
  )
})
