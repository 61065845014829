import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler'
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { big3_initStrategies } from './big3_initStrategies'
import { big3_initStyling } from './big3_initStyling'
import { Big3_SidePane1 } from './big3_SidePane1'
import { Big3_SidePane2 } from './big3_SidePane2'
import { Big3_Topbar } from './big3_Topbar'

export const big3_init = (templateProps: TemplateProps) => {
  big3_initStyling(templateProps)
  big3_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')
  templateProps.layout.login = (
    <Preset_LoginPageOfCarousel resources={[{ image: [true, '/big3/background.png'] }]} />
  )

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TXAM-1',
    interval: '3',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderCompare: true,
    disabledGoToDate: true,
    disabledHeaderSaveload: true,
    overrides: {
      ...store.charting.darkOverrides,
    },
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `

  templateProps.layout.Row1 = Big3_Topbar
  templateProps.layout.Drawer1 = Big3_SidePane1
  templateProps.layout.Drawer2 = Big3_SidePane2
}
