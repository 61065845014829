import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { m168168_bandTrade } from '~/trades/indicators/m168168/m168168_bandTrade'
import { m168168_dealer } from '~/trades/indicators/m168168/m168168_dealer'
import { m168168_foreignerRatio } from '~/trades/indicators/m168168/m168168_foreigenrRatio'
import { m168168_foreigner } from '~/trades/indicators/m168168/m168168_foreigner'
import { m168168_kd } from '~/trades/indicators/m168168/m168168_kd'
import { m168168_trust } from '~/trades/indicators/m168168/m168168_trust'
import { m168168_volume } from '~/trades/indicators/m168168/m168168_volume'

export const m168168Stock_strategiesGroup = {
  main: [
    {
      displayName: '波段策略',
      indicators: [
        m168168_bandTrade,
        //m168168_foreignerRatio,
        m168168_volume,
        m168168_foreigner,
        m168168_trust,
        m168168_kd,
      ],
      symbol: '2330',
      interval: '1D',
      panesRatio: 60,
    },
    {
      displayName: '當沖策略',
      indicators: [m168168_volume],
      symbol: '2330',
      interval: '5',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const m168168Stock_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[m168168_volume, m168168_foreigner, m168168_trust, m168168_kd, m168168_foreignerRatio],
  ]
  store.charting.initStrategy({
    configs: [...m168168Stock_strategiesGroup.main],
  })
}
