import React, { memo, useMemo } from 'react'
import { css } from '@emotion/react'
import { useFirebaseValue } from '~/hooks/useFirebaseValue'
import { Tooltip, Fade, styled } from '@mui/material'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { createIPadCss } from '~/css/createIPadCss'
import { useSnapshot } from 'valtio'
import { trafficLightStore } from '~/pages/s178178/useTrafficLightStore'

type SignalValueNumber = {
  value: number
}

export const Light = memo<
  ReactProps<{ value: string; setState: string; enableColor: string; disableColor: string }>
>(function Light(props) {
  const displayColor = props.value === props.setState ? props.enableColor : props.disableColor
  const halo = props.value === props.setState ? props.enableColor : 'rgba(0, 0, 0, 0.4)'
  return (
    <div
      css={css`
        ${lightCss}
        background-color: ${displayColor};
        box-shadow: 0px 2px 9px 9px ${halo + '44'}, inset 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
      `}
    ></div>
  )
})

export const TrafficLight = memo(function TrafficLight(props: { titleName: string }) {
  const store = useSnapshot(trafficLightStore)
  const state = store.state
  return (
    <div css={trafficLightCss}>
      <div css={lightContainer}>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'紅燈'}
        >
          <Light
            enableColor='#ff3834'
            disableColor='#3D171C'
            value={state}
            setState={'red'}
          />
        </Tooltip>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'黃燈'}
        >
          <Light
            enableColor='#f2e812'
            disableColor='#4F3900'
            value={state}
            setState={'yellow'}
          />
        </Tooltip>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'綠燈'}
        >
          <Light
            enableColor='#5aff00'
            disableColor='#212B0D'
            value={state}
            setState={'green'}
          />
        </Tooltip>
      </div>
    </div>
  )
})

export const trafficLightCss = css`
  ${flex.v.crossCenter};
  width: 300px;
  height: 60px;
  margin: 5px 0px;
  border-radius: 5px;
  background-color: #343434;
  font-size: 16px;
  border: 2px solid ${'#666666'};
  box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.6);
  ${createMobileCss2(
    css`
      width: 100%;
    `,
  )};
  ${createIPadCss(
    css`
      width: 100%;
    `,
  )};
`
export const lightContainer = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  gap: 40px;
  border-radius: 5px;
`
export const lightCss = css`
  ${flex.h.allCenter};
  width: 40px;
  height: 40px;
  border: 1px solid ${'#666666'};
  border-radius: 50px;
  box-shadow: 5px 5px 7px 1px rgba(0, 0, 0, 0.4), inset 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
`
