import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

export const SignUp = memo<ReactProps>(function SignUp() {
  return (
    <styleds.container>
      <Item
        href='https://www.pionex.com/zh-TW/sign/ref/Z0d4ilgU?redirect=https%3A%2F%2Fwww.pionex.com%2Ffutures%2FBTC.PERP_USDT%2FManual'
        imgSrc='https://blog.pionex.com/wp-content/uploads/2023/03/terms-300x300.png'
        title='註冊享專屬服務'
      >
        立即註冊
      </Item>

      <Item
        href='https://line.me/ti/p/B5Ddu6GcBB'
        imgSrc='https://blog.pionex.com/wp-content/uploads/2023/03/location-1-300x300.png'
        title='「台幣」換「虛擬貨幣」窗口'
      >
        點擊兌換
      </Item>

      <Item
        href='https://line.me/ti/g2/-YTTW37FlH736kHEdudcwyO4wyVZW7G-kr9OYg?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'
        imgSrc='https://blog.pionex.com/wp-content/uploads/2023/03/customer-service-300x300.png'
        title='期天資訊專屬客服'
      >
        立即諮詢
      </Item>
    </styleds.container>
  )
})

const Item = memo<ReactProps<{ title: string; href: string; imgSrc: string }>>(function Item(
  props,
) {
  return (
    <styleds.item>
      <styleds.itemTitle>{props.title}</styleds.itemTitle>
      <div css={fill_horizontal_all_center}>
        <styleds.itemImage src={props.imgSrc}></styleds.itemImage>
      </div>

      <div css={fill_horizontal_all_center}>
        <styleds.linkButton
          href={props.href}
          target={'_blank'}
          rel='noreferrer'
        >
          {props.children}
        </styleds.linkButton>
      </div>
    </styleds.item>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    gap: 8px;
  `,
  item: styled.div`
    ${fill_vertical_all_center};
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 180px;
    border: 1px solid #e0e3ea;
    border-radius: 4px;
  `,
  itemTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    font-size: 15px;
    font-weight: bold;
  `,

  itemImage: styled.img`
    width: 68px;
    height: 68px;
  `,
  linkButton: styled.a`
    ${fill_horizontal_all_center};
    width: 168px;
    height: 36px;
    border-radius: 4px;
    background-color: #fe632d;
    color: #fff;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #fe8447;
    }
  `,
}
