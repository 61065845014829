import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const jerry597_futureSubChart = createIndicator({
  displayName: '金旺期貨短線指標',
  id: 'jerry597-futureIndicator',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const fastLength = this._input(0)
      const slowLength = this._input(1)
      const macdLength = this._input(2)
      const macd = this.macd(fastLength, slowLength, macdLength)
      const macdEmpty = this.macd(fastLength, slowLength, macdLength)
      const macd3 = this.macd(fastLength * 1.5, slowLength * 1.5, macdLength * 1.5)

      return [
        macd3.osc > 0 ? macd3.osc : NaN,
        macd3.osc < 0 ? macd3.osc : NaN,
        macd.osc > 0 ? macd.osc : NaN,
        macd.osc < 0 ? macd.osc : NaN,
        macdEmpty.osc * 0.95,
      ]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'positive2', type: 'line' },
      { id: 'negative2', type: 'line' },
      { id: 'positive', type: 'line' },
      { id: 'negative', type: 'line' },

      { id: 'empty', type: 'line' },
    ],

    defaults: {
      styles: {
        positive2: {
          linestyle: 0,
          linewidth: 4,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff0000',
        },
        negative2: {
          linestyle: 0,
          linewidth: 4,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#5ffa6d',
        },
        positive: {
          linestyle: 0,
          linewidth: 4,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff1744',
        },
        negative: {
          linestyle: 0,
          linewidth: 4,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#2196f3',
        },
        empty: {
          linestyle: 0,
          linewidth: 2,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#16161d',
        },
      },
      precision: 2,

      inputs: {
        Param1: 12,
        Param2: 26,
        Param3: 9,
      },
    },

    styles: {
      positive2: {
        title: 'OSC',
        histogramBase: 0,
        joinPoints: false,
      },
      negative2: {
        title: 'OSC',
        histogramBase: 0,
        joinPoints: false,
      },
      positive: {
        title: 'OSC',
        histogramBase: 0,
        joinPoints: false,
      },
      negative: {
        title: 'OSC',
        histogramBase: 0,
        joinPoints: false,
      },
      empty: {
        title: 'OSC',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [
      {
        id: 'Param1',
        name: 'Fast Length',
        defval: 12,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'Param2',
        name: 'Slow Length',
        defval: 26,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'Param3',
        name: 'MACD Length',
        defval: 9,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
