import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { s178178_initStrategies } from './s178178_initStrategies'
import { s178178_initStyling } from './s178178_initStyling'
import { S178178_SidePane1 } from './s178178_SidePane1'
import { S178178_SidePane2 } from './s178178_SidePane2'
import { Styleds } from './styleds'

export const s178178_init = (templateProps: TemplateProps) => {
  s178178_initStyling(templateProps)
  s178178_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  templateProps.layout.Charting = Styleds.Charting

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    custom_css_url: '../../s178178/chartingTheme.css',
  }

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    overrides: store.charting.darkOverrides,
  }

  templateProps.layout.Row1 = (
    <Preset_Topbar
      hideIfPcView
      showLeftBurger
      showRightBurger
      css={css`
        background-color: #333333;
      `}
    />
  )
  templateProps.layout.Drawer1 = S178178_SidePane1
  templateProps.layout.Drawer2 = S178178_SidePane2

  templateProps.layout.cssset = css`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `
}
