import dayAPI from '~/utils/dayAPI'
import { VirtualExchangeTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource'

export type MonthlyStatsticsItem = {
  month: string
  totalNumberOfTrades: number
  pnl: number
}

export interface GodPosition {
  side: 'long' | 'short'
  quantity: number
  averagePrice: number
  unrealizedPnL: number
}

export interface GodTrade {
  closeTime: string
  side: 'long' | 'short'
  openPrice: number
  closePrice: number
  pnl: number
}

export class GodPerformanceManager {
  openPositions: Array<GodPosition> = []
  closedTrades: Array<GodTrade> = []

  constructor(private readonly executions: VirtualExchangeTransaction[]) {
    executions.forEach(e => this._onOrderExecuted(e))
  }

  get monthlyStatistics(): Array<MonthlyStatsticsItem> {
    const monthlyStatistics: {
      [month: string]: MonthlyStatsticsItem
    } = {}

    this.closedTrades.forEach(trade => {
      const closeTime = dayAPI(trade.closeTime)
      const month = `${closeTime.year()}/${(closeTime.month() + 1).toLocaleString()}`

      if (!monthlyStatistics[month]) {
        monthlyStatistics[month] = {
          month: month,
          totalNumberOfTrades: 0,
          pnl: 0,
        }
      }
      monthlyStatistics[month].totalNumberOfTrades++
      monthlyStatistics[month].pnl += trade.pnl
    })
    return Object.values(monthlyStatistics)
  }

  getClosedTradesByDate(date: string): Array<GodTrade> {
    return this.closedTrades.filter(
      trade => dayAPI(trade.closeTime).format('MM-DD') === dayAPI(date).format('MM-DD'),
    )
  }

  _onOrderExecuted(execution: VirtualExchangeTransaction): void {
    if (execution.positionType === 'O') {
      this.openPositions.push({
        side: execution.bs === 'B' ? 'long' : 'short',
        quantity: execution.qty,
        averagePrice: execution.price,
        unrealizedPnL: 0,
      })
    } else if (execution.positionType === 'C') {
      let quantityToClose = execution.qty
      const BIG_POINT_VALUE = 200

      while (quantityToClose > 0) {
        const openPosition = this.openPositions[0]
        openPosition.quantity--

        this.closedTrades.push({
          closeTime: execution.datetime,
          side: openPosition.side,
          openPrice: openPosition.averagePrice,
          closePrice: execution.price,
          pnl:
            openPosition.side === 'long'
              ? (execution.price - openPosition.averagePrice) * BIG_POINT_VALUE
              : (openPosition.averagePrice - execution.price) * BIG_POINT_VALUE,
        })
        quantityToClose--
        if (openPosition.quantity === 0) this.openPositions.shift()
      }
    }
  }
}
