import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'

const symbols = [
  '2353',
  '2371',
  '6139',
  '2743',
  '5284',
  '4763',
  '2482',
  '9921',
  '2731',
  '8011',
  '1608',
]
const watchListGroup = 'datatree_group_1'
export const Rich888_SidePane1 = memo<ReactProps>(function Rich888_SidePane1() {
  const charting = useSnapshot(store).charting

  return (
    <styleds.container>
      <styleds.card height={'170px'}>
        <TitleSymbolQuote.Default symbol={charting.symbol} />
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={13}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={13}
        />
      </styleds.card>
      <styleds.card height={'calc(100% - 200px)'}>
        <SymbolPlatformBase.Display
          symbol={symbols}
          watchListGroup={watchListGroup}
        />
      </styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #ffffff;
    padding: 4px;
    gap: 6px;
  `,
  card: styled.div<{ height: number | string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${props => props.height};
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 8px 0px #dddddd;
  `,
}
