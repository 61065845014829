import { css } from '@emotion/react'
import { quoteBasicStyled } from '~/modules/investment-consultant/symbolList/css/quoteBasicStyled'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const pionex_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${(quoteBasicStyled.valueUpFill = css`
        &.light {
          color: #f04848;
        }
      `)} 
      ${(quoteBasicStyled.valueDnFill = css`
        &.light {
          color: #00b070;
        }
      `)} 
      }
    }
  `
}

store.charting.lightOverrides = {
  'mainSeriesProperties.candleStyle.borderDownColor': '#4ead75',
  'mainSeriesProperties.candleStyle.borderUpColor': '#de554f',
  'mainSeriesProperties.candleStyle.downColor': '#4ead75',
  'mainSeriesProperties.candleStyle.upColor': '#de554f',
  'mainSeriesProperties.candleStyle.wickDownColor': '#4ead75',
  'mainSeriesProperties.candleStyle.wickUpColor': '#de554f',
  'mainSeriesProperties.candleStyle.drawBorder': false,
  'mainSeriesProperties.showPriceLine': true,
  'mainSeriesProperties.showCountdown': true,
  'paneProperties.horzGridProperties.style': 1,
  'paneProperties.vertGridProperties.style': 1,
  'paneProperties.topMargin': 15,
  'paneProperties.bottomMargin': 15,
  'paneProperties.vertGridProperties.color': '#909297',
  'paneProperties.horzGridProperties.color': '#909297',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.background': '#fafafa',
  'timeScale.rightOffset': 0,
  'scalesProperties.fontSize': 12,
  'scalesProperties.textColor': '#909297',
  'scalesProperties.lineColor': '#909297',
}
