import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { wu5868_bband } from '~/trades/indicators/wu5868/wu5868_bband'
import { wu5868_volume } from '~/trades/indicators/wu5868/wu5868_volume'
import { store } from '../heineken_template/_private/store'

export const wu5868_strategiesDayTradeGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '指標',
      indicators: [wu5868_volume, wu5868_bband],
      symbol: '2330',
      interval: '1D',
      panesRatio: 66,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const wu5868_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded]

  store.charting.initStrategy({
    configs: [...wu5868_strategiesDayTradeGroup.main],
  })
}
