import { memo, useEffect, useMemo } from 'react'
import { useSignalrStore } from '../Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '../Signalr/useSignalrStoreValueOHLC'
import dayAPI from '~/utils/dayAPI'
/** 返回`價差:number`&`時間:string` 未來請yu chen 做後端的期現價差api */

export const usePercentDiffValue = (symbol1: string, symbol2: string) => {
  const symbolInfo1 = useSignalrStoreValueOHLC(state => state.value[symbol1])
  const symbolInfo2 = useSignalrStoreValueOHLC(state => state.value[symbol2])

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([symbol1, symbol2], 'ohlc')

    return () => {
      useSignalrStore.getState().subscribeRemove([symbol1, symbol2], 'ohlc')
    }
  }, [symbol1, symbol2])

  const changPercent1 = ((symbolInfo1?.close ?? 0) / (symbolInfo1?.prevRef ?? 0) - 1) * 100
  const changPercent2 = ((symbolInfo2?.close ?? 0) / (symbolInfo2?.prevRef ?? 0) - 1) * 100
  const value1 = Math.round(changPercent1 * 10) / 10
  const value2 = Math.round(changPercent2 * 10) / 10

  const time = symbolInfo1?.datetime ?? ''
  const timeValue = new Date(time).toLocaleTimeString([], {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })

  return {
    changPercent1: value1,
    changPercent2: value2,
    changPercentUpdateTime: timeValue,
  }
}
