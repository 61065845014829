import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  fill,
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { Charting } from '~/pages/heineken_template/_private/Charting'

export const styleds = {
  SideBar: styled.div`
    ${fill_vertical_cross_center}
    background-color: #1a1a1a;
    gap: 4px;
    padding: 4px;
  `,
  ScreenerContent: styled.div`
    ${fill_vertical_cross_center}
    width: 100%;
    height: calc(100% - 160px);
    gap: 4px;
  `,
  ScreenerList: styled.div`
    width: 100%;
    height: calc(50% - 36px);
  `,
  ScreenerTitle: styled.div<{ variant: 'buy' | 'short' }>`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 28px;
    border-radius: 7px;
    ${options => {
      const buyCssset =
        options.variant === 'buy' &&
        css`
          background-color: #d3483e;
        `
      const shortCssset =
        options.variant === 'short' &&
        css`
          background-color: #7bb972;
        `
      return css([buyCssset, shortCssset])
    }}
  `,
  ScreenerDateString: styled.div`
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background-color: #333333;
    border-radius: 5px;
    font-size: 16px;
  `,
  DataChartContent: styled.div`
    ${fill_vertical_all_center}
    width: 100%;
    height: 80px;
    background-color: #333333;
    border-radius: 5px;
    padding: 4px;
  `,

  TrendChartContent: styled.div`
    width: 100%;
    height: 216px;
  `,

  WatchToggleButton: styled.div`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 28px;
    gap: 4px;
  `,
  WatchListTitle: styled.div`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    gap: 4px;
  `,
  WatchListTypeBtton: styled.div<{ variant: 'long' | 'short'; active: boolean }>`
    ${fill_horizontal_all_center}
    width: 140px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;

    ${options => {
      const longDefault = options.variant === 'long' && options.active === false
      const longActive = options.variant === 'long' && options.active === true
      const shortDefault = options.variant === 'short' && options.active === false
      const shortActive = options.variant === 'short' && options.active === true

      const longCssset =
        longDefault &&
        css`
          background-color: #8e4947;
          border: 1px solid #555555;
          color: #cccccc;
        `
      const longActiveCssset =
        longActive &&
        css`
          background-color: #c35245;
          border: 1px solid #eeeeee;
        `
      const shortCssset =
        shortDefault &&
        css`
          background-color: #5b974e;
          border: 1px solid #555555;
          color: #cccccc;
        `
      const shortActiveCssset =
        shortActive &&
        css`
          background-color: #7bb972;
          border: 1px solid #eeeeee;
        `
      return css([longCssset, longActiveCssset, shortCssset, shortActiveCssset])
    }}
  `,

  WatchListContent: styled.div<{ variant: 'long' | 'short' }>`
    width: 100%;
    height: calc(100% - 324px);
    ${options => {
      const long = options.variant === 'long'
      const short = options.variant === 'short'
      const longCssset =
        long &&
        css`
          background-color: #2a1a1aaa;
        `
      const shortCssset =
        short &&
        css`
          background-color: #1a2a1aaa;
        `

      return css([longCssset, shortCssset])
    }}
  `,

  Charting: function () {
    return (
      <div
        css={css`
          height: 100%;
          padding: 4px;
          border-radius: 5px;
        `}
      >
        <Charting />
      </div>
    )
  },
}
