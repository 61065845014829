import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { big3_analyzer } from '~/trades/indicators/big3/big3_analyzer'
import { big3_bandDc } from '~/trades/indicators/big3/big3_bandDc'
import { big3_dc } from '~/trades/indicators/big3/big3_dc'
import { big3_stockDayTrade } from '~/trades/indicators/big3/big3_stockDayTrade'
import { big3_stockPower } from '~/trades/indicators/big3/big3_stockPower'
import { big3_stockTrade } from '~/trades/indicators/big3/big3_stockTrade'
import { big3_priceLine } from '~/trades/indicators/big3/bog3_priceLine'

export const bandTradeIndicators = [
  big3_stockTrade,
  big3_dc,
  big3_bandDc,
  big3_priceLine,
  big3_stockPower,
  big3_analyzer,
]
export const dayTradeIndicators = [big3_stockDayTrade, big3_dc, big3_stockPower, big3_analyzer]

export const big3Stock_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '動能波段訊號',
      indicators: bandTradeIndicators,
      symbol: '2330',
      interval: '1D',
      panesRatio: 60,
    },
    {
      displayName: '強勢當沖訊號',
      indicators: dayTradeIndicators,
      symbol: '2330',
      interval: '3',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const big3Stock_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[
      big3_stockDayTrade,
      big3_stockTrade,
      big3_dc,
      big3_bandDc,
      big3_stockPower,
      big3_priceLine,
      big3_analyzer,
    ],
  ]

  store.charting.initStrategy({
    configs: [...big3Stock_strategiesGroup.main],
  })
}
