import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const ao1 = createIndicator({
  id: 'ao1',
  displayName: '短線指標',
  enabledOn(symbol, data, channel) {
    return (
      symbol.includes('-') || data?.type === channel.os_futures || data?.type === channel.tw_futures
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const f_0 = function (e: any, t: any) {
        return e - t
      }
      const f_1 = (e: any) => {
        return this.PineJS.Std.le(e, 0) ? 0 : 1
      }

      const i = this.PineJS.Std.hl2(this._context)
      const o = this._context.new_var(i)
      const r = this.PineJS.Std.sma(o, 5, this._context)
      const s = this._context.new_var(i)
      const a = this.PineJS.Std.sma(s, 34, this._context)
      const c = f_0(r, a)
      const u = this._context.new_var(c)
      const h = this.PineJS.Std.change(u)
      return [c, f_1(h), 15, -15]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    scriptIdPart: '',
    is_price_study: !1,
    is_hidden_study: !1,
    isCustomIndicator: true,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 4,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#000080',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#ffaa22',
        },
        plot_3: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#00eeee',
        },
      },
      precision: 2,
      palettes: {
        palette_0: {
          colors: {
            0: {
              color: '#008000',
              width: 2,
              style: 0,
            },
            1: {
              color: '#FF0000',
              width: 2,
              style: 0,
            },
          },
        },
      },
      inputs: {},
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    palettes: {
      palette_0: {
        colors: {
          0: {
            name: 'Color 0',
          },
          1: {
            name: 'Color 1',
          },
        },
      },
    },
    inputs: [],
  },
})
