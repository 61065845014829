import { Socket } from '~/modules/SDK/socket2/Socket'
import React, { memo, useEffect } from 'react'
import { css } from '@emotion/react'
import SymbolRankingHeader from '~/modules/investment-consultant/symbolList/header/SymbolRankingHeader'
import SymbolRankingContent from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'
import { SymbolRankingSpectrum } from '~/modules/screener/components/invesement-consultant/SymbolChangeRanking/SymbolRankingSpectrum'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { sortSymbolByValue } from '~/pages/lung88988_stock/modules/sortSymbolByValue'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { CheckTrendMode } from '~/modules/screener/components/invesement-consultant/SymbolChangeRanking/useCheckPriceState'
import { generateScrollbar } from '~/css/scrollbarCss'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol'
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators'
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn'
import { useSnapshot } from 'valtio'
import { store } from '~/pages/heineken_template/_private/store'

const contianer = css`
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  ${generateScrollbar('#454545', '#bbbbbb')}
`

export const SymbolChangeRanking = memo<
  ReactProps<{
    symbolNumber: Socket.SymbolString[]
    useChart: ChartTypes.ChartingV2OrV4
    indicators?: ChartTypes.Indicator[]
    /** 是否啟用趨勢過濾 */
    checkTrend?: boolean
    /** 過濾種類 */
    checkTrendMode?: CheckTrendMode
    /** 擷取4個時間點的價格 */
    countArray: [number, number, number, number]
    handleClick?: React.MouseEventHandler<HTMLDivElement>
  }>
>(function SymbolChangeRanking(props) {
  const currentSymbol = useSnapshot(store.charting).symbol
  const changeIndicators = useChartingChangeIndicatorsFn(props.useChart)
  const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.useChart)

  const value = useSignalrStoreValueOHLC(state => state.value)

  const priceValue: keyof Signalr.ValueOfOHLC = 'volume'
  const items = sortSymbolByValue(value, props.symbolNumber, priceValue)

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(props.symbolNumber || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(props.symbolNumber || [])], 'ohlc')
    }
  }, [props.symbolNumber])

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <SymbolRankingSpectrum />
      <SymbolRankingHeader.Standard countArray={props.countArray} />
      <div css={contianer}>
        {items.map(datum => {
          const symbol = datum as Signalr.SymbolString

          return (
            <div
              key={datum}
              onClick={event => {
                props.handleClick?.(event)

                if (currentSymbol !== symbol) {
                  changeSymbolInterval({ symbol: symbol })
                }

                if (props.indicators) {
                  changeIndicators(props.indicators)
                }
              }}
            >
              <SymbolRankingContent.standard.Display
                currentSymbol={currentSymbol}
                symbol={datum}
                checkTrendMode={props.checkTrendMode}
                countArray={props.countArray}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
})
