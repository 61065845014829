import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { winner98_initStrategies } from '../winner98/_private/winner98_initStrategies'
import { winner98_initStyling } from '../winner98/_private/winner98_initStyling'
import { Winner98_crypto_Charting } from './winner98_crypto_Charting'
import { Winner98_crypto_Topbar } from './winner98_crypto_Tipbar'

export const winner98_crypto_init = (templateProps: TemplateProps) => {
  winner98_initStyling(templateProps)
  winner98_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'BTCUSDT',
    interval: '5',
    custom_css_url: '../../winner/winner98/chartingTheme.css',
    overrides: store.charting.darkOverrides,
  }

  templateProps.layout.Charting = Winner98_crypto_Charting

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr 336px;
    user-select: none;
    grid-template-areas:
      'Row1 Row1'
      'Chart Chart'
      'Chart Chart';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `
  templateProps.layout.Row1 = Winner98_crypto_Topbar
}
