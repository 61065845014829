import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import {
  usePercentRangeResource,
  useTurnoverRateResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { staticStore } from '../heineken_template/_private/staticStore'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import { css } from '@emotion/react'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import QuoteDetail from '../win988/component/QuoteDetail'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton'

/** 自選股 */
const watchListGroupString = 'wu5868_stock_group_1'

export const Wu5868_SidePane1 = memo<ReactProps>(function Wu5868_SidePane1(props) {
  const charting = useSnapshot(store.charting)
  const lastTradeDate = useSnapshot(staticStore).tradedDate.day0

  return (
    <styleds.container>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 30px;
          background-color: #dddddd;
          border-radius: 4px;
        `}
      >
        <TitleSymbolQuote.Default symbol={charting.symbol} />
      </div>

      <div
        css={css`
          width: 100%;
          height: 156px;
        `}
      >
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: 140px;
        `}
      >
        <QuoteDetail.Display symbol={[charting.symbol]} />
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 40% 60%;
          width: 100%;
          height: 32px;
          padding: 2px;
          border-top: 1px solid #aaaaaa;
        `}
      >
        <div css={fill_vertical_all_center}>自選股狀態</div>
        <AddSymbolButtonSmall
          groupName={watchListGroupString}
          symbolNumber={charting.symbol}
          listSize={50}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 336px);
        `}
      >
        <WatchListWithChart
          groupName={watchListGroupString}
          useChart={store.charting}
        />
      </div>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    padding: 8px;
    gap: 2px;
    border-right: 1px solid #aaaaaa;
  `,
  card: styled.div<{ height: number | string }>`
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #fafafa;
    border-radius: 8px;
  `,
}
