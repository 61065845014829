import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stock_hma1 = createIndicator({
  id: 'stockhma1',
  displayName: '空間預知線',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const in_0 = 55

      const interval = this.PineJS.Std.interval(this._context)

      const i = this.PineJS.Std.close(this._context)
      const o = in_0
      const r = in_0 / 2
      const s = this._context.new_var(i)
      const a = this.PineJS.Std.wma(s, r, this._context)
      const l = this._context.new_var(i)
      const c = this.PineJS.Std.wma(l, o, this._context)
      const u = 2 * a - c
      const h = this.PineJS.Std.sqrt(o)
      const d = this.PineJS.Std.round(h)
      const p = this._context.new_var(u)
      return [this.PineJS.Std.wma(p, d, this._context)]
    },
  },
  metainfo: {
    visible: false,
    _metainfoVersion: 27,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 4,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#0000ff',
        },
      },
      precision: 4,
      inputs: {},
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: 'length',
        defval: 55,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
    ],
    is_price_study: !0,
    scriptIdPart: '',
  },
})
