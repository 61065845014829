import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stock_sma1 = createIndicator({
  id: 'stocksm1',
  displayName: '動能感知量',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const i = 27 //this._input(0)
      const o = 33 //this._input(1)
      const r = 55 //this._input(2)

      const s = this.PineJS.Std.close(this._context)
      const a = this._context.new_var(s)
      const l = this.PineJS.Std.tsi(a, i, o, this._context)
      const c = this._context.new_var(l)
      const u = this.PineJS.Std.ema(c, r, this._context)

      let up = NaN
      let un = NaN
      if (l - u >= 0) {
        up = l - u
        un = NaN
      } else {
        up = NaN
        un = l - u
      }
      let lp = NaN
      let ln = NaN
      if (l >= 0) {
        lp = l
        ln = NaN
      } else {
        lp = NaN
        ln = l
      }
      return [lp, ln, u, up, un]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 8,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#ff0700',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 8,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#2196f3',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 4,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#10131c',
        },
        plot_3: {
          linestyle: 0,
          linewidth: 2,
          plottype: 1,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#ff5095',
        },
        plot_4: {
          linestyle: 0,
          linewidth: 2,
          plottype: 1,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#b3daff',
        },
      },
      precision: 2,
      inputs: {
        /*in_0: 27,
        in_1: 33,
        in_2: 55,*/
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'line',
      },
      {
        id: 'plot_4',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Indicator',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Signal',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        title: 'Oscillator',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_3: {
        title: 'Oscillator',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_4: {
        title: 'Oscillator',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: 'shortlen',
        defval: 5,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
      {
        id: 'in_1',
        name: 'longlen',
        defval: 20,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
      {
        id: 'in_2',
        name: 'siglen',
        defval: 5,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
    ],
    is_price_study: !1,
    scriptIdPart: '',
  },
})
