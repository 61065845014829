import { proxy } from 'valtio'
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2'

export const gorich55688_store = proxy({
  marketStatus: '' as
    | '一點高盤'
    | '二點高盤'
    | '轉折二高盤'
    | '三點高盤'
    | '一點低盤'
    | '二點低盤'
    | '轉折二低盤'
    | '三點低盤',

  screenerTabs: new FuiTabs2(['強勢整理股', '籌碼優勢股']),
})
