import { memo, useEffect } from 'react'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { AvailableStrategySelect } from '~/pages/strategy_center/_private/StrategyModuleRelated/AvailableStrategySelect'

const StrategyModuleRelated = memo(function StrategyModuleRelated() {
  const localAuthValue = globalThis.localStorage?.getItem(
    'futuresai.futuresai.strategy-center-auth',
  )
  const globalAuthValue = useTradeStore(s => s.strategyAuth)
  const strategies = useTradeStore(s => s.strategies)
  const currentStrategyName = useTradeStore(s => s.currentStrategyName)
  if (localAuthValue && !globalAuthValue) useTradeStore.setState({ strategyAuth: localAuthValue })

  useEffect(() => {
    if (!currentStrategyName && strategies.length)
      useTradeStore.setState({
        currentSelectAccountId: strategies[0].id,
        currentSelectAccountBalance: strategies[0].balance,
      })
  }, [strategies, currentStrategyName])

  return (
    <AvailableStrategySelect
      strategies={strategies}
      changeDelegate={event => {
        useTradeStore.setState({ currentStrategyName: event.target.value })
      }}
      value={currentStrategyName}
    />
  )
})

export default StrategyModuleRelated
