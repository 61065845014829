import { css } from '@emotion/react'
import { memo } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import RadialSeparators from '~/pages/daddy960_tc1688/component/RadialSeparators'

export const Speedometers = memo<ReactProps<{ value: number }>>(function Gauge(props) {
  const fill = props.value > 0 ? '#d23f38' : props.value < 0 ? '#4fc33b' : '#7e7e7e'
  return (
    <CircularProgressbarWithChildren
      value={Math.abs(props.value)}
      text={`${props.value}%`}
      strokeWidth={10}
      styles={buildStyles({
        strokeLinecap: 'butt',
        rotation: 4 / 8,
        pathColor: fill,
        textColor: fill,
        trailColor: '#49525a',
      })}
      css={css`
        .CircularProgressbar-path {
          filter: drop-shadow(0px 0px 0px ${fill});
        }
      `}
    >
      <RadialSeparators
        count={10}
        style={{
          background: '#1d1e29',
          width: '2px',
          height: `${12}%`,
        }}
      />
    </CircularProgressbarWithChildren>
  )
})

//b
