import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'

export const signal = createIndicator({
  displayName: 'SUPERSTOCK',
  id: 'lung88988signal',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      
      const resolution = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W

      const positionType = useChartPositionStore.positionType
      const open = this.ohlc.open
      const high = this.ohlc.high
      const low = this.ohlc.low
      const close = this.ohlc.close

      const open_array = this.ohlc.openArray
      const high_array = this.ohlc.highArray
      const low_array = this.ohlc.lowArray
      const close_array = this.ohlc.closeArray

      const length = 60
      const length_ma = 60

      const dc = this.ladderChannl(length)
      const ma = this.PineJS.Std.sma(close_array, length_ma, this._context)
      const ma_array = this._context.new_var(ma)

      const dmi = this.dmi(20, 14)
      const di_long = dmi[0]
      const di_short = dmi[1]
      const adx = dmi[2]
      const di_long_array = this._context.new_var(di_long)
      const di_short_array = this._context.new_var(di_short)
      const long = this.PineJS.Std.sma(di_long_array, 1, this._context)
      const short = this.PineJS.Std.sma(di_short_array, 1, this._context)
      const sma = this.PineJS.Std.sma(close_array, 120, this._context)

      //符號變數
      let buy_icon = NaN
      let buy_exit_icon1 = NaN
      let buy_exit_icon2 = NaN
      let short_icon = NaN
      let short_exit_icon1 = NaN
      let short_exit_icon2 = NaN
      //部位變數
      /** 部位 */
      const marketposition = this._context.new_var()
      /** 停利階段 */
      const pvflag = this._context.new_var()
      /** 進場價 */
      const entryPrice = this._context.new_var()

      ma_array.get(1)
      close_array.get(1)
      high_array.get(1)
      low_array.get(1)

      marketposition.get(0)
      marketposition.get(1)

      if (resolution === '1D') {
        const targetValue = (l1: number, l2: number, l3: number, l4: number, l5: number) => {
          if (close < 10) return l1
          if (close < 50) return l2
          if (close < 100) return l3
          if (close < 500) return l4
          if (close < 5000) return l5
        }

        const target1 = targetValue(0.05, 0.05, 0.06, 0.06, 0.06) ?? 0.02
        const target2 = targetValue(0.24, 0.24, 0.25, 0.25, 0.25) ?? 0.05

        //條件
        /** Entry condition */
        const buy_condition = isNaN(dc[1]) && long > short && long > adx && close > sma

        const short_condition = isNaN(dc[0]) && long < short && short > adx && close < sma

        //部位變1
        if (positionType !== 'short' && buy_condition) {
          marketposition.set(1)
        }
        if (positionType !== 'long' && short_condition) {
          marketposition.set(-1)
        }

        const sar_long = this.sarLongStopLoss('nonLinear', 0, marketposition, 20, 3, 0.0075, 0.5)
        const sar_short = this.sarShortStopLoss('nonLinear', 0, marketposition, 20, 1.5, 0.035, 0.5)

        const marketposition_buy = marketposition.get(0) === 1 && marketposition.get(1) === 1
        const marketposition_short = marketposition.get(0) === -1 && marketposition.get(1) === -1

        const buy_target_condition1 =
          pvflag.get(0) === 0 && marketposition_buy && high > entryPrice.get(0) * (1 + target1)
        const buy_target_condition2 =
          pvflag.get(0) === 1 && marketposition_buy && high > entryPrice.get(0) * (1 + target2)
        const buy_target_condition3 =
          pvflag.get(0) > 0 &&
          marketposition_buy &&
          close < sar_long.get(0) &&
          low > entryPrice.get(0)
        const buy_target_condition4 = close < sar_long.get(0)

        const short_target_condition1 =
          pvflag.get(0) === 0 && marketposition_short && low < entryPrice.get(0) * (1 - target1)
        const short_target_condition2 =
          pvflag.get(0) === 1 && marketposition_short && low < entryPrice.get(0) * (1 - target2)
        const short_target_condition3 =
          pvflag.get(0) > 0 &&
          marketposition_short &&
          close > sar_short.get(0) &&
          high < entryPrice.get(0)
        const short_target_condition4 = close > sar_short.get(0)

        //進場-----------------------------------------------
        //顯示符號
        if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
          entryPrice.set(close)
          buy_icon = 1
          pvflag.set(0)
        }
        if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
          entryPrice.set(close)
          short_icon = 1
          pvflag.set(0)
        }
        //出場
        if (buy_target_condition1) {
          buy_exit_icon1 = 1
          pvflag.set(1)
        }
        if (buy_target_condition2) {
          buy_exit_icon1 = 1
          pvflag.set(2)
        }
        if (buy_target_condition3) {
          buy_exit_icon2 = 1
          pvflag.set(2)
          marketposition.set(0)
        }
        if (buy_target_condition4) {
          marketposition.set(0)
        }
        //出場
        if (short_target_condition1) {
          short_exit_icon1 = 1
          pvflag.set(1)
        }
        if (short_target_condition2) {
          short_exit_icon1 = 1
          pvflag.set(2)
        }
        if (short_target_condition3) {
          short_exit_icon2 = 1
          pvflag.set(2)
          marketposition.set(0)
        }
        if (short_target_condition4) {
          marketposition.set(0)
        }

        const colorIndex = marketposition.get(0) > 0 ? 0 : marketposition.get(0) === 0 ? 2 : 1

        return [
          buy_icon,
          short_icon,
          buy_exit_icon1,
          buy_exit_icon2,
          short_exit_icon1,
          short_exit_icon2,
          colorIndex,
        ]
      }
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00ff00',
        },
        plot_2: {
          color: '#ffaa22',
          textColor: '#ffaa22',
          transparency: 10,
          visible: true,
        },
        plot_3: {
          color: '#ffaa22',
          textColor: '#ffaa22',
          transparency: 10,
          visible: true,
        },
        plot_4: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 10,
          visible: true,
        },
        plot_5: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 10,
          visible: true,
        },
      },
      precision: 4,
      inputs: {
        // in_0: 60,
        // in_1: 60,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'chars',
      },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_8',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#D82235',
            width: 1,
            style: 0,
          },
          1: {
            color: '#22D835',
            width: 1,
            style: 0,
          },
          2: {
            color: '#BDBDBD',
            width: 1,
            style: 0,
          },
        },
      },
    },
    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多訊',
        title: '多訊',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空訊',
        title: '空訊',
      },
      plot_2: {
        isHidden: false,
        location: 'AboveBar',
        char: '☆',
        size: 'small',
        title: '多單短停利',
        text: '短停利',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '多單出場',
        text: '出場',
      },
      plot_4: {
        isHidden: false,
        location: 'BelowBar',
        char: '☆',
        size: 'small',
        title: '空單短停利',
        text: '短停利',
      },
      plot_5: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '空單出場',
        text: '出場',
      },
      plot_8: {
        title: '紫金',
        histogramBase: 0,
      },
    },

    is_price_study: !0,
    inputs: [
      // {
      //   id: 'in_0',
      //   name: '階梯length',
      //   defval: 60,
      //   type: 'integer',
      //   min: 1,
      //   max: 2e3,
      // },
      // {
      //   id: 'in_1',
      //   name: '均線length',
      //   defval: 60,
      //   type: 'integer',
      //   min: 1,
      //   max: 2e3,
      // },
    ],
    scriptIdPart: '',
  },
})
