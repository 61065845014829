import { css } from '@emotion/react'
import { memo, useEffect } from 'react'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import { store } from '~/pages/heineken_template/_private/store'
import { useSnapshot } from 'valtio'
import IntradayTrendChart from '~/modules/trendChart'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState'
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import { WatchSymbolToggleSingleButton } from '../heineken_template/_col/col_WatchSymbolToggleSingleButton'
import { yaya168Stock_store } from './yaya168Stock_store'
import GaugeChart from 'react-gauge-chart'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { styleds } from './styleds'

export const Yaya168Stock_SidePane1 = memo<ReactProps>(function Yaya168Stock_SidePane1() {
  const symbolName = useSnapshot(store.charting).symbol
  const state = useSnapshot(yaya168Stock_store)

  const ratio = Math.abs(state.bsValue / state.volume)
  const bsState = state.bsValue > state.bsMaValue * 2
  const important = ratio > 0.2 || bsState
  const bsDisplay = state.bsValue > 10 ? '多方' : state.bsValue < -10 ? '空方' : '盤整'
  const bsColor = state.bsValue > 10 ? '#fe0013' : state.bsValue < -10 ? '#1ab926' : '#4b5b6b'

  return (
    <styleds.SideBar>
      <TitleSymbolQuote.Default symbol={symbolName} />
      <styleds.TrendChartBoard>
        <IntradayTrendChart
          symbol={symbolName}
          ticksSize={12}
          ticksHeight={20}
          priceTicksMargin={-10}
          priceTicksSize={11}
        />
      </styleds.TrendChartBoard>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          ${jc.spaceAround};
          height: 28px;
        `}
      >
        <div
          css={css`
            width: 50%;
          `}
        >
          新增自選股
        </div>
        <WatchSymbolToggleSingleButton
          groupName='yaya168_stock_group_1'
          describeText={symbolName}
        />
      </div>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 64px;
          gap: 32px;
        `}
      >
        <TrendState state={state.trendState}>近期多空主導</TrendState>
        <TrendState state={state.trendLeader}>近期動能領導</TrendState>
      </div>

      <styleds.Board>
        <styleds.BoardTitle>乖離指標</styleds.BoardTitle>
        <Gauge />
      </styleds.Board>
      <styleds.Board>
        <styleds.BoardTitle>盤中主力動向</styleds.BoardTitle>
        <div
          css={css`
            ${fill_horizontal_all_center};
            width: 104px;
            height: 48px;
            font-size: 22px;
            background-color: ${bsColor};
            border-radius: 5px;
            margin: 12px 0px;
          `}
        >
          {bsDisplay}
        </div>
        <div>佔成交比重: {(ratio * 100).toFixed(2)}%</div>
      </styleds.Board>
      <IndexBoard />
      <useStockPriceChangeDistributionState.Provider>
        <DataChart />
      </useStockPriceChangeDistributionState.Provider>
    </styleds.SideBar>
  )
})

export const IndexBoard = memo<ReactProps>(function IndexBoard() {
  const symbolList = ['TSEA', 'OTCA', 'TSE13', 'TSE17']

  useEffect(() => {
    signalrStore2.addQuote(symbolList)
    return () => {
      signalrStore2.removeQuote(symbolList)
    }
  }, [JSON.stringify(symbolList)])

  const value = signalrHooks2.useQuotes(symbolList)

  const change = value.map(
    data => processQuoteToReadable(data as Signalr.ValueOfOHLC).closeChangePercent,
  )
  const maxChange = Math.max(...change.map(a => Math.abs(a)))

  return (
    <styleds.Board>
      <styleds.BoardTitle>重點指數</styleds.BoardTitle>
      <div
        css={css`
          ${fill_horizontal_all_center};
          ${jc.spaceAround};
          height: calc(100% - 28px);
          padding: 2px;
        `}
      >
        {symbolList.map(data => (
          <IndexChange
            symbol={data}
            maxHeight={maxChange}
            key={data}
          />
        ))}
      </div>
    </styleds.Board>
  )
})

export const IndexChange = memo<ReactProps<{ symbol: string; maxHeight: number }>>(
  function IndexChange(props) {
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]
    const changePercent = processQuoteToReadable(quote as Signalr.ValueOfOHLC).closeChangePercent

    // useEffect(() => {
    //   signalrStore2.addQuote(props.symbol)
    //   console.log('~~~')
    //   return () => {
    //     signalrStore2.removeQuote(props.symbol)
    //   }
    // }, [JSON.stringify(props.symbol)])

    const dictionary = useSymbolDictionaryStore(s => s.dictionary)

    const changeColor = changePercent < 0 ? '#00aa00' : '#ff0000'
    return (
      <div
        css={css`
          ${fill_vertical_cross_center};
          width: 32px;
          justify-content: end;
          font-size: 12px;
        `}
      >
        <div
          css={css`
            width: 32px;
            height: ${Math.abs((changePercent / props.maxHeight) * 33)}%;
            background-color: ${changeColor};
            padding: 1px;
          `}
        />
        {dictionary[props.symbol ?? ''].slice(0, 2)}
        <div
          css={css`
            color: ${changeColor};
          `}
        >
          <div>{changePercent}%</div>
        </div>
      </div>
    )
  },
)

export const TrendState = memo<ReactProps<{ state: string }>>(function TrendState(props) {
  const color = () => {
    if (props.state.includes('多')) {
      return '#cc2200'
    }
    if (props.state.includes('空')) {
      return '#00cc22'
    } else return '#555555'
  }
  return (
    <div>
      {props.children}
      <div
        css={css`
          ${fill_horizontal_all_center};
          width: 100px;
          height: 28px;
          background-color: ${color()};
          border-radius: 6px;
        `}
      >
        {props.state}
      </div>
    </div>
  )
})

export const DataChart = memo(function DataChart_() {
  const {
    state: { overallCount, fallCount, flatCount, riseCount, vBar },
  } = useStockPriceChangeDistributionState.useContainer()

  const up = riseCount / overallCount
  const dn = fallCount / overallCount
  const flat = flatCount / overallCount

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        max-height: 148px;
        min-height: 148px;
        width: 100%;
        font-size: 14px;
        gap: 8px;
        background: linear-gradient(32deg, #34374099, #32333699);
        border-radius: 5px;
        padding: 4px;
      `}
    >
      <div
        css={css`
          ${fill_horizontal_all_center};
          width: 80%;
          height: 24px;
          background-color: #1a1e28;
          border-radius: 5px;
        `}
      >
        市場漲跌變化
      </div>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: calc(100% - 32px);
          gap: 8px;
        `}
      >
        <div
          css={css`
            width: 33%;
            height: 104px;
          `}
        >
          <CircularProgressbar
            value={up * 100}
            text={`上漲:${riseCount}`}
            strokeWidth={12}
            styles={buildStyles({
              textSize: 16,
              strokeLinecap: 'butt',
              pathColor: '#fd0013',
              trailColor: '#556677',
              textColor: '#eeeeee',
            })}
          />
        </div>
        <div
          css={css`
            width: 33%;
            height: 104px;
          `}
        >
          <CircularProgressbar
            value={dn * 100}
            text={`下跌:${fallCount}`}
            strokeWidth={12}
            styles={buildStyles({
              textSize: 16,
              strokeLinecap: 'butt',
              pathColor: '#169f21',
              trailColor: '#556677',
              textColor: '#eeeeee',
            })}
          />
        </div>
        <div
          css={css`
            width: 33%;
            height: 104px;
          `}
        >
          <CircularProgressbar
            value={flat * 100}
            text={`平盤:${flatCount}`}
            strokeWidth={12}
            styles={buildStyles({
              textSize: 16,
              strokeLinecap: 'butt',
              pathColor: '#feb12a',
              trailColor: '#556677',
              textColor: '#eeeeee',
            })}
          />
        </div>
      </div>
    </div>
  )
})

const Gauge = memo<ReactProps>(function Gauge() {
  const rsi = useSnapshot(yaya168Stock_store)
  const gaugeValue = !isNaN(rsi.bisa) ? rsi.bisa / 100 : 0
  return (
    <div
      css={css`
        width: 72%;
        height: 72%;
      `}
    >
      <GaugeChart
        id={'chart'}
        percent={gaugeValue}
        formatTextValue={() => ''}
        nrOfLevels={100}
        textColor='transparent'
        arcPadding={0.0}
        cornerRadius={0}
        arcWidth={0.15}
        needleBaseColor='#cccccc'
        needleColor='#cccccc'
        colors={['#46a930', '#93cd77', '#CFD8DC', '#f17275', '#cd2524']}
        arcsLength={[0.075, 0.075, 0.075, 0.075, 0.075]}
      />
    </div>
  )
})
