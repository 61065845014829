/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const lrc0 = createIndicator({
  id: 'lrc0',
  displayName: '力道線',
  // enabledOn(symbol, data, channel) {
  //   return data?.type === channel.tw_futures || data?.type === channel.os_futures
  // },
  constructorScope: {
    init(context, inputCallback) {
      return
    },

    main(context, inputCallback) {
      const linregSlope = function (iclose: any, in1: number, in2: number) {
        let n = NaN
        let o = NaN
        let r = NaN
        let s = 0
        let a = 0
        let l = 0
        let c = 0

        for (n = 0; n < in1; ++n)
          (o = iclose.get(n)), (s += r = in1 - 1 - n + 1), (a += o), (l += r * r), (c += o * r)

        return (in1 * c - s * a) / (in1 * l - s * s)
      }

      const n = 20 /*this._input(0)*/
      const c = this.PineJS.Std.close(this._context)
      const vc = this._context.new_var(c)
      const v = this.PineJS.Std.linreg(vc, n, 0)
      const nv = this._context.new_var(v)

      const slope = linregSlope(vc, n, 0)
      const nslope = this._context.new_var(slope)

      const slope_ma = this.PineJS.Std.alma(nslope, 10, 0.8, 6)
      if (nslope.get(0) >= slope_ma) {
        return [v, NaN]
      } else {
        return [NaN, v]
      }
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: !0,
    isCustomIndicator: true,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 10,
          visible: !0,
          color: '#ffe800',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 10,
          visible: !0,
          color: '#ffe800',
        },
      },
      precision: 0,
      inputs: {
        /*in_0: 20*/
      },
    },
    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
        linestyle: 0,
        linewidth: 2,
        plottype: 2,
        trackPrice: !1,
        transparency: 10,
        visible: !0,
        color: '#ff5b5b',
      },
      plot_1: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
        linestyle: 0,
        linewidth: 2,
        plottype: 2,
        trackPrice: !1,
        transparency: 10,
        visible: !0,
        color: '#00e600',
      },
    },
    inputs: [
      /*{
                id: 'in_0',
                name: 'in_0',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 1000,
        }*/
    ],
  },
})
