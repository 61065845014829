import { MantineProvider } from '@mantine/core'
import { memo } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useSnapshot } from 'valtio'
import { store } from '../heineken_template/_private/store'
import IntradayTrendChart from '~/modules/trendChart'
import FuiButton from '../heineken_template/components/FuiButton'
import styleds from './styleds'
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple'
import { jerry597_stockIndicators, jerry597_strategyGroup } from './jerry597_initStrategies'
import { GiRadarSweep } from 'react-icons/gi'
import { MdAddchart } from 'react-icons/md'
import { useBig3ConsecutiveResource } from '~/modules/screener/containers/useStockScreenerResource'
import { staticStore } from '../heineken_template/_private/staticStore'
import { jerry597_store } from './jerry597_store'
import { SignalSymbolArray, useFirebaseValue } from '~/hooks/useFirebaseValue'
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'

export const Jerry597_SidePane1 = memo<ReactProps>(function Jerry597_SidePane1() {
  const colorTheme = useThemeStore(state => state.theme)
  const charting = useSnapshot(store.charting)
  const state = useSnapshot(jerry597_store)

  /** 自選股 */
  const watchListGroupString = 'jerry597_stock_group_1'
  /** Firebase老師自行輸入精選股 */
  const long = useFirebaseValue<SignalSymbolArray>('jerry597_signal', 'long')

  const lastTradeDate = useSnapshot(staticStore).tradedDate.day0

  const longSymbol = useBig3ConsecutiveResource({
    date: lastTradeDate.format('YYYY-MM-DD'),
    days: 3,
    action: 'buy',
    institution: 'foreigner',
    amount_gte: 100000000,
    volume_gte: 5000,
  })

  const shortSymbol = useBig3ConsecutiveResource({
    date: lastTradeDate.format('YYYY-MM-DD'),
    days: 5,
    action: 'sell',
    institution: 'foreigner',
    amount_gte: 100000000,
    volume_gte: 5000,
  })

  // console.log('Strategy DisplayName =>', store.charting.strategySelected.displayName)
  return (
    <MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar1>
        <styleds.IntradayTrendChart>
          <styleds.AreaTitle>
            <TitleSymbolQuote.Default symbol={charting.symbol} />
          </styleds.AreaTitle>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={14}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={14}
            priceTicksMargin={-15}
          />
        </styleds.IntradayTrendChart>
        <styleds.WatchlistButton>
          <div css={fill_vertical_all_center}>自選股狀態</div>
          <AddSymbolButtonSmall
            groupName={watchListGroupString}
            symbolNumber={charting.symbol}
            listSize={50}
          />
        </styleds.WatchlistButton>

        <styleds.StrategyArea>
          <styleds.AreaTitle>
            <MdAddchart
              size={'30px'}
              fill={'#ff5500'}
            />
            功能選擇
          </styleds.AreaTitle>
          <styleds.StrategyGroup>
            <styleds.SymbolTypeButton
              selected={state.pageMode === 'screener'}
              onClick={() => (jerry597_store.pageMode = 'screener')}
            >
              選股
            </styleds.SymbolTypeButton>
            <styleds.SymbolTypeButton
              selected={state.pageMode === 'pro'}
              onClick={() => (jerry597_store.pageMode = 'pro')}
            >
              精選
            </styleds.SymbolTypeButton>
            <styleds.SymbolTypeButton
              selected={state.pageMode === 'watchlist'}
              onClick={() => (jerry597_store.pageMode = 'watchlist')}
            >
              自選
            </styleds.SymbolTypeButton>
          </styleds.StrategyGroup>
        </styleds.StrategyArea>

        {state.pageMode === 'screener' && (
          <styleds.SymbolLsitArea>
            <styleds.AreaTitle>
              <GiRadarSweep
                size={'30px'}
                fill={'#ff5500'}
              />
              金旺選股
            </styleds.AreaTitle>
            <styleds.ScreenerTitle variant='long'>金旺多方選股</styleds.ScreenerTitle>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart
                data={longSymbol.data?.map(s => s.symbol)?.slice(0, 10) ?? []}
                chart={store.charting}
                indicators={jerry597_stockIndicators}
                handleClick={() => console.log('TODO: Handle clicked')}
              />
            </styleds.SymbolList>
            <styleds.ScreenerTitle variant='short'>金旺空方選股</styleds.ScreenerTitle>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart
                data={shortSymbol.data?.map(s => s.symbol)?.slice(0, 10) ?? []}
                chart={store.charting}
                indicators={jerry597_stockIndicators}
                handleClick={() => console.log('TODO: Handle clicked')}
              />
            </styleds.SymbolList>
          </styleds.SymbolLsitArea>
        )}
        {state.pageMode === 'pro' && (
          <styleds.SymbolLsitArea>
            <SimpleQuoteListInteractWithChart
              data={long?.symbols ?? []}
              chart={store.charting}
            />
          </styleds.SymbolLsitArea>
        )}
        {state.pageMode === 'watchlist' && (
          <styleds.SymbolLsitArea>
            <WatchListWithChart
              groupName={watchListGroupString}
              useChart={store.charting}
            />
          </styleds.SymbolLsitArea>
        )}
      </styleds.SideBar1>
    </MantineProvider>
  )
})
