import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar'

export const Rich888_Topbar = memo<ReactProps>(function Rich888_Topbar() {
  const { isPc } = useMedia()

  return (
    <div>
      {isPc && (
        <div
          css={css`
          ${flex.h.allCenter}
          justify-content: end;
          background-color: #e0e3ea;
          width: 100%
          height: 100%
         `}
        >
          <ChartingServerSwitch
            charting={store.charting}
            css={css`
            color: #f8f8f9
              background-color:#ffffff;
            `}
          />
          <UserAvatarAsDialogButton />
        </div>
      )}
      {!isPc && (
        <Preset_Topbar
          css={css`
            background-color: #e0e3ea;
          `}
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div css={fill_horizontal_all_center}>
              <ChartingServerSwitch
                charting={store.charting}
                css={css`
                  background-color: #ffffff;
                `}
              />
              <UserAvatarAsDialogButton />
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})
