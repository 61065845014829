import { memo } from 'react'
import Styleds from '~/pages/kabujukuTW/_private/styleds'

export const Kabujuku_Topbar = memo<ReactProps>(function Kabujuku_Topbar(props) {
  return (
    <Styleds.Topbar>
      <img src='kabujukuTW/logo.png' />
      <div>股票操盤是一門技術！</div>
    </Styleds.Topbar>
  )
})
