import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { TemplateProps } from '../heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { Gorich55688_Topbar } from './gorich55688_TopBar'
import { Gorich55688_SidePane1 } from './gorich55688_SidePane1'
import { gorich55688_initStrategies } from './gorich55688_initStrategies'
import { gorich55688_initStyling } from './gorich55688_initStyling'
import { useThemeStore } from '~/components/theme/useThemeStore'

export const gorich55688_init = (templateProps: TemplateProps) => {
  gorich55688_initStyling(templateProps)
  gorich55688_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TSEA',
    interval: '5',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: true,
    disabledPaneMenu: true,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderSaveload: true,
    disabledHeaderCompare: true,
    overrides: store.charting.darkOverrides,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100% - 72px) 24px;
    grid-template-columns: 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `

  // Init components
  templateProps.layout.Row1 = Gorich55688_Topbar
  templateProps.layout.Drawer1 = Gorich55688_SidePane1
}
