import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import AgentFavicon from '~/pages/heineken_template/components/AgentFavicon'
import { store } from '~/pages/heineken_template/_private/store'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'

export const winnerA_Topbar = memo<ReactProps>(function Topbar(props) {
  const { isPc } = useMedia()

  return (
    <div
      css={css`
        ${flex.h.allCenter};
        width: 100%;
        height: 48px;
        justify-content: space-between;
        background-color: #141721;
        border-bottom: 1px solid #555555;
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
        `}
      >
        {isPc && <AgentFavicon.Display />}
        {!isPc && (
          <store.drawerLeft.DrawerToggle
            css={css`
              height: 32px;
              width: 52px;
            `}
          />
        )}
      </div>

      <div
        css={css`
          ${flex.h.allCenter};
        `}
      >
        <ChartingServerSwitch charting={store.charting} />
        <UserAvatarAsDialogButton />
      </div>
    </div>
  )
})
