import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const st0 = createIndicator({
  id: 'st0',
  displayName: '趨勢撐壓(大週期)',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const f_0 = () => {
        const value1 = 60 /*this._input(0)*/
        const value2 = 3 /*this._input(1)*/

        const atr = this.PineJS.Std.atr(value1, this._context)

        const up = this.PineJS.Std.hl2(this._context) + atr * value2

        const dn = this.PineJS.Std.hl2(this._context) - atr * value2

        const close_array = this._context.new_var(this.PineJS.Std.close(this._context))

        const n = this._context.new_var()

        const tmax = this.PineJS.Std.max(dn, n.get(1))

        n.set(this.PineJS.Std.gt(close_array.get(1), n.get(1)) ? tmax : dn)

        const o = this._context.new_var()
        const tmin = this.PineJS.Std.min(up, o.get(1))
        o.set(this.PineJS.Std.lt(close_array.get(1), o.get(1)) ? tmin : up)

        const i = this._context.new_var()

        const nz = this.PineJS.Std.nz(i.get(1), 1)
        const lt = this.PineJS.Std.lt(this.PineJS.Std.close(this._context), n.get(1)) ? -1 : nz
        i.set(this.PineJS.Std.gt(this.PineJS.Std.close(this._context), o.get(1)) ? 1 : lt)

        return [
          this.PineJS.Std.eq(i.get(0), 1) ? n.get(0) : o.get(0),
          this.PineJS.Std.eq(i.get(0), 1) ? 0 : 1,
          1 === i.get(0) && -1 === i.get(1),
          -1 === i.get(0) && 1 === i.get(1),
        ]
      }

      const i = f_0()
      return [i[0], i[1], i[2], i[3]]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    scriptIdPart: '',
    is_price_study: !0,
    is_hidden_study: !1,
    isCustomIndicator: true,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 6,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#000080',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 6,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#000080',
        },
        plot_2: {
          linestyle: 0,
          linewidth: 4,
          plottype: 6,
          trackPrice: !1,
          transparency: 25,
          visible: !0,
          color: '#FF0000',
        },
        plot_3: {
          linestyle: 0,
          linewidth: 4,
          plottype: 6,
          trackPrice: !1,
          transparency: 25,
          visible: !0,
          color: '#00FF00',
        },
      },
      precision: 2,
      palettes: {
        palette_0: {
          colors: {
            0: {
              color: '#e20000',
              width: 2,
              style: 0,
            },
            1: {
              color: '#00ba00',
              width: 2,
              style: 0,
            },
          },
        },
      },
      inputs: {},
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer',
      },
      {
        id: 'plot_2',
        type: 'shapes',
      },
      {
        id: 'plot_3',
        type: 'shapes',
      },
    ],
    styles: {
      plot_0: {
        title: 'SuperTrend',
        histogramBase: 0,
        joinPoints: !1,
        isHidden: !1,
      },
      plot_2: {
        title: 'Up Arrow',
        histogramBase: 0,
        joinPoints: !1,
        isHidden: !1,
        size: 'small',
        location: 'BelowBar',
        plottype: 'shape_label_up',
      },
      plot_3: {
        title: 'Down Arrow',
        histogramBase: 0,
        joinPoints: !1,
        isHidden: !1,
        size: 'small',
        location: 'AboveBar',
        plottype: 'shape_label_down',
      },
    },
    palettes: {
      palette_0: {
        colors: {
          0: {
            name: 'Color 0',
          },
          1: {
            name: 'Color 1',
          },
        },
        valToIndex: {
          0: 0,
          1: 1,
        },
      },
    },
    inputs: [],
  },
})
