export interface CrocodilesParams {
  biasLength: number
  percentRLength: number
  rsiLength: number
  kdLength: number
}

export const crocodiles = (
  resolution: '1W' | '1D' | '15' | '5' | '1',
  bias: number,
  percentR: number,
  rsi: number,
  kValue: number,
): number =>
  resolution === '1W'
    ? (bias + percentR + rsi + kValue) / 3
    : resolution === '1D'
    ? (3 * bias + percentR + rsi + kValue) / 3
    : resolution === '5' || resolution === '1' || resolution === '15'
    ? (30 * bias + percentR + rsi + kValue) / 3
    : NaN
