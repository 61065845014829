import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { good178S_initCharting } from '~/pages/good178_stock/_private/good178S_initCharting'
import { good178S_initStyling } from '~/pages/good178_stock/_private/good178S_initStyling'
import { Good178S_Row2 } from '~/pages/good178_stock/_private/good178S_Row2'
import { Good178S_Sidebar1 } from '~/pages/good178_stock/_private/good178S_Sidebar1'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Good178S_TopBar } from './good178S_Topbar'

export const good178S_init = (templateProps: TemplateProps) => {
  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock')
  good178S_initStyling(templateProps)
  good178S_initCharting()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode(useThemeStore.getState().theme)

  templateProps.layout.Row1 = Good178S_TopBar
  templateProps.layout.Row2 = Good178S_Row2
  templateProps.layout.Drawer1 = Good178S_Sidebar1

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 72px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Row2 Row2'
      'Drawer1 Chart Chart';

    ${createIPadCss(css`
      grid-template-rows: 48px 72px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 72px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Chart Chart Chart';
    `)}
  `
}
