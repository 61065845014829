import { css } from '@emotion/react'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'

export const weng888Stock_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 32px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #2b2f45;
        border: 1px solid #555555;
        &:hover {
          background-color: #202a38;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #588a83;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #4ea49a;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `
}

store.charting.darkOverrides = {
  ...store.charting.darkOverrides,
  'mainSeriesProperties.candleStyle.borderDownColor': '#22f42a',
  'mainSeriesProperties.candleStyle.borderUpColor': '#ff2e26',
  'mainSeriesProperties.candleStyle.downColor': '#22f42a',
  'mainSeriesProperties.candleStyle.upColor': '#ff2e26',
  'mainSeriesProperties.candleStyle.wickDownColor': '#cccccc',
  'mainSeriesProperties.candleStyle.wickUpColor': '#cccccc',
  'mainSeriesProperties.candleStyle.drawBorder': false,
  'paneProperties.topMargin': 10,
  'paneProperties.bottomMargin': 10,
  'timeScale.rightOffset': 5,
  'paneProperties.vertGridProperties.color': '#111111',
  'paneProperties.horzGridProperties.color': '#111111',
  'mainSeriesProperties.showPriceLine': false,
}
