import { intersection, isString, sortBy, filter } from 'lodash'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'

export const sortSymbolByValue = (
  // value: Partial<{
  //   [symbolString: string]: Signalr.ValueOfOHLC
  // }>,
  value: any,
  symbolNumber: Signalr.SymbolString[],
  sortType: keyof Signalr.ValueOfOHLC,
) => {
  const data = intersection(
    sortBy(value, datum => datum?.[sortType])
      .reverse()
      .map(datum => datum?.symbol)
      .filter(isString) as Signalr.SymbolString[],
    symbolNumber,
  )
  return data
}

export const filterSymbolByValue = (
  value: Partial<{
    [symbolString: string]: Signalr.ValueOfOHLC
  }>,
  symbolNumber: Signalr.SymbolString[],
  sortType: keyof Signalr.ValueOfOHLC,
  filterValue: number,
) => {
  const data = intersection(
    filter(value, datum => (Number(datum?.[sortType]) ?? 0) > filterValue)
      .map(datum => datum?.symbol)
      .filter(isString) as Signalr.SymbolString[],
    symbolNumber,
  )
  return data
}

export const sortAndFilterSymbolByValue = (
  value: Partial<{
    [symbolString: string]: Signalr.ValueOfOHLC
  }>,
  symbolNumber: Signalr.SymbolString[],
  type: keyof Signalr.ValueOfOHLC,
  filterValue: number,
) => {
  const dataFilter = intersection(
    filter(value, datum => (Number(datum?.[type]) ?? 0) > filterValue),
  )
  const data = intersection(
    sortBy(dataFilter, datum => datum?.[type])
      .reverse()
      .map(datum => datum?.symbol)
      .filter(isString) as Signalr.SymbolString[],
    symbolNumber,
  )
  return data
}
