import { memo } from 'react'
import { css } from '@emotion/react'
import { south_strategies } from './south_strategies'
import { Select } from '@mantine/core'
import { south_store } from './south_store'
import { useSnapshot } from 'valtio'

export const South_StrategySelector = memo<
  ReactProps<{
    onChanged?: (strategyId: string) => void
  }>
>(function South_StrategySelector(props) {
  const store = useSnapshot(south_store)

  console.log(store.selectedStrategy)
  return (
    <Select
      className='south-strategy-selector'
      css={css`
        width: 100%;
        height: 32px;
      `}
      // value={south_strategies[0].id} // Default selected value
      defaultValue={south_strategies[0].id}
      data={south_strategies.map(strategy => {
        return { value: strategy.id, label: `${strategy.id} ${strategy.description}` }
      })}
      onChange={id => {
        props.onChanged?.(String(id))
      }}
    ></Select>
  )
})
