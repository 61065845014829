import { AnyFunction } from 'tsdef'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store'

export const stock_ichi2 = createIndicator({
  id: 'stockichi2',
  displayName: '主力線',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const donchian = (e: any) => {
        const t = this._context.new_var(this.PineJS.Std.low(this._context))
        const i = this._context.new_var(this.PineJS.Std.high(this._context))
        const o = this.PineJS.Std.avg(
          this.PineJS.Std.lowest(t, e, this._context),
          this.PineJS.Std.highest(i, e, this._context),
        )
        return o
      }

      const f_1 = () => {
        const e = 7 /*this._input(0)*/
        const t = 29 /*this._input(1)*/
        const i = 47 /*this._input(2)*/
        const o = 13 /*this._input(3)*/
        const r = donchian(e)
        const s = donchian(t)
        const a = this.PineJS.Std.avg(r, s)
        const l = donchian(i)
        const c = this.PineJS.Std.close(this._context)
        const u = this.PineJS.Std.gt(a, l) ? 0 : 1
        return [r, s, c, a, l, -o, o, o, u]
      }

      const i = f_1()

      const interval = this._context.symbol.period
      const symbolName = this._context.symbol.ticker

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const itime = this.PineJS.Std.time(this._context)
      const rsv = this.PineJS.Std.stoch(close, high, low, 20, this._context).toFixed(0)
      const nrsv = this._context.new_var(rsv - 50)
      nrsv.get(30)

      if (!isNaN(rsv)) {
        good178S_store.trend = [
          nrsv.get(0),
          nrsv.get(1),
          nrsv.get(2),
          nrsv.get(3),
          nrsv.get(4),
          nrsv.get(5),
          nrsv.get(6),
          nrsv.get(7),
          nrsv.get(8),
          nrsv.get(9),
        ].reverse()
      } else good178S_store.trend = []

      return [i[1]]
    },
  },
  metainfo: {
    visible: true,
    _metainfoVersion: 42,
    isCustomIndicator: true,
    is_price_study: !0,
    is_hidden_study: !0,
    scriptIdPart: '',
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 3,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#0496ff',
        },
      },
      inputs: {
        /*in_0: 9,
              in_1: 26,
              in_2: 52,
              in_3: 26*/
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '主力線',
        histogramBase: 0,
        joinPoints: !1,
        isHidden: !1,
      },
    },
    palettes: {},
    filledAreas: [],
    inputs: [],
  },
})
