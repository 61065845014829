import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 成交量 */
export const m168168_volume = createIndicator({
  displayName: '成交量',
  id: 'm168168-volume',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const vol = this.PineJS.Std.volume(this._context)
      const display_volume = vol > 10e7 ? vol / 10e7 : vol
      const vol_array = this._context.new_var(display_volume)

      const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1

      const avg_ma1 = this.PineJS.Std.sma(vol_array, 5, this._context)
      const avg_ma2 = this.PineJS.Std.sma(vol_array, 10, this._context)
      const avg_ma3 = this.PineJS.Std.sma(vol_array, 20, this._context)
      const avg_ma4 = this.PineJS.Std.sma(vol_array, 60, this._context)

      return [display_volume, colorIndex, avg_ma1, avg_ma2, avg_ma3, avg_ma4]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
      {
        id: 'ma1',
        type: 'line',
      },
      {
        id: 'ma2',
        type: 'line',
      },
      {
        id: 'ma3',
        type: 'line',
      },
      {
        id: 'ma4',
        type: 'line',
      },
    ],

    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
        ma1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#ffff00',
        },
        ma2: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#00ffff',
        },
        ma3: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#ff00ff',
        },
        ma4: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !1,
          color: '#00ffaa',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#ff0000',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00ff00',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },

    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
    precision: 0,
  },
})
