import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { proxy } from 'valtio'
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { FuiUserAvatar } from '../heineken_template/components/FuiUserAvatar'
import { store } from '../heineken_template/_private/store'
import { PionexLogoutView } from './component/LogoutView'

export const pionex_store = proxy({ userAvatar: new FuiUserAvatar() })
export const Pionex_Topbar = memo<ReactProps>(function Pionex_Topbar() {
  return (
    <styleds.container>
      <store.drawerLeft.DrawerToggle css={styleds.drawerCss} />
      <img
        css={css`
          width: 100px;
          height: 20px;
        `}
        src='pionex/pionex_icon.png'
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        `}
      >
        <ChartingServerSwitch
          charting={store.charting}
          css={css`
            background-color: #ffffff;
          `}
        />
        <pionex_store.userAvatar.AvatarModal
          cssset={css`
            width: 280px;
            height: 360px;
            transition: 0.3s;
          `}
        >
          <PionexLogoutView />
        </pionex_store.userAvatar.AvatarModal>
      </div>
      <store.drawerRight.DrawerToggle css={styleds.drawerCss} />
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    background-color: #f9fafb;
    padding: 0px 12px;
    border-bottom: 1px solid #e0e4ea;
  `,
  drawerCss: css`
    height: 36px;
    width: 36;
    color: #ff7028;
  `,
}
