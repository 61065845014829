import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { yaya168Stock_store } from '~/pages/yaya168_stock/yaya168Stock_store'

/** RSI */
export const yaya168_main_power = createIndicator({
  id: 'yaya168-main-power',
  displayName: '大戶鎖單',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#bs_indicator_acc'

      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      const i = context.new_unlimited_var(this._context.symbol.time)

      this._context.select_sym(1)
      const time1 = this._context.new_unlimited_var(this._context.symbol.time)
      const high = this.PineJS.Std.high(this._context)
      const unlimited1 = this._context.new_unlimited_var(high)

      const chip = unlimited1.adopt(time1, i, 0) // <=這是籌碼

      //主圖商品
      this._context.select_sym(0)
      this.PineJS.Std.period(this._context)

      this.f_0 = function (e: number) {
        return this.PineJS.Std.max(e, 0)
      }

      this.f_1 = function (e: number) {
        return -this.PineJS.Std.min(e, 0)
      }

      this.f_2 = function (e: number, t: number) {
        return this.PineJS.Std.eq(e, 0)
          ? 100
          : this.PineJS.Std.eq(t, 0)
          ? 0
          : 100 - 100 / (1 + t / e)
      }

      const close = this.PineJS.Std.close(this._context)
      const volume = this.PineJS.Std.volume(this._context)
      const length = 5
      const overSold = 25

      // Param0: 5,
      // Param1: 25,

      const close_array = this._context.new_var(close)
      const change = this.PineJS.Std.change(close_array)
      const up = this.f_0(change)
      const up_array = this._context.new_var(up)
      const up_ram = this.PineJS.Std.rma(up_array, length, this._context)
      const dn = this.f_1(change)
      const dn_array = this._context.new_var(dn)
      const dn_rma = this.PineJS.Std.rma(dn_array, length, this._context)

      const rsi = this.f_2(dn_rma, up_ram)
      const rsi_array = this._context.new_var(rsi)
      const rsi_ma = this.PineJS.Std.sma(rsi_array, 3, this._context)

      const chip_array = this._context.new_var(chip)
      const chip_ma = this.PineJS.Std.sma(chip_array, 20, this._context)

      //經過變形的RSI 在超賣時<35 看起來是法人在買的樣子
      const new_rsi = this._context.new_var()
      //佈局的數值 使用絕對值(收盤 - 均線)
      const ema = this.PineJS.Std.ema(close_array, 3, this._context)
      const price = this.PineJS.Std.hlc3(this._context)
      const value = Math.abs(price - ema)

      if (rsi_ma < overSold) {
        new_rsi.set(value)
      } else new_rsi.set(NaN)

      rsi_array.get(2)
      yaya168Stock_store.bisa = rsi_ma
      yaya168Stock_store.volume = volume
      yaya168Stock_store.bsValue = chip
      yaya168Stock_store.bsMaValue = chip_ma

      return [new_rsi.get(0), 0, value * 1.2]
    },
  },

  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'plot0', type: 'line' },
      { id: 'plot1', type: 'line' },
      { id: 'plot2', type: 'line' },
    ],

    defaults: {
      styles: {
        plot0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#dd5e56',
        },
        plot1: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: false,
          transparency: 10,
          visible: true,
          color: '#aaaaaa',
        },
        plot2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 100,
          visible: true,
          color: '#151720',
        },
      },
      precision: 2,

      // Set input param default values
      inputs: {},
    },

    styles: {
      plot0: {
        title: '佈局力道',
        histogramBase: 0,
        joinPoints: false,
      },
      plot1: {
        title: '0軸',
        histogramBase: 0,
        joinPoints: false,
      },
      plot2: {
        title: '座標',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [],
  },
})
