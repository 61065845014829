import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { volume } from '~/trades/indicators/lung88988/volume'
import { mvp888_bandTrade } from '~/trades/indicators/mvp888/mvp888_bandTrade'
import { mvp888_chip } from '~/trades/indicators/mvp888/mvp888_chip'
import { mvp888_dayTrade } from '~/trades/indicators/mvp888/mvp888_dayTrade'
import { mvp888_power } from '~/trades/indicators/mvp888/mvp888_power'
import { mvp888_tx_daytrade } from '~/trades/indicators/mvp888/mvp888_tx1_daytrade'

import { mvp888Stock_agentStore } from './mvp888Stock_store'

export const mvp888Stock_strategiesGroup = {
  main: [
    {
      displayName: '波段',
      indicators: [mvp888_bandTrade, mvp888_chip, volume],
      interval: '1D',
    },
    {
      displayName: '當沖',
      indicators: [mvp888_dayTrade, mvp888_power, volume],
      interval: '5',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const mvp888Stock_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[volume, mvp888_power, mvp888_chip, mvp888_bandTrade, mvp888_dayTrade, mvp888_tx_daytrade],
  ]
  store.charting.initStrategy({
    configs: [...mvp888Stock_strategiesGroup.main],
  })
  store.charting.changeSymbol('TSEA')
}

export const returnIndicators = (state: typeof mvp888Stock_agentStore.strategyType) => {
  if (state === 'bandtrade') {
    return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0]
  } else if (state === 'daytrade') {
    return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[1]
  }
}

export const returnInterval = (state: typeof mvp888Stock_agentStore.strategyType) => {
  if (state === 'bandtrade') {
    return '1D'
  } else return '5'
}

export const returnWatchIndicators = (
  state: typeof mvp888Stock_agentStore.watchListStrategyType,
) => {
  if (state === 'bandtrade') {
    return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0]
  } else if (state === 'daytrade') {
    return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[1]
  }
}
