import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { TrafficLight } from '../morerich/modules/Trafficlight'
import { Styleds } from './styleds'

const symbols = ['TSEA', 'TX-1', 'TXAM-1']

export const S178178_SidePane1 = memo<ReactProps>(function S178178_SidePane1() {
  const charting = useSnapshot(store.charting)
  const currentSymbol = charting.symbol

  return (
    <Styleds.Sidebar>
      <TrafficLight titleName='測試' />
      <TitleSymbolQuote.Default symbol={currentSymbol} />
      <Styleds.TrendChartContainer>
        <IntradayTrendChart
          symbol={currentSymbol}
          ticksSize={12}
          ticksHeight={20}
          priceTicksMargin={-15}
          priceTicksSize={11}
        />
      </Styleds.TrendChartContainer>
      <InformationForm symbol={currentSymbol} />
      <Styleds.SymbolList>
        <SimpleQuoteListInteractWithChart
          data={symbols}
          chart={store.charting}
        />
      </Styleds.SymbolList>
    </Styleds.Sidebar>
  )
})
