import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { AnyFunction } from 'tsdef'
import { LiteralUnion } from 'type-fest'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import SimpleTrendChart from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/SimpleTrendChart'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { store } from '~/pages/heineken_template/_private/store'

import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { fadeInTransformY } from './styleds'

const exampleSymbol = [
  'FDX-1',
  'FDXM-1',
  'FGBS-1',
  'FGBM-1',
  'FGBL-1',
  'FGBX-1',
  'FOAT-1',
  'STXE-1',
  'FSXE-1',
  'FMCH-1',
  'FSTB-1',
  'FVS-1',
  'FXXP-1',
]

export const eurex_SidePaneMenu = memo<ReactProps>(function eurex_SidePaneMenu() {
  return (
    <styleds.Sidebar>
      <styleds.Title>觀察清單</styleds.Title>
      {exampleSymbol.map((symbol, index) => {
        return (
          <SymbolSimpleTrendChartCard
            key={symbol}
            css={css`
              animation: ${fadeInTransformY} ${index / 5}s;
            `}
            symbol={symbol}
            showSymbolNumber={false}
            interval='hour'
            timeCount={12}
            dataInterval='5'
            onClick={() => {
              store.charting.changeSymbol(symbol)
              store.charting.updateFromState()
            }}
          />
        )
      })}
    </styleds.Sidebar>
  )
})

const styleds = {
  Sidebar: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    gap: 4px;
    padding: 4px 4px;
    ${scrollbar2Css}
  `,
  Title: styled.div`
    ${fill_vertical_all_center};
    width: 100%;
    height: 32px;
    margin-bottom: 4px;
    border-radius: 5px;
    box-shadow: 0 0 8px 1px #d1d1d1;
  `,
}

/** 新版報價磚與國內傳統不太一樣 役歐交所 */
export const SymbolSimpleTrendChartCard = memo<
  ReactProps<{
    symbol: Signalr.SymbolString
    showSymbolNumber?: boolean
    /** 時間單位, 可以顯示近期的 `週、日、小時` 圖表區間 */
    interval?: 'day' | 'hour' | 'week'
    /** 要顯示的數量, 5即是 `5週|5天|5小時` 根據所選的時間單位 */
    timeCount?: number
    /** 拿到報價週期預設 */
    dataInterval?: LiteralUnion<'1D' | '1H', string>
    /** 外部給予圖表顏色 */
    fillColor?: string
    onClick?: AnyFunction
  }>
>(function SymbolSimpleTrendChartCard(props) {
  const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]
  const quoteChanges = processQuoteToReadable(quote as Signalr.ValueOfOHLC)

  const close = quote?.close ?? 0
  const open = quote?.prevRef ?? 0
  const color = close >= open ? '#dd2d33' : '#22aa22'

  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [props.symbol])

  /** 主題 */
  const theme = useThemeStore(s => s.theme)
  const backgroundFill = theme === 'dark' ? '#252525' : '#f1f1f1'
  const hoverFill = theme === 'dark' ? '#252525' : '#f1f1f1'
  const shadowFill = theme === 'dark' ? '#252525' : '#e1e1e1'
  const stringFill = theme === 'dark' ? '#f1f1f1' : '#252525'

  return (
    <div
      css={css`
        ${fill_vertical_all_center}
        width: 100%;
        height: 56px;
        box-shadow: 0 0 8px 1px ${shadowFill};
        border-radius: 5px;
        padding: 2px;
        cursor: pointer;
        background-color: ${backgroundFill};
        &:hover {
          background-color: ${hoverFill};
          transform: scale(1.025);
          transition: 0.3s;
        }
      `}
      onClick={event => {
        props.onClick?.()
      }}
      className={props.className}
    >
      <div
        css={css`
          ${fill_horizontal_all_center};
          width: 100%;
          height: 22px;
          font-size: 14px;
        `}
      >
        <div
          css={css`
            ${fill_horizontal_cross_center};
            width: 50%;
            color: ${stringFill};
            display: inline;
            white-space: nowrap;
            font-size: 13px;
          `}
        >
          {props.showSymbolNumber !== false && props.symbol}
          {dictionary[props.symbol ?? '']?.slice(0, 8)}
        </div>
        <div
          css={css`
            ${fill_horizontal_all_center};
            ${jc.spaceAround};
            width: 50%;
            color: ${color};
          `}
        >
          <span>{quoteChanges.closeChange}</span>
          <span>{quoteChanges.closeChangePercent}%</span>
        </div>
      </div>
      <div
        css={css`
          ${fill_horizontal_all_center}
          height: 28px;
          font-size: 14px;
        `}
      >
        <div
          css={css`
            ${fill_horizontal_all_center}
            width: 30%;
            color: ${color};
          `}
        >
          {quote?.close}
        </div>
        <div
          css={css`
            ${fill_horizontal_all_center}
            width: 70%;
          `}
        >
          <SimpleTrendChart
            symbol={props.symbol}
            interval={props.interval}
            timeCount={props.timeCount}
            dataInterval={props.dataInterval}
            fillColor={color}
          />
        </div>
      </div>
    </div>
  )
})
