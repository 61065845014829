import { css } from '@emotion/react'
import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import SymbolPlatformBase from '~/modules/symbolPlatform/base/SymbolPlatformBase'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useThemeStore } from '~/components/theme/useThemeStore'

const symbol = ['TX-1', '2330']
const watchListGroup = 'fubon_group_1'

export const Fubon_SidePane = memo<ReactProps>(function Fubon_SidePane() {
  const charting = store.charting

  const theme = useThemeStore(t => t.theme)
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        padding: 4px;
        gap: 4px;
      `}
    >
      <div
        className={theme}
        css={css`
          width: 100%;
          height: 30px;
          padding: 4px;

          border-radius: 4px;

          &.dark {
            background-color: #202026;
          }
          &.light {
            background-color: #fafafa;
          }
        `}
      >
        <TitleSymbolQuote.Default symbol={charting.symbol} />
      </div>

      <div
        className={theme}
        css={css`
          width: 100%;
          height: 164px;
          padding: 4px;
          border-radius: 4px;
          &.dark {
            background-color: #202026;
          }
          &.light {
            background-color: #fafafa;
          }
        `}
      >
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 208px);
        `}
      >
        <SymbolPlatformBase.Display
          symbol={symbol}
          watchListGroup={watchListGroup}
          listTypeSwitch={false}
        />
      </div>
    </div>
  )
})
