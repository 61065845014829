import { memo, useEffect } from 'react'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { css } from '@emotion/react'
import { fill, fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new'
import { useThemeStore } from '~/components/theme/useThemeStore'
import styled from '@emotion/styled'

/** 給整數座標 */
const maxTicks = (value: number) => {
  //數字單位  ex: 30678  //5位數
  const valueLength = value.toString().length - 1
  //取數值最大的整數單位 ex: 30678  //3
  const integer = (value / Math.pow(10, valueLength)) % 10
  //單位轉換  ex: 3 * 10000
  const quantile = Math.pow(10, valueLength)
  //轉換座標最大值 ex: 30678 => 45000
  const result = Math.round(integer) * quantile * 1.5

  //console.log('max', valueLength, integer, quantile, result)

  return result
}

/** 成交量單位 */
const tick = (value: number) => {
  if (value >= 1e5 && value < 1e8) {
    return (value / 1e4).toFixed(0) + '萬'
  } else if (value >= 1e8) {
    return (value / 1e8).toFixed(0) + '億'
  } else return value.toFixed(0)
}
const SymbolRankingHorizontalBar = memo<
  ReactProps<{
    symbols: string[]
    type: 'volume' | 'changePercent'
    nameLabelWidth?: number
    fontSize?: number
    barSize?: number
  }>
>(function SymbolRankingHorizontalBar(props) {
  const symbols = props.symbols

  const theme = useThemeStore(s => s.theme)

  /** 文字與座標顏色 */
  const defaultFill = theme === 'light' ? '#555555' : '#dddddd'
  const tooltipCursor = theme === 'light' ? '#eeeeeeaa' : '#555555aa'

  useEffect(() => {
    signalrStore2.addQuote(symbols)
    return () => {
      signalrStore2.removeQuote(symbols)
    }
  }, [JSON.stringify(symbols)])

  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  const value = signalrHooks2.useQuotes(symbols)

  const rowData = () => {
    return value.map(s => {
      const quoteChanges = processQuoteToReadable(s as Signalr.ValueOfOHLC)
      const volume = s.volume
      const changePercent = quoteChanges.closeChangePercent ?? 0

      return {
        symbol: s.symbol,
        name: dictionary[s.symbol],
        changePercent: changePercent,
        volume: volume,
      }
    })
  }

  const volumeType = props.type === 'volume'
  const nameLabelWidth = props.nameLabelWidth ?? 80
  const fontSize = props.fontSize ?? 13
  const barSize = props.barSize ?? 20

  const data = volumeType
    ? rowData().sort((a, b) => b.volume - a.volume)
    : rowData().sort((a, b) => b.changePercent - a.changePercent)

  const barDataType = volumeType ? 'volume' : 'changePercent'

  //根據不同模式 調整最大值 最大成交量、最大漲跌幅
  const max = volumeType
    ? Math.max(...data.map(s => s.volume))
    : Math.max(...data.map(s => Math.abs(s.changePercent) * 2))

  //根據不同模式 調整資料區間 `成交量: 0 ~ 10萬` `漲跌幅: -最大幅度 ~ 最大幅度`
  const domainType = volumeType ? [0, max] : [-max, max]
  //根據不同模式 調整座標 `成交量: 0 ~ 10萬` `漲跌幅: -最大幅度 ~ 最大幅度`
  const ticksType = volumeType ? [0, maxTicks(max) / 2, maxTicks(max)] : [-max, 0, max]

  return (
    <div css={fill}>
      {!data || (data && data.length === 0) ? (
        <div css={fill_vertical_all_center}>資料載入中</div>
      ) : (
        <ResponsiveContainer
          width='100%'
          height='100%'
        >
          <BarChart
            data={data}
            layout='vertical'
            margin={{ top: 0, right: 40, left: 40, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray='0.5 1'
              horizontal={false}
              stroke={defaultFill}
            />
            <XAxis
              type='number'
              orientation='bottom'
              fontSize={fontSize}
              stroke={defaultFill}
              axisType='angleAxis'
              domain={domainType}
              ticks={ticksType}
              interval={0}
              tickFormatter={ticks => (volumeType ? tick(ticks) : ticks + '%')}
            />
            <YAxis
              dataKey='name'
              type='category'
              orientation={'left'}
              width={nameLabelWidth}
              fontSize={fontSize}
              stroke={defaultFill}
              dx={-5}
              interval={0}
              tickLine={false}
              axisLine={false}
              //tickFormatter={tickFormatter}
            />
            <Tooltip
              content={<CustomizedTooltip />}
              cursor={{ fill: tooltipCursor }}
            />
            <Bar
              dataKey={barDataType}
              fill='#3b83fa'
              barSize={barSize}
              radius={1}
            >
              {data.map((datum, index) => (
                <Cell
                  key={index}
                  fill={
                    props.type === 'changePercent'
                      ? datum.changePercent >= 0
                        ? classesFill.horizontalBarUpFill
                        : classesFill.horizontalBarDnFill
                      : classesFill.horizontalBarDefaultFill
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
})

//客製化的游標資料浮動視窗
const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
  if (!payload || (payload && payload.length < 1)) return null

  const datum = payload[0].payload
  const name = datum.name
  const number = datum.symbol
  const changePercent = datum.changePercent
  const volume = datum.volume

  return (
    <ToolTip
      name={name}
      number={number}
      volume={volume}
      changePercent={changePercent}
    />
  )
}

export const ToolTip = memo<
  ReactProps<{
    name: string
    number: number
    volume: number
    changePercent: number
  }>
>(function ToolTip(props) {
  const theme = useThemeStore(s => s.theme)

  //這邊比較不會客製化 先寫死 日後有需要再改就好
  const changeColor = props.changePercent >= 0 ? '#d32f2f' : '#4caf50'

  return (
    <classes.container className={theme}>
      <div>{props.name}</div>
      <div> {props.number}</div>
      <classes.volume className={theme}>成交量(Volume): {tick(props.volume)}</classes.volume>
      <div
        css={css`
          color: ${changeColor};
        `}
      >
        漲跌幅(Change): {props.changePercent}%
      </div>
    </classes.container>
  )
})

const classes = {
  container: styled.div`
    padding: 8px;
    opacity: 0.9;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    &.dark {
      border: 1px solid #888888;
      background-color: #252525;
    }
    &.light {
      border: 1px solid #b4b4b4;
      background-color: #fafafa;
    }
  `,
  name: styled.div``,
  number: styled.div``,
  volume: styled.div`
    &.dark {
      color: #00ffff;
    }
    &.light {
      color: #3b83fa;
    }
  `,
  change: styled.div``,
}

const classesFill = {
  horizontalBarDefaultFill: '#3b83fa',
  horizontalBarUpFill: '#d32f2f',
  horizontalBarDnFill: '#4caf50',
}

export default {
  Display: SymbolRankingHorizontalBar,
  classes,
  classesFill,
}
