import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Symbols,
} from 'recharts-new'
import { useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage'

export const VolumeTable = memo<ReactProps<{ data: any[]; barFill1: string; barFill2: string }>>(
  function VolumeTable(props) {
    const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
      if (!payload || (payload && payload.length < 1)) return null
      const datum = payload[0].payload
      const title = datum.name
      const amp = datum.value
      const volume = datum.value1

      return (
        <TooltipStyled.Container>
          <div>{title}</div>
          <div
            css={css`
              color: ${props.barFill1};
            `}
          >
            振幅(amplitude):{amp.toFixed(0)}
          </div>
          <div
            css={css`
              color: ${props.barFill2};
            `}
          >
            成交量(Volume):{volume.toFixed(0)}
          </div>
        </TooltipStyled.Container>
      )
    }

    const CustomizedLegend = ({ payload }: { payload?: any[] }) => {
      const circleCss = css`
        width: 8px;
        height: 8px;
        border-radius: 50px;
      `
      return (
        <LegendStyled.Container>
          <div>
            <LegendStyled.LegendItem barFill={props.barFill1} />
            振幅
          </div>
          <div>
            <LegendStyled.LegendItem barFill={props.barFill2} />
            成交量
          </div>
        </LegendStyled.Container>
      )
    }
    return (
      <div
        css={css`
          width: 100%;
          height: 200px;
          padding: 4px;
        `}
      >
        <ResponsiveContainer
          width='100%'
          height='100%'
        >
          <ComposedChart
            data={props.data}
            margin={{
              top: 10,
              right: -30,
              left: -15,
              bottom: 0,
            }}
            barSize={24}
          >
            <XAxis
              dataKey='name'
              scale='point'
              padding={{ left: 24, right: 24 }}
              axisLine={true}
              fontSize={12}
            />
            <YAxis
              width={60}
              yAxisId='left'
              fontSize={12}
              domain={[0, 'auto']}
            >
              <Label
                value={'Amplitude'}
                angle={-90}
                position='outside'
                fill='#676767'
                fontSize={10}
              />
            </YAxis>
            <YAxis
              width={88}
              yAxisId='right'
              orientation='right'
              fontSize={12}
              domain={[0, 'auto']}
            >
              <Label
                value={'Volume'}
                angle={-90}
                position='outside'
                fill='#676767'
                fontSize={10}
              />
            </YAxis>
            <CartesianGrid strokeDasharray='1 1' />
            <Bar
              yAxisId='left'
              dataKey='value'
              fill={props.barFill1}
              barSize={15}
            />
            <Bar
              yAxisId='right'
              dataKey='value1'
              fill={props.barFill2}
              barSize={15}
            />
            <Tooltip content={<CustomizedTooltip />} />
            <Legend
              width={320}
              content={<CustomizedLegend />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  },
)

const TooltipStyled = {
  Container: styled.div`
    padding: 8px;
    background-color: #fafafa;
    opacity: 0.9;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
  `,
}

const LegendStyled = {
  Container: styled.div`
    ${fill_horizontal_all_center};
    & > * {
      ${fill_horizontal_all_center};
      width: 20%;
    }
    font-size: 12px;
    line-height: 20px;
  `,
  LegendItem: styled.div<{ barFill: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: ${options => options.barFill};
  `,
}
