import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fontWeight600 } from '~/css/font'
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '~/pages/heineken_template/_private/store'
import ChartingStrategy from '~/modules/SDK/chart4/components/ChartingStrategy'
import { ChartingStrategyButtonMenu } from '~/modules/SDK/chart4/components/ChartingStrategyButtonMenu'
import winnerA_AttackLineToggle from '~/pages/winnerA/_private/winnerA_AttackLineToggle'
import { IndicatorsValueForm } from '../modules/indicatorsValueForm'
import { resetIndicatorsValue } from '../modules/indicatorsValueStore'
import { scrollbar2Css } from '~/css/scrollbarCss'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'

export const winnerA_Sidebar = memo(function Sidebar(props) {
  const symbolName = useSnapshot(store.charting).symbol

  return (
    <div css={sideBarCss}>
      <div css={titleBarCss}>{symbolName}商品報價</div>
      <TitleSymbolQuote.Default symbol={symbolName} />
      <div css={trendChartCss}>
        <IntradayTrendChart
          symbol={symbolName}
          ticksSize={12}
          ticksHeight={20}
          priceTicksMargin={-10}
          priceTicksSize={11}
        />
      </div>
      <div css={infoFormCss}>
        <IndicatorsValueForm symbol={symbolName} />
      </div>
      <div css={titleBarCss}>策略選擇</div>

      <div
        css={css`
          ${fill_vertical_cross_center};
          ${scrollbar2Css}
          height: calc(100% - 448px);
          padding: 0px 4px;
          gap: 4px;
        `}
      >
        <ChartingStrategy.Button
          charting={store.charting}
          strategy={store.charting.strategyConfigs[0]}
          buttonProps={{
            onClick: () => {
              resetIndicatorsValue()
            },
          }}
        />
        {/* 老師要求「攻擊線」要放在策略集合的中間 */}
        <winnerA_AttackLineToggle.Button />
        {store.charting.strategyConfigs.map((strategy, index) => {
          if (index < 1) {
            return
          }
          return (
            <ChartingStrategy.Button
              key={strategy.displayName}
              charting={store.charting}
              strategy={strategy}
              buttonProps={{
                onClick: () => {
                  resetIndicatorsValue()
                },
              }}
            />
          )
        })}
      </div>
    </div>
  )
})

const sideBarCss = css`
  ${fill_vertical_cross_center};
  background-color: #141721;
  border-right: 1px solid #666666;
  gap: 4px;
`

const trendChartCss = css`
  height: 180px;
  width: 100%;
`

const infoFormCss = css`
  height: 150px;
  width: 100%;
`

const titleBarCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 35px;
  color: #eeeeee;
  font-size: 20px;
  ${fontWeight600};
  background: linear-gradient(45deg, #1f2433, #323952);
`
