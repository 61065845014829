import styled from '@emotion/styled'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

export const styleds = {
  selecterBoard: styled.div`
    ${fill_horizontal_cross_center};
    height: 64px;
    gap: 4px;
    padding: 4px;
  `,
  col: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    padding: 0px 4px;
  `,
  selecterContract: styled.div`
    width: 150px;
    height: 64px;
  `,
  selecterDateTime: styled.div`
    width: 180px;
    height: 64px;
  `,
  sidePane: styled.div`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 8px;
    padding: 4px;
  `,
  strategyContent: styled.div`
    ${fill_horizontal_all_center};
    width: 248px;
    height: 26px;
  `,
}
