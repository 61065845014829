import { css } from '@emotion/react'
import { memo } from 'react'
import { ThemeToggle } from '~/components/theme/ThemeToggle'
import { useThemeStore } from '~/components/theme/useThemeStore'
import {
  fill,
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '~/pages/heineken_template/_private/store'
import useMedia from '~/hooks/useMedia'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'

export const Good178S_TopBar = memo<ReactProps>(function Good178_TopBar() {
  const theme = useThemeStore(s => s.theme)
  const { isPc } = useMedia()
  const topbarColor = theme === 'dark' ? '#141721' : '#dadada'

  return (
    <div
      css={css`
        ${fill};
      `}
    >
      {isPc && (
        <div
          css={css`
            ${fill_horizontal_cross_center};
            justify-content: end;
            background-color: ${topbarColor};
            border-bottom: 1px solid #999999;
          `}
        >
          <ThemeToggle
            onClick={event => {
              store.charting.setThemeMode(useThemeStore.getState().theme)
              store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators)
              store.charting.updateFromThemeMode()
            }}
          />
          <ChartingServerSwitch charting={store.charting} />
          <UserAvatarAsDialogButton />
        </div>
      )}
      {!isPc && (
        <Preset_Topbar
          css={css`
            background-color: ${topbarColor};
          `}
          componentsInRight={
            <div css={fill_horizontal_all_center}>
              <ThemeToggle
                onClick={event => {
                  store.charting.setThemeMode(useThemeStore.getState().theme)
                  store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators)
                  store.charting.updateFromThemeMode()
                }}
              />
              <ChartingServerSwitch charting={store.charting} />
              <UserAvatarAsDialogButton />
            </div>
          }
          componentsInLeft={
            <store.drawerLeft.DrawerToggle
              css={css`
                color: #73c0f8;
              `}
            />
          }
        />
      )}
    </div>
  )
})
