import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore'

const sounds = useSoundStore.getState()
let exit_sound_flag_ = 0

export const daytrade_default = createIndicator({
  id: 'daytradedefault',
  displayName: '豐收1號',
  constructorScope: {
    init(context, inputCallback) {
      localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString())
    },
    main(context, inputCallback) {
      let triggerTradeAction = false
      let triggerExitPrice = 0

      const state = useIndicatorStore.getState()
      const high = this.PineJS.Std.high(this._context)
      const high_array = this._context.new_var(high)
      const daily_high = this._context.new_var()
      const low = this.PineJS.Std.low(this._context)
      const low_array = this._context.new_var(low)
      const daily_low = this._context.new_var()
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const itime = this.PineJS.Std.time(this._context)
      const itime_array = this._context.new_var(itime)
      const start_h = new Date(itime).getHours()
      const start_m = new Date(itime).getMinutes()

      const dayExitPosition = this._context.new_var()

      const pc_high = this._context.new_var()
      const pc_low = this._context.new_var()

      const bState = this._context.new_var()
      const bsentry = this._context.new_var()
      const bDt = this._context.new_var()
      const entryTime = this._context.new_var()
      const entryHigh = this._context.new_var()
      const entryLow = this._context.new_var()
      const bExitSound = this._context.new_var()

      let pv0 = NaN
      let pv1 = NaN
      const pvflag = this._context.new_var()

      const entryPrice = this._context.new_var()
      const arwPrice = this._context.new_var()

      let pb1 = NaN
      let pb2 = NaN
      let pbx = NaN
      let ps1 = NaN
      let ps2 = NaN
      let psx = NaN

      //0845 重新計算開盤區間---------------------
      if (start_h === 8 && start_m === 45) {
        daily_high.set(high)
        daily_low.set(low)
        bsentry.set(0)
        bState.set(0)
        dayExitPosition.set(0)
      }

      //0900 停止計算開盤區間---------------------
      if (start_h === 9 && start_m === 0) {
        pc_high.set(daily_high * 1)
        pc_low.set(daily_low * 1)
      }

      //1530 停止計算夜盤區間---------------------
      if (start_h === 15 && start_m === 30) {
        pc_high.set(daily_high * 1)
        pc_low.set(daily_low * 1)
        bsentry.set(0)
      }

      if (high > daily_high) {
        daily_high.set(high)
      }

      if (low < daily_low) {
        daily_low.set(low)
      }

      if (start_h === 8) {
        pc_high.set(NaN)
        pc_low.set(NaN)
      }

      bState.get(1)
      bState.get(2)
      entryPrice.get(1)
      bExitSound.get(1)
      bExitSound.get(2)
      close_array.get(1)
      low_array.get(1)
      high_array.get(1)
      entryHigh.get(1)
      entryLow.get(1)
      itime_array.get(5)
      entryTime.get(5)
      dayExitPosition.get(1)

      /** 多空參數控制 */
      const setPosition = this._input(0)

      const input_sar_start = this._input(9)

      const sar_value_buy = this.PineJS.Std.sar(0.003, 0.001, 0.019, this._context)
      const sar_value_array_buy = this._context.new_var(sar_value_buy)
      const sar_value_short = this.PineJS.Std.sar(0.02, 0.001, 0.001, this._context)
      const sar_value_array_short = this._context.new_var(sar_value_short)
      sar_value_array_buy.get(1)
      sar_value_array_short.get(1)

      //日盤出手限制次數=4----------------------------
      /** 部位確立 */
      if (start_h < 13 || start_h > 15) {
        if (setPosition !== -1 && close > pc_high && close > sar_value_buy && bsentry.get(0) < 2) {
          bState.set(1)
          bDt.set(itime)
          arwPrice.set(close)
        } else if (
          setPosition !== 1 &&
          close < pc_low &&
          close < sar_value_short &&
          bsentry.get(0) < 2
        ) {
          bState.set(-1)
          bDt.set(itime)
          arwPrice.set(itime)
        }
      }

      //Entry High Entry Low-------------------------------------------------------------------
      if (bState.get(0) === 0) {
        entryHigh.set(0)
        entryLow.set(0)
      }
      if (bState.get(0) === 1 && high > entryHigh) {
        entryHigh.set(high)
      }
      if (bState.get(0) === -1 && low < entryLow) {
        entryLow.set(low)
      }

      //如果15:30突破 要出現進場訊號 前一根K棒bState必須=0,強制歸0
      //否則第300行的bState會持續set 1 or -1
      //造成15:30無訊號
      if (start_h === 15 && start_m === 29) {
        bState.set(0)
      }

      //部位進場與成立訊號-------------------------------------------------------------------
      if (bState.get(0) === 1 && bState.get(1) !== 1 && bsentry.get(0) < 2) {
        pv0 = 1
        pv1 = NaN
        pvflag.set(0)
        dayExitPosition.set(1)
        bExitSound.set(0)
        bsentry.set(bsentry + 1)
        entryHigh.set(high)
        entryPrice.set(close)
        entryTime.set(itime)
        triggerTradeAction = true
        triggerExitPrice = close
      } else if (bState.get(0) === -1 && bState.get(1) !== -1 && bsentry.get(0) < 2) {
        pv0 = NaN
        pv1 = 1
        pvflag.set(0)
        dayExitPosition.set(-1)
        bExitSound.set(0)
        bsentry.set(bsentry + 1)
        entryLow.set(low)
        entryPrice.set(close)
        entryTime.set(itime)
        triggerTradeAction = true
        triggerExitPrice = close
      }
      //多方出場-------------------------------------------------------------------
      //時間出場
      if (bState.get(0) === 1 && bState.get(1) === 1) {
        //時間出場
        if (start_h === 13 && start_m === 30) {
          bState.set(0)
          pb2 = 1
          triggerTradeAction = true
          triggerExitPrice = close
        }
        //停損價
        if (low < entryPrice - 40) {
          bState.set(0)
          pbx = 1
          triggerTradeAction = true
          triggerExitPrice = entryPrice - 40
        }
        //固定停利
        if (pvflag.get(0) === 0 && high > entryPrice + 60) {
          pvflag.set(1)
          pb1 = 1
        }
        //sar停利
        if (low < sar_value_buy && low > entryPrice.get(0)) {
          pb2 = 1
          bState.set(0)
          dayExitPosition.set(0)
          triggerTradeAction = true
          triggerExitPrice = sar_value_array_buy.get(1)
        }
        //sar停損 不顯示
        if (low < sar_value_buy) {
          bState.set(0)
          triggerTradeAction = true
          triggerExitPrice = sar_value_array_buy.get(1)
        }
      }

      //空方出場-------------------------------------------------------------------
      //時間出場
      if (bState.get(0) === -1 && bState.get(1) === -1) {
        if (start_h === 13 && start_m === 30) {
          bState.set(0)
          ps2 = 1
          triggerTradeAction = true
          triggerExitPrice = close
        }
        //停損價
        if (high > entryPrice + 50) {
          bState.set(0)
          psx = 1
          triggerTradeAction = true
          triggerExitPrice = entryPrice + 50
        }
        //固定停利
        if (pvflag.get(0) === 0 && low < entryPrice - 60) {
          pvflag.set(1)
          ps1 = 1
        }
        //sar停利
        if (high > sar_value_short && high < entryPrice.get(0)) {
          ps2 = 1
          dayExitPosition.set(0)
          bState.set(0)
          triggerTradeAction = true
          triggerExitPrice = sar_value_array_short.get(1)
        }
        //sar停損 不顯示
        if (high > sar_value_short) {
          bState.set(0)
          triggerTradeAction = true
          triggerExitPrice = sar_value_array_short.get(1)
        }
      }

      //強制在尾盤出現出場(大星星)訊號
      if (dayExitPosition.get(0) === 1 && start_h === 13 && start_m === 30) {
        pb2 = 1
      }
      if (dayExitPosition.get(0) === -1 && start_h === 13 && start_m === 30) {
        ps2 = 1
      }

      //對翻-------------------------------------------------------------------
      if (bState.get(0) === 1 && bState.get(1) === -1) {
        triggerTradeAction = true
        triggerExitPrice = close
        entryPrice.set(close)
        bDt.set(itime)
      }
      if (bState.get(0) === -1 && bState.get(1) === 1) {
        triggerTradeAction = true
        triggerExitPrice = close
        entryPrice.set(close)
        bDt.set(itime)
      }

      //聲音------------------------------------------------------------------
      const displayTime = new Date(itime).toLocaleTimeString('en-US')
      const kbarLoadingTimeMs = 10000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs
      const new_bar = this._context.symbol.isNewBar

      /** 進場部位聲音 */
      if (isInitialized && new_bar) {
        if (bState.get(1) === 1 && bState.get(2) !== 1 && entryTime.get(0) === itime_array.get(1)) {
          sounds.playSoundWin168LongDebounced()
          console.log('✅', displayTime, '進多單')
        } else if (
          bState.get(1) === -1 &&
          bState.get(2) !== -1 &&
          entryTime.get(0) === itime_array.get(1)
        ) {
          sounds.playSoundWin168ShortDebounced()
          console.log('✅', displayTime, '進空單')
        }
      }

      //出場部位聲音
      if (isInitialized && new_bar) {
        exit_sound_flag_ = 0
      }

      const long_exit_condition =
        (pb1 === 1 && exit_sound_flag_ !== 1) || (pb2 === 1 && exit_sound_flag_ !== 1)

      const short_exit_condition =
        (ps1 === 1 && exit_sound_flag_ !== 1) || (ps2 === 1 && exit_sound_flag_ !== 1)

      if (isInitialized) {
        if (long_exit_condition) {
          sounds.playSoundWin168ExitDebounced()
          exit_sound_flag_ = 1
          console.log('🚨', displayTime, '當沖多單停利或出場訊號')
        }
        if (short_exit_condition) {
          sounds.playSoundWin168ExitDebounced()
          exit_sound_flag_ = 1
          console.log('🚨', displayTime, '當沖空單停利或出場訊號')
        }
      }

      return [pv0, pv1, pb1, pb2, ps1, ps2]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_2: {
          color: '#ff0000',
          textColor: '#ff0000',
          transparency: 0,
          visible: true,
        },
        plot_3: {
          color: '#00ff00',
          textColor: '#00ff00',
          transparency: 0,
          visible: true,
        },
        plot_4: {
          color: '#ff2222',
          textColor: '#ff2222',
          transparency: 50,
          visible: true,
        },
        plot_5: {
          color: '#ff2222',
          textColor: '#ff2222',
          transparency: 0,
          visible: true,
        },
        plot_6: {
          color: '#538234',
          textColor: '#538234',
          transparency: 0,
          visible: true,
        },
        plot_7: {
          color: '#00ff00',
          textColor: '#00ff00',
          transparency: 0,
          visible: true,
        },
      },
      inputs: { in_0: 0 },
    },
    plots: [
      {
        id: 'plot_2',
        type: 'chars',
      },
      {
        id: 'plot_3',
        type: 'chars',
      },
      {
        id: 'plot_4',
        type: 'chars',
      },
      {
        id: 'plot_5',
        type: 'chars',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: 'plot_7',
        type: 'chars',
      },
    ],
    styles: {
      plot_2: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: 'B',
        title: '多單參考訊號',
      },
      plot_3: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: 'S',
        title: '空單參考訊號',
      },
      plot_4: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '多單參考停利(短)',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'normal',
        title: '多單參考停利',
      },
      plot_6: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '空單參考停利(短)',
      },
      plot_7: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'normal',
        title: '空單參考停利',
      },
    },
    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '部位選擇:多[1] 空[-1] 多空[0]',
        defval: 0,
        type: 'integer',
        min: -1,
        max: 1,
      },
    ],
    scriptIdPart: '',
  },
})
