import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { big3_store } from '~/pages/big3/_private/big3_store'

export const big3_analyzer = createIndicator({
  displayName: '分析儀',
  id: 'big3-analyzer',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const k = this.analyzer(18, 6, 6)[0]
      const d = this.analyzer(18, 6, 6)[1]

      const colorIndex = k - d > 0 ? 0 : 1

      if (k - d > 0 && d < 80) {
        big3_store.analyzerTrend = 1
      } else if (k - d < 0 && d > 20) {
        big3_store.analyzerTrend = -1
      } else big3_store.analyzerTrend = 0

      return [k, d, colorIndex]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'colorer',
        target: 'filledAreaId1',
        palette: 'paletteId1',
      },
    ],

    filledAreas: [
      {
        id: 'filledAreaId1',
        objAId: 'plot_1',
        objBId: 'plot_0',
        type: 'plot_plot',
        title: '趨勢指標',
        palette: 'paletteId1',
      },
    ],

    palettes: {
      paletteId1: {
        valToIndex: {
          0: 0,
          1: 1,
        },
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#b31dad',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#00b2b3',
        },
      },
      //填充區域
      filledAreasStyle: {
        filledAreaId1: {
          color: '#000000',
          transparency: 10,
          visible: !0,
        },
      },
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#b31dad',
              width: 1,
              style: 0,
            },
            1: {
              color: '#00b2b3',
              width: 1,
              style: 1,
            },
          },
        },
      },
    },

    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})
