import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Fubon_Footer } from './fubon_Footer'
import { fubon_initStrategies } from './fubon_initStrategies'
import { fubon_initStyling } from './fubon_initStyling'
import { Fubon_SidePane } from './fubon_SidePane'
import { Fubon_TopBar } from './fubon_TopBar'

export const fubon_init = (templateProps: TemplateProps) => {
  fubon_initStyling(templateProps)
  fubon_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode(useThemeStore.getState().theme)

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  // store.charting.widgetOptions = {
  //   ...store.charting.widgetOptions,
  //   custom_css_url: '../../big3/chartingTheme.css',
  // }

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TX-1',
    interval: '5',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderCompare: true,
    disabledGoToDate: true,
    disabledHeaderSaveload: true,
    overrides: {
      ...store.charting.darkOverrides,
    },
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100vh - 48px) 256px;
    grid-template-columns: 336px 1fr 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px calc(100vh - 48px) 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 1fr 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `

  templateProps.layout.Row1 = Fubon_TopBar
  templateProps.layout.Drawer1 = Fubon_SidePane
  templateProps.layout.Row2 = Fubon_Footer
  //templateProps.layout.Drawer2 = Fubon_SidePane
}
